import {Component} from '@angular/core';

@Component({
	selector: 'app-footer', // not used
	templateUrl: './app-footer.html',
	styleUrls: [
		'./app-footer.less'
	]
})
export class ComponentAppFooter {
	//
	public constructor() {
		//
	}
}
