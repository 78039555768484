import {Component, Input} from '@angular/core';
// ===== Components ===== //
import {ComponentInput} from '../input';
//
@Component({
	selector: 'labeled-input',
	templateUrl: './labeled-input.html',
	styleUrls: [
		'./labeled-input.less'
	]
})
export class ComponentLabeledInput extends ComponentInput {
	@Input()
	public label: string = '';
	//
	public constructor() {
		super();
	}
}
