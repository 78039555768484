<app-header *ngIf="accountToken.length > 0"></app-header>
<div id="page-complete-account" class="page" *ngIf="accountToken.length > 0">
	<div class="content-wrapper">
		<div class="heading">Complete Your Registration</div>
		<div *ngIf="accountCompleted">
			<div class="sub-heading">Your registration has been completed.</div>
			<div class="do-what-next">
				<div>Please <a [routerLink]="[ '/' + routes.signIn ]">Sign-In</a> to continue.</div>
			</div>
		</div>
		<div *ngIf="accountAlreadyCompleted">
			<div class="sub-heading">Looks like your registration has been previously completed. The form submission has not been saved.</div>
			<div class="do-what-next">
				<div>Please <a [routerLink]="[ '/' + routes.signIn ]">Sign-In</a>.</div>
			</div>
		</div>
		<div *ngIf="invalidToken">
			<div class="sub-heading">The verification token is no longer valid.</div>
			<div class="do-what-next">
				<div>You may need to <a [routerLink]="[ '/' + routes.signIn ]">Sign Up</a> to receive a token.</div>
				<div>Or you can try to <a [routerLink]="[ '/' + routes.signIn ]">reset your password</a> or <a [routerLink]="[ '/' + routes.signIn ]">Sign-In</a>.</div>
			</div>
		</div>
		<div *ngIf="formShown">
			<div class="sub-heading">Tell us who you are.</div>
			<div class="form-wrapper">
				<label class="half half-gap">
					<input type="text" autocomplete="off" placeholder="First Name"
						[class.error]="completeAccountErrors.firstName"
						[(ngModel)]="completeAccountData.firstName"
						[ngModelOptions]="{ updateOn: 'blur' }"
						(ngModelChange)="validateData( 'firstName' );"
						(focus)="clearError( 'firstName' );"
					/>
					<span class="frame-label"></span>
				</label>

				<label class="half">
					<input type="text" autocomplete="off" placeholder="Last Name"
						[class.error]="completeAccountErrors.lastName"
						[(ngModel)]="completeAccountData.lastName"
						[ngModelOptions]="{ updateOn: 'blur' }"
						(ngModelChange)="validateData( 'lastName' );"
						(focus)="clearError( 'lastName' );"
					/>
					<span class="frame-label"><i class="fa-solid fa-user-astronaut"></i></span>
				</label>

				<label>
					<input type="password" autocomplete="off" placeholder="Password"
						[class.error]="completeAccountErrors.password1"
						[(ngModel)]="completeAccountData.password1"
						[ngModelOptions]="{ updateOn: 'blur' }"
						(ngModelChange)="comparePasswords();"
						(focus)="clearError( 'password1' );"
					/>
					<span class="frame-label"><i class="fa-solid fa-lock"></i></span>
				</label>

				<label>
					<input type="password" autocomplete="off" placeholder="Retype Password"
						[class.error]="completeAccountErrors.password2"
						[(ngModel)]="completeAccountData.password2"
						[ngModelOptions]="{ updateOn: 'blur' }"
						(ngModelChange)="comparePasswords();"
						(focus)="clearError( 'password2' );"
					/>
					<span class="frame-label"><i class="fa-solid fa-lock"></i></span>
				</label>

				<hr />

				<label class="wide">
					<input type="text" autocomplete="off" placeholder="Street Address"
						[class.error]="completeAccountErrors.street"
						[(ngModel)]="completeAccountData.street"
						[ngModelOptions]="{ updateOn: 'blur' }"
						(ngModelChange)="validateData( 'street' );"
					/>
					<span class="frame-label"></span>
				</label>

				<label class="half half-gap">
					<input type="text" autocomplete="off" placeholder="Unit"
						[class.error]="completeAccountErrors.unit"
						[(ngModel)]="completeAccountData.unit"
						[ngModelOptions]="{ updateOn: 'blur' }"
						(ngModelChange)="validateData( 'unit' );"
					/>
					<span class="frame-label"></span>
				</label>

				<label class="half">
					<input type="text" autocomplete="off" placeholder="City"
						[class.error]="completeAccountErrors.city"
						[(ngModel)]="completeAccountData.city"
						[ngModelOptions]="{ updateOn: 'blur' }"
						(ngModelChange)="validateData( 'city' );"
					/>
					<span class="frame-label"></span>
				</label>

				<label class="half half-gap">
					<input type="text" autocomplete="off" placeholder="State"
						[class.error]="completeAccountErrors.state"
						[(ngModel)]="completeAccountData.state"
						[ngModelOptions]="{ updateOn: 'blur' }"
						(ngModelChange)="validateData( 'state' );"
					/>
					<span class="frame-label"></span>
				</label>

				<label class="half">
					<input type="text" autocomplete="off" placeholder="Zip Code"
						[class.error]="completeAccountErrors.zip"
						[(ngModel)]="completeAccountData.zip"
						[ngModelOptions]="{ updateOn: 'blur' }"
						(ngModelChange)="validateData( 'zip' );"
					/>
					<span class="frame-label"></span>
				</label>

				<label class="half half-gap dob">
					<input type="date" autocomplete="off" placeholder="Date of Birth"
						[class.error]="completeAccountErrors.dob"
						[(ngModel)]="completeAccountData.dob"
						[ngModelOptions]="{ updateOn: 'blur' }"
						(ngModelChange)="validateData( 'dob' )"
					/>
					<span class="frame-label"></span>
					<b class="dob">DOB</b>
				</label>

				<label class="half">
					<input type="text" autocomplete="off" placeholder="Phone"
						[class.error]="completeAccountErrors.phone"
						[(ngModel)]="completeAccountData.phone"
						[ngModelOptions]="{ updateOn: 'blur' }"
						(ngModelChange)="validateData( 'phone' )"
					/>
					<span class="frame-label"></span>
				</label>

				<hr />
			</div>

			<div class="actions">
				<span class="button onwards" (click)="completeRegistration();">Continue</span>
			</div>
		</div>
	</div>
</div>
