<app-header></app-header>
<h1>Group Tickets</h1><!-- needs to use <header> -->
<div><p></p></div>
<div class="page wrapper">
	<div class="group-details-form">
		<div class="card">
			<div class="organization-details form">
				<h3>Organization Details</h3>
				<label
					[ngClass]="{
						'grid-org-name': true,
						'error' : groupDetailsErrors.organization.name
					}
				">
					<span class="frame-label">Organization Name</span>
					<input type="text" autocomplete="off"
						[(ngModel)]="groupDetailsData.organization.name"
						[ngModelOptions]="{ updateOn: 'blur' }"
						(ngModelChange)="validateOrganizationFields( 'name' )"
					/>
				</label>
				<label
					[ngClass]="{
						'grid-addr1': true,
						'error': groupDetailsErrors.organization.address.street1
					}"
				>
					<span class="frame-label">Address 1</span>
					<input type="text" autocomplete="off"
						[(ngModel)]="groupDetailsData.organization.address.street1"
						[ngModelOptions]="{ updateOn: 'blur' }"
						(ngModelChange)="validateOrganizationFields( 'address', 'street1' )"
					/>
				</label>
				<label class="grid-addr2">
					<span class="frame-label">Address 2</span>
					<input type="text" autocomplete="off"
						[(ngModel)]="groupDetailsData.organization.address.street2"
						[ngModelOptions]="{ updateOn: 'blur' }"
					/>
				</label>
				<label
					[ngClass]="{
						'grid-city': true,
						'error': groupDetailsErrors.organization.address.city
					}"
				>
					<span class="frame-label">City</span>
					<input type="text" autocomplete="off"
						[(ngModel)]="groupDetailsData.organization.address.city"
						[ngModelOptions]="{ updateOn: 'blur' }"
						(ngModelChange)="validateOrganizationFields( 'address', 'city' )"
					/>
				</label>
				<label
					[ngClass]="{
						'grid-state': true,
						'error': groupDetailsErrors.organization.address.state
					}"
				>
					<span class="frame-label">State</span>
					<input type="text" autocomplete="off"
						[(ngModel)]="groupDetailsData.organization.address.state"
						[ngModelOptions]="{ updateOn: 'blur' }"
						(ngModelChange)="validateOrganizationFields( 'address', 'state' )"
					/>
				</label>
				<label
					[ngClass]="{
						'grid-zip': true,
						'error': groupDetailsErrors.organization.address.zip
					}"
				>
					<span class="frame-label">Zip</span>
					<input type="text" autocomplete="off"
						[(ngModel)]="groupDetailsData.organization.address.zip"
						[ngModelOptions]="{ updateOn: 'blur' }"
						(ngModelChange)="validateOrganizationFields( 'address', 'zip' )"
					/>
				</label>
			</div>
		</div>
		<div class="card">
			<div class="primary-contact-details form">
				<h3>Primary Contact</h3>
				<label
					[ngClass]="{
						'grid-first-name': true,
						'error': groupDetailsErrors.organization.primaryContact.firstName
					}"
				>
					<span class="frame-label">First Name</span>
					<input type="text" autocomplete="off"
						[(ngModel)]="groupDetailsData.organization.primaryContact.firstName"
						[ngModelOptions]="{ updateOn: 'blur' }"
						(ngModelChange)="validateOrganizationFields( 'primaryContact', 'firstName' )"
					/>
				</label>
				<label
					[ngClass]="{
						'grid-last-name': true,
						'error': groupDetailsErrors.organization.primaryContact.lastName
					}"
				>
					<span class="frame-label">Last Name</span>
					<input type="text" autocomplete="off"
						[(ngModel)]="groupDetailsData.organization.primaryContact.lastName"
						[ngModelOptions]="{ updateOn: 'blur' }"
						(ngModelChange)="validateOrganizationFields( 'primaryContact', 'lastName' )"
					/>
				</label>
				<label
					[ngClass]="{
						'grid-email': true,
						'error': groupDetailsErrors.organization.primaryContact.email
					}"
				>
					<span class="frame-label">Email</span>
					<input type="text" autocomplete="off"
						[(ngModel)]="groupDetailsData.organization.primaryContact.email"
						[ngModelOptions]="{ updateOn: 'blur' }"
						(ngModelChange)="validateOrganizationFields( 'primaryContact', 'email' )"
					/>
				</label>
				<label
					[ngClass]="{
						'grid-phone': true,
						'error': groupDetailsErrors.organization.primaryContact.phone
					}"
				>
					<span class="frame-label">Phone</span>
					<input type="text" autocomplete="off"
						[(ngModel)]="groupDetailsData.organization.primaryContact.phone"
						[ngModelOptions]="{ updateOn: 'blur' }"
						(ngModelChange)="validateOrganizationFields( 'primaryContact', 'phone' )"
					/>
				</label>
			</div>
		</div>
		<div class="actions"></div>
	</div>
</div>
