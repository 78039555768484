import {Pipe, PipeTransform} from '@angular/core';
// ===== Interfaces ===== //
import {InterfaceOWDoclet} from '../interfaces/interfaces';
// ===== Transformers ===== //
import {TransformerVenuePassportTicket} from '../transformers/vpTicket';
//
@Pipe({
	name: 'ticketAdmissionType',
	pure: true
})
export class PipeTicketAdmissionType implements PipeTransform {
	// ticket | ticketAdmissionType // 'Junior'
	public transform( ticket: InterfaceOWDoclet ): string {
		return TransformerVenuePassportTicket.getAdmissionTicketTypeDisplay( ticket );
	}
}
