import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
// ===== App ===== //
import {AppConfig} from '../../app.config';
import {AppEvents} from '../../app.events';
import {AppRouterLinks} from '../../app.router-links';
// ===== Collections ===== //
import {CollectionProfiles} from '../../collections/profiles';
// ===== Interfaces ===== //
import {
	InterfaceAnyObject,
	InterfaceAppContext,
	InterfaceAppEvent,
	InterfaceHTTPGateway,
	InterfaceNavMenuItem,
	InterfaceOWAPICardVault,
	InterfaceOWAPICardVaultResponse,
	InterfaceOWAPICreateWeaveResponse,
	InterfaceOWAPIGetDocletResponse,
	InterfaceOWAPIGetDocletsResponse,
	InterfaceOWAPIGetWeavesResponse,
	InterfaceOWAPIResponse,
	InterfaceOWAPISeasonPassAdmissionAvailabilityResponse,
	InterfaceOWDoclet,
	InterfaceOWSeasonPassAdmissionAvailability,
	InterfaceOWTemplateConsumer,
	InterfaceOWTemplateParkVisit,
	InterfaceOWUser,
	InterfaceOWWeaveV2
} from '../../interfaces/interfaces';
// ===== Services ===== //
import {ServiceAuthentication} from '../../services/authentication';
import {ServiceNavigate} from '../../services/navigate';
import {ServiceOWAPI} from '../../services/ow-api';
import {ServiceRegex} from '../../services/regex';
import {ServiceSorting} from '../../services/sorting';
// ===== Transformers ===== //
interface InterfacePrimaryAccountUser {
	consumerDocletID: string;
	firstName: string;
	lastName: string;
	photo: string;
	cashlessSpending: boolean;
}
interface InterfaceFamilyWeavesDataMembers {
	member: InterfaceOWDoclet;
	ticket: InterfaceOWDoclet;
}
interface InterfaceFamilyWeavesData {
	familyDoclet: InterfaceOWDoclet | null;
	familyDocletID: string | null;
	members: InterfaceFamilyWeavesDataMembers[]; // { member: ..., ticket: ... }
}
interface InterfaceFamilyWeavesFlags {
	familyDoclet: boolean;
	totalMembers: number;
	membersAcquired: number;
	loaded: boolean; // once all doclets and weaves are fetched and cached/loaded/etc.
}
interface InterfaceGroupReservation {
	parkVisitID: string;
	status: 'active' | 'canceled' | 'pending'; // i don't think 'pending' is a real value for Park Visits.
	date: {
		year: number;
		month1: number; // months are 1-based. 1 - 12.
		day: number;
	};
	strDateYYYYMMDD1: string; // month is 1-based. 01 - 12. used for the calendar logic to show off you booked 'that' day.
	memberIDs: string[];
	parkingID: string | undefined;
	cabanaIDs: string[];
}
interface InterfaceGroupReservationData {
	placeholder: boolean;
	memberID: string;
	member?: InterfaceOWDoclet;
	ticket?: InterfaceOWDoclet;
}
interface InterfaceOrderWithTickets {
	orderID: string;
	ticketIDs: string[];
	parkingIDs: string[];
	cabanaIDs: string[];
}
interface InterfacePastVisit { // common data between past SPH reservations and orders from the POS/Portal.
	date: {
		year: number;
		month1: number; // 1 - 12
		day: number;
	};
	memberIDs: string[];
	parkingIDs: string[];
	cabanaIDs: string[];
}
interface InterfacePurchasedTicketsForVisit {
	consumerWithTicket: {
		consumer: InterfaceOWDoclet;
		ticket: InterfaceOWDoclet;
	}[];
	parking: InterfaceOWDoclet[];
	cabanas: InterfaceOWDoclet[];
}
interface InterfacePurchasedTicketsByVisitDate {
	[YYYYMMDD1: string]: InterfacePurchasedTicketsForVisit;// YYYY-MM-DD where MM is 01 through 12.
}
interface InterfaceWovenOrderIDs {
	orderID: string;
	consumerIDWithTicketID: {
		consumerID: string;
		ticketID: string;
	}[];
	parkingIDs: string[];
	cabanaIDs: string[];
}
interface InterfaceWovenOrderDocletsConsumerWithTicket {
	consumer: InterfaceOWDoclet;
	ticket: InterfaceOWDoclet;
}
interface InterfaceWovenOrderDoclets {
	order: InterfaceOWDoclet,
	consumerWithTicket: InterfaceWovenOrderDocletsConsumerWithTicket[];
	parking: InterfaceOWDoclet[];
	cabanas: InterfaceOWDoclet[];
}
// ===== Transformers ===== //
import {TransformerVenuePassportTicket} from '../../transformers/vpTicket';
// ===== Types ===== //
type TypeReservationFormStep = 1 | 2;
type TypeJSMonthIdx =  0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
type TypeParkVisitPanel = 'visit-overview' | 'cancel-reservation';
//
const now: Date = new Date();
const parkOpensOn2024Jan1st: Date = new Date( 2024, 0, 1, 0, 0, 0, 0 ); // July is the 7th month, so 6 is used.
if ( now.getTime() < parkOpensOn2024Jan1st.getTime() ) {
	now.setTime( parkOpensOn2024Jan1st.getTime() );
}
const intNowYYYYMMDD1: number = now.getFullYear() * 10000 + (now.getMonth() + 1) * 100 + now.getDate();
const dateParkClosesOn2024Oct1st: Date = new Date( 2024, 9, 1, 0, 0, 0, 0 );
//
@Component( {
	selector: 'page-dashboard',
	templateUrl: './dashboard.html',
	styleUrls: [
		'./dashboard.less'
	]
} )
export class PageDashboard implements OnDestroy, OnInit {
	// TODO: disable step 2's confirm button, unless somebody is picked.
	// TODO: instead of "reached limit", make it say "Already going", except when you reached the 5 day limit, etc.
	// TODO: do NOT allow users to cancel their reservation, when it is scheduled for tomorrow.
	private readonly realmIdPortal: string = this.appConfig.getRealmID();
	//
	@ViewChild( 'cancellationReason' )
	private cancellationReason: ElementRef | undefined;
	//
	public isParkeClosed: boolean = new Date().getTime() > dateParkClosesOn2024Oct1st.getTime();
	public seasonPassYear: string = '2024';
	public reachedMaxReservations: boolean = false;
	public NumBurr: NumberConstructor = Number; // can't use Number in html. can't do public Number = Number; bah.
	public routes: typeof AppRouterLinks = AppRouterLinks;
	private subProfilesUpdated: Subscription | null = null;
	private subUserReSync: Subscription | null = null;
	//
	public mastHeading: string = 'Hello'; // 'Hello, firstname'
	public navMenuItems: InterfaceNavMenuItem[] = [
		{
			route: '/' + this.routes.dashboard,
			text: 'Dashboard'
		},
		{
			route: '/' + this.routes.family,
			text: 'Manage Family',
			shortText: 'Family',
			locked: true
		},/*
		{
			route: '/' + this.routes.editGroup,
			text: 'Manage Group',
			shortText: 'Group',
			locked: false
		},*/
		{
			route: '/' + this.routes.orders,
			text: 'Orders & Billing',
			shortText: 'Billing'
		}
	];
	//
	public haveUserInfo: boolean = false;
	public primaryAccountHolder: InterfacePrimaryAccountUser = {
		consumerDocletID: '',
		firstName: '',
		lastName: '',
		photo: '',
		cashlessSpending: false
	};
	private subCardReSync: Subscription | null = null;
	private subAccountReSync: Subscription | null = null;
	public accountDoclet: InterfaceOWDoclet | undefined = undefined;
	private cardsOnFile: InterfaceOWAPICardVault[] = [];
	public haveAccountInfo: boolean = false;
	private readonly strAccountTemplateID: string = this.appConfig.getTemplateID( 'Account' );
	//
	public monthLabels: string[] = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
	//
	public memberIDsAlreadyReserved: {
		[memberID: string]: true;  // if you create a reservation, but don't pick everybody, you can go through again and pick the day again. this is a list of those who CANNOT go.
	} = {};
	public capacity: InterfaceOWSeasonPassAdmissionAvailability = {}; // the keys will be "YYYY-MM-DD". the value will be an array of family member IDs.
	public reservationForm: {
		active: boolean;
		step: TypeReservationFormStep;
		cache: {
			strYY_MM1: string; // target date's YYYY-MM. months are 1-based. so 01 - 12. linked to server-side responses where the keys are like "2022-12-01" for Dec 1st 2022.
			strYYYYMM0: string; // target date's YYYYMM. months are 0-based. so 00 - 11. used on the HTML side to go with all other 0-based logic.
			intYYYYMMDD0: number; // initialized once. months are 0-based. pre-calculations for the calendar logic. MM is 00-11, DD is 01-31. only used for the current date.
			datesUsed1: { // each reserved day's YYYYMMDD (present onwards) will appear in this. used for calendar logic to block out the days you already have.
				[YYYYMMDD1: string]: true; // the month is 1-based, so 01 to 12. comes from server-side.
			};
		};
		flags: {
			noGoingBack: boolean; // true when you're on the current month or less. false when viewing future months.
			noGoingForwards: boolean; // true when you hit september. not allowed to go past Sept 25th, for now.
			weekendsInUse: boolean; // true when the user already has too many reserved days on the weekends. (max of 2 weekends. max of 5 total reservations)
			capacityLoaded: boolean; // true when the network request for dailyCapacity finishes.
		};
		calendar: {
			month0: TypeJSMonthIdx; // target month. 0 - 11.
			year: number; // target year.
		};
		selected: number; // 0 through 30'ish
		dates: {
			weeks: undefined[]; // an amount to loop over. unused.
			first: Date | undefined;
			last: Date | undefined;
			offset: undefined[]; // an amount to loop over.
			days: undefined[]; // an amount to loop over.
			remainder: undefined[]; // an amount to loop over.
		};
		members: {
			[id: string]: boolean;
		}
	} = {
		active: false,
		step: 1,
		cache: {
			strYY_MM1: now.getFullYear() + '-' + String('0' + (now.getMonth() + 1)).slice( -2 ),
			strYYYYMM0: now.getFullYear() + String( '0' + now.getMonth() ).slice( -2 ),
			intYYYYMMDD0: Number( now.getFullYear() + String( '0' + now.getMonth() ).slice( -2 ) + String( '0' + now.getDate() ).slice( -2 ) ),
			datesUsed1: {}
		},
		flags: {
			noGoingBack: true,
			noGoingForwards: false,
			weekendsInUse: false,
			capacityLoaded: false
		},
		calendar: {
			month0: now.getMonth() as TypeJSMonthIdx,
			year: now.getFullYear()
		},
		selected: 0,
		dates: {
			weeks: [],
			first: undefined,
			last: undefined,
			offset: [],
			days: [],
			remainder: []
		},
		members: {}
	};
	public pastGroupReservations: InterfaceGroupReservation[] = []; // past only.
	public groupReservations: InterfaceGroupReservation[] = []; // present, or future.
	//
	public orderDocletIDs: { [docletID: string]: true } = {};
	private wovenOrdersIDs: InterfaceWovenOrderIDs[] = [];
	public wovenOrdersDoclets: InterfaceWovenOrderDoclets[] = [];
	public purchasedTicketsByVisitDate: InterfacePurchasedTicketsByVisitDate = {};
	public dateKeysByPurchasedTicketDates: string[] = [];
	public wovenOrdersDocletsLoaded: boolean = false;
	public rogueTicketsChecking: boolean = false;
	public rogueTicketsLoaded: boolean = false;
	// ===== //
	public currentOrFutureVisitDates: string[] = []; // dates of 'any' or '2023-XX-XX', for showing entries in 'My Tickets'
	public seasonParkingTickets: InterfaceOWDoclet[] = [];
	// ===== //
	//
	public pastVisits: InterfacePastVisit[] = []; // the combine output of pastGroupReservations (SPH) and past purchasedTicketsByVisitDate (non SPH)
	//
	private readonly strComplexProductTickets: string = this.appConfig.getTemplateID( 'Complex Product Ticket' );
	// daily tickets
	private readonly strDailyAdmissionTicketTemplateID: string = this.appConfig.getTemplateID( 'Daily Admission Ticket' );
	// season tickets
	private readonly strSeasonAdmissionTicketTemplateID: string = this.appConfig.getTemplateID( 'Season Admission Ticket' ); // the imported ticket
	private readonly strSPHDailyAdmissionTicketTemplateID: string = this.appConfig.getTemplateID( 'SPH Daily Admission Ticket' ); // the special daily ticket for SPHs.
	private readonly strSeasonParkingTicketTemplateID: string = this.appConfig.getTemplateID( 'Season Parking Ticket' );
	// parking tickets
	private readonly strParkingTicketTemplateID: string = this.appConfig.getTemplateID( 'Parking Ticket' );
	// cabana tickets
	private readonly arrCabanaTemplateIDs: string[] = [
		this.appConfig.getTemplateID( 'Kontiki Cove Ticket' ),
		this.appConfig.getTemplateID( 'Cook\'s Cove Ticket' ),
		this.appConfig.getTemplateID( 'River Cabanas Ticket' ),
		this.appConfig.getTemplateID( 'Wavepool Beach Ticket' ),
		this.appConfig.getTemplateID( 'Wavepool North Ticket' ),
		this.appConfig.getTemplateID( 'Wavepool South Ticket' )
	];
	// infrastructure things.
	private readonly strConsumerTemplateID: string = this.appConfig.getTemplateID( 'Consumer' );
	private readonly strFamilyTemplateID: string = this.appConfig.getTemplateID( 'Family' );
	private readonly strOrderTemplateID: string = this.appConfig.getTemplateID( 'Order' );
	private readonly strParkVisitTemplateID: string = this.appConfig.getTemplateID( 'Park Visit' );
	//
	public noFamily: boolean = true;
	public activeQR: string = '';
	public activeQRTitle: string = '';
	//
	private docletCache: { [docletID: string]: InterfaceOWDoclet } = {};
	//
	public recycleTB: boolean = true; // trying to fix an angular framework issue...
	//
	public constructor(
		private readonly appConfig: AppConfig,
		private readonly appEvents: AppEvents,
		private readonly auth: ServiceAuthentication,
		private readonly colProfiles: CollectionProfiles,
		private readonly nav: ServiceNavigate,
		private readonly owapi: ServiceOWAPI,
		private readonly title: Title
	) {
		this.title.setTitle( 'Wild Rivers Portal' );
		if ( this.auth.isSignedIn() ) {
			this.subAccountReSync = this.appEvents.listen( 'account:re-sync' ).subscribe( (_:InterfaceAppEvent): void => {
				this.fetchAccountInfo();
			} );
			this.subCardReSync = this.appEvents.listen( 'card:re-sync' ).subscribe( (_: InterfaceAppEvent): void => {
				this.fetchCardInfo();
			} );
			this.subProfilesUpdated = this.colProfiles.updated.subscribe( (): void => {
				this.fetchUserInfo();
			} );
			this.subUserReSync = this.appEvents.listen( 'user:re-sync' ).subscribe( (_: InterfaceAppEvent): void => {
				this.fetchUserInfo();
			} );
			this.fetchFamilyWeaves();
		}
	}

	private fetchUserInfo(): void {
		this.colProfiles.getMyUserProfile( (userData: InterfaceOWUser | null): void => {
			if ( userData && userData.data ) {
				this.haveUserInfo = true;
				const templateConsumer: InterfaceOWTemplateConsumer = userData.data as InterfaceOWTemplateConsumer;
				this.mastHeading = 'Hello, ' + templateConsumer.first_name + ' ' + templateConsumer.last_name;
				this.primaryAccountHolder.consumerDocletID = userData.doclet_id;
				this.primaryAccountHolder.firstName = templateConsumer.first_name ? templateConsumer.first_name : '';
				this.primaryAccountHolder.lastName = templateConsumer.last_name ? templateConsumer.last_name : '';
				this.primaryAccountHolder.photo = templateConsumer.photo ? templateConsumer.photo : '';
				// account holders don't have a serial code. family members do that have a ticket/season-pass.
				// maybe in the future they will... or maybe they'll only create family members... not sure.
			}
		} );
	}

	private fetchCardInfo(): void {
		const profileID: string | null = this.auth.getProfileID();
		if ( profileID === null ) {
			return;
		}
		this.owapi.workspace.actions.core.getSavedPaymentMethod( this.appConfig.getContext(), profileID, this.realmIdPortal ).subscribe( (response: InterfaceHTTPGateway): void => {
			if ( response && response.success ) {
				this.cardsOnFile = [];
				const apiResponse: InterfaceOWAPICardVaultResponse = response.data;
				if ( apiResponse && apiResponse.data && Array.isArray( apiResponse.data.items ) && apiResponse.data.items.length > 0 ) {
					this.cardsOnFile = apiResponse.data.items;
					console.log( 'cards on file', this.cardsOnFile );
					if ( !this.accountDoclet ) { // cheating.. it may not have existed until we tried to fetch the card info
						console.log( 'cache miss, re-fetching account...' );
						this.fetchAccountInfo();
					} else {
						if ( this.cardsOnFile.length > 0 && this.accountDoclet.data['cashless_spending'] ) {
							this.setCashlessToggleBox( true );
						}
						if ( this.cardsOnFile.length < 1 || !this.accountDoclet.data['cashless_spending'] ) {
							this.setCashlessToggleBox( false );
						}
					}
				}
			}
		} );
	}

	private fetchAccountInfo(): void {
		const appContext: InterfaceAppContext = this.appConfig.getContext();
		const profileID: string | null = this.auth.getProfileID();
		if ( profileID === null ) {
			return;
		}
		this.colProfiles.getMyUserProfile( (userProfile: InterfaceOWUser | null): void => {
			if ( userProfile && userProfile.account_id ) {
				this.owapi.workspace.doclets.getDocletsByTemplateID( appContext, this.strAccountTemplateID, 'realm.aid:{id}' + userProfile.account_id.$oid ).subscribe( (response: InterfaceHTTPGateway): void => {
					if ( response && response.success ) {
						const apiResponse: InterfaceOWAPIGetDocletsResponse = response.data;
						if ( apiResponse && apiResponse.data && Array.isArray( apiResponse.data.items ) && apiResponse.data.items.length > 0 ) {
							this.accountDoclet = apiResponse.data.items[0];
							if ( this.accountDoclet && this.accountDoclet.data && this.accountDoclet.data['cashless_spending'] && this.cardsOnFile.length > 0 ) {
								this.setCashlessToggleBox( true );
							} else {
								this.setCashlessToggleBox( false );
							}
							console.log( 'account doc', this.accountDoclet );
						}
					}
					this.haveAccountInfo = true;
					if ( !this.accountDoclet ) {
						// cheating, this ought to create one..
						this.owapi.workspace.actions.core.getSavedPaymentMethod( appContext, profileID, this.realmIdPortal ).subscribe( (r: InterfaceHTTPGateway): void => console.log( 'cache-miss: created account doclet', r ) );
					}
				} );
			} else {
				console.log( 'No user account ID, cannot fetch account data.' );
				// cheating, this ought to create one..
				this.owapi.workspace.actions.core.getSavedPaymentMethod( appContext, profileID, this.realmIdPortal ).subscribe( (r: InterfaceHTTPGateway): void => console.log( 'created account doclet', r ) );
				this.haveAccountInfo = true;
			}
		} );
	}

	public ngOnInit(): void {
		if ( this.auth.isSignedIn() ) {
			this.fetchAccountInfo();
			this.fetchCardInfo();
			this.fetchUserInfo();
		} else {
			this.nav.toURL( '/' + this.routes.signIn );
		}
	}

	public ngOnDestroy(): void {
		if ( this.subAccountReSync ) {
			this.subAccountReSync.unsubscribe();
			this.subAccountReSync = null;
		}
		if ( this.subCardReSync ) {
			this.subCardReSync.unsubscribe();
			this.subCardReSync = null;
		}
		if ( this.subProfilesUpdated ) {
			this.subProfilesUpdated.unsubscribe();
			this.subProfilesUpdated = null;
		}
		if ( this.subUserReSync ) {
			this.subUserReSync.unsubscribe();
			this.subUserReSync = null;
		}
	}

	public showCCModal(): void {
		this.appEvents.broadcast( 'modal:open:credit-card' );
	}

	public cashlessToggle( b: boolean ): void {
		const profileID: string | null = this.auth.getProfileID();
		if ( profileID === null ) {
			return;
		}
		if ( this.accountDoclet && this.cardsOnFile.length > 0 ) {
			this.owapi.workspace.actions.core.toggleCashlessSpending( this.appConfig.getContext(), profileID, b, this.realmIdPortal ).subscribe( (response: InterfaceHTTPGateway): void => {
				let failed: boolean = true;
				if ( response && response.success ) {
					const apiResponse: InterfaceOWAPIGetDocletsResponse = response.data;
					if ( apiResponse && apiResponse.data && apiResponse.data.items ) {
						failed = false;
						const justAPIThings: ({} | InterfaceOWAPICardVault)[] = apiResponse.data.items;
						if ( justAPIThings.length > 0 && justAPIThings[0].hasOwnProperty( 'vault_id' ) ) {
							this.setCashlessToggleBox( true );
						} else {
							this.setCashlessToggleBox( false );
						}
					}
				}
				if ( failed ) {
					this.setCashlessToggleBox( false );
				}
			} );
		} else {
			this.setCashlessToggleBox( false );
			this.showCCModal();
		}
	}

	// ===== Calendar - BEGIN ===== //
	public fetchDailyCapacity(): void {
		this.capacity = {};
		this.reservationForm.flags.capacityLoaded = false;
		this.reachedMaxReservations = true; // prove otherwise
		// server-side will claim the park is full, on the days you've reserved a visit.
		this.owapi.workspace.actions.core.getSeasonPassAvailabilityFromDateRange( this.appConfig.getContext(), this.familyWeaves.data.familyDocletID as string, now, dateParkClosesOn2024Oct1st ).subscribe( (response: InterfaceHTTPGateway): void => {
			if ( response && response.success && response.status === 200 ) {
				const apiResponse: InterfaceOWAPISeasonPassAdmissionAvailabilityResponse = response.data;
				if ( apiResponse && apiResponse.data && Array.isArray( apiResponse.data.items ) ) {
					const apiResponseData: InterfaceOWSeasonPassAdmissionAvailability | undefined = apiResponse.data.items.shift(); // pop_front();
					const maxCapacityFlag: { 'max_capacity': boolean; } | undefined = apiResponse.data.items.shift() as { 'max_capacity': boolean } | undefined; // pop_front();
					if ( apiResponseData ) {
						this.capacity = apiResponseData;
						this.reachedMaxReservations = !!(maxCapacityFlag && maxCapacityFlag['max_capacity']);
					}
				}
			}
			this.reservationForm.flags.capacityLoaded = true;
		} );
	}

	private reSyncDatesUsed(): void {
		this.reservationForm.cache.datesUsed1 = {};
		// need to check if the members going on each reserved day,
		// is all of (or more) of the members woven to the family.
		// if all woven members are going on a day, then that day should be blocked.
		// else the remaining members are still allowed to make a reservation that day.
		for ( let x: number = 0; x < this.groupReservations.length; ++x ) {
			let isDateBlocked: boolean = true; // assume everyone is going...
			const famMemberIDs: {
				[memberID: string]: boolean;
			} = {};
			for ( let y: number = 0; y < this.familyWeaves.data.members.length; ++y ) {
				famMemberIDs[ this.familyWeaves.data.members[y].member._id.$oid ] = false;
			}
			// track who is going for a day...
			for ( let y: number = 0; y < this.groupReservations[x].memberIDs.length; ++y ) {
				famMemberIDs[ this.groupReservations[x].memberIDs[y] ] = true;
			}
			// check the members going for a reservation, if all family members are going, then that day is blocked.
			let membersGoing: string[] = Object.keys( famMemberIDs );
			for ( let y: number = 0; isDateBlocked && y < membersGoing.length; ++y ) {
				isDateBlocked = famMemberIDs[ membersGoing[y] ];
			}
			if ( isDateBlocked ) {
				this.reservationForm.cache.datesUsed1[ this.groupReservations[x].strDateYYYYMMDD1 ] = true;
			}
		}
	}

	public setReservationCalendarMonth( month: TypeJSMonthIdx, year: number ): void { // the month param needs to be 0-based for this to work...
		this.reservationForm.calendar.month0 = month;
		this.reservationForm.calendar.year = year; // the target year and month.
		this.reservationForm.cache.strYYYYMM0 = year + String( '0' + month ).slice( -2 ); // month is 0-based. 00 - 11.
		this.reservationForm.cache.strYY_MM1 = year + '-' + String( '0' + (month + 1) ).slice( -2 ); // month is 1-based. 01 - 12.
		this.reservationForm.selected = 0;

		// Cache all of the month's stuff for various things to be used places
		this.reservationForm.dates.first = new Date( this.reservationForm.calendar.year, this.reservationForm.calendar.month0, 1 );
		this.reservationForm.dates.last = new Date( this.reservationForm.calendar.year, this.reservationForm.calendar.month0 + 1, 0 );
		this.reservationForm.dates.offset = new Array( this.reservationForm.dates.first.getDay() );
		this.reservationForm.dates.days = new Array( this.reservationForm.dates.last.getDate() );
		// date selected, is a weekend, if (selected - 1 + offset) % 7 === 0 or 6 (Sun or Sat)

		// Calculate the number of weeks in the month, including Feb when it's whack.
		const total: number = this.reservationForm.dates.first.getDay() + this.reservationForm.dates.last.getDate();
		const weeks: number = Math.ceil( total / 7 );

		this.reservationForm.dates.remainder = new Array( (weeks * 7) - (this.reservationForm.dates.offset.length + this.reservationForm.dates.days.length) );
	}

	public prevReservationCalendarMonth(): void {
		const intCurrentYearMonth: number = Number( String( now.getFullYear() ) + String( '0' + now.getMonth() ).slice( -2 ) );
		let intPrevYearMonth: number = 0; // YYYYMM where MM is 00 through 11
		let strTargetYear: string = '0000'; // 0000 - 275760. (64 bit,  or if it is 32 bit... Y2K38)
		let strTargetMonth: string = '00'; // 00 - 11
		if ( this.reservationForm.calendar.month0 < 1 ) { // then it's the previous year and just 11.  "202111"
			strTargetYear = String( this.reservationForm.calendar.year - 1 );
			strTargetMonth = '11';
		} else { // else it's the current year, just one month less. "202200"
			strTargetYear = String( this.reservationForm.calendar.year );
			strTargetMonth = String( '0' + (this.reservationForm.calendar.month0 - 1) ).slice( -2 );
		}
		intPrevYearMonth = Number( strTargetYear + strTargetMonth );
		if ( intPrevYearMonth < intCurrentYearMonth ) { // intCurrentMonth uses 00 through 11 for it's month.
			// don't use <= here, because we want to allow the user to go back to the current month.
			return;
		}
		this.reservationForm.flags.noGoingBack = intPrevYearMonth <= intCurrentYearMonth; // do not allow the user to go back to the past.
		this.reservationForm.flags.noGoingForwards = Number( strTargetYear ) >= 2022 && Number( strTargetMonth ) > 7; // or past Sept.
		this.setReservationCalendarMonth( Number( strTargetMonth ) as TypeJSMonthIdx, Number( strTargetYear ) ); // month is zero-based. so 0 - 11.
	}

	public nextReservationCalendarMonth(): void {
		// TODO: allow users to travel from Jan to Dec last year, as needed.
		const targetMonth: number = this.reservationForm.calendar.month0 + 1;
		const targetYear: number = this.reservationForm.calendar.year + Number( targetMonth > 11 );
		this.reservationForm.flags.noGoingBack = false;
		this.reservationForm.flags.noGoingForwards = targetYear >= 2022 && targetMonth > 7; // it's OK if they view september. JS Month0 number 8.
		//
		this.setReservationCalendarMonth( (targetMonth % 12) as TypeJSMonthIdx, targetYear );
	}

	private resetReservationForm(): void {
		this.reservationForm.selected = 0;
		this.reservationForm.step = 1;
		this.setReservationCalendarMonth( now.getMonth() as TypeJSMonthIdx, now.getFullYear() );
		this.reservationForm.flags.noGoingBack = true; // don't allow the user to go back to the past.
		this.reservationForm.flags.noGoingForwards = this.reservationForm.calendar.year >= 2022 && this.reservationForm.calendar.month0 > 7; // or past Sept.
	}

	public showReservationForm(): void {
		this.resetReservationForm();
		this.reservationForm.active = true;
	}

	public hideReservationForm(): void {
		this.reservationForm.active = false;
		this.resetReservationForm();
	}

	public setReservationFormStep( step: TypeReservationFormStep ): void {
		switch( step ) {
			case 2: {
				this.memberIDsAlreadyReserved = {};
				// set all members to false, or else you'll submit them when making a reservation.
				// loop over the allowed members for that day from the capacity
				// just set those members to true
				//const memberIDs: string[] = Object.keys( this.reservationForm.members ); // why is this empty? should be 1
				const memberIDs: string[] = this.familyWeaves.data.members.map( (memberWithTicket: InterfaceFamilyWeavesDataMembers): string => {
					return memberWithTicket.member._id.$oid;
				} );
				const validSeasonPassMemberIDs: { [id: string]: true; } = {};
				for ( let x: number = 0; x < memberIDs.length; ++x ) {
					validSeasonPassMemberIDs[ memberIDs[x] ] = true;
					this.reservationForm.members[ memberIDs[x] ] = false; // the ones that stay false, ought to be un-selectable.
				}
				// check the capacity to see who is allowed (or not) to reserve the date today.
				const strYYYYMMDD1: string = this.reservationForm.calendar.year + '-' + String( '0' + (this.reservationForm.calendar.month0 + 1) ).slice( -2 ) + '-' + String( '0' + this.reservationForm.selected ).slice( -2 );
				if ( Array.isArray( this.capacity[strYYYYMMDD1] ) ) {
					for ( let x: number = 0; x < this.capacity[strYYYYMMDD1].length; ++x ) {
						let memberID: string = this.capacity[strYYYYMMDD1][x];
						if ( validSeasonPassMemberIDs[ memberID ] ) {
							// pre-select the members that are allowed to go today.
							this.reservationForm.members[ memberID ] = true;
						}
					}
				}
				// hide the check-box for the members that are not allowed to go today.
				for ( let x: number = 0; x < memberIDs.length; ++x ) {
					if ( !this.reservationForm.members[ memberIDs[x] ] ) {
						this.memberIDsAlreadyReserved[ memberIDs[x] ] = true;
					}
				}
				console.log( 'members not allowed:', this.memberIDsAlreadyReserved );
				console.log( 'members pre-selected:', this.reservationForm.members );
				break;
			}
		}
		this.reservationForm.step = step;
	}

	public toggleGroupMember( id: string ): void {
		if ( this.reservationForm.members.hasOwnProperty( id ) ) {
			this.reservationForm.members[id] = !this.reservationForm.members[id];
		}
	}

	public createReservation(): void {
		const memberIDs: string[] = Object.keys( this.reservationForm.members );
		const membersGoing: string[] = [];
		for ( let x: number = 0; x < memberIDs.length; ++x ) {
			if ( this.reservationForm.members[memberIDs[x]] ) {
				membersGoing.push( memberIDs[x] );
			}
		}
		if ( membersGoing.length > 0 ) {
			const datePicked: Date = new Date( this.reservationForm.calendar.year, this.reservationForm.calendar.month0, this.reservationForm.selected );
			const parkingTicketID: undefined = undefined; // hmm ??
			this.owapi.workspace.actions.core.assignSeasonPassMembersToGroupEvent( this.appConfig.getContext(), this.familyWeaves.data.familyDocletID as string, datePicked, membersGoing ).subscribe( (response: InterfaceHTTPGateway): void => {
				// console.log( 'Resp', response );
				if ( response && response.success && response.status === 200 ) {
					const apiResponse: InterfaceOWAPICreateWeaveResponse = response.data;
					// console.log( 'Created reservation', apiResponse );
					if ( apiResponse && apiResponse.data && Array.isArray( apiResponse.data.items ) ) {
						if ( apiResponse.data.items.length > 0 ) {
							if ( apiResponse.data.items[0].doclet_id?.length ?? 0 > 0 ) {
								// create a fake-entry so we don't have to make yet another network request.
								const strYYYYMMDD1: string = String( this.reservationForm.calendar.year ) + String( '0' + (this.reservationForm.calendar.month0 + 1) ).slice( -2 ) + String( '0' + this.reservationForm.selected ).slice( -2 );
								this.groupReservations.push( { // creating a reservation
									parkVisitID: apiResponse.data.items[0].doclet_id,
									status: 'active',
									date: { // this date uses 1-based for its month, but the calendar's month is 0-based.
										year: this.reservationForm.calendar.year,
										month1: this.reservationForm.calendar.month0 + 1,
										day: this.reservationForm.selected
									},
									strDateYYYYMMDD1: strYYYYMMDD1,
									memberIDs: membersGoing,
									parkingID: parkingTicketID,
									cabanaIDs: []
								} );
								this.reservationForm.cache.datesUsed1[strYYYYMMDD1] = true;
								// sort the page's listing of reserved dates.
								this.sortFamilyReservationsByDate();
								// the daily capacity stored up is now invalid. re-fetch it.
								this.fetchDailyCapacity();
							}
						}
					}
					this.hideReservationForm();
				} else {
					alert( 'An error occurred. Please try again later.' );
					console.log( 'Failed to create a reservation', response );
				}
			} );
		}
	}
	// ===== Calendar - END ===== //

	private initFamilyMembers(): void {
		if ( this.familyWeaves.flags.loaded ) {
			for ( let x: number = 0; x < this.familyWeaves.data.members.length; ++x ) {
				this.reservationForm.members[ this.familyWeaves.data.members[x].member._id.$oid ] = true;
			}
		}
	}

	private sortFamilyReservationsByDate(): void {
		this.groupReservations.sort( (A: InterfaceGroupReservation, B: InterfaceGroupReservation): number => {
			return (A.date.year * 10000 + A.date.month1 * 100 + A.date.day) - (B.date.year * 10000 + B.date.month1 * 100 + B.date.day);
		} );
		this.pastGroupReservations.sort( (A: InterfaceGroupReservation, B: InterfaceGroupReservation): number => {
			return (A.date.year * 10000 + A.date.month1 * 100 + A.date.day) - (B.date.year * 10000 + B.date.month1 * 100 + B.date.day);
		} );
	}

	// ===== Family - BEGIN ===== //
	public familyWeaves: {
		data: InterfaceFamilyWeavesData;
		flags: InterfaceFamilyWeavesFlags;
	} = {
		data: {
			familyDoclet: null,
			familyDocletID: null,
			members: []
		},
		flags: {
			familyDoclet: false,
			loaded: false,
			membersAcquired: 0,
			totalMembers: 0
		}
	};

	private fetchFamilyWeaves(): void {
		if ( this.familyWeaves.flags.loaded ) {
			return; // ...ooorrrr we can re-assign it all to null's and [] ...and start all over again.
		}
		const appContext: InterfaceAppContext = this.appConfig.getContext();
		this.colProfiles.getMyUserProfile( (userProfile: InterfaceOWUser | null): void => {
			if ( userProfile && userProfile.doclet_id ) {
				const userProfileDocletID: string = userProfile.doclet_id;
				// ===== fetch weaves for signed-in user ===== //
				console.log( 'Fetching (User)<-(Family)<-(Members)<-(Tickets)  and  (Family)<-(Park Visits)<-(Members)' );
				// one day, you're going to fetch too many things. because orders never go away, and family is not supposed to go away.
				// and there isn't a great way to only fetch one type of template_id based upon conditions and not the other...
				console.log( 'Purchaser CID', userProfileDocletID );
				this.owapi.workspace.doclets.getWeavesByDocletID( appContext, userProfileDocletID ).subscribe( (responseUserWeaves: InterfaceHTTPGateway): void => {
					if ( responseUserWeaves?.success ) {
						const apiResponseUserWeaves: InterfaceOWAPIGetWeavesResponse = responseUserWeaves?.data;
						if ( apiResponseUserWeaves && Array.isArray( apiResponseUserWeaves?.data?.items ) ) {
							const userWeaves: InterfaceOWWeaveV2[] = apiResponseUserWeaves.data.items;
							console.log( 'weaves on user', userWeaves );
							let familyDocletKnown: boolean = false; // familyDocletFound will become !!(this.familyWeaves.data.familyDoclet)
							const seasonParkingTicketIDs: string[] = [];
							const rogueDailyTicketIDsToCheck: string[] = [];
							const rogueCabanaTicketIDsToCheck: string[] = [];
							for ( let uw: number = 0; uw < userWeaves.length; ++uw ) {
								// find the entry for the Family template/doclet, then fetch the Family doclet by ID.
								switch ( userWeaves[uw].t_id.$oid ) {
									case this.strFamilyTemplateID: {
										familyDocletKnown = true;
										this.familyWeaves.data.familyDocletID = userWeaves[uw].c_id.$oid;
										// ===== fetch family doclet woven to user ===== //
										this.owapi.workspace.doclets.getDocletByID( appContext, userWeaves[uw].c_id.$oid ).subscribe( (responseGetFamilyDoclet: InterfaceHTTPGateway): void => {
											if ( responseGetFamilyDoclet?.success ) {
												const apiResponseGetFamilyDoclet: InterfaceOWAPIGetDocletResponse | undefined = responseGetFamilyDoclet?.data;
												if ( apiResponseGetFamilyDoclet?.data?._id?.$oid ) {
													const familyDoclet: InterfaceOWDoclet = apiResponseGetFamilyDoclet.data;
													// console.log( 'Fam doc', familyDoclet );
													if ( familyDoclet && familyDoclet._id ) {
														this.familyWeaves.data.familyDoclet = familyDoclet;
														this.familyWeaves.flags.familyDoclet = true;
														this.noFamily = false;
														this.fetchDailyCapacity();
													} else {
														this.reservationForm.flags.capacityLoaded = true; // no family, no way to fetch dailyCapacity.
													}
												}
											}
										} );
										// ===== fetch weaves on family ===== //
										this.owapi.workspace.doclets.getWeavesByDocletID( appContext, this.familyWeaves.data.familyDocletID ).subscribe( (responseWeavesOnFamily: InterfaceHTTPGateway): void => {
											if ( responseWeavesOnFamily?.success ) {
												const apiResponseWeavesOnFamily: InterfaceOWAPIGetWeavesResponse = responseWeavesOnFamily?.data;
												if ( apiResponseWeavesOnFamily && Array.isArray( apiResponseWeavesOnFamily?.data?.items ) ) {
													const weavesOnFamily: InterfaceOWWeaveV2[] = apiResponseWeavesOnFamily.data.items;
													const consumerDocletIDs: string[] = [];
													const parkVisitDocletIDs: string[] = [];
													for ( let wof: number = 0; wof < weavesOnFamily.length; ++wof ) {
														switch ( weavesOnFamily[wof].t_id.$oid ) {
															case this.strConsumerTemplateID: {
																if ( weavesOnFamily[wof]?.data?.['role'] === 'Pass Holder' ) {
																	consumerDocletIDs.push( weavesOnFamily[wof].c_id.$oid );
																}
																break;
															}
															case this.strParkVisitTemplateID: {
																parkVisitDocletIDs.push( weavesOnFamily[wof].c_id.$oid );
																break;
															}
														}
													}
													// ===== Fetching Consumers, Season Passes --- BEGIN --- ===== //
													this.familyWeaves.flags.totalMembers = consumerDocletIDs.length; /// ************************ // need to only count the members with a 2023 pass. but we don't have passes yet.
													console.log( 'Family Member IDs', consumerDocletIDs );
													for ( let cd: number = 0; cd < consumerDocletIDs.length; ++cd ) {
														// ===== fetch consumer ===== //
														this.owapi.workspace.doclets.getDocletByID( appContext, consumerDocletIDs[cd] ).subscribe( (responseGetMemberDoclet: InterfaceHTTPGateway): void => {
															if ( responseGetMemberDoclet?.success ) {
																const apiResponseGetMemberDoclet: InterfaceOWAPIGetDocletResponse | undefined = responseGetMemberDoclet?.data;
																if ( apiResponseGetMemberDoclet?.data?._id?.$oid ) {
																	const famMember: InterfaceOWDoclet = apiResponseGetMemberDoclet?.data;
																	if ( famMember && famMember._id ) {
																		// ===== fetch weaves on consumers, look for a season passes ===== //
																		this.owapi.workspace.doclets.getWeavesByDocletID( appContext, famMember._id.$oid ).subscribe( (responseWeavesOnMember: InterfaceHTTPGateway): void => {
																			if ( responseWeavesOnMember?.success ) {
																				const apiResponseWeavesOnMember: InterfaceOWAPIGetWeavesResponse = responseWeavesOnMember?.data;
																				if ( apiResponseWeavesOnMember && Array.isArray( apiResponseWeavesOnMember?.data?.items ) ) {
																					const weavesOnMember: InterfaceOWWeaveV2[] = apiResponseWeavesOnMember.data.items;
																					let seasonPassesToTry: number = 0;
																					for ( let wom: number = 0; wom < weavesOnMember.length; ++wom ) {
																						if ( weavesOnMember[wom].t_id.$oid === this.strSeasonAdmissionTicketTemplateID ) {
																							++seasonPassesToTry; // could make this an array of doclet IDs and just pop() them away and then check if arr.length is zero instead of if seasonPassesToTry is === 0
																						}
																					}
																					console.log( 'fam member', famMember._id.$oid, 'has', seasonPassesToTry, 'season passes to try' );
																					if ( seasonPassesToTry < 1 ) {
																						--this.familyWeaves.flags.totalMembers;
																						this.familyWeaveResolved();
																					} else {
																						let foundSeasonPass: boolean = false;
																						for ( let wom: number = 0; wom < weavesOnMember.length; ++wom ) {
																							if ( weavesOnMember[wom].t_id.$oid === this.strSeasonAdmissionTicketTemplateID ) {
																								const seasonPassDocletID: string = weavesOnMember[wom].c_id.$oid;
																								this.owapi.workspace.doclets.getDocletByID( appContext, seasonPassDocletID ).subscribe( (responseGetSPDoclet: InterfaceHTTPGateway): void => {
																									if ( responseGetSPDoclet?.success ) {
																										const apiResponseGetSPDoclet: InterfaceOWAPIGetDocletResponse | undefined = responseGetSPDoclet?.data;
																										if ( apiResponseGetSPDoclet?.data?._id?.$oid ) {
																											const seasonPass: InterfaceOWDoclet = apiResponseGetSPDoclet.data;
																											--seasonPassesToTry;
																											if ( !foundSeasonPass ) {
																												if ( seasonPass && seasonPass._id ) {
																													let seasonPassStatus: string = seasonPass.data['status'] ? seasonPass.data['status'] : 'active';
																													if ( seasonPassStatus === 'active' ) {
																														let sapYear: number = 0;
																														if ( typeof seasonPass.data['year'] === 'string' && seasonPass.data['year'].match( /^\d\d\d\d$/ ) ) {
																															sapYear = Number( seasonPass.data['year'] );
																														}
																														if ( typeof seasonPass.data['date'] === 'string' && seasonPass.data['date'].match( /^\d\d\d\d-\d\d-\d\d$/ ) ) {
																															const arrDate: string[] = seasonPass.data['date'].split( /-/g );
																															if ( Number( arrDate[0] ) > sapYear ) {
																																sapYear = Number( arrDate[0] );
																															}
																														}
																														if ( sapYear >= Number( this.seasonPassYear ) ) {
																															seasonPass.data['__sap_tier_display'] = this.getSAPTierText( seasonPass );
																															this.familyWeaves.data.members.push( {
																																member: famMember,
																																ticket: seasonPass
																															} );
																															foundSeasonPass = true;
																															console.log( 'Valid SPH for member', famMember._id.$oid );
																															this.familyWeaveResolved();
																														} else {
																															console.log( 'found an expired(?) season pass for member', famMember._id.$oid, seasonPass );
																														}
																													} else {
																														console.log( 'found a non-active season pass for member', famMember._id.$oid, seasonPass );
																													}
																												} else {
																													console.log( 'Season pass doclet does not exist, but is woven to the consumer.' );
																												}
																											}
																											if ( seasonPassesToTry === 0 && !foundSeasonPass ) {
																												console.log( 'No valid active season pass for member', famMember._id.$oid );
																												--this.familyWeaves.flags.totalMembers;
																												this.familyWeaveResolved();
																											}
																										}
																									}
																								} );
																							} else { // end if this was the season pass...
																								// extra tracking/logging for...weird chit
																								switch ( weavesOnMember[wom].t_id.$oid ) {
																									case this.strSeasonParkingTicketTemplateID: {
																										console.log( 'family member', famMember._id.$oid, 'has a season parking pass?!?', weavesOnMember[wom].c_id.$oid );
																										// can't push the doclet_id into seasonParkingTicketIDs ...because that was already looped over long ago.
																										break;
																									}
																								}
																							}
																						} // end for each woven entry on the family member.
																						if ( weavesOnMember.length < 1 ) {
																							console.log( 'No weaves on member, cannot search for a season pass.' );
																							this.familyWeaveResolved();
																						} // end if no weaves on members
																					} // end else there is a woven season admission pass.
																				} // end if there were weaves on the family member.
																			} // end if fetching a family members weaves was successful.
																		} );
																	} else {
																		console.log( 'Family Member (Consumer) doclet does not exist, but is woven to the Family doclet.' );
																		this.familyWeaveResolved();
																	}
																}
															}
														} );
													} // end for each family member doclet
													// ===== Fetching Consumers, Season Passes --- END --- ===== //
													// ===== Fetching Park Visits, Cabana Tickets, Parking Tickets --- BEGIN --- ===== //
													for ( let pv: number = 0; pv < parkVisitDocletIDs.length; ++pv ) {
														// the reservation doclet, has the date.
														const groupReservation: InterfaceGroupReservation = {
															parkVisitID: parkVisitDocletIDs[pv],
															status: 'active',
															date: {
																year: 0,
																month1: 0, // 1 - 12.
																day: 0
															},
															strDateYYYYMMDD1: '',
															memberIDs: [],
															parkingID: undefined,
															cabanaIDs: []
														};
														this.owapi.workspace.doclets.getDocletByID( appContext, parkVisitDocletIDs[pv] ).subscribe( (responseGetParkVisitDoclet: InterfaceHTTPGateway): void => {
															if ( responseGetParkVisitDoclet?.success ) {
																const apiResponseGetParkVisitDoclet: InterfaceOWAPIGetDocletResponse | undefined = responseGetParkVisitDoclet?.data;
																if ( apiResponseGetParkVisitDoclet?.data?._id?.$oid ) {
																	const parkVisit: InterfaceOWDoclet = apiResponseGetParkVisitDoclet.data;
																	if ( parkVisit && parkVisit.data && typeof parkVisit.data['date'] === 'string' ) {
																		const parkVisitData: InterfaceOWTemplateParkVisit = parkVisit.data as InterfaceOWTemplateParkVisit;
																		groupReservation.status = parkVisitData.status ? parkVisitData.status : 'active';
																		const arrDate: string[] = parkVisitData.date.split( /-/g );
																		if ( arrDate.length === 3 ) {
																			// month is 1-based, not the usual 0-based like in Date.getMonth().
																			groupReservation.date.year = parseInt( arrDate[0], 10 ); // 2022 => 2022 // 1969 - 2038?
																			groupReservation.date.month1 = parseInt( arrDate[1], 10 ); // 06 => 6 // 1 - 12
																			groupReservation.date.day = parseInt( arrDate[2], 10 ); // 06 => 6 // 1 - 31
																			groupReservation.strDateYYYYMMDD1 = groupReservation.date.year + ('0' + groupReservation.date.month1).slice( -2 ) + ('0' + groupReservation.date.day).slice( -2 );
																			// we don't have enough of the properties yet to display it correctly...
																			// ...but we can keep adding them to groupReservation, and it'll populate the entry in the array.
																			// (because it's a reference to chit)
																			//
																			// ensure the park visit is in the future.
																			const intParkVisitYYYYMMDD1: number = groupReservation.date.year * 10000 + groupReservation.date.month1 * 100 + groupReservation.date.day;
																			// the park visit's date, has its month already in the range of 1 - 12.
																			if ( intNowYYYYMMDD1 <= intParkVisitYYYYMMDD1 && groupReservation.status === 'active' ) {
																				this.groupReservations.push( groupReservation ); // collecting up upcoming SPH reservations
																			} else {
																				this.pastGroupReservations.push( groupReservation ); // collecting up past SPH reservations
																			}
																			// if we have the last record...
																			if ( this.groupReservations.length + this.pastGroupReservations.length >= parkVisitDocletIDs.length ) {
																				this.sortFamilyReservationsByDate(); // once we're at the end of the for-loop, sort both lists.
																				console.log( 'Fetched (Family)<-(Park Visits)<-(Members)' );
																				this.reSyncDatesUsed();
																				this.buildPastVisits();
																			}
																			this.owapi.workspace.doclets.getWeavesByDocletID( appContext, parkVisitDocletIDs[pv] ).subscribe( (responseWeavesOnParkVisit: InterfaceHTTPGateway): void => {
																				if ( responseWeavesOnParkVisit?.success ) {
																					const apiResponseWeavesOnParkVisit: InterfaceOWAPIGetWeavesResponse = responseWeavesOnParkVisit?.data;
																					if ( apiResponseWeavesOnParkVisit && Array.isArray( apiResponseWeavesOnParkVisit?.data?.items ) ) {
																						const weavesOnParkVisit: InterfaceOWWeaveV2[] = apiResponseWeavesOnParkVisit.data.items;
																						// loop over the weaves, pull away only the ones that are cabana, parking and consumers.
																						for ( let wopv: number = 0; wopv < weavesOnParkVisit.length; ++wopv ) {
																							switch ( weavesOnParkVisit[wopv].t_id.$oid ) {
																								case this.strConsumerTemplateID: {
																									groupReservation.memberIDs.push( weavesOnParkVisit[wopv].c_id.$oid );
																									break;
																								}
																								case this.strParkingTicketTemplateID: { // assumed to only ever be 1.
																									groupReservation.parkingID = weavesOnParkVisit[wopv].c_id.$oid;
																									break;
																								}
																								default: { // maybe it's one of the 6 cabanas?
																									for ( let cabanaIdx: number = 0; cabanaIdx < this.arrCabanaTemplateIDs.length; ++cabanaIdx ) {
																										if ( weavesOnParkVisit[wopv].t_id.$oid === this.arrCabanaTemplateIDs[cabanaIdx] ) {
																											groupReservation.cabanaIDs.push( weavesOnParkVisit[wopv].c_id.$oid );
																										}
																									}
																									break;
																								}
																							} // end switch on template_id.
																						} // end for each weave on the park visit.
																					}
																				}
																			} );
																		} // end if there are all the parts of the date.
																	} // end if park visit has date.
																	// if no park visit, or no park visit date, then trash it.
																}
															}
														} );
													} // end for each park visit docletID.
													// ===== Fetching Park Visits, Cabana Tickets, Parking Tickets --- END --- ===== //
													if ( weavesOnFamily.filter( (w: InterfaceOWWeaveV2): boolean => {
														return w?.data?.['role'] === 'Pass Holder';
													} ).length < 1 ) {
														this.familyWeaveResolved(); // user has a family, but no members (they were moved to another account, etc.)
													}
												}
											}
										} ); // end fetch ing the family doclet.
										break;
									} // end case weave is a Family
									case this.strOrderTemplateID: {
										this.orderDocletIDs[ userWeaves[uw].c_id.$oid ] = true;
										break;
									} // end case Order
									case this.strSeasonParkingTicketTemplateID: {
										seasonParkingTicketIDs.push( userWeaves[uw].c_id.$oid );
										break;
									}
									case this.strDailyAdmissionTicketTemplateID: {
										// it's possible these ticket are not woven to an order.
										rogueDailyTicketIDsToCheck.push( userWeaves[uw].c_id.$oid );
										this.rogueTicketsChecking = true;
										break;
									}
									default: {
										this.arrCabanaTemplateIDs.forEach( (strCabanaID: string): void => {
											if ( userWeaves[uw].t_id.$oid === strCabanaID ) {
												rogueCabanaTicketIDsToCheck.push( userWeaves[uw].c_id.$oid );
												this.rogueTicketsChecking = true;
											}
										} );
										break;
									}
								} // end switch templateID for weaves on this user.
							} // end for each woven entry tied to the currently signed-in user.
							// ===== //
							for ( let x: number = 0; x < seasonParkingTicketIDs.length; ++x ) {
								this.owapi.workspace.doclets.getDocletByID( appContext, seasonParkingTicketIDs[x] ).subscribe( (responseGetSPTicket: InterfaceHTTPGateway): void => {
									if ( responseGetSPTicket?.success ) {
										const apiResponseGetSPTicket: InterfaceOWAPIGetDocletResponse | undefined = responseGetSPTicket?.data;
										if ( apiResponseGetSPTicket?.data?._id?.$oid ) {
											const seasonParkingTicket: InterfaceOWDoclet = apiResponseGetSPTicket.data;
											if ( seasonParkingTicket && seasonParkingTicket.data ) {
												if ( seasonParkingTicket.data['status'] === 'pending' || seasonParkingTicket.data['status'] === 'active' ) {
													let sppYear: number = 0;
													if ( typeof seasonParkingTicket.data['date'] === 'string' && seasonParkingTicket.data['date'].match( /^\d\d\d\d-\d\d-\d\d$/ ) ) {
														const arrDate: string[] = seasonParkingTicket.data['date'].split( /-/g );
														sppYear = Number( arrDate[0] );
													}
													if ( typeof seasonParkingTicket.data['year'] === 'string' && seasonParkingTicket.data['year'].match( /^\d\d\d\d$/ ) ) {
														if ( Number( seasonParkingTicket.data['year'] ) > sppYear ) {
															sppYear = Number( seasonParkingTicket.data['year'] );
														}
													}
													if ( sppYear >= Number( this.seasonPassYear ) ) {
														this.seasonParkingTickets.push( seasonParkingTicket );
													}
												}
											}
										}
									}
								} );
							}
							// ===== //
							let rogueTicketIDsRemaining: number = rogueDailyTicketIDsToCheck.length;
							let rogueCabanaIDsRemaining: number = rogueCabanaTicketIDsToCheck.length;
							if ( rogueTicketIDsRemaining < 1 && rogueCabanaIDsRemaining < 1 ) {
								this.rogueTicketsChecking = false;
								this.rogueTicketsLoaded = true;
							}
							for ( let x: number = 0; x < rogueDailyTicketIDsToCheck.length; ++x ) {
								const rogueDailyTicketID: string = rogueDailyTicketIDsToCheck[x];
								this.owapi.workspace.doclets.getWeavesByDocletID( appContext, rogueDailyTicketID ).subscribe( (rogueTicketResponse: InterfaceHTTPGateway): void => {
									if ( rogueTicketResponse?.success ) {
										const rogueTicketAPIResponse: InterfaceOWAPIGetWeavesResponse = rogueTicketResponse.data;
										if ( Array.isArray( rogueTicketAPIResponse?.data?.items ) && rogueTicketAPIResponse.data.items.length > 0 ) {
											const rogueTicketWeaves: InterfaceOWWeaveV2[] = rogueTicketAPIResponse.data.items;
											const ticketIsWovenToOrder: boolean = rogueTicketWeaves.filter( (w: InterfaceOWWeaveV2): boolean => {
												return w.t_id.$oid === this.strOrderTemplateID;
											} ).length > 0;
											if ( !ticketIsWovenToOrder ) {
												this.owapi.workspace.doclets.getDocletByID( appContext, rogueDailyTicketID ).subscribe( (rogueTicketResponse: InterfaceHTTPGateway): void => {
													if ( rogueTicketResponse?.success ) {
														const rogueTicketAPIResponse: InterfaceOWAPIGetDocletResponse = rogueTicketResponse.data;
														if ( rogueTicketAPIResponse?.data?._id?.$oid ) {
															const rogueDailyAdmissionTicket: InterfaceOWDoclet = rogueTicketAPIResponse.data;
															switch ( rogueDailyAdmissionTicket?.data?.['status'] ) {
																case 'activated':
																case 'active':
																case 'pending': {
																	if ( typeof rogueDailyAdmissionTicket.data['date'] === 'string' && rogueDailyAdmissionTicket.data['date'].length > 0 ) {
																		const strDate: string = rogueDailyAdmissionTicket.data['date'];
																		let isDateTodayOrFurtherOrAnyDay: boolean = false;
																		if ( strDate.match( /^\d\d\d\d-\d\d-\d\d$/ ) && Number( strDate.replace( /-/g, '' ) ) >= intNowYYYYMMDD1 ) {
																			isDateTodayOrFurtherOrAnyDay = true;
																		} else if ( strDate === 'any' ) {
																			isDateTodayOrFurtherOrAnyDay = true;
																		}
																		if ( isDateTodayOrFurtherOrAnyDay ) {
																			this.colProfiles.getMyUserProfile( (userProfile: InterfaceOWUser | null): void => {
																				if ( !this.purchasedTicketsByVisitDate.hasOwnProperty( strDate ) ) {
																					this.purchasedTicketsByVisitDate[ strDate ] = {
																						consumerWithTicket: [],
																						parking: [],
																						cabanas: []
																					};
																					if ( this.currentOrFutureVisitDates.filter( (str: string): boolean => str === strDate ).length < 1 ) {
																						this.currentOrFutureVisitDates.push( strDate );
																					}
																				}
																				if ( userProfile?.doclet_id && userProfile?.data ) {
																					this.purchasedTicketsByVisitDate[ strDate ].consumerWithTicket.push( {
																						consumer: {
																							_id: {
																								$oid: userProfile.doclet_id
																							},
																							data: userProfile.data,
																							stats: undefined,
																							status: undefined,
																							tags: [],
																							template_id: {
																								$oid: this.strConsumerTemplateID
																							},
																							tc: '',
																							tc_raw: { $date: 0 },
																							tc_utc: '',
																							tc_utc_raw: { $date: 0 },
																							title: '',
																							user_id_created: undefined,
																							workspace_id: {
																								$oid: this.appConfig.getWorkspaceID()
																							}
																						} as InterfaceOWDoclet,
																						ticket: rogueDailyAdmissionTicket
																					} );
																				}
																			} );
																		} else { // end if the ticket is for today or later or 'any'
																			// TODO: shove them into visit-history.
																		}
																	} // end if the ticket date exists
																	break;
																}
															} // end switch ticket.data.status
														} // end if the result is a doclet.
													} // end if fetch doclet was successful.
												} ); // end fetch doclet by ID.
											} // end if the ticket is not already woven to an order.
										} // end if fetch-weaves had results.
									} // end if we successfully made an api request.
									--rogueTicketIDsRemaining;
									if ( rogueTicketIDsRemaining < 1 && rogueCabanaIDsRemaining < 1 ) {
										this.rogueTicketsChecking = false;
										this.rogueTicketsLoaded = true;
									}
								} );
							} // end for each rogue daily ticket to check
							// ===== //
							for ( let x: number = 0; x < rogueCabanaTicketIDsToCheck.length; ++x ) {
								const rogueCabanaTicketID: string = rogueCabanaTicketIDsToCheck[x];
								this.owapi.workspace.doclets.getWeavesByDocletID( appContext, rogueCabanaTicketID ).subscribe( (rogueTicketResponse: InterfaceHTTPGateway): void => {
									if ( rogueTicketResponse?.success ) {
										const rogueTicketAPIResponse: InterfaceOWAPIGetWeavesResponse = rogueTicketResponse.data;
										if ( Array.isArray( rogueTicketAPIResponse?.data?.items ) && rogueTicketAPIResponse.data.items.length > 0 ) {
											const rogueTicketWeaves: InterfaceOWWeaveV2[] = rogueTicketAPIResponse.data.items;
											const ticketIsWovenToOrder: boolean = rogueTicketWeaves.filter( (w: InterfaceOWWeaveV2): boolean => {
												return w.t_id.$oid === this.strOrderTemplateID;
											} ).length > 0;
											if ( !ticketIsWovenToOrder ) {
												this.owapi.workspace.doclets.getDocletByID( appContext, rogueCabanaTicketID ).subscribe( (rogueTicketResponse: InterfaceHTTPGateway): void => {
													if ( rogueTicketResponse?.success ) {
														const rogueTicketAPIResponse: InterfaceOWAPIGetDocletResponse = rogueTicketResponse.data;
														if ( rogueTicketAPIResponse?.data?._id?.$oid ) {
															const rogueCabanaTicket: InterfaceOWDoclet = rogueTicketAPIResponse.data;
															switch ( rogueCabanaTicket?.data?.['status'] ) {
																case 'activated':
																case 'active':
																case 'pending': {
																	if ( typeof rogueCabanaTicket.data['date'] === 'string' && rogueCabanaTicket.data['date'].length > 0 ) {
																		const strDate: string = rogueCabanaTicket.data['date'];
																		let isDateTodayOrFurtherOrAnyDay: boolean = false;
																		if ( strDate.match( /^\d\d\d\d-\d\d-\d\d$/ ) && Number( strDate.replace( /-/g, '' ) ) >= intNowYYYYMMDD1 ) {
																			isDateTodayOrFurtherOrAnyDay = true;
																		} else if ( strDate === 'any' ) {
																			isDateTodayOrFurtherOrAnyDay = true;
																		}
																		if ( isDateTodayOrFurtherOrAnyDay ) {
																			if ( !this.purchasedTicketsByVisitDate.hasOwnProperty( strDate ) ) {
																				this.purchasedTicketsByVisitDate[strDate] = {
																					consumerWithTicket: [],
																					parking: [],
																					cabanas: []
																				};
																			}
																			this.purchasedTicketsByVisitDate[strDate].cabanas.push( rogueCabanaTicket );
																		}
																	} // end if the cabana has a date
																	break;
																}
															}  // end switch cabana.data.status
														} // end if the result is a doclet
													} // end if the network request succeeded.
												} ); // end get doclet by ID
											} // end if the cabana is not already woven to an order.
										} // end if the api request had results.
									} // end if the api request succeeded.
									--rogueCabanaIDsRemaining;
									if ( rogueTicketIDsRemaining < 1 && rogueCabanaIDsRemaining < 1 ) {
										this.rogueTicketsChecking = false;
										this.rogueTicketsLoaded = true;
									}
								} );
							} // end for each rogue cabana ticket.
							// ===== //
							if ( !familyDocletKnown ) {
								console.log( 'No Family doclet woven to the user.' );
								this.reservationForm.flags.capacityLoaded = true; // no family, no way to fetch dailyCapacity.
								this.fetchOrderWeaves(); // since we're not going to call it, when familyWeavesResolved happens...
							}
							// missing ext[] data
							// cannot continue without knowing who is the primary user.
						} // end if there are weaves on the user.
					} // end if the network request worked to fetch a users' weaves.
				} );
			} else {
				console.log( 'No user Doclet ID, cannot fetch family data.' );
			}
		} );
	}

	private familyWeaveResolved(): void {
		if ( !this.familyWeaves.flags.loaded && ++this.familyWeaves.flags.membersAcquired >= this.familyWeaves.flags.totalMembers ) {
			this.familyWeaves.data.members.sort( (A: InterfaceFamilyWeavesDataMembers, B: InterfaceFamilyWeavesDataMembers): number => {
				const tierA: number = A.ticket.data['level'] ?? 0;
				const tierB: number = B.ticket.data['level'] ?? 0;
				if ( A.ticket.data['year'] < B.ticket.data['year'] ) { // later years go further down.
					return -1;
				} else if ( A.ticket.data['year'] > B.ticket.data['year'] ) {
					return 1;
				}
				if ( tierA < tierB ) { // diamond, then gold, then silver.
					return 1;
				} else if ( tierA > tierB ) {
					return -1;
				}
				return ServiceSorting.naturalSort( A.ticket.data['assigned_first_name'] ?? '', B.ticket.data['assigned_last_name'] ?? '' );
			} );
			this.familyWeaves.flags.loaded = true;
			console.log( 'Fetched (User)<-(Family)<-(Members)<-(Tickets)' );
			this.initFamilyMembers();
			this.setReservationCalendarMonth( now.getMonth() as TypeJSMonthIdx, now.getFullYear() );
			this.fetchOrderWeaves(); // now do it again, but for orders.
		}
	}

	public viewingFamilyQR: boolean = false;

	public viewFamilyQRCodes(): void {
		this.viewingFamilyQR = true;
	}

	public getFamilyQRCode( consumer: InterfaceOWDoclet ): string {
		return JSON.stringify( {
			id: consumer._id.$oid
		} );
	}
	// ===== Family - END ===== //

	private loopExchangeWovenIDsForDoclets( docletIDs: string[], idxStart: number, batchAmount: number, callback: () => void ): void {
		this.owapi.workspace.doclets.getDocletByID( this.appConfig.getContext(), docletIDs.slice( idxStart, idxStart + batchAmount ) ).subscribe( (response: InterfaceHTTPGateway): void => {
			if ( response && response.success ) {
				const apiMixedResponse: InterfaceOWAPIGetDocletResponse | InterfaceOWAPIGetDocletsResponse = response.data;
				// regardless of how many doclet IDs were handed over, if one doclet was returned, it's GetDocletResponse, but if multiple were returned, it's GetDocletsResponse (plural)
				if ( apiMixedResponse.data.hasOwnProperty( '_id' ) ) {
					const apiResponse: InterfaceOWAPIGetDocletResponse = response.data; // response.data.data is the record itself.
					this.docletCache[ apiResponse.data._id.$oid ] = apiResponse.data;
				} else if ( Array.isArray( (apiMixedResponse as InterfaceOWAPIGetDocletsResponse).data?.items ) ) {
					const apiResponse: InterfaceOWAPIGetDocletsResponse = response.data; // response.data.data.items an array of records.
					apiResponse.data.items.forEach( (doclet: InterfaceOWDoclet): void => {
						this.docletCache[ doclet._id.$oid ] = doclet;
					} );
				}
			}
			idxStart += batchAmount;
			if ( idxStart < docletIDs.length ) {
				this.loopExchangeWovenIDsForDoclets( docletIDs, idxStart, batchAmount, callback );
			} else {
				callback();
			}
		} );
	}

	private exchangeWovenIDsForDoclets(): void {
		// part 3!!!
		// once the wovenOrdersIDs is populated, we can fetch doclets to populate wovenOrdersDoclets,
		// ...so that we can build a listing with content that isn't just IDs.
		//
		const allDocletIDs: string[] = [];
		const typeCounts: { [ticketType: string]: number; } = {
			orders: this.wovenOrdersIDs.length,
			daily: 0,
			parking: 0,
			cabana: 0
		};
		for ( let x: number = 0; x < this.wovenOrdersIDs.length; ++x ) {
			allDocletIDs.push( this.wovenOrdersIDs[x].orderID );
			for ( let y: number = 0; y < this.wovenOrdersIDs[x].consumerIDWithTicketID.length; ++y ) {
				allDocletIDs.push( this.wovenOrdersIDs[x].consumerIDWithTicketID[y].consumerID );
				allDocletIDs.push( this.wovenOrdersIDs[x].consumerIDWithTicketID[y].ticketID );
				++typeCounts['daily'];
			}
			for ( let y: number = 0; y < this.wovenOrdersIDs[x].parkingIDs.length; ++y ) {
				allDocletIDs.push( this.wovenOrdersIDs[x].parkingIDs[y] );
				++typeCounts['parking'];
			}
			for ( let y: number = 0; y < this.wovenOrdersIDs[x].cabanaIDs.length; ++y ) {
				allDocletIDs.push( this.wovenOrdersIDs[x].cabanaIDs[y] );
				++typeCounts['cabana'];
			}
		}
		// putting docletIDs on the url makes it break once we reach the upper limit of how many char's can go into a URL.
		// it's somewhere below 7k characters. it's also a server-side issue, not just a browser issue.
		// IDs are 24 chars. and the comma is another, but a comma is escaped into %2C.
		// so about 27 chars per doclet ID. 2048 bytes / 30 is about 68 IDs.
		// but the API limits things to 20 now, unless you override it using the new pagination flags.
		//
		allDocletIDs.sort(); // sort + unique.
		for ( let x: number = 1; x < allDocletIDs.length; ++x ) {
			if ( allDocletIDs[x - 1] === allDocletIDs[x] ) {
				allDocletIDs.splice( x--, 1 );
			}
		}
		this.loopExchangeWovenIDsForDoclets( allDocletIDs, 0, 20, (): void => {
			console.log( 'Fetched all woven doclets.' );
			for ( let x: number = 0; x < this.wovenOrdersIDs.length; ++x ) {
				const wovenOrderDoclet: InterfaceWovenOrderDoclets = {
					order: this.docletCache[ this.wovenOrdersIDs[x].orderID ],
					consumerWithTicket: [],
					parking: [],
					cabanas: []
				};
				for ( let y: number = 0; y < this.wovenOrdersIDs[x].consumerIDWithTicketID.length; ++y ) {
					const docC: string = this.wovenOrdersIDs[x].consumerIDWithTicketID[y].consumerID;
					const docT: string = this.wovenOrdersIDs[x].consumerIDWithTicketID[y].ticketID;
					if ( docC && docT ) {
						wovenOrderDoclet.consumerWithTicket.push( {
							consumer: this.docletCache[ docC ],
							ticket: this.docletCache[ docT ]
						} );
					} else {
						console.log( 'Missing doclet by ID', !docC ? this.wovenOrdersIDs[x].consumerIDWithTicketID[y].consumerID : '', !docT ? this.wovenOrdersIDs[x].consumerIDWithTicketID[y].ticketID : '' );
					}
				}
				for ( let y: number = 0; y < this.wovenOrdersIDs[x].parkingIDs.length; ++y ) {
					const doc: InterfaceOWDoclet | null = this.docletCache[ this.wovenOrdersIDs[x].parkingIDs[y] ];
					if ( doc ) {
						wovenOrderDoclet.parking.push( doc );
					} else {
						console.log( 'Missing doclet by ID', this.wovenOrdersIDs[x].parkingIDs[y] );
					}
				}
				for ( let y: number = 0; y < this.wovenOrdersIDs[x].cabanaIDs.length; ++y ) {
					const doc: InterfaceOWDoclet | null = this.docletCache[ this.wovenOrdersIDs[x].cabanaIDs[y] ];
					if ( doc ) {
						wovenOrderDoclet.cabanas.push( doc );
					} else {
						console.log( 'Missing doclet by ID', this.wovenOrdersIDs[x].cabanaIDs[y] );
					}
				}
				this.wovenOrdersDoclets.push( wovenOrderDoclet );
			} // end for each woven order ID, and it's junk.
			for ( let x: number = 0; x < this.wovenOrdersDoclets.length; ++x ) {
				for ( let y: number = 0; y < this.wovenOrdersDoclets[x].consumerWithTicket.length; ++y ) {
					const cwt: InterfaceWovenOrderDocletsConsumerWithTicket = this.wovenOrdersDoclets[x].consumerWithTicket[y];
					if ( cwt && cwt.ticket && cwt.ticket.data ) {
						switch ( cwt.ticket.data['status'] ) {
							case 'pending': // if they're not yet in the park
								// TODO: if its' active, then it ought to be in the visit-history, instead.
							case 'active': // if they're in the park
							case 'activated': { // if they're in the park
								if ( typeof cwt.ticket.data['date'] === 'string' && cwt.ticket.data['date'].length > 0 ) {
									if ( !this.purchasedTicketsByVisitDate.hasOwnProperty( cwt.ticket.data['date'] ) ) {
										this.purchasedTicketsByVisitDate[ cwt.ticket.data['date'] ] = {
											consumerWithTicket: [],
											parking: [],
											cabanas: []
										};
									}
									this.purchasedTicketsByVisitDate[ cwt.ticket.data['date'] ].consumerWithTicket.push( cwt );
								}
								break;
							}
							default: { // cancelled
								break;
							}
						} // end switch daily-ticket status
					} // end if the admission ticket has a date
				} // end for each consumer with tickets.
				for ( let y: number = 0; y < this.wovenOrdersDoclets[x].parking.length; ++y ) {
					const pt: InterfaceOWDoclet = this.wovenOrdersDoclets[x].parking[y];
					switch ( pt.data['status'] ) {
						case 'pending':
						case 'active':
						case 'activated': {
							if ( pt && pt.data && typeof pt.data['date'] === 'string' && pt.data['date'].length > 0 ) {
								if ( !this.purchasedTicketsByVisitDate.hasOwnProperty( pt.data['date'] ) ) {
									this.purchasedTicketsByVisitDate[ pt.data['date'] ] = {
										consumerWithTicket: [],
										parking: [],
										cabanas: []
									};
								}
								this.purchasedTicketsByVisitDate[ pt.data['date'] ].parking.push( pt );
							} // end if the parking ticket has a date
							break;
						}
					} // end switch parking ticket status
				} // end for each parking ticket.
				for ( let y: number = 0; y < this.wovenOrdersDoclets[x].cabanas.length; ++y ) {
					const ct: InterfaceOWDoclet = this.wovenOrdersDoclets[x].cabanas[y];
					switch ( ct.data['status'] ) {
						case 'pending':
						case 'active':
						case 'activated': {
							if ( ct && ct.data && typeof ct.data['date'] && ct.data['date'].length > 0 ) {
								if ( !this.purchasedTicketsByVisitDate.hasOwnProperty( ct.data['date'] ) ) {
									this.purchasedTicketsByVisitDate[ ct.data['date'] ] = {
										consumerWithTicket: [],
										parking: [],
										cabanas: []
									};
								}
								this.purchasedTicketsByVisitDate[ ct.data['date'] ].cabanas.push( ct );
							} // end if the cabana ticket had a date.
							break;
						}
					} // end switch cabana ticket status
				} // end for each cabana ticket.
			} // end for each ticket.
			this.dateKeysByPurchasedTicketDates = Object.keys( this.purchasedTicketsByVisitDate ).sort( ServiceSorting.naturalSort );
			// filter away
			let hasAny: boolean = false;
			for ( let x: number = 0; x < this.dateKeysByPurchasedTicketDates.length; ++x ) {
				if ( this.dateKeysByPurchasedTicketDates[x] === 'any' ) {
					hasAny = true;
				} else if ( this.dateKeysByPurchasedTicketDates[x].match( /^\d\d\d\d-\d\d-\d\d$/ ) ) {
					const intDate: number = Number( this.dateKeysByPurchasedTicketDates[x].replace( /-/g, '' ) );
					if ( intDate >= intNowYYYYMMDD1 ) { // if it's for today, or for the future.
						if ( this.currentOrFutureVisitDates.filter( (str: string): boolean => str === this.dateKeysByPurchasedTicketDates[x] ).length < 1 ) {
							this.currentOrFutureVisitDates.push( this.dateKeysByPurchasedTicketDates[x] );
						}
					} // else is for the past year...
				} else if ( this.dateKeysByPurchasedTicketDates[x].indexOf( this.seasonPassYear ) === 0 ) {
					if ( this.currentOrFutureVisitDates.filter( (str: string): boolean => str === this.dateKeysByPurchasedTicketDates[x] ).length < 1 ) {
						this.currentOrFutureVisitDates.push( this.dateKeysByPurchasedTicketDates[x] );
					}
				}
			}
			if ( hasAny ) { // send it to the top.
				this.currentOrFutureVisitDates.unshift( 'any' ); // .push_front()
			}
			this.wovenOrdersDocletsLoaded = true;
			this.buildPastVisits();
		} );
	}

	private figureOutWhoseTicketsTheseAre( ordersWithTickets: InterfaceOrderWithTickets[] ): void { // they came from the weaves on orders.
		// this is part 2 for fetching orders.
		// once we have orderIDs with linked ticketIDs, we need to find the Consumer that is linked to each ticket.
		//
		// for each order, loop over the ticketIDs.
		console.log( 'Fetching (Consumer)<-(Ticket) by woven orderID' );
		let remainingOrdersWithTickets: number = ordersWithTickets.length;
		for ( let x: number = 0; x < ordersWithTickets.length; ++x ) {
			const wovenOrderIDs: InterfaceWovenOrderIDs = {
				orderID: ordersWithTickets[x].orderID,
				consumerIDWithTicketID: [], // if no consumer with ticket, or parking, or cabana, then don't push this onto the listing.
				parkingIDs: [],
				cabanaIDs: []
			};
			// for each ticket, grab the weaves on the ticket to try and find a Consumer.
			let remainingTicketIDs: number = ordersWithTickets[x].ticketIDs.length;
			// already know which parking and cabana tickets belong to this order. just toss them in.
			for ( let y: number = 0; y < ordersWithTickets[x].parkingIDs.length; ++y ) {
				wovenOrderIDs.parkingIDs.push( ordersWithTickets[x].parkingIDs[y] );
			}
			for ( let y: number = 0; y < ordersWithTickets[x].cabanaIDs.length; ++y ) {
				wovenOrderIDs.cabanaIDs.push( ordersWithTickets[x].cabanaIDs[y] );
			}
			if ( ordersWithTickets[x].ticketIDs.length < 1 ) {
				// ----- copy/paste ----- //
				if ( wovenOrderIDs.consumerIDWithTicketID.length > 0 || wovenOrderIDs.parkingIDs.length > 0 || wovenOrderIDs.cabanaIDs.length > 0 ) {
					// orders without tickets are bugs, ignore them.
					this.wovenOrdersIDs.push( wovenOrderIDs );
				}
				if ( --remainingOrdersWithTickets === 0 ) {
					console.log( 'Finished fetching (Consumer)<-(Ticket) by woven orderID - A' );
					this.exchangeWovenIDsForDoclets();
				}
				// ----- copy/paste ----- //
			}
			for ( let y: number = 0; y < ordersWithTickets[x].ticketIDs.length; ++y ) {
				this.owapi.workspace.doclets.getWeavesByDocletID( this.appConfig.getContext(), ordersWithTickets[x].ticketIDs[y] ).subscribe( (responseWeavesOnTicket: InterfaceHTTPGateway): void => {
					if ( responseWeavesOnTicket?.success ) {
						const apiResponseWeavesOnTicket: InterfaceOWAPIGetWeavesResponse = responseWeavesOnTicket?.data;
						if ( apiResponseWeavesOnTicket && Array.isArray( apiResponseWeavesOnTicket?.data?.items ) ) {
							const weavesOnTicket: InterfaceOWWeaveV2[] = apiResponseWeavesOnTicket.data.items;
							for ( let z: number = 0; z < weavesOnTicket.length; ++z ) {
								switch ( weavesOnTicket[z].t_id.$oid ) {
									case this.strConsumerTemplateID: { // Consumer
										wovenOrderIDs.consumerIDWithTicketID.push( {
											consumerID: weavesOnTicket[z].c_id.$oid, // ordinary daily tickets are woven to the purchaser. unlike SPH tickets.
											ticketID: ordersWithTickets[x].ticketIDs[y]
										} );
										break;
									}
									case this.strParkingTicketTemplateID: { // Parking
										wovenOrderIDs.parkingIDs.push( weavesOnTicket[z].c_id.$oid );
										break;
									}
									default: { // maybe it's one of the 6 Cabanas?
										for ( let cti: number = 0; cti < this.arrCabanaTemplateIDs.length; ++cti ) {
											if ( weavesOnTicket[z].t_id.$oid === this.arrCabanaTemplateIDs[cti] ) {
												wovenOrderIDs.cabanaIDs.push( weavesOnTicket[z].c_id.$oid );
											}
										}
										break;
									}
								} // end switch templateID for the woven entry. (weaves on the ticket)
							} // end for each weave on the ticket
							// ----- copy/paste ----- //
							if ( --remainingTicketIDs === 0 ) {
								if ( wovenOrderIDs.consumerIDWithTicketID.length > 0 || wovenOrderIDs.parkingIDs.length > 0 || wovenOrderIDs.cabanaIDs.length > 0 ) {
									// orders without tickets are bugs, ignore them.
									this.wovenOrdersIDs.push( wovenOrderIDs );
								}
								if ( --remainingOrdersWithTickets === 0 ) {
									console.log( 'Finished fetching (Consumer)<-(Ticket) by woven orderID - B' );
									this.exchangeWovenIDsForDoclets();
								}
							}
							// ----- copy/paste ----- //
						}
					}
				} ); // end fetch weaves on the ticket.
			} // end for each order with tickets to figure out.
		} // end for each orders with tickets.
	}

	private fetchOrderWeaves(): void {
		// Orders are woven to Tickets and Consumers (and Transactions and other things)
		// for each weave on an order, grab the ticketIDs.
		// later, we'll figure out who has what ticket, because each person is also woven to the same order as the tickets.
		console.log( 'Fetching (Order)<-(Ticket) by orderIDs.' );
		let orderIDs: string[] = Object.keys( this.orderDocletIDs );
		const ordersWithTickets: InterfaceOrderWithTickets[] = []; // plural, |orders|
		let orderIDsRemaining: number = orderIDs.length;
		if ( orderIDs.length < 1 ) {
			console.log( 'No orderIDs. woven orders loaded.' );
			this.wovenOrdersDocletsLoaded = true;
		}
		// for each order, bucket the ticketIDs up with the orderID.
		for ( let x: number = 0; x < orderIDs.length; ++x ) {
			// TODO: make it so it sends only a few network requests off at a time, instead of blasting the server with 'all' of them at once.
			this.owapi.workspace.doclets.getWeavesByDocletID( this.appConfig.getContext(), orderIDs[x] ).subscribe( (responseWeavesOnOrder: InterfaceHTTPGateway): void => {
				if ( responseWeavesOnOrder?.success ) {
					const apiResponseWeavesOnOrder: InterfaceOWAPIGetWeavesResponse = responseWeavesOnOrder?.data;
					if ( apiResponseWeavesOnOrder && Array.isArray( apiResponseWeavesOnOrder?.data?.items ) ) {
						const weaves: InterfaceOWWeaveV2[] = apiResponseWeavesOnOrder.data.items;
						const orderWithTickets: InterfaceOrderWithTickets = { // singular, |order|. not the one above that's plural, |orders|
							orderID: orderIDs[x],
							ticketIDs: [],
							parkingIDs: [],
							cabanaIDs: []
						};
						// for each weave on this order, hunt down which ones are tickets.
						for ( let w: number = 0; w < weaves.length; ++w ) {
							switch ( weaves[w].t_id.$oid ) {
								// case this.strConsumerTemplateID:
								// case this.strTransactionTemplateID: {
								// 	break; // not these.
								// }
								case this.strSeasonAdmissionTicketTemplateID: {
									//
									break;
								}
								case this.strDailyAdmissionTicketTemplateID: {
									orderWithTickets.ticketIDs.push( weaves[w].c_id.$oid );
									break;
								}
								case this.strParkingTicketTemplateID: {
									orderWithTickets.parkingIDs.push( weaves[w].c_id.$oid );
									break;
								}
								default: { // maybe it's one of the 6 cabanas.
									for ( let cti: number = 0; cti < this.arrCabanaTemplateIDs.length; ++cti ) {
										if ( weaves[w].t_id.$oid === this.arrCabanaTemplateIDs[cti] ) {
											orderWithTickets.cabanaIDs.push( weaves[w].c_id.$oid );
											break;
										}
									}
									break;
								}
							} // end switch template_id for what's woven to an order.
						} // end for of the woven entries to an order.
						if ( orderWithTickets.ticketIDs.length > 0 || orderWithTickets.parkingIDs.length > 0 || orderWithTickets.cabanaIDs.length > 0 ) {
							ordersWithTickets.push( orderWithTickets );
						} else {
							// console.log( 'order with no IDs', orderWithTickets );
						}
						if ( --orderIDsRemaining === 0 ) {
							console.log( 'Finished fetching (Order)<-(Ticket) by orderIDs' );
							if ( ordersWithTickets.length > 0 ) {
								this.figureOutWhoseTicketsTheseAre( ordersWithTickets ); // ordersWithTickets is an array of skeletons schema: { "ticketType" : [ docletID1, docletID2, ...] }
							} else {
								console.log( 'No woven orders to process. woven orders loaded.' );
								this.wovenOrdersDocletsLoaded = true;
							}
						}
					}
				}
			} );
		} // end for each orderID to fetch weaves for.
	}

	// ===== Park Visit Details - BEGIN ===== //
	public parkVisitDetails: { // view, edit, cancel. SPH only
		active: boolean; // shows the form, or not.
		groupReservation: InterfaceGroupReservation | undefined; // the reservation to mess with.
		groupMembers: InterfaceGroupReservationData[]; // member and ticket details for this group.
		panel: TypeParkVisitPanel; // controls which panel (or step) to show.
		antiClickTimer: Date; // used to prevent users from accidentally clicking the next/kill/cancel buttons quickly on the next form...
		busy: boolean; // true when waiting for server-side to do it's thing.
	} = {
		active: false,
		groupReservation: undefined,
		groupMembers: [],
		panel: 'visit-overview',
		antiClickTimer: new Date(),
		busy: false
	};
	public purchasedTicketsVisitDetails: {
		YYYYMMDD1: string; // YYYY-MM-DD where MM is 01 through 12
		visit: InterfacePurchasedTicketsForVisit;
	} | undefined = undefined;
	public cancelParkVisitErrors: {
		reason: boolean;
	} = {
		reason: false
	};

	public getSAPTierText( ticket: InterfaceOWDoclet ): string {
		return TransformerVenuePassportTicket.getSAPTierText( ticket );
	}

	private getAntiClickTimer(): Date {
		return new Date( new Date().getTime() + 1000 ); // just some time into the future, not a lot...
	}

	private getParkVisitMemberDetails( groupReservation: InterfaceGroupReservation ): void {
		const memberIDsMissingData: string[] = [];
		this.parkVisitDetails.groupMembers = [];
		for ( let x: number = 0; x < groupReservation.memberIDs.length; ++x ) {
			// pull in the members for this group, from the family weave data.
			// it's possible that there are members on this reservation that no longer exist on the family weave,
			// ...so we will need to re-fetch those.
			let memberDataFound: boolean = false;
			for ( let y: number = 0; !memberDataFound && y < this.familyWeaves.data.members.length; ++y ) {
				if ( this.familyWeaves.data.members[y].member._id.$oid === groupReservation.memberIDs[x] ) {
					this.parkVisitDetails.groupMembers.push( {
						placeholder: false,
						memberID: groupReservation.memberIDs[x],
						member: this.familyWeaves.data.members[y].member,
						ticket: this.familyWeaves.data.members[y].ticket
					} );
					memberDataFound = true;
				}
			}
			if ( !memberDataFound ) {
				let placeholderMember: InterfaceGroupReservationData = {
					placeholder: true,
					memberID: groupReservation.memberIDs[x],
					member: undefined,
					ticket: undefined
				}
				this.parkVisitDetails.groupMembers.push( placeholderMember );
				memberIDsMissingData.push( groupReservation.memberIDs[x] );
				// TODO: fetch the user by ID, then i guess fetch it's weaves to try and find the ticket.
				// but you don't know which ticket is for this park visit, because if it existed, we would of already had it in the cache, so...
				// ...but if you can fetch it, just update the placeholderMember fields, because it's already in the array as a reference.
				// remember to set placeholder to false, if you get both the ticket and the member...
			} // end if this members data is missing from the cached data off of the family weaves.
		} // end for each person in the group reservation.
	}

	public showQRCode( code: string | InterfaceAnyObject, qrTitle?: string ): void {
		if ( typeof code === 'string' ) {
			this.activeQR = code;
		} else {
			this.activeQR = JSON.stringify( code );
		}
		this.activeQRTitle = qrTitle ?? '';
	}

	public hideQRCode(): void {
		this.activeQR = '';
		this.activeQRTitle = '';
	}

	public showParkVisitDetails( groupReservation: InterfaceGroupReservation ): void {
		this.getParkVisitMemberDetails( groupReservation );
		this.parkVisitDetails.groupReservation = groupReservation;
		this.parkVisitDetails.panel = 'visit-overview';
		this.parkVisitDetails.antiClickTimer = this.getAntiClickTimer();
		this.parkVisitDetails.active = true;
		this.cancelParkVisitErrors.reason = false;
	}

	public closeParkVisitDetails(): void {
		this.parkVisitDetails.groupReservation = undefined;
		this.parkVisitDetails.active = false;
		this.parkVisitDetails.panel = 'visit-overview';
		this.parkVisitDetails.antiClickTimer = new Date( 0 );
		this.cancelParkVisitErrors.reason = false;
	}

	public showPurchasedTicketVisitDetailsByDate( YYYYMMDD1: string ): void {
		if ( this.purchasedTicketsByVisitDate.hasOwnProperty( YYYYMMDD1 ) ) {
			this.purchasedTicketsVisitDetails = {
				YYYYMMDD1: YYYYMMDD1,
				visit: this.purchasedTicketsByVisitDate[YYYYMMDD1]
			}
		}
	}

	public hidePurchasedTicketVisitDetails(): void {
		this.purchasedTicketsVisitDetails = undefined;
	}

	public changePanel( panel: TypeParkVisitPanel, debounce?: true ): void {
		if ( this.parkVisitDetails.busy ) {
			return;
		}
		if ( debounce && new Date().getTime() < this.parkVisitDetails.antiClickTimer.getTime() ) {
			return; // clicked too fast... trying to prevent an accidental click.
		}
		this.parkVisitDetails.antiClickTimer = this.getAntiClickTimer();
		this.parkVisitDetails.panel = panel;
	}

	public cancelReservation(): void {
		if ( this.parkVisitDetails.busy || !this.parkVisitDetails.groupReservation ) {
			return;
		}
		if ( new Date().getTime() < this.parkVisitDetails.antiClickTimer.getTime() ) {
			return; // clicked too fast... trying to prevent an accidental click.
		}
		const textarea: HTMLTextAreaElement | undefined = this.cancellationReason && this.cancellationReason.nativeElement ? this.cancellationReason.nativeElement as HTMLTextAreaElement : undefined;
		if ( textarea ) {
			let strReason: string = textarea.value.replace( ServiceRegex.trimRegExp, '' );
			if ( strReason.length < 5 ) {
				this.cancelParkVisitErrors.reason = true;
			} else {
				const parkVisitID: string = this.parkVisitDetails.groupReservation.parkVisitID;
				this.owapi.workspace.actions.core.cancelSeasonPassGroupEvent( this.appConfig.getContext(), parkVisitID, strReason ).subscribe( (response: InterfaceHTTPGateway): void => {
					this.parkVisitDetails.busy = false;
					if ( response && response.success && response.status >= 200 && response.status < 300 ) {
						const apiResponse: InterfaceOWAPIResponse = response.data;
						for ( let x: number = 0; x < this.groupReservations.length; ++x ) {
							if ( this.groupReservations[x].parkVisitID === parkVisitID ) {
								this.groupReservations[x].status = 'canceled';
								this.pastGroupReservations.push( this.groupReservations.splice( x, 1 ).pop() as InterfaceGroupReservation );
								// TODO: need to re-sync the merging of group reservations & order history by date.
								break;
							}
						}
						this.reservationForm.cache.datesUsed1 = {};
						for ( let x: number = 0; x < this.groupReservations.length; ++x ) {
							this.reservationForm.cache.datesUsed1[ this.groupReservations[x].strDateYYYYMMDD1 ] = true;
						}
						this.fetchDailyCapacity();
						this.sortFamilyReservationsByDate();
					} else {
						console.log( 'Failed to cancel a reservation', response );
						alert( 'An error occurred. Please try again later.' );
					}
					this.closeParkVisitDetails();
				} );
			}
		} else {
			return; // page not yet loaded?!?
		}
	}
	// ===== Park Visit Details - END ===== //

	private buildPastVisits(): void {
		this.pastVisits = [];
		const pastVisitByDate: {
			[YYYYMMDD: string]: InterfacePastVisit;
		} = {};
		for ( let x: number = 0; x < this.pastGroupReservations.length; ++x ) {
			if ( this.pastGroupReservations[x].status === 'canceled' || (this.pastGroupReservations[x].memberIDs.length < 1 && !this.pastGroupReservations[x].parkingID && this.pastGroupReservations[x].cabanaIDs.length < 1) ) {
				continue;
			}
			const strYYYYMMDD1: string = this.pastGroupReservations[x].date.year + ('0' + this.pastGroupReservations[x].date.month1).slice( -2 ) + ('0' + this.pastGroupReservations[x].date.day).slice( -2 );
			if ( Number( strYYYYMMDD1 ) >= intNowYYYYMMDD1 ) {
				continue; // date was in the future, or is today.
			}
			// format is YYYYMMDD and not YYYY-MM-DD
			if ( !pastVisitByDate.hasOwnProperty( strYYYYMMDD1 ) ) {
				pastVisitByDate[ strYYYYMMDD1 ] = {
					date: {
						year: this.pastGroupReservations[x].date.year,
						month1: this.pastGroupReservations[x].date.month1,
						day: this.pastGroupReservations[x].date.day
					},
					memberIDs: [],
					parkingIDs: [],
					cabanaIDs: []
				};
			}
			for ( let y: number = 0; y < this.pastGroupReservations[x].memberIDs.length; ++y ) {
				pastVisitByDate[ strYYYYMMDD1 ].memberIDs.push( this.pastGroupReservations[x].memberIDs[y] );
			}
			if ( typeof this.pastGroupReservations[x].parkingID === 'string' && this.pastGroupReservations[x].parkingID!.length > 0 ) {
				pastVisitByDate[ strYYYYMMDD1 ].parkingIDs.push( this.pastGroupReservations[x].parkingID as string );
			}
			for ( let y: number = 0; y < this.pastGroupReservations[x].cabanaIDs.length; ++y ) {
				pastVisitByDate[ strYYYYMMDD1 ].cabanaIDs.push( this.pastGroupReservations[x].cabanaIDs[y] );
			}
		}
		for ( let x: number = 0; x < this.dateKeysByPurchasedTicketDates.length; ++x ) {
			const strYYYYMMDD1: string = this.dateKeysByPurchasedTicketDates[x];
			// format is YYYY-MM-DD.
			if ( strYYYYMMDD1 === 'any' ) {
				continue; // these are never in the past...
			}
			if ( Number( strYYYYMMDD1.replace( /-/g, '' ) ) >= intNowYYYYMMDD1 ) {
				continue; // date is in the future, or is today.
			}
			const intYear: number = Number( strYYYYMMDD1.slice( 0, 4 ) );
			const intMonth1: number = Number( strYYYYMMDD1.slice( 5, 7 ) );
			const intDay: number = Number( strYYYYMMDD1.slice( 8, 10 ) );
			if ( !pastVisitByDate.hasOwnProperty( strYYYYMMDD1 ) ) {
				pastVisitByDate[ strYYYYMMDD1 ] = {
					date: {
						year: intYear,
						month1: intMonth1,
						day: intDay
					},
					memberIDs: [],
					parkingIDs: [],
					cabanaIDs: []
				};
			}
			for ( let y: number = 0; y < this.purchasedTicketsByVisitDate[ strYYYYMMDD1 ].consumerWithTicket.length; ++y ) {
				const cwt: InterfaceWovenOrderDocletsConsumerWithTicket = this.purchasedTicketsByVisitDate[ strYYYYMMDD1 ].consumerWithTicket[y];
				pastVisitByDate[ strYYYYMMDD1 ].memberIDs.push( cwt.consumer._id.$oid );
			}
			for ( let y: number = 0; y < this.purchasedTicketsByVisitDate[ strYYYYMMDD1 ].parking.length; ++y ) {
				pastVisitByDate[ strYYYYMMDD1 ].parkingIDs.push( this.purchasedTicketsByVisitDate[ strYYYYMMDD1 ].parking[y]._id.$oid );
			}
			for ( let y: number = 0; y < this.purchasedTicketsByVisitDate[ strYYYYMMDD1 ].cabanas.length; ++y ) {
				pastVisitByDate[ strYYYYMMDD1 ].cabanaIDs.push( this.purchasedTicketsByVisitDate[ strYYYYMMDD1 ].cabanas[y]._id.$oid );
			}
		}
		const pastVisitDates: string[] = Object.keys( pastVisitByDate );
		for ( let x: number = 0; x < pastVisitDates.length; ++x ) {
			this.pastVisits.push( pastVisitByDate[ pastVisitDates[x] ] );
		}
		this.pastVisits.sort( (A: InterfacePastVisit, B: InterfacePastVisit): number => {
			return (A.date.year * 10000 + A.date.month1 * 100 + A.date.day) - (B.date.year * 10000 + B.date.month1 * 100 + B.date.day);
		} );
	}

	private setCashlessToggleBox( b: boolean ): void {
		// angular won't update the child component because the @Input() doesn't see a change.
		// it looks at references rather than values, and the variable's reference didn't change.
		this.primaryAccountHolder.cashlessSpending = b;
		setTimeout( (): void => { console.log( 'set cashless' +
			' to', this.primaryAccountHolder.cashlessSpending ); }, 1400 );
		this.recycleTB = false;
		setTimeout( (): void => {
			this.recycleTB = true;
		}, 10 );
		// */
	}

	public getAdmissionTicketType( ticket: InterfaceOWDoclet ): string {
		// the transformer isn't a public property of this class, so a wrapper fn must be used to statically access it.
		return TransformerVenuePassportTicket.getAdmissionTicketTypeDisplay( ticket );
	}
}
