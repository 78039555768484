import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
// ===== Interfaces ===== //
import {InterfaceAppContext, InterfaceHTTPGateway} from '../../../../interfaces/interfaces';
// ===== Services ===== //
import {ServiceAuthentication} from '../../../authentication';
import {ServiceOWGateway} from '../ow-gateway';
//
@Injectable( {
	providedIn: 'root'
} )
export class ServiceOWAPIAccountAuth {
	private readonly routePrefix: string = 'account/';
	//
	public constructor(
		private readonly auth: ServiceAuthentication,
		private readonly gateway: ServiceOWGateway
	) {
		//
	}

	public validateAuthToken(): Observable<InterfaceHTTPGateway> {
		return this.gateway.fetch( 'get', this.routePrefix + 'auth' );
	}

	public login( appContext: InterfaceAppContext, email: string, password: string ): Observable<InterfaceHTTPGateway> {
		// you log into an instance of the app (the realm and workspace)
		// not into the application definition (appRealm appWorkspace)
		return this.gateway.fetch( 'post', this.routePrefix + 'auth', JSON.stringify( {
			realm_id: appContext.realmID,
			workspace_id: appContext.workspaceID.length < 1 ? undefined : appContext.workspaceID, // the instance of the app (the venue, the park, etc)
			auth_params: {
				username: email.toLowerCase(),
				password: password
			}
		} ), true );
	}

	public updatePassword( appContext: InterfaceAppContext, email: string, oldPassword: string, newPassword: string ): Observable<InterfaceHTTPGateway> {
		// regardless of the email address used, the currently logged in users password is changed. it doesn't even have to match...
		return this.gateway.fetch( 'post', this.routePrefix + 'auth/password', JSON.stringify( {
			profile_id: this.auth.getProfileID(),
			realm_id: appContext.realmID,
			email: email,
			old_password: oldPassword,
			password: newPassword
		} ) );
		// returns status code 417 if the old password is wrong. returns status 400 if the password is incorrect.
	}

	public redeemPasswordResetToken( token: string, newPassword: string ): Observable<InterfaceHTTPGateway> {
		if ( token.length > 0 && newPassword.length > 7 ) {
			return this.gateway.fetch( 'post', this.routePrefix + 'auth/password/reset/' + encodeURIComponent( token ), JSON.stringify( {
				password: newPassword
			} ), true );
		} else {
			return this.gateway.requestDenied();
		}
		// returns 200 on success. 400 if the token is bad.
	}
}
