import {Injectable} from '@angular/core';
// ===== Services ===== //
import {ServiceOWAPIAssetsFiles} from './assets/files';
//
@Injectable( {
	providedIn: 'root'
} )
export class ServiceOWAPIAssets {
	private readonly routePrefix: string = 'assets/';
	//
	public constructor(
		public files: ServiceOWAPIAssetsFiles
	) {
		//
	}
}
