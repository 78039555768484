import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
// ===== App ===== //
import {AppRouterLinks} from '../../app.router-links';
// ===== Collections ===== //
import {CollectionProfiles} from '../../collections/profiles';
// ===== Interfaces ===== //
import {InterfaceNavMenuItem, InterfaceOWTemplateConsumer, InterfaceOWUser} from '../../interfaces/interfaces';
// ===== Services ===== //
import {ServiceAuthentication} from '../../services/authentication';
import {ServiceNavigate} from '../../services/navigate';
//
@Component( {
	selector: 'page-family',
	templateUrl: './family.html',
	styleUrls: [
		'./family.less'
	]
} )
export class PageFamily implements OnInit {
	public routes: typeof AppRouterLinks = AppRouterLinks;
	//
	public mastHeading: string = 'Hello';
	//
	public serialCode: string = '';
	//
	public navMenuItems: InterfaceNavMenuItem[] = [
		{
			route: '/' + this.routes.dashboard,
			text: 'Dashboard'
		},
		{
			route: '/' + this.routes.family,
			text: 'Manage Family',
			shortText: 'Family',
			locked: true
		},/*
		{
			route: '/' + this.routes.editGroup,
			text: 'Manage Group',
			shortText: 'Group',
			locked: false
		},*/
		{
			route: '/' + this.routes.orders,
			text: 'Orders & Billing',
			shortText: 'Billing'
		}
	];
	//
	public constructor(
		private readonly auth: ServiceAuthentication,
		private readonly colProfiles: CollectionProfiles,
		private readonly nav: ServiceNavigate,
		private readonly title: Title
	) {
		this.title.setTitle( 'Wild Rivers Portal' );
	}

	public haveUserInfo: boolean = false;
	private fetchUserInfo(): void {
		this.colProfiles.getMyUserProfile( (userData: InterfaceOWUser | null): void => {
			if ( userData && userData.data ) {
				this.haveUserInfo = true;
				const templateConsumer: InterfaceOWTemplateConsumer = userData.data as InterfaceOWTemplateConsumer;
				this.mastHeading = 'Hello, ' + templateConsumer.first_name + ' ' + templateConsumer.last_name;
			}
		} );
	}

	public ngOnInit(): void {
		if ( !this.auth.isSignedIn() ) {
			console.log( 'not signed in' );
			this.nav.toURL( '/' + this.routes.signIn );
		} else {
			this.fetchUserInfo();
		}
	}
}
