<app-header></app-header>
<app-masthead
	[mastHeading]="mastHeading"
	[navMenuItems]="navMenuItems"
></app-masthead>
<div class="wrapper">
	<section class="card columned cashless-spending">
		<div class="column">
			<h3>Cashless Spending</h3>
			<div class="cashless-toggle-wrapper">
				<div class="cashless-spending-note">
					<div>Activate in park cashless spending on your park issued Wild Rivers wristband by storing your credit card.</div>
					<div>Leave your wallet in your locker and simply tap your wristband to make any purchase throughout the park.</div>
				</div>
				<ui-toggle-box *ngIf="haveAccountInfo && recycleTB" [checked]="primaryAccountHolder.cashlessSpending" (changed)="cashlessToggle( $event );"></ui-toggle-box>
			</div>
		</div>
	</section>
	<section class="card columned sph-and-family"
		*ngIf="!noFamily && familyWeaves.flags.totalMembers > 0"
	>
		<div class="column">
			<h3>Upcoming Reservations</h3>
			<ul class="visit-list">
				<li
					*ngFor="let groupReservation of groupReservations;"
					[ngClass]="{
						'none': groupReservation.memberIDs.length < 1 || groupReservation.date.day < 1
					}"
					(click)="showParkVisitDetails( groupReservation );"
				>
					<span class="date"><b>{{ ('0' + groupReservation.date.day).slice( -2 ) }}</b><span>{{ monthLabels[ groupReservation.date.month1 - 1 ].substring( 0, 3 ) }}</span></span>
					<span class="details">
						<b>Visit for {{ groupReservation.memberIDs.length }} guest{{ groupReservation.memberIDs.length === 1 ? '' : 's' }}</b>
						<span class="visit-id">{{ groupReservation.parkVisitID.toUpperCase() }}</span>
						<span class="mobileonly tickets">
							<span
								[ngClass]="{
									'parking': true,
									'inactive': !groupReservation.parkingID
								}"
							><i class="fa-solid fa-car"></i><span>Park</span></span>
							<span class="guests"><i class="fa-solid fa-ticket"></i><span>{{ groupReservation.memberIDs.length }}</span></span>
							<span class="cabana"><i class="fa-solid fa-campground"></i><span>{{ groupReservation.cabanaIDs.length }}</span></span>
						</span>
					</span>
					<span
						[ngClass]="{
							'parking': true,
							'nomobile': true,
							'inactive': !groupReservation.parkingID
						}"
					><i class="fa-solid fa-car"></i><span>Park</span></span>
					<span class="guests nomobile"><i class="fa-solid fa-ticket"></i><span>{{ groupReservation.memberIDs.length }}</span></span>
					<span class="cabana nomobile"><i class="fa-solid fa-campground"></i><span>{{ groupReservation.cabanaIDs.length }}</span></span>
				</li>
				<li class="add"
					*ngIf="reservationForm.flags.capacityLoaded && !isParkeClosed && familyWeaves.flags.loaded && !reachedMaxReservations"
					(click)="showReservationForm();"
				>
					<i class="fa-solid fa-plus"></i>&nbsp;Create a New Reservation
				</li>

				<li class="locked"
					*ngIf="reservationForm.flags.capacityLoaded && (isParkeClosed || reachedMaxReservations)"
				>
					<i class="fa-solid fa-lock"></i>&nbsp;Maximum Reservations Reached
				</li>

				<li class="locked"
					*ngIf="!reservationForm.flags.capacityLoaded"
				>
					<i class="fa-solid fa-calendar"></i>&nbsp;Getting availability...
				</li>

				<li
					*ngIf="false"
					[ngClass]="{
						'rules': true,
						'no-margin': noFamily
					}"
				>
					<i>Maximum 5 concurrent reservations at any time</i>
					<i>Maximum 2 concurrent weekend day reservations</i>
				</li>
			</ul>
		</div>
		<div
			[ngClass]="{
				'column': true,
				'no-family': noFamily
			}"
		>
			<h3>Family Overview</h3>
			<ul class="family-list fl-1" *ngIf="familyWeaves.flags.loaded">
				<li class="view-family-qr"
					*ngIf="familyWeaves.data.members.length > 0"
					(click)="viewFamilyQRCodes();"
				>
					<span class="photo">
						<i class="fa-solid fa-qrcode show-family-qr-icon"></i>
					</span>
					<span class="name">Your QR Codes</span>
					<ul class="details">
						<li class="show-family-qr"><span class="nomobile">Click</span><span class="mobileonly">Tap</span> Here to View</li>
					</ul>
				</li>
				<li
					*ngFor="let groupEntry of familyWeaves.data.members;"
					[routerLink]="[ '/' + routes.editFamily + '/' + groupEntry.member._id.$oid ]"
				>
					<span class="photo">
						<span *ngIf="!groupEntry.member.data['photo'] || groupEntry.member.data['photo'].length < 1">NA</span>
						<img alt=""
							*ngIf="groupEntry.member.data['photo'] && groupEntry.member.data['photo'].length > 0"
							[src]="groupEntry.member.data['photo']"
						/>
					</span>
					<span class="name">{{ (groupEntry.member.data['first_name'] ?? '') + ' ' + (groupEntry.member.data['last_name'] ?? '') }}</span>
					<ul class="details">
						<li class="sap-tier"><i class="fa-solid fa-id-card"></i>{{ groupEntry.ticket.data['__sap_tier_display'] }}&nbsp;{{ groupEntry.ticket.data['year'] ?? '' }}</li>
					</ul>
				</li>
				<li class="season-parking" style="display: none;"
					*ngFor="let spt of seasonParkingTickets;"
					(click)="viewFamilyQRCodes();"
				>
					<span class="photo"><i class="fa-solid fa-car"></i></span>
					<span class="name">Season Parking Pass</span>
					<ul class="details">
						<li><i class="fa-solid fa-qrcode"></i></li>
					</ul>
				</li>
			</ul>
		</div>
	</section>
	<section class="card columned season-parking-passes"
		*ngIf="seasonParkingTickets.length > 0"
	>
		<div class="column">
			<h3>Season Parking Passes</h3>
			<ul class="visit-list">
				<li
					*ngFor="let seasonParkingTicket of seasonParkingTickets;"
					(click)="showQRCode( { 'id': seasonParkingTicket._id.$oid }, 'Season Parking Pass' );"
				>
					<span class="parking">
						<b class="fa-solid fa-car"></b><span>Park</span>
					</span>
					<span class="details">
						<b>{{ seasonParkingTicket.data['year'] }} Season Parking Pass</b>
						<span class="visit-id">{{ seasonParkingTicket._id.$oid.toUpperCase() }}</span>
					</span>
				</li>
			</ul>
		</div>
	</section>
	<section class="card columned tickets-from-pos-portal">
		<div class="column">
			<h3>My Tickets</h3>
			<div class="fetching-tickets" *ngIf="!wovenOrdersDocletsLoaded || (rogueTicketsChecking && !rogueTicketsLoaded)">Fetching your tickets...</div>
			<div class="no-tickets-to-show" *ngIf="wovenOrdersDocletsLoaded && (!rogueTicketsChecking && rogueTicketsLoaded) && currentOrFutureVisitDates.length < 1">There are no tickets to display</div>
			<ul class="visit-list" *ngIf="wovenOrdersDocletsLoaded && (rogueTicketsLoaded && !rogueTicketsChecking)">
				<li
					*ngFor="let visitDate of currentOrFutureVisitDates"
					(click)="showPurchasedTicketVisitDetailsByDate( visitDate );"
				>
					<span class="date">
						<b>{{ visitDate === 'any' ? '--' : visitDate.slice( -2 ) }}</b><span>{{ visitDate === 'any' ? 'Any' : monthLabels[ NumBurr( visitDate.substring( 5, 7 ) ) - 1 ].substring( 0, 3 ) }}</span>
					</span>
					<span class="details">
						<b>Visit for {{ purchasedTicketsByVisitDate[visitDate].consumerWithTicket.length }} guest{{ purchasedTicketsByVisitDate[visitDate].consumerWithTicket.length === 1 ? '' : 's' }}</b>
						<span class="mobileonly tickets">
							<span
								[ngClass]="{
									'parking': true,
									'inactive': purchasedTicketsByVisitDate[visitDate].parking.length < 1
								}"
							><i class="fa-solid fa-car"></i><span>Park</span></span>
							<span
								[ngClass]="{
									'guests': true,
									'inactive': purchasedTicketsByVisitDate[visitDate].consumerWithTicket.length < 1
								}"
							><i class="fa-solid fa-ticket"></i><span>{{ purchasedTicketsByVisitDate[visitDate].consumerWithTicket.length }}</span></span>
							<span
								[ngClass]="{
									'cabana': true,
									'inactive': purchasedTicketsByVisitDate[visitDate].cabanas.length < 1
								}"
							><i class="fa-solid fa-campground"></i><span>{{ purchasedTicketsByVisitDate[visitDate].cabanas.length }}</span></span>
						</span>
					</span>
					<span
						[ngClass]="{
							'parking': true,
							'nomobile': true,
							'inactive': purchasedTicketsByVisitDate[visitDate].parking.length < 1
						}"
					><i class="fa-solid fa-car"></i><span>Park</span></span>
					<span
						[ngClass]="{
							'guests': true,
							'nomobile': true,
							'inactive': purchasedTicketsByVisitDate[visitDate].consumerWithTicket.length < 1
						}"
					><i class="fa-solid fa-ticket"></i><span>{{ purchasedTicketsByVisitDate[visitDate].consumerWithTicket.length }}</span></span>
					<span
						[ngClass]="{
							'cabana': true,
							'nomobile': true,
							'inactive': purchasedTicketsByVisitDate[visitDate].cabanas.length < 1
						}"
					><i class="fa-solid fa-campground"></i><span>{{ purchasedTicketsByVisitDate[visitDate].cabanas.length }}</span></span>
				</li>
			</ul>
			<div class="buy-now"><a [routerLink]="[ '/' + routes.buyNow ]">Buy more tickets</a></div>
		</div>
	</section>
	<section class="card columned past-visits">
		<div class="column">
			<h3>Visit History</h3>
			<div class="fetching-tickets" *ngIf="!wovenOrdersDocletsLoaded">Fetching your visits...</div>
			<ul class="visit-list" *ngIf="wovenOrdersDocletsLoaded">
				<li class="meta" *ngIf="pastVisits.length < 1">You have no recent visit history.</li>
				<li *ngFor="let pastVisit of pastVisits;">
					<span class="date">
						<b>{{ pastVisit.date.month1 === 0 ? '--' : ('0' + pastVisit.date.day).slice( -2 ) }}</b><span>{{ pastVisit.date.month1 === 0 ? 'Any' : monthLabels[ pastVisit.date.month1 - 1 ].substring( 0, 3 ) }}</span>
					</span>
					<span class="details">
						<b>Visit for {{ pastVisit.memberIDs.length }} guest{{ pastVisit.memberIDs.length === 1 ? '' : 's' }}</b>
						<span class="visit-id">&nbsp;</span>
						<span class="mobileonly tickets">
							<span
								[ngClass]="{
									'parking': true,
									'inactive': pastVisit.parkingIDs.length < 1
								}"
							><i class="fa-solid fa-car"></i><span>Park</span></span>
							<span
								[ngClass]="{
									'guests': true,
									'inactive': pastVisit.memberIDs.length < 1
								}"
							><i class="fa-solid fa-ticket"></i><span>{{ pastVisit.memberIDs.length }}</span></span>
							<span
								[ngClass]="{
									'cabana': true,
									'inactive': pastVisit.cabanaIDs.length < 1
								}"
							><i class="fa-solid fa-campground"></i><span>{{ pastVisit.cabanaIDs.length }}</span></span>
						</span>
					</span>
					<span
						[ngClass]="{
							'parking': true,
							'nomobile': true,
							'inactive': pastVisit.parkingIDs.length < 1
						}"
					><i class="fa-solid fa-car"></i><span>Park</span></span>
					<span
						[ngClass]="{
							'guests': true,
							'nomobile': true,
							'inactive': pastVisit.memberIDs.length < 1
						}"
					><i class="fa-solid fa-ticket"></i><span>{{ pastVisit.memberIDs.length }}</span></span>
					<span
						[ngClass]="{
							'cabana': true,
							'nomobile': true,
							'inactive': pastVisit.cabanaIDs.length < 1
						}"
					><i class="fa-solid fa-campground"></i><span>{{ pastVisit.cabanaIDs.length }}</span></span>
				</li>
			</ul>
		</div>
	</section>
</div>
<div
	[ngClass]="{
		'modal-overlay': true,
		'active': reservationForm.active || parkVisitDetails.active || purchasedTicketsVisitDetails
	}"
>
	<div class="modal card modal-reservation" *ngIf="reservationForm.active">
		<div class="step step-1"
			[ngClass]="{
				'active': reservationForm.step === 1
			}"
		>
			<div class="modal-header">
				<span class="button prev"
					[ngClass]="{
						'disabled': reservationForm.flags.noGoingBack
					}"
					(click)="prevReservationCalendarMonth();"
				><i class="fa-solid fa-chevron-left"></i></span>
				<span class="title"><span class="nomobile">Available Reservations in&nbsp;</span>{{ monthLabels[ reservationForm.calendar.month0 ] }}, {{ reservationForm.calendar.year }}</span>
				<span class="button next"
					[ngClass]="{
						'disabled': reservationForm.flags.noGoingForwards
					}"
					(click)="nextReservationCalendarMonth();"
				><i class="fa-solid fa-chevron-right"></i></span>
			</div>
			<div class="modal-body">
				<ul class="row labels">
					<li>Sun</li>
					<li>Mon</li>
					<li>Tue</li>
					<li>Wed</li>
					<li>Thu</li>
					<li>Fri</li>
					<li>Sat</li>
				</ul>
				<ul class="calendar">
					<li class="offset" *ngFor="let day of reservationForm.dates.offset"></li>
					<li class="day"
						*ngFor="let day of reservationForm.dates.days; let i = index;"
						[ngClass]="{
							'selected': reservationForm.selected === i + 1,
							'is-weekend': (reservationForm.dates.offset.length + i) % 7 === 0 || (reservationForm.dates.offset.length + i) % 7 === 6,
							'already-reserved': reservationForm.cache.datesUsed1[ (NumBurr( reservationForm.cache.strYYYYMM0 ) + 1) + ('0' + (i + 1)).slice( -2 ) ],
							'no-capacity': !capacity || !capacity[ reservationForm.cache.strYY_MM1 + '-' + ('0' + (i + 1)).slice( -2 ) ] || capacity[ reservationForm.cache.strYY_MM1 + '-' + ('0' + (i + 1)).slice( -2 ) ].length < 1,
							'blocked': !('i swear im not crazy')
								|| !('if the date is in the past')
								|| NumBurr( reservationForm.cache.strYYYYMM0 + ('0' + (i + 1)).slice( -2 ) ) < reservationForm.cache.intYYYYMMDD0
								|| !('or if the capacity is not yet loaded, or if server-side says the park is full that day')
								|| !capacity
								|| !capacity[ reservationForm.cache.strYY_MM1 + '-' + ('0' + (i + 1)).slice( -2 ) ]
								|| capacity[ reservationForm.cache.strYY_MM1 + '-' + ('0' + (i + 1)).slice( -2 ) ].length < 1
								|| !('or if the day is already reserved')
								|| reservationForm.cache.datesUsed1[ reservationForm.cache.strYY_MM1 + '-' + ('0' + (i + 1)).slice( -2 ) ]
						}"
						(click)="reservationForm.selected = i + 1"
					><b>{{ i + 1 }}</b></li>
					<li class="remainder" *ngFor="let day of reservationForm.dates.remainder"></li>
				</ul>
			</div>
			<div class="modal-footer">
				<span class="button cancel" (click)="hideReservationForm();">Cancel</span>
				<span class="button next"
					[ngClass]="{
						'disabled': reservationForm.selected === 0
					}"
					(click)="setReservationFormStep( 2 );"
				>Continue</span>
			</div>
		</div>
		<div class="step step-2"
			[ngClass]="{
				'active': reservationForm.step === 2
			}"
		>
			<div class="modal-header">
				<span class="title">Who's Going?</span>
			</div>
			<div class="modal-body">
				<ul class="family-list fl-2" *ngIf="familyWeaves.flags.loaded">
					<li *ngFor="let groupEntry of familyWeaves.data.members;">
						<span class="photo">
							<span *ngIf="!groupEntry.member.data['photo'] || groupEntry.member.data['photo'].length < 1">NA</span>
							<img alt=""
								*ngIf="groupEntry.member.data['photo'] && groupEntry.member.data['photo'].length > 0"
								[src]="groupEntry.member.data['photo']"
							/>
						</span>
						<span class="name">{{ groupEntry.member.data['first_name'] + ' ' + groupEntry.member.data['last_name'] }}</span>
						<ul class="details">
							<li><i class="fa-solid fa-qrcode"></i>{{ groupEntry.ticket.data['serial'] }}</li>
						</ul>
						<div
							*ngIf="!memberIDsAlreadyReserved[ groupEntry.member._id.$oid ]"
							[ngClass]="{
								'checkbox': true,
								'checked': reservationForm.members[ groupEntry.member._id.$oid ]
							}"
							(click)="toggleGroupMember( groupEntry.member._id.$oid );"
						></div>
						<div class="warning-max-reached" *ngIf="memberIDsAlreadyReserved[ groupEntry.member._id.$oid ]">Reached limit</div>
					</li>
				</ul>
			</div>
			<div class="modal-footer">
				<span class="button cancel" (click)="setReservationFormStep( 1 );">Back</span>
				<span class="button next" (click)="createReservation();">Confirm</span>
			</div>
		</div>
	</div>
	<div class="modal card modal-park-visit-details sph-reservation-overview"
		*ngIf="parkVisitDetails.active"
	>
		<div [ngClass]="{
			'step': true,
			'visit-overview': true,
			'active': parkVisitDetails.panel === 'visit-overview'
		}">
			<div class="modal-header">
				<span class="title">Reservation for&nbsp;{{ parkVisitDetails.groupReservation ? monthLabels[ parkVisitDetails.groupReservation.date.month1 - 1 ] + '&nbsp;' + parkVisitDetails.groupReservation.date.day + ',&nbsp;' + parkVisitDetails.groupReservation.date.year : '' }}</span>
			</div>
			<div class="modal-body">
				<ul class="family-list fl-3">
					<li
						*ngFor="let memberAndTicket of parkVisitDetails.groupMembers;"
						(click)="memberAndTicket.member && showQRCode( { 'id': memberAndTicket.member._id.$oid }, 'SPH Ticket' );"
					>
						<span class="photo">
							<span *ngIf="memberAndTicket.placeholder || !memberAndTicket.member || !memberAndTicket.member.data || !memberAndTicket.member.data['photo'] || memberAndTicket.member.data['photo'].length < 1">NA</span>
							<img alt=""
								*ngIf="!memberAndTicket.placeholder || memberAndTicket.member && memberAndTicket.member.data && memberAndTicket.member.data['photo'] && memberAndTicket.member.data['photo'].length > 0"
								[src]="memberAndTicket.member!.data['photo']"
							/>
						</span>
						<span class="name">{{ memberAndTicket.placeholder || !memberAndTicket.member ? 'N/A' : memberAndTicket.member!.data['first_name'] + ' ' + memberAndTicket.member!.data['last_name'] }}</span>
						<ul class="details">
							<li><i class="fa-solid fa-qrcode"></i>{{ memberAndTicket.ticket && memberAndTicket.ticket.data && memberAndTicket.ticket.data['serial'] ? memberAndTicket.ticket!.data['serial'] : '-----' }}</li>
						</ul>
					</li>
				</ul>
			</div>
			<div class="modal-footer">
				<span class="button cancel" (click)="closeParkVisitDetails();">Close</span>
				<span class="button remove-visit" (click)="changePanel( 'cancel-reservation', true );">Cancel Reservation</span>
			</div>
		</div>
		<div
			[ngClass]="{
				'step': true,
				'cancel-reservation': true,
				'active': parkVisitDetails.panel === 'cancel-reservation'
			}"
		>
			<div class="modal-header">
				<span class="title">Cancel Reservation</span>
			</div>
			<div class="modal-body">
				<div class="give-reason">Please give a reason for the cancellation:</div>
				<textarea id="cancellation-reason"
					#cancellationReason
					[ngClass]="{
						'input': true,
						'error': cancelParkVisitErrors.reason
					}"
					(focus)="cancelParkVisitErrors.reason = false;"
				></textarea>
			</div>
			<div class="modal-footer">
				<span class="button cancel" (click)="changePanel( 'visit-overview' );">Back</span>
				<span class="button remove-visit" (click)="cancelReservation();">Confirm</span>
			</div>
		</div>
	</div>
	<div class="modal card modal-park-visit-details purchased-tickets non-sph-reservation-overview"
		*ngIf="purchasedTicketsVisitDetails"
	>
		<div
			[ngClass]="{
				'step': true,
				'visit-overview': true,
				'active': true
			}"
		>
			<div class="modal-header">
				<span class="title">{{
					purchasedTicketsVisitDetails.YYYYMMDD1 === 'any'
						? 'Ticket for any normal ' + this.seasonPassYear + ' operating day.'
						: 'Reservation for ' + monthLabels[ NumBurr( purchasedTicketsVisitDetails.YYYYMMDD1.slice( 5, 7 ) ) - 1 ] + ' ' + purchasedTicketsVisitDetails.YYYYMMDD1.slice( -2 ) + ', ' + purchasedTicketsVisitDetails.YYYYMMDD1.slice( 0, 4 )
				}}</span>
			</div>
			<div class="modal-body">
				<ul class="family-list fl-4">
					<li
						*ngFor="let cwt of purchasedTicketsVisitDetails.visit.consumerWithTicket;"
						(click)="showQRCode( { 'id': cwt.ticket._id.$oid }, getAdmissionTicketType( cwt.ticket ) + ' Admission' );"
					>
						<span class="photo">
							<span class="no-photo" *ngIf="!cwt.consumer || !cwt.consumer.data || !cwt.consumer.data['photo']"><i class="fa-solid fa-ticket"></i></span>
							<img alt=""
								*ngIf="cwt.consumer && cwt.consumer.data && cwt.consumer.data['photo'] && cwt.consumer.data['photo'].length > 0"
								[src]="cwt.consumer.data['photo']"
							/>
						</span>
						<span class="name">{{ cwt.ticket | ticketAdmissionType }}&nbsp;Admission{{
							(cwt.ticket.data?.['time']?.length ?? 0) > 0 ? ' ' + (cwt.ticket | ticketAdmissionTime) : ''
						}}&nbsp;{{
							cwt.ticket.data['assigned_first_name'] && cwt.ticket.data['assigned_last_name']
							? ('for ' + cwt.ticket.data['assigned_first_name'] + ' ' + cwt.ticket.data['assigned_last_name'])
							: ''
						}}</span>
						<ul class="details">
							<li>Show QR Code</li>
						</ul>
					</li>
					<li
						*ngFor="let parkingTicket of purchasedTicketsVisitDetails!.visit.parking;"
						(click)="showQRCode( { 'id': parkingTicket._id.$oid }, 'Parking Ticket' );"
					>
						<span class="photo">
							<span class="no-photo"><i class="fa-solid fa-car"></i></span>
						</span>
						<span class="name">{{ parkingTicket.title }}</span>
						<ul class="details">
							<li>Show QR Code</li>
						</ul>
					</li>
					<li
						*ngFor="let cabanaTicket of purchasedTicketsVisitDetails!.visit.cabanas;"
						(click)="showQRCode( { 'id': cabanaTicket._id.$oid }, 'Cabana Ticket' );"
					>
						<span class="photo">
							<span class="no-photo"><i class="fa-solid fa-campground"></i></span>
						</span>
						<span class="name">{{ cabanaTicket.title }}{{ (cabanaTicket.data?.['location_id']?.length ?? 0) > 0 ? ' #' + cabanaTicket.data['location_id'] : '' }}</span>
						<ul class="details">
							<li>Show QR Code</li>
						</ul>
					</li>
				</ul>
			</div>
			<div class="modal-footer">
				<span class="button cancel" (click)="hidePurchasedTicketVisitDetails();">Close</span>
			</div>
		</div>
	</div>
</div>
<div
	[ngClass]="{
		'modal-overlay': true,
		'active': activeQR
	}"
>
	<div class="modal card modal-qrcode">
		<span class="sap-tier">{{ activeQRTitle }}&nbsp;</span>
		<qrcode class="logic-qr-daily-tickets-season-parking-passes"
			*ngIf="activeQR"
			[qrdata]="activeQR"
			[width]="400"
			[margin]="0"
			[errorCorrectionLevel]="'H'"
		></qrcode>
		<span style="display: none;">{{ activeQR }} {{ activeQR ? 'activeQR is truthy' : 'activeQR is FALSE' }}</span>
		<span class="button" (click)="hideQRCode();">Close</span>
	</div>
</div>
<div id="viewing-family-qr-codes"
	[ngClass]="{
		'modal-overlay': true,
		'active': viewingFamilyQR
	}"
>
	<div class="modal card family-qr-codes">
		<div class="overflow-wrapper">
			<div
				*ngIf="viewingFamilyQR"
				[ngClass]="{
					'qr-member-listing': true,
					'need-to-swipe': familyWeaves.data.members.length + seasonParkingTickets.length > 1
				}"
			>
				<span class="member-details" *ngFor="let cwt of familyWeaves.data.members;">
					<span class="sap-tier">{{ cwt.ticket.data['__sap_tier_display'] ?? ' ' }}</span>
					<qrcode class="logic-qr-family-member-admission-reservation"
						[errorCorrectionLevel]="'H'"
						[margin]="0"
						[qrdata]="getFamilyQRCode( cwt.member )"
						[width]="400"
					></qrcode>
					<span class="name">{{ cwt.member.data['first_name'] ? cwt.member.data['first_name'] : '' }}&nbsp;{{ cwt.member.data['last_name'] ? cwt.member.data['last_name'] : '' }}</span>
					<span style="display: none;">{{ getFamilyQRCode( cwt.member ) }}</span>
				</span>
				<span class="member-details" *ngFor="let seasonParkingTicket of seasonParkingTickets;">
					<span class="sap-tier">{{ seasonParkingTicket.data['__spp_tier_display'] ?? '\u00A0' }}</span>
					<qrcode class="logic-qr-family-season-parking"
						[errorCorrectionLevel]="'H'"
						[margin]="0"
						[qrdata]="getFamilyQRCode( seasonParkingTicket )"
						[width]="400"
					></qrcode>
					<span class="name">{{ seasonParkingTicket.data['date'].slice( 0, 4 ) }} Season Parking Pass</span>
					<span style="display: none;">{{ getFamilyQRCode( seasonParkingTicket ) }}</span>
				</span>
			</div>
		</div>
		<div class="button" (click)="viewingFamilyQR = false;">Close</div>
	</div>
</div>
