import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
// ===== App ===== //
import {AppEvents} from '../../app.events';
import {AppRouterLinks} from '../../app.router-links';
// ===== Interfaces ===== //
import {InterfaceAppEvent} from '../../interfaces/interfaces';
// ===== Services ===== //
import {ServiceAuthentication} from '../../services/authentication';
import {ServiceNavigate} from '../../services/navigate';
//
@Component({
	selector: 'app-header',
	templateUrl: './app-header.html',
	styleUrls: [
		'./app-header.less'
	]
})
export class ComponentAppHeader implements OnDestroy, OnInit {
	@Input() lockdown: boolean = false; // when true, the user cannot do anything but sign out, or sign in.
	public readonly routes = AppRouterLinks;
	private subUserReSync: Subscription | null = null;
	public isSignedIn: boolean = false;
	public isShowingMenu: boolean = false;
	public enabledLinkMyAccount: boolean = false;
	public enabledLinkDashboard: boolean = false;

	public constructor(
		private appEvents: AppEvents,
		private auth: ServiceAuthentication,
		private nav: ServiceNavigate,
		public router: Router
	) {
		this.isSignedIn = this.auth.isSignedIn();
		this.subUserReSync = this.appEvents.listen( 'user:re-sync' ).subscribe( (_: InterfaceAppEvent): void => {
			this.isSignedIn = this.auth.isSignedIn();
		} );
	}

	public ngOnInit(): void {
		this.enabledLinkDashboard = this.router.url !== '/' + this.routes.dashboard;
		this.enabledLinkMyAccount = this.router.url !== '/' + this.routes.myAccount;
	}

	public ngOnDestroy(): void {
		if ( this.subUserReSync ) {
			this.subUserReSync.unsubscribe();
			this.subUserReSync = null;
		}
	}

	public signOut(): void {
		this.appEvents.broadcast( 'user:signed-out' );
	}

	public goBuyNow(): void { // U BUY NOAW
		this.nav.toURL( '/' + this.routes.buyNow );
	}

	public goSignIn(): void {
		this.nav.toURL( '/' + this.routes.signIn );
	}

	public goMyAccount(): void {
		this.nav.toURL( '/' + this.routes.myAccount );
	}

	public goDashboard(): void {
		this.nav.toURL( '/' + this.routes.dashboard );
	}

	public toggleMenu(): void {
		this.isShowingMenu = !this.isShowingMenu;
	}
}
