import {Pipe, PipeTransform} from '@angular/core';
//
@Pipe( {
	name: 'trim'
} )
export class PipeTrim implements PipeTransform {
	public transform( inValue: any ): string {
		try {
			const value: string = String( inValue );
			return value.substring( 0, value.length - 1 );
		} catch ( _ ) {
			return '';
		}
	}
}
