import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Navigation, Router} from '@angular/router';
import {Subscription} from 'rxjs';
// ===== App ===== //
import {AppConfig} from '../../app.config';
import {AppEvents} from '../../app.events';
import {AppRouterLinks} from '../../app.router-links';
// ===== Collections ===== //
import {CollectionProfiles} from '../../collections/profiles';
// ===== Interfaces ===== //
import {
	InterfaceAppContext,
	InterfaceAppEvent,
	InterfaceDocletIDToTicketProps_T,
	InterfaceHTTPGateway,
	InterfaceOWAPIBulkRecordRequest,
	InterfaceOWAPIDailyAdmissionAvailabilityResponse,
	InterfaceOWAPIGetDocletResponse,
	InterfaceOWAPIGetWeavesResponse,
	InterfaceOWAPIOrderItems,
	InterfaceOWAPIOrderPaymentInfo,
	InterfaceOWAPIOrderResponse,
	InterfaceOWAPIOrderResponseData,
	InterfaceOWAPIPromoCodeItems,
	InterfaceOWAPIPromoCodeItemsItem,
	InterfaceOWAPIPromoCodeResponse,
	InterfaceOWAPISignUpResponse,
	InterfaceOWAPITicketPriceByDatePriceTier,
	InterfaceOWDailyAdmissionAvailability,
	InterfaceOWDoclet,
	InterfaceOWTemplateConsumer,
	InterfaceOWUser,
	InterfaceOWWeaveV2,
	InterfaceOWWeaveWeaves,
	InterfaceVenuePassportCartItem,
	InterfaceVenuePassportCartItemTicket,
	InterfaceVenuePassportCompactCartItems,
	InterfaceVenuePassportTicketPriceType,
	InterfaceVenuePassportTicketPricing
} from '../../interfaces/interfaces';
interface InterfaceAccountData {
	consumerDocletID: string;
	firstName: string;
	lastName: string;
	email: string;
	password1: string;
	password2: string;
	cashlessSpending: boolean;
}
interface InterfaceAccountErrors {
	firstName: boolean;
	lastName: boolean;
	email: boolean;
	password1: boolean;
	password2: boolean;
}
interface InterfaceCheckoutData {
	card: {
		name: string;
		number: string;
		expMM: string;
		expYYYY: string;
		cvv: string;
	};
	billing: {
		street: string;
		unit: string;
		city: string;
		state: string;
		zip: string;
	};
}
interface InterfaceCheckoutErrors {
	card: {
		name: boolean;
		number: boolean;
		expMM: boolean;
		expYYYY: boolean;
		cvv: boolean;
	};
	billing: {
		street: boolean;
		city: boolean;
		state: boolean;
		zip: boolean;
	};
}
interface InterfaceDisplayLineItemsPassProps {
	passDocletID: string;
	passName: string;
	heldTicketID: string;
	first_name?: string;
	last_name?: string;
	dob?: string;
	year?: string;
	errors: {
		first_name?: boolean;
		last_name?: boolean;
	};
}
interface InterfaceDisplayLineItems_v2 {
	strYYYYMMDD: 'any' | string; // the date to send server-side.
	strDisplayDate: string; // the date to display on the front end.
	strDisplayTime?: string;
	passDisplayName: string;
	passProps: InterfaceDisplayLineItemsPassProps[];
	lineItemSubTotal: number;
	year?: string;
	locationID?: string[];
}
interface InterfaceDisplayLineItems {
	passID: string;
	strYYYYMMDD: string; // the date to send server-side.
	strDisplayDate: string; // the date to display on the front end.
	strDisplayTime: string;
	passDisplayName: string;
	passProps: InterfaceDisplayLineItemsPassProps[];
	lineItemSubTotal: number;
	isDiscounted: boolean;
	discountAmount: number;
	seasonPassYear?: string;
	locationID?: string[];
}
// ===== Services ===== //
import {ServiceAuthentication} from '../../services/authentication';
import {ServiceNavigate} from '../../services/navigate';
import {ServiceOWAPI} from '../../services/ow-api';
import {ServiceRegex} from '../../services/regex';
import {ServiceSorting} from '../../services/sorting';
// ===== Transformers ===== //
import {TransformerVenuePassportTicket} from '../../transformers/vpTicket';
//
const now: Date = new Date();
const parkClosesOn2024Oct8th: Date = new Date( 2024, 9, 8, 23, 59, 59, 0 );
//
@Component( {
	selector: 'page-checkout',
	templateUrl: './checkout.html',
	styleUrls: [
		'./checkout.less'
	]
} )
export class PageCheckout implements OnInit {
	public cartHeldIDsFatalError: boolean = false; // if true, the user cannot check out and must head back to /buynow
	public dobMaxDate: string = new Date().toISOString().split( 'T' )[0];
	public routes: typeof AppRouterLinks = AppRouterLinks;
	public liabilityAgreementChecked: boolean = false;
	public termsAndConditionsChecked: boolean = false;
	public readonly monthLabels: string[] = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
	private busy: boolean = false;
	public promoCode: string = '';
	public promoCodes: string[] = []; // only valid ones go into this array.
	private promoCodesInUse: { [code: string]: true; } = {}; // trying to keep the array of promo codes, unique.
	private discountedItems: InterfaceOWAPIPromoCodeItems[] = [];
	public invalidPromoCode: boolean = false;
	public isSignedIn: boolean = false;
	public isSeasonPassHolder: boolean = false;
	private subUserReSync: Subscription | null = null;
	private ticketSAPYearsActive: { [year: string]: boolean; } = {}; // if the user has a 2022 season pass that isn't cancelled, then we'll see {"2022":true} -- used for sph_pricing logic.
	// ===== Captcha ===== //
	public readonly reCaptchaSiteKey: string = this.appConfig.getReCaptchaSiteKey();
	private reCaptchUserToken: string = '';
	// ===== Ticket availability (just for pricing logic) ===== //
	public dailyAdmissionAvailability: InterfaceOWDailyAdmissionAvailability = {}; // [YYYY-MM-DD] : { location: int } // MM is 01 - 12
	public readonly ticketIDToCountSoldKey: { [settings_key: string]: string; } = {};
	public availabilityLoaded: boolean = false;
	// ===== Cart Items ===== //
	private haveCartItems: boolean = false; // cart items is just a bunch of IDs and quantities. it has no content, though.
	public cartData: InterfaceVenuePassportCartItem[] = []
	public productListing: InterfaceDisplayLineItems[] = [];
	public subTotal: number = 0; // a sum of all products
	public discounts: number = 0;
	public grandTotal: number = 0; // subTotal - discounts = grandTotal
	// ===== Account Data ===== //
	public accountData: InterfaceAccountData = {
		consumerDocletID: '',
		firstName: '',
		lastName: '',
		email: '',
		password1: '',
		password2: '',
		cashlessSpending: true
	};
	public accountErrors: InterfaceAccountErrors = {
		firstName: false,
		lastName: false,
		email: false,
		password1: false,
		password2: false
	};
	public checkoutData: InterfaceCheckoutData = {
		card: {
			name: '',
			number: '',
			expMM: '',
			expYYYY: '',
			cvv: ''
		},
		billing: {
			street: '',
			unit: '',
			city: '',
			state: '',
			zip: ''
		}
	};
	public checkoutErrors: InterfaceCheckoutErrors = {
		card: {
			name: false,
			number: false,
			expMM: false,
			expYYYY: false,
			cvv: false
		},
		billing: {
			street: false,
			city: false,
			state: false,
			zip: false
		}
	};
	private cacheEmailAlreadyInUse: { [email: string]: true; } = {};
	public emailIsInUse: boolean = false;
	private readonly strWebRole: string = this.appConfig.getRoleID( 'Web' );
	// ===== Pass Content ===== //
	private readonly strCabanaPassTemplateID: string = this.appConfig.getTemplateID( 'Cabana Pass' );
	private readonly strConsumerTemplateID: string = this.appConfig.getTemplateID( 'Consumer' );
	private readonly strDailyAdmissionPassTemplateID: string = this.appConfig.getTemplateID( 'Daily Admission Pass' );
	private readonly strFamilyTemplateID: string = this.appConfig.getTemplateID( 'Family' );
	private readonly strParkingPassTemplateID: string = this.appConfig.getTemplateID( 'Parking Pass' );
	private readonly strSeasonAdmissionPassTemplateID: string = this.appConfig.getTemplateID( 'Season Admission Pass' );
	private readonly strSeasonAdmissionTicketTemplateID: string = this.appConfig.getTemplateID( 'Season Admission Ticket' );
	private readonly strSeasonParkingPassTemplateID: string = this.appConfig.getTemplateID( 'Season Parking Pass' );
	private readonly strComplexProductTemplateID: string = this.appConfig.getTemplateID( 'Complex Product Pass' );
	private havePassContent: boolean = false; // true when we have content to use, with all the cart item IDs.
	public passIDToPassProps: { [passID: string]: InterfaceDocletIDToTicketProps_T<InterfaceVenuePassportTicketPriceType>; } = {};
	public passIDToTicketProps: { [passID: string]: {
		dataFirstLastName: boolean; // daily and season admission passes need this.
		dataDOB: boolean; // daily and season admission need this.
		flagsFirstLastIsOptional?: boolean // cabana's have an optional first/last name.
	}; } = {};
	public displayReady: boolean = false; // true once the cart items and the content passes are used to make display items.
	// ===== Checking Out ===== //
	private usersProfileIDForCart: string | null = null;
	public isCheckingOut: boolean = false;
	public haveCheckoutErrors: boolean = false;
	public declinedReason: string = ''; // 'Declined: Something something'
	private trackingCookie: number = Math.floor( Math.random() * 100000 ) + new Date().getTime();
	private trackingByURL: boolean = false;
	//
	public constructor(
		private activeRoute: ActivatedRoute,
		private appConfig: AppConfig,
		private appEvents: AppEvents,
		private auth: ServiceAuthentication,
		private colProfiles: CollectionProfiles,
		private nav: ServiceNavigate,
		private owapi: ServiceOWAPI,
		private router: Router,
		private title: Title
	) {
		this.title.setTitle( 'Wild Rivers Waterpark Irvine Tickets & Season Passes' );
		this.ticketIDToCountSoldKey['62aa164d5576850cbaa529de'] = 'ticket_count'; // adult/general
		this.ticketIDToCountSoldKey['62aa168c5576850cbaa529df'] = 'ticket_count'; // junior
		this.ticketIDToCountSoldKey['62aa145b5576850cbaa529dd'] = 'parking_count'; // parking
		this.ticketIDToCountSoldKey['629a58bda0fdd06636abddb3'] = 'kontiki_cove_count'; // Kontiki Cove, Yellow
		this.ticketIDToCountSoldKey['629a58bda0fdd06636abddb1'] = 'cooks_cove_count'; // Cook's Cove, Orange
		this.ticketIDToCountSoldKey['629a58bda0fdd06636abddb2'] = 'wavepool_beach_count'; // Wavepool Beach, Blue
		this.ticketIDToCountSoldKey['629a58bde702436560e6b742'] = 'wavepool_north_count'; // Wavepool North, Aqua
		this.ticketIDToCountSoldKey['629a58bde702436560e6b741'] = 'wavepool_south_count'; // Wavepool South, Red
		this.ticketIDToCountSoldKey['629a58bde702436560e6b740'] = 'river_cabanas_count'; // River Cabanas, Green

		// if the user reloads the page, the nav state is lost.
		const navEvent: Navigation | null = this.router.getCurrentNavigation(); // only accessible in the constructor.
		if ( navEvent && navEvent.extras.state ) {
			let navExtras: any[] = Array.isArray( navEvent.extras.state ) ? navEvent.extras.state : [ navEvent.extras.state ]; // it should already be an array...
			for ( let x: number = 0; x < navExtras.length; ++x ) {
				if ( navExtras[x] && navExtras[x].hasOwnProperty( 'data-type' ) && navExtras[x]['data-type'] === 'cart-data' && navExtras[x].hasOwnProperty( 'data' ) ) {
					let compactCartItems: InterfaceVenuePassportCompactCartItems[] = [];
					const cartPayload: any = navExtras[x]['data'];
					if ( Array.isArray( cartPayload ) ) {
						// old format.
						compactCartItems = navExtras[x]['data'] as InterfaceVenuePassportCompactCartItems[];
						this.promoCode = '';
					} else if ( cartPayload.hasOwnProperty( 'items' ) && Array.isArray( cartPayload['items'] ) ) {
						compactCartItems = cartPayload['items'];
						// it's now possible to have zero items in the cart, but have a promo code...
						this.promoCode = cartPayload.hasOwnProperty( 'promo' ) && typeof cartPayload['promo'] === 'string' ? cartPayload['promo'] : '';
					}
					if ( compactCartItems.length > 0 ) {
						for ( let y: number = 0; y < compactCartItems.length; ++y ) {
							this.cartData.push( {
								date: {
									year: compactCartItems[y].d.y,
									month: compactCartItems[y].d.m,
									day: compactCartItems[y].d.d
								},
								tickets: compactCartItems[y].t.filter( (ticket: InterfaceVenuePassportCompactCartItems['t'][number] ): boolean => {
									return ticket.q > 0;
								} ).map( (ticket: InterfaceVenuePassportCompactCartItems['t'][number]): InterfaceVenuePassportCartItemTicket => {
									return {
										passID: ticket.i, // the ID of the pass to buy
										qty: ticket.q, // quantity to buy
										holds: ticket.h, // held ticket IDs
										location: ticket.l
									};
								} )
							} );
						}
					}
					if ( this.cartData && this.cartData.length > 0 ) {
						this.haveCartItems = true;
					}
					console.log( 'Cart data', this.cartData );
					this.owapi.workspace.actions.core.recordResourceUse(
						this.appConfig.getContext(),
						this.routes.checkout,
						'page',
						{
							'isSignedIn': this.auth.isSignedIn(),
							'profileID': this.auth.getProfileID(),
							'isParkClosed': parkClosesOn2024Oct8th.getTime() < new Date().getTime(),
							'cartStats': {
								'promo_code': this.promoCode,
								'byURL': false,
								'tracker': this.trackingCookie,
								'dates': this.cartData.map( (data: InterfaceVenuePassportCartItem): InterfaceVenuePassportCartItem['date'] => {
									return data.date;
								} )
							}
						}
					).subscribe( (_: InterfaceHTTPGateway): void => {} );
				}
			}
			console.log( 'navEvent.extras.state', navEvent.extras.state );
		} else {
			console.log( 'no nav event, or no state.' );
		}
		this.isSignedIn = this.auth.isSignedIn();
		this.subUserReSync = this.appEvents.listen( 'user:re-sync' ).subscribe( (_: InterfaceAppEvent): void => {
			this.isSignedIn = this.auth.isSignedIn();
			if ( this.isSignedIn ) {
				this.cacheEmailAlreadyInUse = {};
				this.emailIsInUse = false;
			}
			this.fetchUserInfo();
		} );
		this.fetchUserInfo();
	}

	private fetchPasses( callback: () => void ): void { // build passIDToPassProps
		console.log( '===== Fetching Public Passes For Sale =====' ); // part 1 of 4
		this.owapi.workspace.doclets.getAllDocletsByTemplateID( this.appConfig.getContext(), {
			templateID: [
				this.strSeasonAdmissionPassTemplateID,
				this.strSeasonParkingPassTemplateID,
				this.strDailyAdmissionPassTemplateID,
				this.strParkingPassTemplateID,
				this.strCabanaPassTemplateID,
				this.strComplexProductTemplateID
			],
			query: {
				'data.status': 'active'
			},
			withoutAuth: true
		}, false, (response: InterfaceOWAPIBulkRecordRequest<InterfaceOWDoclet>): void => {
			if ( response.success ) {
				let passDoclets: InterfaceOWDoclet[] = response.records;
				for ( let x: number = 0; x < passDoclets.length; ++x ) {
					const passID: string = passDoclets[x]._id.$oid;
					switch ( passDoclets[x].template_id.$oid ) {
						case this.strSeasonAdmissionPassTemplateID: {
							this.passIDToPassProps[passID] = {
								// possibly need to put validation in here, too.
								name: passDoclets[x].data['name'],
								price: TransformerVenuePassportTicket.passPricesToYYYYMMDDPrice( passDoclets[x].data['price'] ),
								sort: 0,
								isDailyAdmission: false,
								isDailyParking: false,
								isCabana: false,
								isCompensation: false,
								isSeasonPass: true,
								isSAP: true,
								isSPP: false,
								year: passDoclets[x].data['year'],
								level: passDoclets[x].data['level'],
								isCertification: false,
								isMerchandise: false,
								skipCapacityCheck: !passDoclets[x].data['require_capacity'] || passDoclets[x].data['is_any_day'],
								role: {
									admin: false,
									staff: false,
									pos: false,
									web: true
								},
								doclet: passDoclets[x]
							};
							this.passIDToTicketProps[passID] = {
								dataFirstLastName: true,
								dataDOB: true
							};
							break;
						}
						case this.strDailyAdmissionPassTemplateID: {
							this.passIDToPassProps[passID] = {
								name: passDoclets[x].data['name'],
								price: TransformerVenuePassportTicket.passPricesToYYYYMMDDPrice( passDoclets[x].data['price'] ),
								sort: 1,
								isDailyAdmission: true,
								isEvening: !!passDoclets[x].data['is_evening'],
								isDailyParking: false,
								isCabana: false,
								isCompensation: false,
								isSeasonPass: false,
								isSAP: false,
								isSPP: false,
								isCertification: false,
								isMerchandise: false,
								skipCapacityCheck: !passDoclets[x].data['require_capacity'] || passDoclets[x].data['is_any_day'],
								role: {
									admin: false,
									staff: false,
									pos: false,
									web: true
								},
								doclet: passDoclets[x]
							};
							this.passIDToTicketProps[passID] = {
								dataFirstLastName: true,
								dataDOB: true
							};
							break;
						}
						case this.strSeasonParkingPassTemplateID: {
							this.passIDToPassProps[passID] = {
								name: passDoclets[x].data['name'],
								price: TransformerVenuePassportTicket.passPricesToYYYYMMDDPrice( passDoclets[x].data['price'] ),
								sort: 2,
								isDailyAdmission: false,
								isDailyParking: true, // field is not used
								isCabana: false,
								isCompensation: false,
								isSeasonPass: true,
								isSAP: false,
								isSPP: true,
								year: passDoclets[x].data['year'],
								level: passDoclets[x].data['level'] ?? undefined,
								isCertification: false,
								isMerchandise: false,
								skipCapacityCheck: !passDoclets[x].data['require_capacity'] || passDoclets[x].data['is_any_day'],
								role: {
									admin: false,
									staff: false,
									pos: false,
									web: true
								},
								doclet: passDoclets[x]
							};
							this.passIDToTicketProps[passID] = {
								dataFirstLastName: false, // season pass parking may eventually need first/last name
								dataDOB: false
							};
							break;
						}
						case this.strParkingPassTemplateID: {
							this.passIDToPassProps[passID] = {
								name: passDoclets[x].data['name'],
								price: TransformerVenuePassportTicket.passPricesToYYYYMMDDPrice( passDoclets[x].data['price'] ),
								sort: 3,
								isDailyAdmission: false,
								isEvening: !!passDoclets[x].data['is_evening'],
								isDailyParking: true,
								isCabana: false,
								isCompensation: false,
								isSeasonPass: false,
								isSAP: false,
								isSPP: false,
								isCertification: false,
								isMerchandise: false,
								skipCapacityCheck: !passDoclets[x].data['require_capacity'] || passDoclets[x].data['is_any_day'],
								role: {
									admin: false,
									staff: false,
									pos: false,
									web: true
								},
								doclet: passDoclets[x]
							};
							this.passIDToTicketProps[passID] = {
								dataFirstLastName: false,
								dataDOB: false
							};
							break;
						}
						case this.strCabanaPassTemplateID: {
							this.passIDToPassProps[passID] = {
								name: passDoclets[x].data['name'],
								price: TransformerVenuePassportTicket.passPricesToYYYYMMDDPrice( passDoclets[x].data['price'] ),
								sort: 4,
								isDailyAdmission: false,
								isEvening: !!passDoclets[x].data['is_evening'],
								isDailyParking: false,
								isCabana: true,
								isCompensation: false,
								isSeasonPass: false,
								isSAP: false,
								isSPP: false,
								isCertification: false,
								isMerchandise: false,
								skipCapacityCheck: !passDoclets[x].data['require_capacity'] || passDoclets[x].data['is_any_day'],
								role: {
									admin: false,
									staff: false,
									pos: false,
									web: true
								},
								doclet: passDoclets[x]
							};
							this.passIDToTicketProps[passID] = {
								dataFirstLastName: true,
								dataDOB: false,
								flagsFirstLastIsOptional: true
							};
							break;
						}
						case this.strComplexProductTemplateID: {
							this.passIDToPassProps[passID] = {
								name: passDoclets[x].data['name'],
								price: TransformerVenuePassportTicket.passPricesToYYYYMMDDPrice( passDoclets[x].data['price'] ),
								sort: 1,
								isDailyAdmission: false,
								isEvening: !!passDoclets[x].data['is_evening'],
								isDailyParking: false,
								isCabana: false,
								isComplexBundle: true,
								isCompensation: false,
								isSeasonPass: false,
								isSAP: false,
								isSPP: false,
								isCertification: false,
								isMerchandise: false,
								skipCapacityCheck: !passDoclets[x].data['require_capacity'] || passDoclets[x].data['is_any_day'],
								role: {
									admin: false,
									staff: false,
									pos: false,
									web: true
								},
								doclet: passDoclets[x]
							};
							this.passIDToTicketProps[passID] = {
								dataFirstLastName: false,
								dataDOB: false
							};
							break;
						}
					} // end switch docletID
				} // end for each doclet to bucket up.
				console.log( 'cached data', this.passIDToPassProps );
			} // end if the bulk-fetch succeeded.
			callback();
		} );
	}

	private fetchSoldTicketCounts( callback: () => void ): void {
		console.log( '===== Fetching Sold Tickets =====' ); // part 3 of 4
		// the sold amounts, come from the availability object.
		// the amount of tickets sold, determine the ticket prices.
		const dailyTicketDates: InterfaceVenuePassportCartItem['date'][] = this.cartData.filter( (cartItem: InterfaceVenuePassportCartItem): boolean => {
			// must be a normal parking or daily ticket, and not an any-day type. (for those, the date.day is zero)
			return cartItem.date.day !== 0 && cartItem.tickets.filter( (ticket: InterfaceVenuePassportCartItemTicket): boolean => {
				return this.passIDToPassProps[ ticket.passID ].isDailyAdmission || this.passIDToPassProps[ ticket.passID ].isDailyParking || this.passIDToPassProps[ ticket.passID ].isCabana;
			} ).length > 0;
		} ).map( (data: InterfaceVenuePassportCartItem): InterfaceVenuePassportCartItem['date'] => {
			return data.date;
		} ).sort( (A: InterfaceVenuePassportCartItem['date'], B: InterfaceVenuePassportCartItem['date']): number => {
			if ( A.year !== B.year ) {
				return A.year - B.year;
			} else if ( A.month !== B.month ) {
				return A.month - B.month;
			}
			return A.day - B.day;
		} );
		if ( dailyTicketDates.length > 0 ) {
			const d2: InterfaceVenuePassportCartItem['date'] = dailyTicketDates.pop() as InterfaceVenuePassportCartItem['date']; // and not also as undefined
			const d1: InterfaceVenuePassportCartItem['date'] = dailyTicketDates.length < 1 ? d2 : dailyTicketDates.shift() as InterfaceVenuePassportCartItem['date'];
			const earliestDate: Date = new Date( d1.year, d1.month - 1, d1.day, 0, 0, 0, 0 );
			const latestDate: Date = new Date( d2.year, d2.month - 1, d2.day, 0, 0, 0, 0 );
			this.owapi.workspace.actions.core.getDailyAdmissionAvailabilityFromDateRange( this.appConfig.getContext(), earliestDate, latestDate, this.strWebRole ).subscribe( (response: InterfaceHTTPGateway): void => {
				if ( response && response.success && response.status === 200 ) {
					const apiResponse: InterfaceOWAPIDailyAdmissionAvailabilityResponse = response.data;
					// don't have to worry about pagination. data.items is an array of length 1.
					if ( apiResponse && apiResponse.data && Array.isArray( apiResponse.data.items ) ) {
						if ( apiResponse.data.items.length > 0 ) {
							this.dailyAdmissionAvailability = apiResponse.data.items[0];
							this.availabilityLoaded = true;
							console.log( 'Availability', this.dailyAdmissionAvailability );
						}
					}
				}
				callback();
			} );
		} else {
			console.log( 'Skipping availability - no dates to use' );
			this.availabilityLoaded = true;
			callback();
		}
	}

	public ngOnInit(): void {
		if ( !this.haveCartItems && this.activeRoute.snapshot.params && typeof this.activeRoute.snapshot.params['cart64'] === 'string' && this.activeRoute.snapshot.params['cart64'].length > 0 ) {
			// from the route /checkout/:cart64
			let marioCart64: string = this.activeRoute.snapshot.params['cart64'];
			if ( marioCart64 && marioCart64.length > 0 ) {
				// Here We Go!
				try {
					let compactCartItems: InterfaceVenuePassportCompactCartItems[] = [];
					const cartPayload: any = JSON.parse( atob( marioCart64.split( '' ).reverse().join( '' ) ) );
					if ( Array.isArray( cartPayload ) ) {
						// old format
						compactCartItems = cartPayload;
					} else if ( cartPayload && cartPayload.hasOwnProperty( 'items' ) && Array.isArray( cartPayload['items'] ) ) {
						compactCartItems = cartPayload.items;
						this.promoCode = typeof cartPayload['promo'] === 'string' && cartPayload['promo'].length > 0 ? cartPayload['promo'] : '';
					}
					if ( compactCartItems.length > 0 ) {
						for ( let y: number = 0; y < compactCartItems.length; ++y ) {
							this.cartData.push( {
								date: {
									year: compactCartItems[y].d.y,
									month: compactCartItems[y].d.m,
									day: compactCartItems[y].d.d
								},
								tickets: compactCartItems[y].t.filter( (ticket: InterfaceVenuePassportCompactCartItems['t'][number]): boolean => {
									return ticket.q > 0;
								} ).map( (ticket: InterfaceVenuePassportCompactCartItems['t'][number]): InterfaceVenuePassportCartItemTicket => {
									return {
										passID: ticket.i, // the ID of the pass to buy
										qty: ticket.q, // quantity to buy
										holds: ticket.h, // held ticket IDs
										location: ticket.l
									};
								} )
							} );
						} // end for each compact cart item.
					} // end if there are any compact cart items.
					if ( this.cartData.length > 0 ) {
						this.haveCartItems = true;
						console.log( 'cart data', this.cartData );
						this.trackingByURL = true;
						this.owapi.workspace.actions.core.recordResourceUse(
							this.appConfig.getContext(),
							this.routes.checkout,
							'page',
							{
								'isSignedIn': this.auth.isSignedIn(),
								'profileID': this.auth.getProfileID(),
								'isParkClosed': parkClosesOn2024Oct8th.getTime() < new Date().getTime(),
								'cartStats': {
									'promo_code': this.promoCode,
									'byURL': true,
									'tracker': this.trackingCookie,
									'dates': this.cartData.map( (data: InterfaceVenuePassportCartItem): InterfaceVenuePassportCartItem['date'] => {
										return data.date;
									} )
								}
							}
						).subscribe( (_: InterfaceHTTPGateway): void => {} );
					} else {
						console.log( 'No cart data from URL.' );
					}
				} catch ( fail ) {
					console.log( 'Failed to carry the cart data over to checkout.' );
				}
			}
		}

		if ( this.haveCartItems ) {
			// fetch public passes for sale.
			// we have the cart-data already.
			// fetch the availability (for the new ticket pricing logic) based upon the dates selected... for daily tickets.
			// calculate line items totals, grand totals, set up the display, etc.
			// then figure out coupon codes, that may override all the logic and work done thus far.
			// ==== Fetch Public Passes ===== //
			this.fetchPasses( (): void => {
				// ===== Extra Flags / Pre-Calc Logic ===== //
				// figure out .isSeasonPass and .isAnyDay, now that we have passIDToPassProps built up.
				for ( let x: number = 0; x < this.cartData.length; ++x ) {
					this.cartData[x].isAnyDay = this.cartData[x].date.month === 0 && this.cartData[x].date.day === 0;
					this.cartData[x].isSeasonPass = this.cartData[x].tickets.filter( (ticket: InterfaceVenuePassportCartItemTicket): boolean => {
						return this.passIDToPassProps.hasOwnProperty( ticket.passID ) && this.passIDToPassProps[ticket.passID].isSeasonPass;
					} ).length > 0;
				}
				console.log( '===== Extra Flags - Ready =====' ); // part 2 of 4
				this.sortCartData(); // put older dates last, further down in the page.
				// ===== Fetch Availability (get sold ticket amounts) ===== //
				this.fetchSoldTicketCounts( (): void => {
					this.buildDisplayData();
					this.displayReady = true;
					if ( this.promoCode ) {
						this.useDiscountCode();
					}
				} );
			} );
			/*
			try {
				const _gtag: Function = (window as any).gtag as Function;
				_gtag( 'config', 'AW-10797476692', {
					'page_path': '/' + this.routes.checkout
				} );
			} catch( _ ) {}
			*/
		} else {
			this.nav.toURL( '/' + this.routes.buyNow );
		}
	}

	public showTinySignIn(): void {
		this.appEvents.broadcast( 'modal:open:tiny-sign-in' );
	}

	private sortCartData(): void {
		if ( this.haveCartItems && this.havePassContent ) {
			this.cartData.sort( (A: InterfaceVenuePassportCartItem, B: InterfaceVenuePassportCartItem): number => {
				if ( A.date.year < B.date.year ) {
					return -1;
				} else if ( A.date.year > B.date.year ) {
					return 1;
				} else {
					if ( A.date.month < B.date.month ) {
						return -1;
					} else if ( A.date.month > B.date.month ) {
						return 1;
					} else {
						if ( A.date.day < B.date.day ) {
							return -1;
						} else if ( A.date.day > B.date.day ) {
							return 1;
						} else {
							return 0;
						}
					}
				}
			} );
			for ( let x: number = 0; x < this.cartData.length; ++x ) {
				this.cartData[x].tickets.sort( (A: InterfaceVenuePassportCartItemTicket, B: InterfaceVenuePassportCartItemTicket): number => {
					if ( this.passIDToPassProps[ A.passID ].sort < this.passIDToPassProps[ B.passID ].sort ) {
						return -1;
					} else if ( this.passIDToPassProps[ A.passID ].sort > this.passIDToPassProps[ B.passID ].sort ) {
						return 1;
					} else {
						return ServiceSorting.naturalSort( this.passIDToPassProps[ A.passID ].name, this.passIDToPassProps[ B.passID ].name );
					}
				} );
			}
		}
	}

	private getTicketTimeByPassID( passID: string, YYYYMMDD1?: string ): string {
		const ticketPricing: InterfaceVenuePassportTicketPriceType | undefined = this.passIDToPassProps[passID]?.price;
		return TransformerVenuePassportTicket.getTicketTimeDisplayFromPriceType( ticketPricing, YYYYMMDD1 );
	}

	private buildDisplayData(): void { // clears and resets the product listing. initializes the consumer fields... first/last name, DOB.
		this.productListing = []; // the product listing is used, when building the final output, when packing up items for server-side.
		this.subTotal = 0;
		this.discounts = 0;
		this.grandTotal = 0;
		const unsortedDisplayItems: {
			[strYYYYMMDD: string]: {
				[passID: string]: InterfaceDisplayLineItems_v2;
			};
		} = {};
		for ( let x: number = 0; x < this.cartData.length; ++x ) {
			console.log( 'this.cartData', this.cartData );
			const cartItem: InterfaceVenuePassportCartItem = this.cartData[x];
			for ( let y: number = 0; y < cartItem.tickets.length; ++y ) {
				const ticket: InterfaceVenuePassportCartItemTicket = cartItem.tickets[y]; // { passID, qty, holds[] }
				const ticketYYYYMMDD1: string = cartItem.date.year + '-' + ('0' + cartItem.date.month).slice( -2 ) + '-' + ('0' + cartItem.date.day).slice( -2 );
				if ( !unsortedDisplayItems.hasOwnProperty( ticketYYYYMMDD1 ) ) {
					unsortedDisplayItems[ticketYYYYMMDD1] = {};
				}
				if ( !unsortedDisplayItems[ticketYYYYMMDD1].hasOwnProperty( ticket.passID ) ) {
					let price: number = 0;
					if ( this.passIDToPassProps[ ticket.passID ].isSeasonPass ) {
						price = this.getSeasonPassPrice( ticket.passID );
					} else {
						price = this.getTicketPrice( ticket.passID, cartItem.date.year, cartItem.date.month, cartItem.date.day );
					}
					unsortedDisplayItems[ticketYYYYMMDD1][ticket.passID] = {
						strYYYYMMDD: ticketYYYYMMDD1,
						strDisplayDate: cartItem.date.month < 1 && cartItem.date.day < 1
							? cartItem.date.year + ' any day'
							: (
								cartItem.date.month > 0 && cartItem.date.day < 1
								? 'Year ' + cartItem.date.year // bundles have an issue where they have a month, but no day.
								: this.monthLabels[ cartItem.date.month - 1 ] + ' ' + cartItem.date.day + ', ' + cartItem.date.year
							),
						strDisplayTime: this.passIDToPassProps[ ticket.passID ].isEvening ? this.getTicketTimeByPassID( ticket.passID, ticketYYYYMMDD1 ) : undefined,
						passDisplayName: this.passIDToPassProps[ ticket.passID ].name,
						passProps: [],
						// some of the items may have a discount. can't just (qty * price) anymore
						lineItemSubTotal: Number( Number( ticket.qty * price ).toFixed( 2 ) ),
						locationID: ticket.location ? [ ticket.location ] : []
					};
				} else { // else we recv'd a duplicate pass ID. probably a cabana with a different location ID.
					if ( typeof ticket.location === 'string' ) {
						if ( !Array.isArray( unsortedDisplayItems[ticketYYYYMMDD1][ticket.passID].locationID ) ) {
							unsortedDisplayItems[ticketYYYYMMDD1][ticket.passID].locationID = [];
						}
						let price: number = 0;
						if ( this.passIDToPassProps[ ticket.passID ].isSeasonPass ) {
							price = this.getSeasonPassPrice( ticket.passID );
						} else {
							price = this.getTicketPrice( ticket.passID, cartItem.date.year, cartItem.date.month, cartItem.date.day )
						}
						unsortedDisplayItems[ticketYYYYMMDD1][ticket.passID].locationID!.push( ticket.location );
						unsortedDisplayItems[ticketYYYYMMDD1][ticket.passID].locationID!.sort( ServiceSorting.naturalSort );
						unsortedDisplayItems[ticketYYYYMMDD1][ticket.passID].lineItemSubTotal = Number( Number(
							unsortedDisplayItems[ticketYYYYMMDD1][ticket.passID].lineItemSubTotal
							+ ticket.qty * price
						).toFixed( 2 ) );
					}
				}

				if ( this.passIDToPassProps[ ticket.passID ].isSeasonPass ) {
					unsortedDisplayItems[ticketYYYYMMDD1][ticket.passID].year = String( cartItem.date.year );
					unsortedDisplayItems[ticketYYYYMMDD1][ticket.passID].strDisplayDate = 'Year ' + cartItem.date.year;
				} // else it's already set, don't overwrite it.

				for ( let z: number = 0; z < ticket.qty; ++z ) {
					const passProps: InterfaceDisplayLineItemsPassProps = {
						passDocletID: ticket.passID,
						passName: this.passIDToPassProps[ ticket.passID ].name, // seems like this ought to of been on the layer above, but we didn't have <ticket> yet, only line item with an array of tickets.
						heldTicketID: ticket.holds?.[z] ?? null,
						errors: {}
					};
					if ( this.passIDToTicketProps[ ticket.passID ].dataFirstLastName ) {
						passProps.first_name = ''
						passProps.last_name = '';
						passProps.errors.first_name = false;
						passProps.errors.last_name = false;
					}
					if ( this.passIDToTicketProps[ ticket.passID ].dataDOB ) {
						passProps.dob = '';
					}
					unsortedDisplayItems[ticketYYYYMMDD1][ticket.passID].passProps.push( passProps );
				}
			} // end for each ticket.
		} // end for each line item in the cart. (a line-item is a day and its tickets...)
		// console.log( 'unsorted display items', unsortedDisplayItems );
		const ticketDates: string[] = Object.keys( unsortedDisplayItems );
		ticketDates.sort( (A: string, B: string): number => {
			return ServiceSorting.naturalSort( A, B );
		} );
		// tickets are sorted by date. (date ASC)
		for ( let x: number = 0; x < ticketDates.length; ++x ) {
			const strYYYYMMDD1: string = ticketDates[x];
			const passIDs: string[] = Object.keys( unsortedDisplayItems[strYYYYMMDD1] );
			passIDs.sort( (A: string, B: string): number => {
				if ( this.passIDToPassProps[A].sort === this.passIDToPassProps[B].sort ) {
					return ServiceSorting.naturalSort( unsortedDisplayItems[strYYYYMMDD1][A].passDisplayName, unsortedDisplayItems[strYYYYMMDD1][B].passDisplayName );
				}
				return this.passIDToPassProps[A].sort - this.passIDToPassProps[B].sort;
			} );
			// tickets are sub-sorted by name. (date ASC, name ASC)
			for ( let y: number = 0; y < passIDs.length; ++y ) {
				this.productListing.push( {
					passID: passIDs[y],
					strYYYYMMDD: strYYYYMMDD1,
					strDisplayDate: unsortedDisplayItems[strYYYYMMDD1][passIDs[y]].strDisplayDate,
					strDisplayTime: unsortedDisplayItems[strYYYYMMDD1][passIDs[y]].strDisplayTime ?? '',
					passDisplayName: unsortedDisplayItems[strYYYYMMDD1][passIDs[y]].passDisplayName,
					passProps: unsortedDisplayItems[strYYYYMMDD1][passIDs[y]].passProps,
					lineItemSubTotal: unsortedDisplayItems[strYYYYMMDD1][passIDs[y]].lineItemSubTotal,
					isDiscounted: false,
					discountAmount: 0,
					seasonPassYear: unsortedDisplayItems[strYYYYMMDD1][passIDs[y]].year,
					locationID: Array.isArray( unsortedDisplayItems[strYYYYMMDD1][passIDs[y]].locationID ) ? unsortedDisplayItems[strYYYYMMDD1][passIDs[y]].locationID : []
				} );
				this.subTotal += unsortedDisplayItems[strYYYYMMDD1][passIDs[y]].lineItemSubTotal;
			}
		}
		this.subTotal = Number( this.subTotal.toFixed( 2 ) );
		this.grandTotal = Number( (this.subTotal - this.discounts).toFixed( 2 ) );
	}

	private reCalcDisplayTimer: number | null = null;
	private reCalcDisplayDataDebounce(): void {
		if ( typeof this.reCalcDisplayTimer === 'number' ) {
			return; // already queued up.
		} else {
			// run this once, then queue it up and ignore the millions of attempts to spam-call reCalcDisplayData()
			this.reCalcDisplayTimer = setTimeout( (): void => {
				this.reCalcDisplayTimer = null;
				this.reCalcDisplayData();
			}, 200 ) as unknown as number; // conversion from NodeJS.Timeout to Number is not allowed...
		}
	}

	private reCalcDisplayData(): void { // the loop for digging up user-weaves calls this fn when it's done checking on season passes. (once per check)
		console.log( 'reCalcDisplayData() called' );
		// ===== reset everything ===== //
		this.grandTotal = 0;
		this.subTotal = 0; // sub-total does not include a discount, it is pre-discount, etc.
		this.discounts = this.discountedItems.reduce( (totalDiscounts: number, promoItems: InterfaceOWAPIPromoCodeItems): number => {
			return totalDiscounts + promoItems.items.reduce( (amountOff: number, item: InterfaceOWAPIPromoCodeItemsItem): number => {
				return amountOff + Number( item.discount_applied );
			}, 0 ); // the total amount off in discounts is already known, because we have the server-side results of what to discount...
		}, 0 ); // only issue is going to be mapping it back onto what item was discounted.
		for ( let x: number = 0; x < this.productListing.length; ++x ) {
			this.productListing[x].lineItemSubTotal = 0;
			this.productListing[x].isDiscounted = false;
			this.productListing[x].discountAmount = 0;
		}
		// ============================ //
		// figure out discounts
		if ( this.discountedItems.length === this.productListing.length ) {
			for ( let x: number = 0; x < this.discountedItems.length; ++x ) {
				// TODO: sanity check - ensure the this.productListing[x].passID matches the this.discountedItems[x].items[???].doclet_id
				this.productListing[x].discountAmount = this.discountedItems[x].items.reduce( (acc: number, item: InterfaceOWAPIPromoCodeItemsItem): number => {
					return acc + Number( item.discount_applied );
				}, 0 );
				this.productListing[x].isDiscounted = this.productListing[x].discountAmount > 0;
			}
		} else { // else the discounted items has not yet created, or something else is wrong.
			console.log( 'amount of items vs discounted amount mis-matched...', this.discountedItems.length, 'vs',  this.productListing.length );
			// this is OK, during the time we're waiting on the API for discount data.
			// otherwise it's a bug :S
			// if there's no promo code, then ignore all this..?
		}
		// figure out line item total, accumulate subTotal
		for ( let x: number = 0; x < this.productListing.length; ++x ) {
			const arrYMD: number[] = this.productListing[x].strYYYYMMDD.split( /-/ ).map( (str: string): number => Number( str ) );
			let originalPrice: number = 0;
			if ( this.passIDToPassProps[ this.productListing[x].passID ].isSeasonPass ) {
				originalPrice = this.getSeasonPassPrice( this.productListing[x].passID );
			} else {
				originalPrice = this.getTicketPrice( this.productListing[x].passID, arrYMD[0], arrYMD[1], arrYMD[2] );
			}
			// line item total is just qty * cost. subTotal is all line item totals, before discounts.
			this.productListing[x].lineItemSubTotal = Number( (this.productListing[x].passProps.length * originalPrice).toFixed( 2 ) );
			this.subTotal = Number( (this.productListing[x].passProps.length * originalPrice + this.subTotal).toFixed( 2 ) );
			if ( this.productListing[x].isDiscounted ) {
				this.productListing[x].lineItemSubTotal = Number( (this.productListing[x].lineItemSubTotal - this.productListing[x].discountAmount).toFixed( 2 ) );
			}
		}
		this.grandTotal = Number( (this.subTotal - this.discounts).toFixed( 2 ) );
	}

	public validateDisplayItem( item: any, key: string ): void {
		if ( item.hasOwnProperty( key ) && typeof item[key] === 'string' ) {
			item[key] = item[key].replace( ServiceRegex.trimRegExp, '' );
			if ( item.hasOwnProperty( 'errors' ) && item['errors'].hasOwnProperty( key ) ) {
				item['errors'][key] = item[key].length < 1;
			}
		}
	}

	private fetchUserInfo(): void {
		this.colProfiles.getMyUserProfile( (userData: InterfaceOWUser | null): void => {
			if ( userData && userData.doclet_id && userData.data ) {
				const consumerData: InterfaceOWTemplateConsumer = userData.data as InterfaceOWTemplateConsumer;
				this.accountData.consumerDocletID = userData.doclet_id;
				this.accountData.firstName = consumerData.first_name ?? '';
				this.accountData.lastName = consumerData.last_name ?? ''
				this.accountData.email = userData.email;
			}
			this.checkIfUserIsSPH();
		} );
	}

	public validateAccData( key: keyof InterfaceAccountData ): void {
		if ( key !== 'password1' && key !== 'password2' && key !== 'cashlessSpending' ) {
			this.accountData[key] = this.accountData[key].replace( ServiceRegex.trimRegExp, '' );
		}
		switch ( key ) {
			case 'firstName': {
				this.accountErrors.firstName = this.accountData.firstName.length < 1;
				break;
			}
			case 'lastName': {
				this.accountErrors.lastName = this.accountData.lastName.length < 1;
				break;
			}
			case 'email': {
				this.accountData.email = this.accountData.email.toLowerCase();
				this.accountErrors.email = !ServiceRegex.emailRegExp.test( this.accountData.email );
				break;
			}
			case 'password1': {
				this.accountErrors.password1 = this.accountData.password1.length < 1;
				if ( this.accountData.password2.length > 0 ) {
					this.accountErrors.password2 = this.accountData.password1 !== this.accountData.password2;
					this.accountErrors.password1 = this.accountData.password1 !== this.accountData.password2;
				}
				break;
			}
			case 'password2': {
				this.accountErrors.password2 = this.accountData.password2.length < 1;
				if ( this.accountData.password1.length > 0 ) {
					this.accountErrors.password1 = this.accountData.password2 !== this.accountData.password1;
					this.accountErrors.password2 = this.accountData.password2 !== this.accountData.password1;
				}
				break;
			}
		}
	}

	public validateCardData( key: keyof InterfaceCheckoutData['card'] ): void {
		let now: Date = new Date();
		this.checkoutData.card[key] = this.checkoutData.card[key].replace( ServiceRegex.trimRegExp, '' );
		switch ( key ) {
			case 'name': {
				this.checkoutErrors.card.name = this.checkoutData.card.name.length < 1;
				break;
			}
			case 'number': { // card numbers are 13 - 16 digits in length.
				this.checkoutErrors.card.number = !this.validateCCNum( this.checkoutData.card.number );
				break;
			}
			case 'expMM': {
				let intMM: number = parseInt( this.checkoutData.card.expMM, 10 );
				// if not a valid month.
				this.checkoutErrors.card.expMM = !!this.checkoutData.card.expMM.match( /^\d\d$/ ) || isNaN( intMM ) || intMM < 1 || intMM > 12;
				// or if expired...
				if ( this.checkoutData.card.expYYYY.length > 0 ) {
					let intYYYY: number = parseInt( this.checkoutData.card.expYYYY, 10 );
					if ( intYYYY < 100 ) {
						intYYYY += Math.floor( now.getFullYear() / 100 ) * 100; // floor(2377/100) = 23. 23 * 100 = 2300.
					}
					this.checkoutErrors.card.expMM = intYYYY < now.getFullYear() || intYYYY === now.getFullYear() && intMM < now.getMonth() + 1;
					this.checkoutErrors.card.expYYYY = this.checkoutErrors.card.expMM;
				}
				break;
			}
			case 'expYYYY': {
				let intYYYY: number = parseInt( this.checkoutData.card.expYYYY, 10 );
				if ( intYYYY < 100 ) {
					intYYYY += Math.floor( now.getFullYear() / 100 ) * 100; // floor(2377/100) = 23. 23 * 100 = 2300.
				}
				// if not a valid year...
				this.checkoutErrors.card.expYYYY = !!this.checkoutData.card.expYYYY.match( /^\d\d$|^\d\d\d\d$/ ) || isNaN( intYYYY ) || intYYYY < now.getFullYear();
				// or if expired...
				if ( this.checkoutData.card.expMM.length > 0 ) {
					let intMM: number = parseInt( this.checkoutData.card.expMM, 10 );
					this.checkoutErrors.card.expYYYY = intYYYY < now.getFullYear() || intYYYY === now.getFullYear() && intMM < now.getMonth() + 1;
					this.checkoutErrors.card.expMM = this.checkoutErrors.card.expYYYY;
				}
				break;
			}
			case 'cvv': {
				this.checkoutErrors.card.cvv = this.checkoutData.card.cvv.length < 1; // 3 or 4, possibly other variants.
				break;
			}
		}
	}

	public validateBillingData( key: keyof InterfaceCheckoutData['billing'] ): void {
		this.checkoutData.billing[key] = this.checkoutData.billing[key].replace( ServiceRegex.trimRegExp, '' );
		switch ( key ) {
			case 'street': {
				this.checkoutErrors.billing.street = this.checkoutData.billing.street.length < 1;
				break;
			}
			case 'city': {
				this.checkoutErrors.billing.city = this.checkoutData.billing.city.length < 1;
				break;
			}
			case 'state': {
				this.checkoutErrors.billing.state = this.checkoutData.billing.state.length < 2;
				break;
			}
			case 'zip': {
				this.checkoutErrors.billing.zip = this.checkoutData.billing.zip.match( /^\d\d\d\d\d/ ) === null; // "12345" or "12345-6789" is OK...
				break;
			}
		}
	}

	public checkIfEmailAlreadyExist(): void {
		const email: string = this.accountData.email.toLowerCase();
		if ( ServiceRegex.emailRegExp.test( email ) ) {
			this.owapi.account.registration.checkIfEmailExists( this.appConfig.getContext(), email ).subscribe( (response: InterfaceHTTPGateway): void => {
				if ( response && !response.success && response.status === 409 ) {
					this.cacheEmailAlreadyInUse[ email ] = true;
					if ( this.accountData.email.toLowerCase() === email ) {
						this.accountErrors.email = true;
						this.emailIsInUse = true;
					}
				}
			} );
		}
	}

	public liabilityCheckboxChanged( checked: boolean ): void {
		this.liabilityAgreementChecked = checked;
	}

	public termsAndConditionsChanged( checked: boolean ): void {
		this.termsAndConditionsChecked = checked;
	}

	private checkoutFailed( reason: string ): void {
		this.haveCheckoutErrors = true;
		this.isCheckingOut = false;
		this.busy = false;
		this.declinedReason = reason; // 'Declined: something something'
	}

	private packUpOrderedItems(): InterfaceOWAPIOrderItems[] {
		const output: InterfaceOWAPIOrderItems[] = [];
		for ( let x: number = 0; x < this.productListing.length; ++x ) {
			const items: InterfaceOWAPIOrderItems['items'] = [];
			for ( let y: number = 0; y < this.productListing[x].passProps.length; ++y ) {
				const passPropsDisplay: InterfaceDisplayLineItemsPassProps = this.productListing[x].passProps[y];
				const dataProps: {
					assigned_first_name?: string;
					assigned_last_name?: string;
					assigned_dob?: string;
					time?: string; // either undefined or 'HH:MM:SS'
					year?: string; // sph year
					level?: number; // SAP tier.
				} = {};
				// if the pass to buy is a type where it needs a first/last name assigned to it.
				if ( this.passIDToTicketProps[ passPropsDisplay.passDocletID ].dataFirstLastName ) {
					dataProps.assigned_first_name = passPropsDisplay.first_name;
					dataProps.assigned_last_name = passPropsDisplay.last_name;
				}
				// if the pass to buy is a type where it wants a DoB assigned to it, that may not exist.
				if ( this.passIDToTicketProps[ passPropsDisplay.passDocletID ].dataDOB ) {
					dataProps.assigned_dob = passPropsDisplay.dob ? passPropsDisplay.dob : '';
				}
				if ( this.passIDToPassProps[ passPropsDisplay.passDocletID ].isSeasonPass ) {
					if ( this.productListing[x].seasonPassYear?.length ?? 0 > 0 ) { // it either doesn't exist, or might incorrectly be a blank string, or it is a valid value of a 4 digit year as a string.
						dataProps.year = this.productListing[x].seasonPassYear;
					}
					if ( 'level' in this.passIDToPassProps[ passPropsDisplay.passDocletID ] ) { // if the field exists, it might be a zero, or 0 - 6 or something.
						dataProps.level = this.passIDToPassProps[ passPropsDisplay.passDocletID ].level;
					}
				}
				if ( this.passIDToPassProps[ passPropsDisplay.passDocletID ].isEvening ) {
					dataProps.time = TransformerVenuePassportTicket.getTicketTimeFromPriceType( this.passIDToPassProps[ passPropsDisplay.passDocletID ].price, this.productListing[x].strYYYYMMDD );
				}
				items.push( {
					doclet_id: passPropsDisplay.passDocletID,
					item_id: passPropsDisplay.heldTicketID,
					source: 'web',
					data: dataProps
				} );
			}
			//
			output.push( {
				date: this.productListing[x].strYYYYMMDD.match( /-00-00$/ ) ? 'any' : this.productListing[x].strYYYYMMDD, // YYYY-MM-DD. month is 01 to 12.
				items: items
			} );
		} // end for each product (cart items + consumer data) to pack up.
		return output;
	}

	private submitOrder( paymentInfo: InterfaceOWAPIOrderPaymentInfo ): void {
		// this is part 2. submitCart is part 1.
		const cardDetailsRequired: boolean = this.grandTotal > 0 || (this.accountData.cashlessSpending && !(this.grandTotal > 0));
		const orderItems: InterfaceOWAPIOrderItems[] = this.packUpOrderedItems();
		this.owapi.workspace.actions.core.submitOrder( // 7 params
			this.appConfig.getContext(),
			this.usersProfileIDForCart as string,
			orderItems,
			paymentInfo,
			cardDetailsRequired ? this.accountData.cashlessSpending : false,
			this.reCaptchUserToken, // param 6
			// ===== TODO: promo code array ===== //
			this.promoCode.length > 0 ? this.promoCode.toUpperCase() : undefined // param 7
			// ================================== //
		).subscribe( (response: InterfaceHTTPGateway): void => {
			this.busy = false;
			this.isCheckingOut = false;
			console.log( 'Checked Out', window.location, response );
			if ( response?.status === 0 ) {
				console.log( 'Browser refused to send the network request. giving up.' );
			}
			if ( response && response.success && response.status === 200 ) {
				const apiResponse: InterfaceOWAPIOrderResponse = response.data;
				if ( apiResponse && apiResponse.data && Array.isArray( apiResponse.data.items ) && apiResponse.data.items.length > 0 ) {
					const orderResponse: InterfaceOWAPIOrderResponseData = apiResponse.data.items.shift() as InterfaceOWAPIOrderResponseData; // .pop_front()
					if ( orderResponse && orderResponse.success ) {
						// ===== checkout successful ===== //
						// regardless of all the junk, we fire-and-forget after this point,
						// we must always move onwards to the thank-you page.
						// ... no failing allowed ...
						// =============================== //
						const orderID: string = orderResponse.order_id;
						this.owapi.workspace.actions.core.recordResourceUse(
							this.appConfig.getContext(),
							this.routes.checkout,
							'click',
							{
								'isSignedIn': this.auth.isSignedIn(),
								'profileID': this.auth.getProfileID(),
								'isParkClosed': parkClosesOn2024Oct8th.getTime() < new Date().getTime(),
								'cartStats': {
									'promo_code': this.promoCode,
									'orderID': orderID,
									'byURL': this.trackingByURL,
									'tracker': this.trackingCookie,
									'dates': this.cartData.map( (data: InterfaceVenuePassportCartItem): InterfaceVenuePassportCartItem['date'] => { return data.date; } )
								}
							}
						).subscribe( (_: InterfaceHTTPGateway): void => {
							// fire and forget
						} );
						/*
						try {
							const _gtag: Function = (window as any).gtag as Function;
							_gtag( 'event', 'conversion', {
								'send_to': 'AW-10797476692',
								'event_category': 'checkout',
								'event_label': 'form_submission'
							} );
						} catch( _ ) {}
						*/
						this.router.navigateByUrl( '/' + this.routes.thankYou, {
							replaceUrl: true,
							state: {
								'data-type': 'order-data',
								'data': {
									'orderID': orderID
								}
							}
						} ).then( (navigated: boolean): void => {
							if ( !navigated ) { // if it failed, kick the user to the next page.
								window.location.href = '/' + this.routes.thankYou;
							}
						} );
					} else {
						if ( orderResponse.hasOwnProperty( 'captcha_status' ) && !orderResponse.captcha_status ) {
							this.checkoutFailed( 'Please select "I\'m not a robot" to prove you are human.' );
						} else if ( Array.isArray( orderResponse?.unavailable ) && orderResponse.unavailable.length > 0 ) {
							this.checkoutFailed( 'One or more items in your order are no longer for sale.' );
							this.cartHeldIDsFatalError = true; // the user can't change them out on this page. they're screwed.
							/*
							const badPassIDs: { [passID: string]: true; } = {}; // pass IDs.
							for ( let x: number = 0; x < this.cartData.length; ++x ) {
								for ( let y: number = 0; y < this.cartData[x].tickets.length; ++y ) {
									for ( let z: number = 0; z < this.cartData[x].tickets[y].holds.length; ++z ) {
										for ( let u: number = 0; u < orderResponse.unavailable.length; ++u ) {
											if ( String( orderResponse.unavailable[u] ).toLowerCase() === this.cartData[x].tickets[y].holds[z].toLowerCase() ) {
												badPassIDs[ this.cartData[x].tickets[y].passID ] = true;
											}
										}
									}
								}
							}
							Object.keys( badPassIDs ).sort( ServiceSorting.naturalSort )....hmm
							*/
						} else {
							this.checkoutFailed( orderResponse.payment_reason ? orderResponse.payment_reason : 'Please try again later.' );
						}
					}
				} else {
					// Please try again later.
				}
			} else { // not a 200
				this.checkoutFailed( 'Please try again later.' );
				this.owapi.workspace.actions.core.recordResourceUse(
					this.appConfig.getContext(),
					this.routes.checkout,
					'checkout-error',
					{
						source: 'Web',
						url: window.location.toString(),
						response: response
					}
				).subscribe( (_: InterfaceHTTPGateway): void => {
					// fire and forget.
				} );
			}
		} );
	}

	public submitCart(): void {
		// "Credit card info is required to use cashless at the venue"
		// this is part 1. submitOrder is part 2
		if ( !this.busy ) {
			if ( !this.isSignedIn && this.emailIsInUse ) {
				this.checkoutFailed( 'Email is already in use.' );
				return;
			}
			this.haveCheckoutErrors = false;
			if ( this.auth.isSignedIn() ) {
				this.usersProfileIDForCart = this.auth.getProfileID();
				if ( this.usersProfileIDForCart === null ) {
					// can't fix this problem...
					return;
				}
			}
			// ===== Validation ===== //
			let haveErrors: boolean = false;
			//
			for ( let x: number = 0; x < this.productListing.length; ++x ) {
				for ( let y: number = 0; y < this.productListing[x].passProps.length; ++y ) {
					const errorKeys: (keyof InterfaceDisplayLineItemsPassProps['errors'])[] = Object.keys( this.productListing[x].passProps[y].errors ) as (keyof InterfaceDisplayLineItemsPassProps['errors'])[];
					for ( let z: number = 0; z < errorKeys.length; ++z ) {
						this.validateDisplayItem( this.productListing[x].passProps[y], errorKeys[z] );
					}
					for ( let z: number = 0; !haveErrors && z < errorKeys.length; ++z ) {
						if ( this.productListing[x].passProps[y].errors[ errorKeys[z] ]) {
							haveErrors = true;
						}
					}
				}
			}
			//
			if ( !this.isSignedIn ) {
				// if the user is signed in, don't try to validate fields they no longer can access.
				let accKeys: string[] = Object.keys( this.accountErrors );
				for ( let x: number = 0; x < accKeys.length; ++x ) {
					this.validateAccData( accKeys[x] as keyof InterfaceAccountErrors );
				}
				for ( let x: number = 0; x < accKeys.length; ++x ) {
					if ( this.accountErrors[ accKeys[x] as keyof InterfaceAccountErrors ] ) {
						haveErrors = true;
					}
				}
			}
			// always validate billing info
			let billingErrors: string[] = Object.keys( this.checkoutErrors.billing );
			for ( let x: number = 0; x < billingErrors.length; ++x ) {
				this.validateBillingData( billingErrors[x] as keyof InterfaceCheckoutErrors['billing'] );
			}
			for ( let x: number = 0; x < billingErrors.length; ++x ) {
				if ( this.checkoutErrors.billing[ billingErrors[x] as keyof InterfaceCheckoutErrors['billing'] ] ) {
					haveErrors = true;
				}
			}
			// always validate CC info, unless the grand total is zero.
			const cardDetailsRequired: boolean = this.grandTotal > 0 || (this.accountData.cashlessSpending && !(this.grandTotal > 0));
			let cardErrors: string[] = Object.keys( this.checkoutErrors.card );
			for ( let x: number = 0; x < cardErrors.length; ++x ) {
				this.validateCardData( cardErrors[x] as keyof InterfaceCheckoutErrors['card'] );
			}
			for ( let x: number = 0; cardDetailsRequired && x < cardErrors.length; ++x ) {
				if ( this.checkoutErrors.card[ cardErrors[x] as keyof InterfaceCheckoutErrors['card'] ] ) {
					haveErrors = true;
				}
			}

			if ( haveErrors ) {
				return;
			}

			const paymentInfo: InterfaceOWAPIOrderPaymentInfo = {
				action: 'CHARGE',
				billing_address: this.checkoutData.billing.street,
				billing_suite: this.checkoutData.billing.unit,
				billing_city: this.checkoutData.billing.city,
				billing_state: this.checkoutData.billing.state,
				billing_zip: String( this.checkoutData.billing.zip ),
				card_number: cardDetailsRequired ? String( this.checkoutData.card.number ) : '',
				card_exp_month: cardDetailsRequired ? String( this.checkoutData.card.expMM ) : '',
				card_exp_year: cardDetailsRequired ? String( this.checkoutData.card.expYYYY ) : '', // will be 2 digits or 4...
				card_cvv: cardDetailsRequired ? String( this.checkoutData.card.cvv ) : ''
			};
			if ( cardDetailsRequired && paymentInfo.card_exp_year.length < 3 ) {
				paymentInfo.card_exp_year = String( new Date().getFullYear() ).slice( 0, 2 ) + paymentInfo.card_exp_year;
			}

			this.busy = true;
			this.isCheckingOut = true;

			// don't use if(thing){ because JS said NULL was truthy..wtf
			if ( this.usersProfileIDForCart === null || this.usersProfileIDForCart.length < 1 ) {
				this.owapi.account.registration.register(
					this.appConfig.getContext(),
					this.accountData.email,
					this.accountData.password1,
					this.strConsumerTemplateID,
					{
						first_name: this.accountData.firstName,
						last_name: this.accountData.lastName
						// don't have anything else. need street, city, state, zip, etc.. but not from this. (it may be someone else's billing info)
					},
					{
						first_name: this.accountData.firstName,
						last_name: this.accountData.lastName
					}
				).subscribe( (response: InterfaceHTTPGateway): void => {
					if ( response && response.success ) { // success will be true, even if it s 409, 500, 400, 200 etc.
						if ( response.status === 201 ) {
							const apiResponse: InterfaceOWAPISignUpResponse = response.data;
							if ( apiResponse && apiResponse.data && apiResponse.data.profile_id ) {
								this.usersProfileIDForCart = apiResponse.data.profile_id;
								this.submitOrder( paymentInfo );
							} else {
								this.checkoutFailed( 'Please try again later.' );
							}
						} else if ( response.status === 409 ) {
							this.emailIsInUse = true;
							this.cacheEmailAlreadyInUse[this.accountData.email] = true;
							this.checkoutFailed( 'Email is already in use.' );
						}
					} else {
						this.checkoutFailed( 'Please try again later.' );
					}
				} );
			} else {
				this.submitOrder( paymentInfo );
			}
		}
	}

	public validateCCNum( ccNum: string ): boolean {
		let ccType: string = '';
		let isValid: boolean = false;
		if ( ccNum.length > 14 ) {
			switch ( ccNum.charAt( 0 ) ) {
				// all credit card info here: http://www.iinbase.com/
				case '3' : { // American Express
					if ( ccNum.charAt( 1 ) == '4' || ccNum.charAt( 1 ) == '7' ) { // 34 or 37
						ccType = 'American Express';
						isValid = ccNum.length == 16 || ccNum.length == 15; // Amex has both 15 and 16 digit card numbers.
					} // system identifier, type, currency, account number, a check digit
					break; // AMEX: SSTCAAAAAAAAAAC
				}
				case '4' : { // Visa
					ccType = 'Visa';
					isValid = ccNum.length == 16; // All 3 other cards, use the 16 digit schema
					break; // SSTCAAAAAAAAAAC
				} // system identifier, issuer/bank identifier, bank number, account number, a check digit
				case '5' : { // Master Card
					if ( ccNum.charAt( 1 ) > '0' && ccNum.charAt( 1 ) < '6' ) { // 51 to 55
						ccType = 'Master Card';
						isValid = ccNum.length == 16;
					}
					break;
				}
				case '6' : { // Discover Card
					if ( ccNum.charAt( 1 ) == '0' && ccNum.charAt( 2 ) == '1' && ccNum.charAt( 3 ) == '1' ) { // 6011
						ccType = 'Discover Card';
						isValid = ccNum.length == 16;
					} // see: https://www.discovernetworkvar.com/common/pdf/var/10-1_VAR_ALERT_April_2010.pdf
					break;
				}
			}
		} // end if CC length is at least 15.
		return isValid;
	}

	private willAllowSPHPricing( sphRestriction: InterfaceVenuePassportTicketPricing['sphRestriction'] ): boolean {
		const requiredYears: string[] = Object.keys( sphRestriction );
		let allowSPHPricing: boolean = true;
		for ( let x: number = 0; allowSPHPricing && x < requiredYears.length; ++x ) {
			if ( !this.ticketSAPYearsActive[ requiredYears[x] ] ) {
				allowSPHPricing = false; // current user does not have a season pass from the required year.
			}
		}
		return allowSPHPricing;
	}

	private gotPrice: number = 0;
	public getTicketPrice( passID: string, year: number, month: number, day: number ): number {
		this.gotPrice++ < 1 && console.log( '===== Accessing Ticket Prices =====' ); // part 4 of 4
		let passPrice: number = 0;
		const YYYY: string = String( year );
		const MM: string = ('0' + month).slice( -2 );
		const DD: string = ('0' + day).slice( -2 );
		const YYYYMMDD: string = YYYY + '-' + MM + '-' + DD;
		if ( this.passIDToPassProps.hasOwnProperty( passID ) ) {
			if ( this.passIDToPassProps[passID].price.hasOwnProperty( YYYYMMDD ) ) {
				let useSPHPrice: boolean = false;
				if ( this.isSeasonPassHolder ) { // new -- extra logic is used to ensure the sph-price applies to only certain season pass holder years, like only 2022, etc.
					useSPHPrice = this.willAllowSPHPricing( this.passIDToPassProps[passID].price[ YYYYMMDD ].sphRestriction );
				}
				if ( useSPHPrice && this.passIDToPassProps[passID].price[ YYYYMMDD ]['sph'] !== null ) {
					passPrice = Number( this.passIDToPassProps[passID].price[ YYYYMMDD ]['sph'] );
				} else if ( Array.isArray( this.passIDToPassProps[passID].price[ YYYYMMDD ].priceTier ) ) {
					const ticketsSold: number = this.dailyAdmissionAvailability[ YYYYMMDD ]?.[ this.ticketIDToCountSoldKey[passID] ] ?? 0;
					// tiered pricing is assumed to be pre-sorted.
					const PT: InterfaceOWAPITicketPriceByDatePriceTier[] = this.passIDToPassProps[passID].price[ YYYYMMDD ].priceTier ?? [];
					let found: boolean = false;
					for ( let x: number = 0; x < PT.length; ++x ) {
						if ( ticketsSold <= PT[x].count ) {
							found = true;
							passPrice = Number( PT[x].price );
							break;
						}
					}
					if ( !found ) { // else the ticket price is just the normal price.
						passPrice = Number( this.passIDToPassProps[passID].price[ YYYYMMDD ]['default'] );
					}
				} else { // else not SPH nor tiered pricing
					passPrice = Number( this.passIDToPassProps[passID].price[ YYYYMMDD ]['default'] );
				}
			} else if ( this.passIDToPassProps[passID].price.hasOwnProperty( 'default' ) ) {
				// same logic as above, except [YYYYMMDD] is ['default'] for the date chosen.
				let useSPHPrice: boolean = false;
				if ( this.isSeasonPassHolder ) {
					useSPHPrice = this.willAllowSPHPricing( this.passIDToPassProps[passID].price['default'].sphRestriction );
				}
				if ( useSPHPrice && this.passIDToPassProps[passID].price['default']['sph'] !== null ) {
					passPrice = Number( this.passIDToPassProps[passID].price['default']['sph'] );
				} else if ( Array.isArray( this.passIDToPassProps[passID].price['default'].priceTier ) ) {
					const ticketsSold: number = this.dailyAdmissionAvailability[YYYYMMDD]?.[ this.ticketIDToCountSoldKey[passID] ] ?? 0;
					const PT: InterfaceOWAPITicketPriceByDatePriceTier[] = this.passIDToPassProps[passID].price['default'].priceTier ?? [];
					let found: boolean = false;
					for ( let x: number = 0; x < PT.length; ++x ) {
						if ( ticketsSold <= PT[x].count ) {
							found = true;
							passPrice = Number( PT[x].price );
							break;
						}
					}
					if ( !found ) { // else the ticket price is just the normal price.
						passPrice = Number( this.passIDToPassProps[passID].price['default']['default'] ); // basically .price[date][non-sph]
					}
				} else { // else not SPH nor tiered pricing.
					passPrice = Number( this.passIDToPassProps[passID].price['default']['default'] ); // default date, default (non-SPH) price
				}
			} else {
				console.log( 'missing price tag for', passID );
			}
		} else {
			console.log( 'no know price for', passID );
		}
		return passPrice;
	}

	public getSeasonPassPrice( passID: string ): number {
		return this.getTicketPrice( passID, now.getFullYear(), now.getMonth() + 1, now.getDate() );
	}

	public clearDiscountCodeError(): void {
		this.invalidPromoCode = false;
	}

	public useDiscountCode(): void {
		this.invalidPromoCode = false;
		this.promoCode = this.promoCode.replace( ServiceRegex.trimRegExp, '' ).toUpperCase();
		if ( this.promoCode.length < 1 ) {
			return;
		}
		const pCode: string = this.promoCode;
		this.owapi.workspace.actions.core.checkPromoCode( this.appConfig.getContext(), this.packUpOrderedItems(), pCode ).subscribe( (response: InterfaceHTTPGateway): void => {
			let failed: boolean = true;
			if ( response && response.success ) {
				const apiResponse: InterfaceOWAPIPromoCodeResponse = response.data;
				if ( apiResponse && apiResponse.data && Array.isArray( apiResponse.data.items ) ) {
					failed = false;
					const items: InterfaceOWAPIPromoCodeItems[] = apiResponse.data.items;
					if ( items.length > 0 && items[0] && items[0].error ) {
						this.invalidPromoCode = true;
					} else {
						this.promoCodesInUse[pCode] = true;
						this.promoCodes = Object.keys( this.promoCodesInUse ).sort(); // the displayed promo codes.
						this.discountedItems = items;
						// TODO: erase the text inside the input, once we have a listing of promo codes to display...
						// this.promoCode = ''; // the text inside the input.
					}
				}
			}
			if ( failed ) {
				this.invalidPromoCode = true;
			}
			this.reCalcDisplayData();
		} );
	}

	public cashlessToggle( b: boolean ): void {
		this.accountData.cashlessSpending = b;
	}

	private checkIfUserIsSPH(): void {
		this.isSeasonPassHolder = false;
		if ( this.isSignedIn ) {
			const appContext: InterfaceAppContext = this.appConfig.getContext();
			this.ticketSAPYearsActive = {};
			this.colProfiles.getMyUserProfile( (userProfile: InterfaceOWUser | null): void => {
				if ( userProfile && userProfile.doclet_id ) {
					const userProfileDocletID: string = userProfile.doclet_id;
					this.owapi.workspace.doclets.getWeavesByDocletID( appContext, userProfileDocletID ).subscribe( (responseUserWeaves: InterfaceHTTPGateway): void => {
						if ( responseUserWeaves?.success ) {
							const apiResponseUserWeaves: InterfaceOWAPIGetWeavesResponse | undefined = responseUserWeaves?.data;
							if ( apiResponseUserWeaves && Array.isArray( apiResponseUserWeaves?.data?.items ) ) {
								const userWeaves: InterfaceOWWeaveV2[] = apiResponseUserWeaves.data.items;
								let familyDocletKnown: boolean = false;
								for ( let uw: number = 0; uw < userWeaves.length; ++uw ) {
									switch ( userWeaves[uw].t_id.$oid ) {
										case this.strFamilyTemplateID: {
											familyDocletKnown = true;
											const familyDocletID: string = userWeaves[uw].c_id.$oid;
											this.owapi.workspace.doclets.getWeavesByDocletID( appContext, familyDocletID ).subscribe( (responseWeavesOnFamily: InterfaceHTTPGateway): void => {
												if ( responseWeavesOnFamily?.success ) {
													const apiResponseWeavesOnFamily: InterfaceOWAPIGetWeavesResponse | undefined = responseWeavesOnFamily?.data;
													if ( apiResponseWeavesOnFamily && Array.isArray( apiResponseWeavesOnFamily?.data?.items ) ) {
														const weavesOnFamily: InterfaceOWWeaveV2[] = apiResponseWeavesOnFamily.data.items;
														const consumerDocletIDs: string[] = [];
														for ( let wof: number = 0; wof < weavesOnFamily.length; ++wof ) {
															switch ( weavesOnFamily[wof].t_id.$oid ) {
																case this.strConsumerTemplateID: {
																	if ( weavesOnFamily[wof]?.data?.['role'] === 'Pass Holder' ) {
																		consumerDocletIDs.push( weavesOnFamily[wof].c_id.$oid );
																	} // end if this consumer is a season pass holder.
																	break;
																} // end id if this weave is a consumer
															} // end switch template_id of this weave
														} // end for each weaves on the family.
														if ( consumerDocletIDs.length > 0 ) {
															console.log( 'user may be a season pass holder, of some kind...' );
															for ( let cd: number = 0; cd < consumerDocletIDs.length; ++cd ) {
																this.owapi.workspace.doclets.getWeavesByDocletID( appContext, consumerDocletIDs[cd] ).subscribe( (responseWeavesOnConsumer: InterfaceHTTPGateway): void => {
																	if ( responseWeavesOnConsumer?.success ) {
																		const apiResponseWeavesOnConsumer: InterfaceOWAPIGetWeavesResponse | undefined = responseWeavesOnConsumer?.data;
																		if ( apiResponseWeavesOnConsumer && Array.isArray( apiResponseWeavesOnConsumer?.data?.items ) ) {
																			const weavesOnConsumer: InterfaceOWWeaveV2[] = apiResponseWeavesOnConsumer.data.items;
																			if ( weavesOnConsumer.length > 0 ) {
																				let seasonPassDocletIDs: string[] = weavesOnConsumer.filter( (w: InterfaceOWWeaveV2): boolean => w.t_id.$oid === this.strSeasonAdmissionTicketTemplateID ).map( (w: InterfaceOWWeaveV2): string => w.c_id.$oid );
																				if ( seasonPassDocletIDs.length ) {
																					// investigating season passes for a consumer...
																					// passes must have 'data.status' and data.status must not be 'canceled' (expired, active, whatever)
																					let seasonPassesToCheck: number = seasonPassDocletIDs.length;
																					for ( let spd: number = 0; spd < seasonPassDocletIDs.length; ++spd ) {
																						this.owapi.workspace.doclets.getDocletByID( appContext, seasonPassDocletIDs[spd] ).subscribe( (responseGetSPDoclet: InterfaceHTTPGateway): void => {
																							if ( responseGetSPDoclet?.success ) {
																								const apiResponseGetSPDoclet: InterfaceOWAPIGetDocletResponse | undefined = responseGetSPDoclet?.data;
																								if ( apiResponseGetSPDoclet?.data?._id?.$oid ) {
																									const seasonPassDoclet: InterfaceOWDoclet = responseGetSPDoclet?.data;
																									if ( seasonPassDoclet && seasonPassDoclet.data && seasonPassDoclet.data['status'] && seasonPassDoclet.data['status'] !== 'canceled' && typeof seasonPassDoclet.data['year'] === 'string' ) {
																										this.isSeasonPassHolder = true; // TODO: only for the current year.
																										// must not be banned. must have an active status.
																										const seasonPassYear: string = seasonPassDoclet.data['year'] as string;
																										this.ticketSAPYearsActive[seasonPassYear] = true;
																									}
																									if ( --seasonPassesToCheck < 1 ) {
																										this.reCalcDisplayDataDebounce();
																									}
																								}
																							}
																						} );
																					} // end for each season pass to check
																				} // else no season passes for this consumer.
																			} // else no weaves on this consumer, so no passes, either.
																		}
																	}
																} );
															}
														} // end if there were any pass holders.
													}
												}
											} );
											break;
										} // end if this was the family template_id
									} // end switch template_id of each weave
								} // end for each weave on the user
								this.reCalcDisplayDataDebounce();
							}
						}
					} ); // end of fetching weaves tied to this user.
				} // end if profile was fetched
			} ); // end fetch profile
		} // end if signed in
	}

	public reCaptchaSetToken( token: string ): void {
		this.reCaptchUserToken = token;
		// console.log( 'reCaptcha user token', token );
	}

	public reCaptchaErrored( nothing: any ): void {
		// this doesn't fire when you think it should. don't use this.
		console.log( 'reCaptcha errored', nothing );
	}
}
