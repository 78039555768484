<div class="buy-now-wrapper">
	<div class="hero">
		<div class="wrapper">
			<div class="logo">
				<a [routerLink]="[ '/' + (isSignedIn ? routes.dashboard : routes.signIn) ]"><img alt="Wild Rivers" src="assets/img/WR_MAINLOGO.png"/></a>
			</div>
			<i class="cart-toggle fa-solid fa-cart-shopping"
				(click)="toggleCart();"
			><b *ngIf="cartCount">{{ cartCount }}</b></i>
			<ng-container *ngIf="false">{{ grandTotal - discountAmount | currency : 'USD':'symbol-narrow':'1.0-0' }}</ng-container>
		</div>
	</div>
	<div class="outer-wrapper">
		<div class="wrapper" *ngIf="showingCalendar">
			<div class="calendar-header">
				<span class="title">{{ monthLabels[ selectedMonth - 1 ] }}</span>
				<span
					[ngClass]="{
						'button': true,
						'prev': true,
						'disabled': noGoingBack
					}"
					(click)="prevReservationCalendarMonth();"
				><i class="fa-solid fa-chevron-left"></i></span>
				<span
					[ngClass]="{
						'button': true,
						'next': true,
						'disabled': noGoingForward
					}"
					(click)="nextReservationCalendarMonth();"
				><i class="fa-solid fa-chevron-right"></i></span>
			</div>
			<div class="calendar-body">
				<ul class="row labels">
					<li>Sun</li>
					<li>Mon</li>
					<li>Tue</li>
					<li>Wed</li>
					<li>Thu</li>
					<li>Fri</li>
					<li>Sat</li>
				</ul>
				<ul class="calendar">
					<li class="offset" *ngFor="let day of leadingBlanks"></li>
					<li class="day"
						*ngFor="let day of daysInMonth; let i = index;"
						[ngClass]="{
							'selected': selectedDay === i + 1,
							'already-reserved': false,
							'logic-is-weekend': (leadingBlanks.length + i) % 7 === 0 || (leadingBlanks.length + i) % 7 === 6,
							'logic-is-past': (selectedYear * 10000 + selectedMonth * 100 + i + 1 < intNowYYYYMMDD1),
							'logic-nothing-to-buy': nothingToBuy,
							'logic-is-sold-out': !isThereCapacityToday( strTargetYYYY_MM1 + '-' + ('0' + (i + 1)).slice( -2 ) ),
							'logic-no-capacity': !skipCapacityCheck &&
							(
								nothingToBuy || !dailyAdmissionAvailability || !availabilityLoaded
								|| !(
										 dailyAdmissionAvailability[ strTargetYYYY_MM1 + '-' + ('0' + (i + 1)).slice( -2 ) ]
									&& dailyAdmissionAvailability[ strTargetYYYY_MM1 + '-' + ('0' + (i + 1)).slice( -2 ) ].hasOwnProperty( 'ticket_availability' )
									)
								|| dailyAdmissionAvailability[ strTargetYYYY_MM1 + '-' + ('0' + (i + 1)).slice( -2 ) ]['ticket_availability'] < 1
							),
							'logic-date-not-valid': (calendarPassDoclet?.data?.['__datesValid'] && !calendarPassDoclet?.data?.['__datesValid']?.[ strTargetYYYY_MM1 + '-' + ('0' + (i + 1)).slice( -2 ) ]),
							'logic-ticket-is-blocking': calendarPassDoclet?.data?.['__blocked_dates']?.[ strTargetYYYY_MM1 + '-' + ('0' + (i + 1)).slice( -2 ) ],
							'blocked': (selectedYear * 10000 + selectedMonth * 100 + i + 1 < intNowYYYYMMDD1)
								|| nothingToBuy
								|| !dailyAdmissionAvailability
								|| !availabilityLoaded
								|| (!skipCapacityCheck && !isThereCapacityToday( strTargetYYYY_MM1 + '-' + ('0' + (i + 1)).slice( -2 ) ))
								|| (calendarPassDoclet?.data?.['__datesValid'] && !calendarPassDoclet?.data?.['__datesValid']?.[ strTargetYYYY_MM1 + '-' + ('0' + (i + 1)).slice( -2 ) ])
								|| calendarPassDoclet?.data?.['__blocked_dates']?.[ strTargetYYYY_MM1 + '-' + ('0' + (i + 1)).slice( -2 ) ]
						}"
						(click)="setSelectedDay( i + 1 ); syncCalendarTicketSelectionFromCart(); showCalendarTicketSelection();"
					><b>{{ i + 1 }}</b><span class="price">{{
						calendarPassID ? (getTicketPrice( calendarPassID, selectedYear, selectedMonth, i + 1 ) | currency : 'USD') : ''
					}}</span></li>
					<li class="remainder" *ngFor="let day of trailingBlanks"></li>
				</ul>
			</div>
			<div class="actions">
				<span class="button" (click)="hideCalendar(); resetCalendarTicketData();">Back</span>
			</div>
			<div class="calendar-ticket-selection-wrapper"
				*ngIf="showingCalendarTicketSelection && calendarPassID && calendarPassDoclet"
			>
				<div class="flex-wrapper">
					<div class="calendar-ticket-selection">
						<div class="ticket">
							<div class="details">
								<b>{{ calendarPassDoclet.data['name'] }}<br />
									<span class="b-note" *ngIf="passIDToPassProps[ calendarPassDoclet._id.$oid ].isJunior">{{ jrText1 }}</span>
								</b>
								<span class="meta">
									<span class="price">{{ getTicketPrice( calendarPassDoclet._id.$oid, selectedYear, selectedMonth, selectedDay ) | currency : 'USD' }}<em>/ea</em></span>
									<span class="date">{{ monthLabels[ selectedMonth - 1 ] + ' ' + numOrdinal( selectedDay ) }}</span>
									<span class="notes">
										<span class="note" *ngIf="passIDToPassProps[ calendarPassDoclet._id.$oid ].isLimitPerOrder">{{ limitPerOrderTextA + calendarPassDoclet.data['limit_per_order'] + limitPerOrderTextB }}</span>
										<span class="note" *ngIf="passIDToPassProps[ calendarPassDoclet._id.$oid ].isJunior">{{ jrText2 }}</span>
										<span class="note" *ngIf="passIDToPassProps[ calendarPassDoclet._id.$oid ].isEvening">{{ eveningTextA }}{{ calendarPassDoclet.data['price'] | passPriceTime : strTargetYYYY_MM1 + '-' + ('0' + selectedDay).slice( -2 ) }}{{ eveningTextB }}</span>
										<span class="note" *ngIf="passIDToPassProps[ calendarPassDoclet._id.$oid ].isAddOn">{{ addonText }}</span>
										<span class="note" *ngIf="calendarPassDoclet.data['__note1']">{{ calendarPassDoclet.data['__note1'] ?? '' }}</span>
										<span class="note" *ngIf="calendarPassDoclet.data['__note2']">{{ calendarPassDoclet.data['__note2'] ?? '' }}</span>
									</span>
								</span>
							</div>
							<div class="quantity-controls" *ngIf="!(passIDToPassProps?.[calendarPassID]?.isCabana ?? false)">
								<span class="dec"
									(click)="!busy && updateTicketSelection( calendarPassDoclet._id.$oid, -1, {
										isAnyDay: false,
										isSeasonPass: passIDToPassProps[ calendarPassDoclet._id.$oid ].isSeasonPass,
										isComplexBundle: passIDToPassProps[ calendarPassDoclet._id.$oid ].isComplexBundle
									} );"
								><i class="fa-solid fa-minus"></i></span>
								<input type="text" readonly value="0"
									*ngIf="passIDToPassProps[calendarPassID].isSeasonPass"
									[ngModel]="ticketSelections_v2.seasonPasses[ calendarPassDoclet._id.$oid ].qty"
								/>
								<input type="text" readonly value="0"
									*ngIf="passIDToPassProps[calendarPassID].isDailyAdmission || passIDToPassProps[calendarPassID].isDailyParking || passIDToPassProps[calendarPassID].isCabana"
									[ngModel]="ticketSelections_v2.dailyPasses[ calendarPassDoclet._id.$oid ].qty"
								/>
								<input type="text" readonly value="0"
									*ngIf="passIDToPassProps[calendarPassID].isComplexBundle"
									[ngModel]="ticketSelections_v2.complexPasses[ calendarPassDoclet._id.$oid ].qty"
								/>
								<span class="inc"
									(click)="!busy && updateTicketSelection( calendarPassDoclet._id.$oid, 1, {
										isAnyDay: false,
										isSeasonPass: passIDToPassProps[calendarPassDoclet._id.$oid].isSeasonPass,
										isComplexBundle: passIDToPassProps[ calendarPassDoclet._id.$oid ].isComplexBundle
									} );"
								><i class="fa-solid fa-plus"></i></span>
							</div>
							<div class="quantity-controls locations" *ngIf="(passIDToPassProps?.[calendarPassID]?.isCabana ?? false)">
								<span class="location-label">Location</span>
								<select class="location-choice"
									[attr.disabled]="cabanaLocationSelectBusy ? true : null"
									[(ngModel)]="selectedCabanaLocationIdx"
									[ngModelOptions]="{
										'updateOn': 'change'
									}"
								>
									<option *ngIf="openCabanaLocations.length < 1" [ngValue]="undefined">None Available</option>
									<option *ngIf="openCabanaLocations.length > 0" [ngValue]="undefined">Select...</option>
									<option *ngFor="let seat of openCabanaLocations; let idx = index" [ngValue]="idx">{{ seat }}</option>
								</select>
							</div>
						</div>
						<div class="actions">
							<span class="button"
								*ngIf="(passIDToPassProps?.[calendarPassID]?.isCabana ?? false)"
								(click)="pickCabanaLocation();"
							>Add To Cart</span>
							<span class="button"
								*ngIf="!(passIDToPassProps?.[calendarPassID]?.isCabana ?? false)"
								(click)="hideCalendarTicketSelection(); resetCalendarTicketData(); hideCalendar(); toggleCart();"
							>Show Cart</span>
							<span class="button" (click)="hideCalendarTicketSelection();">Close</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="wrapper" *ngIf="!showingCalendar">
			<h1>Buy 2024 Season Passes & 2024 Tickets</h1>
			<div class="centered">
				<ul class="tabs">
					<li
						(click)="setTab( 'passes' );"
						[ngClass]="{
							'active': productTab === 'passes'
						}"
					>Season Passes</li>
					<li
						(click)="setTab( 'tickets' );"
						[ngClass]="{
							'active': productTab === 'tickets'
						}"
					>Daily Tickets</li>
					<li
						(click)="setTab( 'cabanas' );"
						[ngClass]="{
							'active': productTab === 'cabanas'
						}"
					>Cabanas</li>
				</ul>
			</div>
			<ng-container *ngIf="productTab === 'passes'">
				<div id="sap-sold-out-heading" style="display: none;">2023 season passes are sold out.</div>
				<div id="sap-sold-out-body" style="display: none;">Please <a href="https://wildrivers.com/">sign up</a> and follow us on social to find out when our 2024 season passes will be available.</div>
				<div class="centered tier-wrapper margin animate__animated animate__fadeIn">
					<p class="responsive-wrapper product-desc">Season Passes are valid any 2024 normal operating day. Each guest must have a valid 2024 Season Pass for entry. Season Passes are non-refundable. Once activated, Season Passes are non-transferable. See terms and conditions for more details.</p>
					<div class="season-admission-passes responsive-wrapper animate__animated animate__fadeInUp animate__delay-1s"
						[ngClass]="{
							'non-renewals': !isSignedIn || allowedSAPQuantityTotal < 1
						}"
					>
						<div class="season-admission-pass renewal sap-t1-renewal"
							*ngIf="seasonPassT1Renewal && allowedSAPQuantityTotal > 0"
						>
							<div class="tier-name">Silver Renewal</div>
							<b class="tier-price">{{ getSeasonPassPrice( seasonPassT1Renewal._id.$oid ) | currency : 'USD':'symbol-narrow':'1.0-0' }}<em>/ea</em></b>
							<div class="notes">
								<div class="note">({{ allowedSAPQuantityTotal }} available for renewal.)</div>
							</div>
							<div class="quantity-controls">
								<span class="dec"
									(click)="!busy && updateTicketSelection( seasonPassT1Renewal._id.$oid, -1, { isSeasonPass: true } );"
								><i class="fa-solid fa-minus"></i></span>
								<input type="text" readonly value="0"
									[ngModel]="ticketSelections_v2.seasonPasses[seasonPassT1Renewal._id.$oid].qty"
								/>
								<span class="inc"
									(click)="!busy && updateTicketSelection( seasonPassT1Renewal._id.$oid, 1, { isSeasonPass: true } );"
								><i class="fa-solid fa-plus"></i></span>
							</div>
						</div>

						<div class="season-admission-pass renewal sap-t2-renewal"
							*ngIf="seasonPassT2Renewal && allowedSAPQuantityTotal > 0"
						>
							<div class="tier-name">Gold Renewal</div>
							<b class="tier-price">{{ getSeasonPassPrice( seasonPassT2Renewal._id.$oid ) | currency : 'USD':'symbol-narrow':'1.0-0' }}<em>/ea</em></b>
							<div class="notes">
								<div class="note">({{ allowedSAPQuantityTotal }} available for renewal.)</div>
							</div>
							<div class="quantity-controls">
								<span class="dec"
									(click)="!busy && updateTicketSelection( seasonPassT2Renewal._id.$oid, -1, { isSeasonPass: true } );"
								><i class="fa-solid fa-minus"></i></span>
								<input type="text" readonly value="0"
									[ngModel]="ticketSelections_v2.seasonPasses[seasonPassT2Renewal._id.$oid].qty"
								/>
								<span class="inc"
									(click)="!busy && updateTicketSelection( seasonPassT2Renewal._id.$oid, 1, { isSeasonPass: true } );"
								><i class="fa-solid fa-plus"></i></span>
							</div>
						</div>

						<div class="season-admission-pass renewal sap-t3-renewal featured"
							*ngIf="seasonPassT3Renewal && allowedSAPQuantityTotal > 0"
						>
							<div class="tier-name">Diamond Renewal</div>
							<b class="tier-price">{{ getSeasonPassPrice( seasonPassT3Renewal._id.$oid ) | currency : 'USD':'symbol-narrow':'1.0-0' }}<em>/ea</em></b>
							<div class="notes">
								<div class="note">({{ allowedSAPQuantityTotal }} available for renewal.)</div>
							</div>
							<div class="quantity-controls">
								<span class="dec"
									(click)="!busy && updateTicketSelection( seasonPassT3Renewal._id.$oid, -1, { isSeasonPass: true } );"
								><i class="fa-solid fa-minus"></i></span>
								<input type="text" readonly value="0"
									[ngModel]="ticketSelections_v2.seasonPasses[seasonPassT3Renewal._id.$oid].qty"
								/>
								<span class="inc"
									(click)="!busy && updateTicketSelection( seasonPassT3Renewal._id.$oid, 1, { isSeasonPass: true } );"
								><i class="fa-solid fa-plus"></i></span>
							</div>
						</div>

						<div class="season-admission-pass sap-t1 demo-bundle"
							*ngIf="membershipBundle"
						>
							<div class="tier-name">{{ membershipBundle.data['name'] }}</div>
							<b class="tier-price">{{ membershipBundle.data['__lowestPrice'] | currency : 'USD' : 'symbol-narrow':'1.0-0' }}<em>/ea</em></b>
							<ul class="sap-silver-perks entitlements">
								<li class="included"
									*ngFor="let entitlement of membershipBundlePerks.includes;"
								><i class="far fa-circle-check"></i><span>{{ entitlement }}</span></li>
								<li class="excluded"
										*ngFor="let entitlement of membershipBundlePerks.excludes;"
								><i class="far fa-circle-check"></i><span>{{ entitlement }}</span></li>
							</ul>
							<div class="quantity-controls">
								<span class="dec"
									(click)="!busy && updateTicketSelection( membershipBundle._id.$oid, -1, { isComplexBundle: true } );"
								><i class="fa-solid fa-minus"></i></span>
								<input type="text" readonly value="0"
									[ngModel]="ticketSelections_v2.complexPasses[membershipBundle._id.$oid].qty"
								/>
								<span class="inc"
									(click)="!busy && updateTicketSelection( membershipBundle._id.$oid, 1, { isComplexBundle: true } );"
								><i class="fa-solid fa-plus"></i></span>
							</div>
						</div>
						<div class="season-admission-pass sap-t1"
							*ngIf="seasonPassT1 && !membershipBundle"
						>
							<div class="tier-name">{{ seasonPassT1.data['name'] }}</div>
							<b class="tier-price">{{
								isSAPSilverForSale
								? (getSeasonPassPrice( seasonPassT1!._id!.$oid ) | currency : 'USD': 'symbol-narrow':'1.0-0')
								: 'Coming Soon'
							}}<em *ngIf="isSAPSilverForSale">/ea</em></b>
							<ul class="sap-silver-perks entitlements"
								*ngIf="showingSAPEntitlements && (seasonPassT1Entitlements.includes.length > 0 || seasonPassT1Entitlements.excludes.length > 0)"
							>
								<li class="included"
									*ngFor="let entitlement of seasonPassT1Entitlements.includes;"
								><i class="far fa-circle-check"></i><span>{{ entitlement }}</span></li>
								<li class="excluded"
									*ngFor="let entitlement of seasonPassT1Entitlements.excludes;"
								><i class="far fa-circle-check"></i><span>{{ entitlement }}</span></li>
							</ul>
							<div class="quantity-controls" *ngIf="isSAPSilverForSale">
								<span class="dec"
									(click)="!busy && updateTicketSelection( seasonPassT1._id.$oid, -1, { isSeasonPass: true } );"
								><i class="fa-solid fa-minus"></i></span>
								<input type="text" readonly value="0"
									[ngModel]="ticketSelections_v2.seasonPasses[seasonPassT1._id.$oid].qty"
								/>
								<span class="inc"
									(click)="!busy && updateTicketSelection( seasonPassT1._id.$oid, 1, { isSeasonPass: true } );"
								><i class="fa-solid fa-plus"></i></span>
							</div>
						</div>

						<div class="season-admission-pass sap-t2"
							*ngIf="seasonPassT2"
						>
							<div class="tier-name">{{ seasonPassT2.data['name'] }}</div>
							<b class="tier-price">{{ getSeasonPassPrice( seasonPassT2._id.$oid ) | currency : 'USD':'symbol-narrow':'1.0-0' }}<em>/ea</em></b>
							<ul class="sap-gold-perks entitlements"
								*ngIf="showingSAPEntitlements && (seasonPassT2Entitlements.includes.length > 0 || seasonPassT2Entitlements.excludes.length > 0)"
							>
								<li class="included"
									*ngFor="let entitlement of seasonPassT2Entitlements.includes;"
								><i class="far fa-circle-check"></i><span>{{ entitlement }}</span></li>
								<li class="excluded"
									*ngFor="let entitlement of seasonPassT2Entitlements.excludes;"
								><i class="far fa-circle-check"></i><span>{{ entitlement }}</span></li>
							</ul>
							<div class="quantity-controls">
								<span class="dec"
									(click)="!busy && updateTicketSelection( seasonPassT2._id.$oid, -1, { isSeasonPass: true } );"
								><i class="fa-solid fa-minus"></i></span>
								<input type="text" readonly value="0"
									[ngModel]="ticketSelections_v2.seasonPasses[ seasonPassT2._id.$oid ].qty"
								/>
								<span class="inc"
									(click)="!busy && updateTicketSelection( seasonPassT2._id.$oid, 1, { isSeasonPass: true } );"
								><i class="fa-solid fa-plus"></i></span>
							</div>
						</div>

						<div class="season-admission-pass sap-t3"
							*ngIf="seasonPassT3"
							[ngClass]="{
								'featured': !isSignedIn || allowedSAPQuantityTotal < 1
							}"
						>
							<div class="tier-name">{{ seasonPassT3.data['name'] }}</div>
							<b class="tier-price">{{ getSeasonPassPrice( seasonPassT3._id.$oid ) | currency : 'USD':'symbol-narrow':'1.0-0' }}<em>/ea</em></b>
							<ul class="sap-diamond-perks entitlements"
								*ngIf="showingSAPEntitlements && (seasonPassT3Entitlements.includes.length > 0 || seasonPassT3Entitlements.excludes.length > 0)"
							>
								<li class="included"
									*ngFor="let entitlement of seasonPassT3Entitlements.includes;"
								><i class="far fa-circle-check"></i><span>{{ entitlement }}</span></li>
								<li class="excluded"
									*ngFor="let entitlement of seasonPassT3Entitlements.excludes;"
								><i class="far fa-circle-check"></i><span>{{ entitlement }}</span></li>
							</ul>
							<div class="quantity-controls">
								<span class="dec"
									(click)="!busy && updateTicketSelection( seasonPassT3._id.$oid, -1, { isSeasonPass: true } );"
								><i class="fa-solid fa-minus"></i></span>
								<input type="text" readonly value="0"
									[ngModel]="ticketSelections_v2.seasonPasses[ seasonPassT3._id.$oid ].qty"
								/>
								<span class="inc"
									(click)="!busy && updateTicketSelection( seasonPassT3._id.$oid, 1, { isSeasonPass: true } );"
								><i class="fa-solid fa-plus"></i></span>
							</div>
						</div>
					</div>
				</div>
				<div class="centered margin animate__animated animate__fadeIn animate__delay-2s" style="display: none;"
					*ngIf="seasonParkingPasses.length > 0"
				>
					<div class="responsive-wrapper"
						*ngFor="let spp of seasonParkingPasses;"
					>
						<div class="addon">
							<div class="details">
								<b>{{ spp.data['name'] }}</b>
								<span class="meta">
									<span class="price">{{ getSeasonPassPrice( spp._id.$oid ) | currency : 'USD' }}<em>/ea</em></span>
									<span class="notes">
										<span class="note">({{ spp.data['year'] ?? currentSeasonYear }} Season Pass Required)</span>
									</span>
								</span>
							</div>
							<div class="quantity-controls">
								<span class="dec"
									(click)="!busy && updateTicketSelection( spp._id.$oid, -1, { isSeasonPass: true } );"
								><i class="fa-solid fa-minus"></i></span>
								<input type="text" readonly value="0"
									[ngModel]="ticketSelections_v2.seasonPasses[ spp._id.$oid ].qty"
								/>
								<span class="inc"
									(click)="!busy && updateTicketSelection( spp._id.$oid, 1, { isSeasonPass: true } );"
								><i class="fa-solid fa-plus"></i></span>
							</div>
						</div>
					</div>
				</div>
			</ng-container>
			<ng-container *ngIf="productTab === 'tickets'">
				<div class="centered margin">
					<p *ngIf="dailyAdmissionPasses.length > 0 || promotedDailyAdmissionPasses.length > 0" class="responsive-wrapper product-desc">Daily tickets are only valid for the date chosen.</p>
					<ul *ngIf="dailyAdmissionPasses.length > 0 || promotedDailyAdmissionPasses.length > 0" class="responsive-wrapper tickets centered animate__animated animate__fadeIn">
						<li class="ticket" *ngFor="let pdap of promotedDailyAdmissionPasses | passFilterSPH : isSeasonPassHolder;">
							<div class="details">
								<b>{{ pdap.data['name'] }}<br />
									<span class="b-note" *ngIf="passIDToPassProps[ pdap._id.$oid ].isJunior">{{ jrText1 }}</span>
								</b>
								<span class="meta">
									<span class="price">Starting at {{ pdap.data['__lowestPrice'] | currency : 'USD' }}<em>/ea</em></span>
									<span class="notes">
										<span class="note" *ngIf="passIDToPassProps[ pdap._id.$oid ].isLimitPerOrder">{{ limitPerOrderTextA + pdap.data['limit_per_order'] + limitPerOrderTextB }}</span>
										<span class="note" *ngIf="passIDToPassProps[ pdap._id.$oid ].isJunior">{{ jrText2 }}</span>
										<span class="note" *ngIf="passIDToPassProps[ pdap._id.$oid ].isEvening">{{ eveningTextA }}{{ pdap.data['price'] | passPriceTime }}{{ eveningTextB }}</span>
										<span class="note" *ngIf="passIDToPassProps[ pdap._id.$oid ].isAddOn">{{ addonText }}</span>
										<span class="note" *ngIf="pdap._id.$oid === militaryPromoID">{{ militaryText }}</span>
									</span>
								</span>
							</div>
							<div class="quantity-controls date-required">
								<span class="button"
									*ngIf="!(passIDToPassProps[ pdap._id.$oid ].isAddOn && !isAddOnAllowedInCart)"
									(click)="showCalendar( pdap._id.$oid );"
								>Select A Date</span>
								<span class="missing-add-on-requirement"
									*ngIf="passIDToPassProps[ pdap._id.$oid ].isAddOn && !isAddOnAllowedInCart"
								>Please select at least 1 daily ticket to unlock the special</span>
							</div>
						</li>
						<li class="ticket" *ngFor="let dap of dailyAdmissionPasses | passFilterSPH : isSeasonPassHolder;">
							<div class="details">
								<b>{{ dap.data['name'] }}<br />
									<span class="b-note" *ngIf="passIDToPassProps[ dap._id.$oid ].isJunior">{{ jrText1 }}</span>
								</b>

								<span class="meta">
									<span class="price">Starting at {{ dap.data['__lowestPrice'] | currency : 'USD' }}<em>/ea</em></span>
									<span class="notes">
										<span class="note" *ngIf="passIDToPassProps[ dap._id.$oid ].isLimitPerOrder">{{ limitPerOrderTextA + dap.data['limit_per_order'] + limitPerOrderTextB }}</span>
										<span class="note" *ngIf="passIDToPassProps[ dap._id.$oid ].isJunior">{{ jrText2 }}</span>
										<span class="note" *ngIf="passIDToPassProps[ dap._id.$oid ].isEvening">{{ eveningTextA }}{{ dap.data['price'] | passPriceTime }}{{ eveningTextB }}</span>
										<span class="note" *ngIf="passIDToPassProps[ dap._id.$oid ].isAddOn">{{ addonText }}</span>
									</span>
								</span>
							</div>
							<div class="quantity-controls date-required">
								<span class="button" (click)="showCalendar( dap._id.$oid );">Select A Date</span>
							</div>
						</li>
					</ul>
					<p *ngIf="parkingPasses.length > 0" class="responsive-wrapper product-desc">Parking tickets are only valid for the date chosen.</p>
					<ul *ngIf="parkingPasses.length > 0" class="responsive-wrapper tickets centered animate__animated animate__fadeIn">
						<li class="ticket" *ngFor="let pp of parkingPasses">
							<div class="details">
								<b>{{ pp.data['name'] }}</b>
								<span class="meta">
									<span class="price">Starting at {{ pp.data['__lowestPrice'] | currency : 'USD' }}<em>/ea</em></span>
								</span>
							</div>
							<div class="quantity-controls date-required">
								<span class="button" (click)="showCalendar( pp._id.$oid );">Select A Date</span>
							</div>
						</li>
					</ul>
					<p *ngIf="complexProducts.length > 0" class="responsive-wrapper product-desc">Bundled tickets are only valid for the date chosen.</p>
					<ul *ngIf="complexProducts.length > 0" class="responsive-wrapper tickets centered animate__animated animate__fadeIn">
						<li class="ticket" *ngFor="let complex of complexProducts">
							<div class="details">
								<b>{{ complex.data['name'] }}</b>
								<span class="meta">
									<span class="price">Starting at {{ complex.data['__lowestPrice'] | currency : 'USD' }}<em>/ea</em></span>
									<span class="notes">
										<span class="note" *ngIf="passIDToPassProps[ complex._id.$oid ].isLimitPerOrder">{{ limitPerOrderTextA + complex.data['limit_per_order'] + limitPerOrderTextB }}</span>
										<span class="note" *ngIf="passIDToPassProps[ complex._id.$oid ].isJunior">{{ jrText2 }}</span>
										<span class="note" *ngIf="passIDToPassProps[ complex._id.$oid ].isEvening">{{ eveningTextA }}{{ complex.data['price'] | passPriceTime }}{{ eveningTextB }}</span>
										<span class="note" *ngIf="passIDToPassProps[ complex._id.$oid ].isAddOn">{{ addonText }}</span>
										<span class="note" *ngIf="complex.data['__note1']">{{ complex.data['__note1'] ?? '' }}</span>
										<span class="note" *ngIf="complex.data['__note2']">{{ complex.data['__note2'] ?? '' }}</span>
									</span>
								</span>
							</div>
							<div class="quantity-controls date-required">
								<span class="button" (click)="showCalendar( complex._id.$oid );">Select A Date</span>
							</div>
						</li>
					</ul>
				</div>
			</ng-container>
			<ng-container *ngIf="productTab === 'cabanas'">
				<div class="centered margin">
					<p class="responsive-wrapper product-desc"
						*ngIf="cabanaPasses.length > 0"
					>Cabana Passes</p>
					<ul class="responsive-wrapper tickets centered animate__animated animate__fadeIn"
						*ngIf="cabanaPasses.length > 0"
					>
						<li class="ticket"
							*ngFor="let cp of cabanaPasses;"
						>
							<div class="details">
								<b>{{ cp.data['name'] }}</b>
								<span class="meta">
									<span class="price">Starting at {{ cp.data['__lowestPrice'] | currency : 'USD' }}<em>/ea</em></span>
								</span>
							</div>
							<div class="quantity-controls date-required">
								<span class="button"
									(click)="showCalendar( cp._id.$oid );"
								>Select A Date</span>
							</div>
						</li>
					</ul>
				</div>
			</ng-container>
			<div class="centered margin show-cart-footer animate__animated animate__fadeIn animate__delay-2s">
				<div class="responsive-wrapper">
				<span class="button" (click)="toggleCart();"><i class="fa-solid fa-cart-shopping"></i>Show Cart</span>
				</div>
			</div>
		</div>
	</div>
	<div
		[ngClass]="{
			'cart': true,
			'active': cartShown,
			'discount-shown': promoCode.length > 0
		}"
	>
		<i class="close fa-solid fa-xmark" (click)="!busy && closeCart();"></i>
		<ul class="items">
			<li class="day" *ngFor="let lineItem of cartItems; let lineIdx = index;">
				<span class="date">{{
					lineItem.isSeasonPass || (lineItem.isComplexBundle && lineItem.date.day < 1)
					? 'Year ' + lineItem.date.year
					: (
						lineItem.isAnyDay
						? 'Year ' + lineItem.date.year + ' any day'
						: monthLabels[lineItem.date.month - 1] + ' ' + numOrdinal( lineItem.date.day )
					)
				}}</span>
				<ul class="tickets">
					<li *ngFor="let ticket of lineItem.tickets; let ticketIdx = index;">
						<span class="label">
							<b>{{ passIDToPassProps[ticket.passID].name }}</b><i class="location" *ngIf="(ticket.location?.length ?? 0) > 0">&#35;{{ ticket.location }}</i>
							<span class="price">{{
								(lineItem.isSeasonPass
									? getSeasonPassPrice( ticket.passID )
									: getTicketPrice( ticket.passID, lineItem.date.year, lineItem.date.month, lineItem.date.day )
								) | currency : 'USD'
							}}&nbsp;EA&nbsp;<span class="remove"
								(click)="!busy && removeTicketGroup( lineItem, ticketIdx );"
							>Remove</span></span>
						</span>
						<span class="quantity">
							<span class="dec"
								*ngIf="!lineItem.isCabana"
								(click)="!busy && updateCartItem( lineIdx, ticketIdx, -1 );"
							><i class="fa-solid fa-minus"></i></span>
							<input type="text" readonly
								[ngClass]="{
									'is-cabana': !!lineItem.isCabana
								}"
								[value]="ticket.qty"
							/>
							<span class="inc"
								*ngIf="!lineItem.isCabana"
								(click)="!busy && updateCartItem( lineIdx, ticketIdx, 1 );"
							><i class="fa-solid fa-plus"></i></span>
						</span>
					</li>
				</ul>
			</li>
		</ul>
		<span class="discount-applied" *ngIf="promoCode.length > 0">
			<span class="label">Discount&nbsp;{{ promoCode }}</span>
			<span class="price">-{{ discountAmount | currency : 'USD' }}</span>
		</span>
		<span class="subtotal">
			<span class="label">Total</span>
			<span class="price">{{ grandTotal - discountAmount | currency : 'USD' }}</span>
		</span>
		<span
			[ngClass]="{
				'busy': busy,
				'no-items': cartItems.length < 1,
				'no-tickets': !cartHasTickets,
				'button': true,
				'check-out': true,
				'disabled': !cartHasTickets || cartItems.length < 1 || busy
			}"
			(click)="!busy && checkout();"
		>Check Out</span>
	</div>
	<div
		[ngClass]="{
			'modal-overlay': true,
			'active': overlayShown
		}"
	>&nbsp;</div>
</div>
