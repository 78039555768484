<modal-back-drop id="modal-credit-card-wrapper">
	<span id="modal-credit-card" class="modal-body">
		<span class="content-wrapper">
			<span class="scroll-wrapper">
				<span class="title">Card On File</span>
				<span class="form">
					<label class="wide">
						<span class="frame-label">Card Number <b>*</b></span>
						<input class="align-center" type="text" name="cc-number" autocomplete="off" maxlength="16" #ccNum
							[class.error]="ccErrors.ccNum"
							[(ngModel)]="ccData.ccNum"
							[ngModelOptions]="{ updateOn: 'blur' }"
							(ngModelChange)="validateField( 'ccNum' )"
							(focus)="ccErrors.ccNum = false;"
							(keydown)="numbersOnly( $event );"
						/>
					</label>
					<label>
						<span class="frame-label">Exp Month <b>*</b></span>
						<input class="align-center" type="text" name="cc-exp-mm" autocomplete="off" placeholder="MM" maxlength="2"
							[class.error]="ccErrors.ccExpMM"
							[(ngModel)]="ccData.ccExpMM"
							[ngModelOptions]="{ updateOn: 'blur' }"
							(ngModelChange)="validateField( 'ccExpMM' )"
							(focus)="ccErrors.ccExpMM = false;"
							(keydown)="numbersOnly( $event );"
						/>
					</label>
					<label>
						<span class="frame-label">Exp Year <b>*</b></span>
						<input class="align-center" type="text" name="cc-exp-yyyy" autocomplete="off" placeholder="YYYY" maxlength="4"
							[class.error]="ccErrors.ccExpYYYY"
							[(ngModel)]="ccData.ccExpYYYY"
							[ngModelOptions]="{ updateOn: 'blur' }"
							(ngModelChange)="validateField( 'ccExpYYYY' )"
							(focus)="ccErrors.ccExpYYYY = false;"
							(keydown)="numbersOnly( $event );"
						/>
					</label>
					<label>
						<span class="frame-label">CVV <b>*</b></span>
						<input class="align-center" type="text" name="cc-cvv" autocomplete="off" maxlength="4"
							[class.error]="ccErrors.ccCVV"
							[(ngModel)]="ccData.ccCVV"
							[ngModelOptions]="{ updateOn: 'blur' }"
							(ngModelChange)="validateField( 'ccCVV' )"
							(focus)="ccErrors.ccCVV = false;"
							(keydown)="numbersOnly( $event );"
						/>
					</label>
					<label>
						<span class="frame-label">Zip Code <b>*</b></span>
						<input class="align-center" type="text" name="cc-zip" autocomplete="off"
							[class.error]="ccErrors.ccZip"
							[(ngModel)]="ccData.ccZip"
							[ngModelOptions]="{ updateOn: 'blur' }"
							(ngModelChange)="validateField( 'ccZip' )"
							(focus)="ccErrors.ccZip = false;"
						/>
					</label>
				</span>
				<span class="actions">
					<span class="action button close" (click)="closeModal();">Close</span>
					<span
						[ngClass]="{
							'action': true,
							'button': true,
							'submit': true,
							'busy': busy
						}"
						(click)="!busy && addCC();"
					>Add Card<i class="busy-icon fa-solid fa-spin fa-circle-notch"></i></span>
				</span>
			</span>
		</span>
	</span>
</modal-back-drop>
