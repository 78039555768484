import {Component} from '@angular/core';
// ===== Components ===== //
import {ComponentCheckBoxActionLink} from '../action-linked';
//
@Component( {
	selector: 'ui-check-box-terms-of-use',
	templateUrl: './terms-of-use.html',
	styleUrls: [
		'../action-linked.less'
	]
} )
export class ComponentCheckBoxTermsOfUse extends ComponentCheckBoxActionLink {
	//
	public constructor() {
		super();
	}
}
