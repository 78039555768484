export interface InterfaceOWTemplateLifeGuardPass {
	name: string;
	price: number;
	status: 'active' | string;
	target: {
		template_id: string; // the ticket to target when buying the pass.
	};
}

/*
{
	"_id" : ObjectId("63fe846671cea602717d5472"),
	"name" : "Lifeguard Certification",
	"workspaces" : [
		ObjectId("6222b56fb41f06d49ea56fc7")
	],
	"fields" : [
		{
			"type" : "string",
			"label" : "Name",
			"key" : "name",
			"sort" : 0
		},
		{
			"label" : "Price",
			"key" : "price",
			"sort" : 1,
			"type" : "number"
		},
		{
			"label" : "Status",
			"key" : "status",
			"sort" : 2,
			"type" : "string"
		},
		{
			"label" : "Target",
			"key" : "target",
			"sort" : 3,
			"type" : "object"
		}
	],
	"realms" : [
		ObjectId("625f38c561b6eb3efaebceb3")
	],
	"tags" : [
		"Pass"
	],
	"group" : "Pass"
}
*/

export interface InterfaceOWTemplateLifeguardCertificationTicket {
	purchase_price: number; // cost of the cert
	status: 'active' | 'canceled' | 'pending' | string; // only pending and canceled mean something... until this field is relied upon more.
	type: string; // the name of the pass that was bought, to produce this ticket.
}

/*
{
	"_id" : ObjectId("63fe866371cea602717d5474"),
	"name" : "Lifeguard Certification",
	"workspaces" : [
		ObjectId("6222b56fb41f06d49ea56fc7")
	],
	"fields" : [
		{
			"label" : "Purchase Price",
			"key" : "purchase_price",
			"sort" : 0,
			"type" : "number"
		},
		{
			"label" : "Status",
			"key" : "status",
			"sort" : 1,
			"type" : "string"
		},
		{
			"label" : "type",
			"key" : "type",
			"sort" : 2,
			"type" : "string"
		}
	],
	"realms" : [
		ObjectId("6222b4b8b41f06d49ea56fc6"),
		ObjectId("625f38c561b6eb3efaebceb3")
	],
	"tags" : [
		"Ticket"
	],
	"group" : "Ticket"
}
*/

export interface InterfaceOWTemplateCRPCertificationPass {
	name: string;
	price: number;
	status: 'active' | string;
	target: {
		template_id: string; // the ticket to target when buying the pass.
	};
}

/*
{
	"_id" : ObjectId("63fe82eb71cea602717d5471"),
	"name" : "CPR Certification",
	"workspaces" : [
		ObjectId("6222b56fb41f06d49ea56fc7")
	],
	"fields" : [
		{
			"type" : "string",
			"label" : "Name",
			"key" : "name",
			"sort" : 0
		},
		{
			"label" : "Price",
			"key" : "price",
			"sort" : 1,
			"type" : "number"
		},
		{
			"label" : "Status",
			"key" : "status",
			"sort" : 2,
			"type" : "string"
		},
		{
			"label" : "Target",
			"key" : "target",
			"sort" : 3,
			"type" : "object"
		}
	],
	"realms" : [
		ObjectId("625f38c561b6eb3efaebceb3")
	],
	"tags" : [
		"Pass"
	],
	"group" : "Pass"
}
*/


export interface InterfaceOWTemplateCPRCertificationTicket {
	purchase_price: number;
	status: 'active' | 'canceled' | 'pending' | string; // only pending and canceled mean something... until this field is relied upon more.
	type: string; // the name of the pass that was bought, to produce this ticket.
}

/*
{
	"_id" : ObjectId("63fe85a871cea602717d5473"),
	"name" : "CPR Certification",
	"workspaces" : [
		ObjectId("6222b56fb41f06d49ea56fc7")
	],
	"fields" : [
		{
			"label" : "Purchase Price",
			"key" : "purchase_price",
			"sort" : 0,
			"type" : "number"
		},
		{
			"label" : "Status",
			"key" : "status",
			"sort" : 1,
			"type" : "string"
		},
		{
			"label" : "type",
			"key" : "type",
			"sort" : 2,
			"type" : "string"
		}
	],
	"realms" : [
		ObjectId("6222b4b8b41f06d49ea56fc6"),
		ObjectId("625f38c561b6eb3efaebceb3")
	],
	"tags" : [
		"Ticket"
	],
	"group" : "Ticket"
}
*/
