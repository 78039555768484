export const AppRouterLinks = {
	// in the app-routing.module.ts, the route paths MUST NOT start with a /slash.
	// but when using it on <a [routerLink]> ...it needs to start with a /slash.
	//
	// for <a [routerLink]> -- the reasoning is because if you're on "/somePath/here"
	// ...and you use <a [routerLink]="there">
	// ...you will travel to "/somePath/there"
	//
	'buyNow' : 'buynow',
	'buyLater' : 'buylater',
	'checkEmail': 'registration/check-email',
	'checkout': 'checkout',
	'completeAccount': 'registration/complete',
	'createGroup': 'group-sign-up',
	'dashboard': '',
	'editFamily': 'family/edit',
	'family': 'family',
	'editGroup': 'edit-group',
	'myAccount': 'my-account',
	'orders': 'orders',
	'resetPassword': 'reset-password',
	'signIn': 'sign-in',
	'thankYou': 'thank-you' // order-complete
};
