<modal-back-drop id="modal-liability-waiver-wrapper">
	<span id="modal-liability-waiver" class="modal-body">
		<span class="content-wrapper">
			<span class="scroll-wrapper">
				<span class="last-updated"></span>
				<span class="segment-chapter">ASSUMPTION OF RISK/WAIVER AND RELEASE OF CLAIMS/INDEMNITY AGREEMENT/SEASON PASS/DAILY TICKET TERMS AND CONDITIONS</span>
				<span class="segment-header">ASSUMPTION AND ACKNOWLEDGMENT OF ALL RISKS:</span>
				<span class="segment-body">I, ON MY OWN BEHALF, AND ON THE BEHALF OF ANY MINOR IN MY FAMILY THAT IS OBTAINING A SEASON PASS OR DAILY TICKET THROUGH ME, AS HIS/HER PARENT, KNOWINGLY, VOLUNTARILY, AND FREELY ACKNOWLEDGE AND ACCEPT AND ASSUME ANY AND ALL RISKS, BOTH KNOWN AND UNKNOWN, OF INJURIES OR OTHER LOSS OR DAMAGE THAT MAY BE SUFFERED WHILE ON WILD RIVERS WATERPARK PREMISES, HOWEVER CAUSED, EVEN IF CAUSED IN WHOLE OR IN PART BY THE ACTION, INACTION OR NEGLIGENCE OF ANY OF THE FOLLOWING:</span>
				<span class="segment-body">
					<ul>
						<li>1) WILD RIVERS IRVINE; LLC;</li>
						<li>2) WILD RIVERS WATERPARK IRVINE, SPE, LLC;</li>
						<li>3) WILD RIVERS WATERPARK IRVINE, LLC;</li>
						<li>4) WILD RIVERS WATERPARK IRVINE HOLDINGS, LLC;</li>
						<li>5) SURF HILL LLC;</li>
						<li>6) SPLASH MANAGEMENT LLC; AND</li>
						<li>7) CITY OF IRVINE (HEREINAFTER COLLECTIVELY REFERRED TO AS "RELEASED PARTIES").</li>
					</ul>
				</span>
				<span class="segment-body">SUCH RISKS INCLUDE, BUT ARE NOT LIMITED TO CUTS AND BRUISES, FALLS, BROKEN BONES, INJURIES TO WRISTS, ARMS, LEGS, FEET, ANKLES, BACKS, HEADS AND NECKS, INJURIES FROM FLIPS OR CONTACT WITH OTHER PARTICIPANTS OR OBJECTS, AND/OR SPINAL INJURIES.</span>
				<span class="segment-header">RELEASE AND WAIVER OF ALL CLAIMS:</span>
				<span class="segment-body">RELEASE AND WAIVER OF ALL CLAIMS: I, ON MY OWN BEHALF, AND ON THE BEHALF OF ANY MINOR IN MY FAMILY THAT IS OBTAINING A SEASON PASS OR DAILY TICKET THROUGH ME, AS HIS/HER PARENT, EXPRESSLY WAIVE ANY AND ALL CLAIMS, SUITS OR DEMANDS FOR PERSONAL INJURY, PROPERTY DAMAGE OR OTHER LOSS AGAINST THE “RELEASED PARTIES”, AND EACH OF THEM, INCLUDING BUT NOT LIMITED TO ANY AND ALL NEGLIGENCE, NEGLIGENT SUPERVISION, NEGLIGENT TRAINING, AND NEGLIGENT INSTRUCTION. TO THE FULLEST EXTENT PERMITTED BY LAW, THIS WAIVER IS INTENDED TO BE A COMPLETE RELEASE OF THE RELEASED PARTIES FOR ANY AND ALL RESPONSIBILITY FOR PERSONAL INJURIES, PROPERTY DAMAGE OR DEATH SUSTAINED BY ME OR ANY OF THE MINORS ON WILD RIVERS’ PREMISES, WHETHER ARISING OUT OF OR RESULTING FROM MY OR THEIR PARTICIPATION IN ACTIVITIES, USE OF WATER SLIDES, LAZY RIVER, WAVE POOL OR OTHER WATER STRUCTURES OR EQUIPMENT, PARKING LOTS, PREMISES AND FACILITIES. THIS RELEASE APPLIES FOR ANY DATE IN THE FUTURE THAT I, OR THE MINOR(S) MAY VISIT WILD RIVERS FACILITY, AND IS FURTHER BINDING ON THE HEIRS, REPRESENTATIVES AND ESTATES OF THE UNDERSIGNED AND MINOR(S). THE SCOPE OF THIS WAIVER AND RELEASE SPECIFICALLY INCLUDES ANY CLAIMS, DAMAGES, INJURIES, EXPENSES, OR DEATH ARISING OUT OF OR RESULTING FROM ADMINISTERING OF OR FAILING TO ADMINISTER MEDICAL ASSISTANCE.</span>
				<span class="segment-header">INDEMNITY:</span>
				<span class="segment-body">I FURTHER AGREE TO DEFEND, INDEMNIFY AND HOLD HARMLESS THE RELEASED PARTIES, AND EACH OF THEM, INCLUDING ATTORNEYS’ FEES, AGAINST ANY AND ALL CLAIMS, LAWSUITS OR DEMANDS FOR ANY LOSS, INJURY, DAMAGE, OR DEATH, AS WELL AS PROPERTY DAMAGE, ARISING OUT OF, CONNECTED TO, OR RELATING IN ANY WAY TO THE PARTICIPATION IN THE ACTIVITIES DESCRIBED HEREIN, AND/OR THE PRESENCE ON THE PROPERTY, INCLUDING BUT NOT LIMITED TO ANY LAWSUIT FILED ON BEHALF OF ANY MINOR(S).</span>
				<span class="segment-header">RECREATIONAL SERVICES:</span>
				<span class="segment-body">I FURTHER ACKNOWLEDGE THAT RECREATIONAL SERVICES ARE BEING PROVIDED BY THE RELEASED PARTIES, AND NO SALE, LEASE OR BAILMENT OF EQUIPMENT IS ANY PART OF TRANSACTION HEREIN. AS SUCH, I AGREE THAT NO CLAIM OF STRICT PRODUCTS LIABILITY WILL BE OR CAN BE ASSERTED AGAINST RELEASED PARTIES.</span>
				<span class="segment-header">POSTED SIGNS AND SAFETY RULES:</span>
				<span class="segment-body">I, ON MY OWN BEHALF, AND ON THE BEHALF OF ANY MINOR IN MY FAMILY THAT IS OBTAINING A SEASON PASS THROUGH ME, AS HIS/HER PARENT, AGREE TO READ, OBEY, AND COMPLY WITH ALL POSTED, SPOKEN AND/OR WRITTEN SAFETY RULES WHILE ATTENDING WILD RIVERS, PARTICIPATING IN ACTIVITIES, AND USING WATERSLIDES, LAZY RIVER, WAVE POOL AND OTHER WATER STRUCTURES AND EQUIPMENT, AND UNDERSTAND THAT THE FAILURE TO DO SO MAY RESULT IN SERIOUS INJURY OR DEATH.</span>
				<span class="segment-header">PARKING RELEASE OF LIABILITY:</span>
				<span class="segment-body">I AGREE TO RELEASE, WAIVE, DISCHARGE, AND COVENANT NOT TO SUE WILD RIVERS WATERPARK IRVINE SPE, LLC OR ARM CA LLC OR AMERICAN RESORT MANAGEMENT, LLC OR THE CITY OF IRVINE, ITS DIRECTORS, OFFICERS, EMPLOYEES, AND AGENTS FROM ALL LIABILITY TO ME, MY PERSONAL REPRESENTATIVE, HEIRS, AND NEXT OF KIN FOR ANY LOSS, THEFT, DAMAGE, CLAIM OR DEMANDS THEREFORE ON ACCOUNT OF INJURY TO MY PROPERTY, WHETHER CAUSED BY THE NEGLIGENCE OF WILD RIVERS WATERPARK IRVINE SPE, LLC OR SPLASH MANAGEMENT LLC OR OTHERWISE DURING THE DATES PROVIDED ON THIS DOCUMENT. INDEMNIFICATION I AGREE TO INDEMNIFY AND SAVE AND HOLD HARMLESS WILD RIVERS WATERPARK IRVINE SPE, LLC OR ARM CA LLC OR AMERICAN RESORT MANAGEMENT, LLC OR THE CITY OF IRVINE FROM ANY LOSS, LIABILITY, THEFT, DAMAGE OR COST THAT MAY INCUR DUE TO MY STORING MY VEHICLE, UPON OR ABOUT THE WILD RIVERS WILD RIVERS WATERPARK IRVINE SPE, LLC OR ARM CA LLC OR AMERICAN RESORT MANAGEMENT, LLC OR THE CITY OF IRVINE’S PREMISES WHETHER CAUSED BY THE NEGLIGENCE OF WILD RIVERS WATERPARK IRVINE SPE, LLC OR ARM CA LLC OR AMERICAN RESORT MANAGEMENT, LLC OR THE CITY OF IRVINE OR OTHERWISE. ACCEPTANCE OF RISK I UNDERSTAND THAT THE WILD RIVERS WATERPARK IRVINE SPE, LLC OR ARM CA LLC OR AMERICAN RESORT MANAGEMENT, LLC OR THE CITY OF IRVINE NEVER INTENDED, NOR DESIGNED THEIR LOT TO BE USED AS A STORAGE FACILITY AND I ACKNOWLEDGE THAT THE WILD RIVERS WATERPARK IRVINE SPE, LLC OR ARM CA LLC OR AMERICAN RESORT MANAGEMENT, LLC OR THE CITY OF IRVINE DOES NOT GUARANTEE THE SECURITY OF THE PROPERTY BEING STORED. IN ADDITION, I REALIZE THAT THE LOT IS NOT LOCKED, SECURED OR GUARDED. THEREFORE, I AGREE TO ASSUME FULL RESPONSIBILITY FOR AND RISK OF PROPERTY DAMAGE DUE TO THE NEGLIGENCE OF THE WILD RIVERS WATERPARK IRVINE SPE, LLC OR ARM CA LLCOR AMERICAN RESORT MANAGEMENT, LLC OR THE CITY OF IRVINE OR OTHERWISE WHILE MY VEHICLE IS PARKED DURING THE DATE PROVIDED.</span>
				<span class="actions">
					<span class="action button" (click)="closeModal();">Close</span>
				</span>
			</span>
		</span>
	</span>
</modal-back-drop>
