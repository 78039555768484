import {Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
// ===== App ===== //
import {AppConfig} from '../../app.config';
import {AppEvents} from '../../app.events';
import {AppRouterLinks} from '../../app.router-links';
// ===== Collections ===== //
import {CollectionProfiles} from '../../collections/profiles';
// ===== Image Cropper ===== //
import {ImageCroppedEvent, LoadedImage} from 'ngx-image-cropper'; // https://www.npmjs.com/package/ngx-image-cropper
// ===== Interfaces ===== //
import {
	InterfaceAnyObject,
	InterfaceAppContext,
	InterfaceAppEvent,
	InterfaceHTTPGateway,
	InterfaceNavMenuItem,
	InterfaceOWAPICardVault,
	InterfaceOWAPICardVaultResponse,
	InterfaceOWAPIGetDocletsResponse,
	InterfaceOWDoclet,
	InterfaceOWUser,
	InterfaceVenuePassportConsumerData,
	InterfaceVenuePassportConsumerErrors
} from '../../interfaces/interfaces';
// ===== Services ===== //
import {ServiceAuthentication} from '../../services/authentication';
import {ServiceRegex} from '../../services/regex';
import {ServiceNavigate} from '../../services/navigate';
import {ServiceOWAPI} from '../../services/ow-api';
//
@Component( {
	selector: 'page-my-account',
	templateUrl: './my-account.html',
	styleUrls: [
		'./my-account.less'
	]
} )
export class PageMyAccount implements OnDestroy, OnInit {
	// TODO: do not allow users to change their account info, to other people. or the season pass can be shuffled around between friends.
	// TODO: allow the purchaser to change their email address.
	public routes: typeof AppRouterLinks = AppRouterLinks;
	private readonly realmIdPortal: string = this.appConfig.getRealmID();
	private subUserReSync: Subscription | null = null;
	private updateUserCacheOnExit: boolean = false;
	//
	private subCardReSync: Subscription | null = null;
	private cardsOnFile: InterfaceOWAPICardVault[] = [];
	//
	private subAccountReSync: Subscription | null = null;
	public accountDoclet: InterfaceOWDoclet | undefined = undefined;
	public haveAccountInfo: boolean = false;
	private readonly strAccountTemplateID: string = this.appConfig.getTemplateID( 'Account' );
	//
	public mastHeading: string = 'Manage Your Account';
	public navMenuItems: InterfaceNavMenuItem[] = [
		{
			route: '/my-account',
			text: 'My Account'
		}
	];
	public serialCode: string = '';
	//
	public imageChangedEvent: Event | undefined = undefined;
	public b64ProfileImageData: string = '';
	public usingImageCropper: boolean = false;
	public formBusy: boolean = false;
	//
	public profileDocletID: string | null = null;
	public templateConsumerData: InterfaceVenuePassportConsumerData = {
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		street: '',
		unit: '',
		city: '',
		state: '',
		zip: '',
		dob: '',
		driversLicense: '',
		photo: ''
	};
	public cashlessSpending: boolean = false;
	public templateConsumerHasErrors: boolean = false;
	public templateConsumerErrors: InterfaceVenuePassportConsumerErrors = {
		firstName: false,
		lastName: false,
		email: false,
		phone: false,
		street: false,
		unit: false,
		city: false,
		state: false,
		zip: false,
		dob: false,
		driversLicense: false
	};
	//
	public recycleTB: boolean = true; // trying to fix an angular framework issue...
	//
	constructor(
		private readonly appConfig: AppConfig,
		private readonly appEvents: AppEvents,
		private readonly auth: ServiceAuthentication,
		private readonly colProfiles: CollectionProfiles,
		private readonly nav: ServiceNavigate,
		private readonly owapi: ServiceOWAPI,
		private readonly title: Title
	) {
		this.title.setTitle( 'Wild Rivers Portal' );
		if ( this.auth.isSignedIn() ) {
			this.subAccountReSync = this.appEvents.listen( 'account:re-sync' ).subscribe( (_: InterfaceAppEvent): void => {
				this.fetchAccountInfo();
			} );
			this.subCardReSync = this.appEvents.listen( 'card:re-sync' ).subscribe( (_: InterfaceAppEvent): void => {
				this.fetchCardInfo();
			} );
			this.subUserReSync = this.appEvents.listen( 'user:re-sync' ).subscribe( (_: InterfaceAppEvent): void => {
				this.fetchUserInfo();
			} );
			// don't listen to updates on the profile collection here, because it may trample over the users in-progress form data.
			// ex: other users profiles were updated, which causes the collection to emit it's update, which causes this component to re-load, etc.
		} else {
			this.nav.toURL( '/' + this.routes.signIn );
		}
	}

	private fetchUserInfo(): void {
		this.colProfiles.getMyUserProfile( (userData: InterfaceOWUser | null): void => {
			if ( userData ) {
				this.templateConsumerData.email = userData.email;
				this.profileDocletID = userData.doclet_id;
				// TODO: userData.confirmed and userData.email_to_confirm
				const consumerData: InterfaceAnyObject = userData.data;
				this.templateConsumerData.firstName = typeof consumerData['first_name'] === 'string' ? consumerData['first_name'] : '';
				this.templateConsumerData.lastName = typeof consumerData['last_name'] === 'string' ? consumerData['last_name'] : '';
				this.templateConsumerData.phone = typeof consumerData['phone'] === 'string' ? consumerData['phone'] : '';
				this.templateConsumerData.street = typeof consumerData['street_address'] === 'string' ? consumerData['street_address'] : '';
				this.templateConsumerData.unit = typeof consumerData['suite'] === 'string' ? consumerData['suite'] : '';
				this.templateConsumerData.city = typeof consumerData['city'] === 'string' ? consumerData['city'] : '';
				this.templateConsumerData.state = typeof consumerData['state'] === 'string' ? consumerData['state'] : '';
				this.templateConsumerData.zip = typeof consumerData['zip_code'] === 'string' ? consumerData['zip_code'] : '';
				this.templateConsumerData.dob = typeof consumerData['dob'] === 'string' ? consumerData['dob'] : '';
				this.templateConsumerData.driversLicense = typeof consumerData['drivers_license'] === 'string' ? consumerData['drivers_license'] : '';
				this.templateConsumerData.photo = typeof consumerData['photo'] === 'string' ? consumerData['photo'] : '';
				if ( 'TODO: if user has a season pass' ) {
					this.serialCode = userData._id.$oid.toUpperCase(); // temp-code
				}
				//
				const keys: (keyof InterfaceVenuePassportConsumerData)[] = Object.keys( this.templateConsumerData ) as (keyof InterfaceVenuePassportConsumerData)[];
				for ( let x: number = 0; x < keys.length; ++x ) {
					this.validateTemplateConsumer( keys[x] );
				}
			}
		} );
	}

	private fetchAccountInfo(): void {
		const appContext: InterfaceAppContext = this.appConfig.getContext();
		const profileID: string | null = this.auth.getProfileID();
		if ( profileID === null ) {
			return;
		}
		this.colProfiles.getMyUserProfile( (userProfile: InterfaceOWUser | null): void => {
			if ( userProfile && userProfile.account_id ) {
				this.owapi.workspace.doclets.getDocletsByTemplateID( appContext, this.strAccountTemplateID, 'realm.aid:{id}' + userProfile.account_id.$oid ).subscribe( (response: InterfaceHTTPGateway): void => {
					// pagination affects this, but it should only be an array of length 1, so it doesn't matter.
					if ( response && response.success ) {
						const apiResponse: InterfaceOWAPIGetDocletsResponse = response.data;
						if ( apiResponse && apiResponse.data && Array.isArray( apiResponse.data.items ) && apiResponse.data.items.length > 0 ) {
							this.accountDoclet = apiResponse.data.items[0];
							if ( this.accountDoclet && this.accountDoclet.data && this.accountDoclet.data['cashless_spending'] && this.cardsOnFile.length > 0 ) {
								this.setCashlessToggleBox( true );
							} else {
								this.setCashlessToggleBox( false );
							}
							console.log( 'account doc', this.accountDoclet );
						}
					}
					this.haveAccountInfo = true;
					if ( !this.accountDoclet ) {
						// cheating, this ought to create one..
						this.owapi.workspace.actions.core.getSavedPaymentMethod( appContext, profileID, this.realmIdPortal ).subscribe( (r: InterfaceHTTPGateway) => console.log( 'cache-miss: created account doclet', r ) );
					}
				} );
			} else {
				console.log( 'No user account ID, cannot fetch account data.' );
				// cheating, this ought to create one..
				this.owapi.workspace.actions.core.getSavedPaymentMethod( appContext, profileID, this.realmIdPortal ).subscribe( (r: InterfaceHTTPGateway) => console.log( 'created account doclet', r ) );
				this.haveAccountInfo = true;
			}
		} );
	}

	private fetchCardInfo(): void {
		const profileID: string | null = this.auth.getProfileID();
		if ( profileID === null ) {
			return;
		}
		this.owapi.workspace.actions.core.getSavedPaymentMethod( this.appConfig.getContext(), profileID, this.realmIdPortal ).subscribe( (response: InterfaceHTTPGateway): void => {
			if ( response && response.success ) {
				this.cardsOnFile = [];
				const apiResponse: InterfaceOWAPICardVaultResponse = response.data;
				if ( apiResponse && apiResponse.data && Array.isArray( apiResponse.data.items ) && apiResponse.data.items.length > 0 ) {
					this.cardsOnFile = apiResponse.data.items;
					console.log( 'cards on file', this.cardsOnFile );
					if ( !this.accountDoclet ) { // cheating.. it may not have existed until we tried to fetch the card info
						console.log( 'cache miss, re-fetching account...' );
						this.fetchAccountInfo();
					} else {
						if ( this.cardsOnFile.length > 0 && this.accountDoclet.data['cashless_spending'] ) {
							this.setCashlessToggleBox( true );
						}
						if ( this.cardsOnFile.length < 1 || !this.accountDoclet.data['cashless_spending'] ) {
							this.setCashlessToggleBox( false );
						}
					}
				}
			}
		} );
	}

	public ngOnInit(): void {
		this.fetchAccountInfo();
		this.fetchCardInfo();
		this.fetchUserInfo();
	}

	public ngOnDestroy(): void {
		if ( this.updateUserCacheOnExit && this.profileDocletID ) {
			this.colProfiles.fetchProfileByID( this.profileDocletID as string );
		}
		if ( this.subAccountReSync ) {
			this.subAccountReSync.unsubscribe();
			this.subAccountReSync = null;
		}
		if ( this.subCardReSync ) {
			this.subCardReSync.unsubscribe();
			this.subCardReSync = null;
		}
		if ( this.subUserReSync ) {
			this.subUserReSync.unsubscribe();
			this.subUserReSync = null;
		}
	}

	public validateTemplateConsumer( key: keyof InterfaceVenuePassportConsumerData ): void {
		if ( key !== 'cashlessSpending' && typeof this.templateConsumerData[key] === 'string' ) {
			// can't rely upon typeof myVar.myKey === 'string', had to hard-code the 'cashlessSpending' because it's a "?boolean" -- typescript fail.
			this.templateConsumerData[key] = (this.templateConsumerData[key] as string).replace( ServiceRegex.trimRegExp, '' );
		}
		switch ( key ) {
			case 'firstName': {
				this.templateConsumerErrors.firstName = this.templateConsumerData.firstName.length < 1;
				break;
			}
			case 'lastName': {
				this.templateConsumerErrors.lastName = this.templateConsumerData.lastName.length < 1;
				break;
			}
			case 'email': {
				this.templateConsumerErrors.email = !ServiceRegex.emailRegExp.test( this.templateConsumerData.email );
				break;
			}
			case 'phone': {
				this.templateConsumerErrors.phone = this.templateConsumerData.phone.length < 1;
				break;
			}
			case 'street': {
				this.templateConsumerErrors.street = this.templateConsumerData.street.length < 1;
				break;
			}
			case 'unit': {
				break;
			}
			case 'city': {
				this.templateConsumerErrors.city = this.templateConsumerData.city.length < 1;
				break;
			}
			case 'state': {
				// needs to be a drop down
				this.templateConsumerErrors.state = this.templateConsumerData.state.length < 2;
				break;
			}
			case 'zip': {
				this.templateConsumerErrors.zip = !this.templateConsumerData.zip.match( /^\d\d\d\d\d$/ ) || this.templateConsumerData.zip.length != 5;
				break;
			}
			case 'dob': {
				this.templateConsumerErrors.dob = !this.templateConsumerData.dob.match( /^\d\d\d\d-\d\d-\d\d$/ );
				break;
			}
			case 'driversLicense': {
				this.templateConsumerErrors.driversLicense = false; // this.templateConsumerData.driversLicense.length < 1;
				break;
			}
		} // end switch ( key )

		this.templateConsumerHasErrors = Object.values( this.templateConsumerErrors ).some( (val: boolean): boolean => {
			return val;
		} );
	}

	public updateProfile(): void {
		// TODO: make photo required for the purchaser too, not just the family members.
		// eventually, this account holder, will be a stand-alone account, not just a purchaser.
		if ( !this.templateConsumerHasErrors && this.profileDocletID && this.templateConsumerData.email.length > 0 ) { // if we have the users profile ID and the data is populated from the inital load....
			const payload = {
				// TODO: change the fields to the lower-cased versions that matches the 'key'.
				first_name: this.templateConsumerData.firstName,
				last_name: this.templateConsumerData.lastName,
				// don't put email in here, yet...
				phone: this.templateConsumerData.phone,
				street_address: this.templateConsumerData.street,
				suite: this.templateConsumerData.unit,
				city: this.templateConsumerData.city,
				state: this.templateConsumerData.state,
				zip_code: this.templateConsumerData.zip,
				dob: this.templateConsumerData.dob,
				drivers_license: this.templateConsumerData.driversLicense, // field is hidden, but still in use. user cannot change it atm.
				photo: this.templateConsumerData.photo // TODO: don't upload the photo each time. just the things that changed.
			};

			this.formBusy = true;

			this.owapi.workspace.doclets.updateDocletData( this.appConfig.getContext(), this.profileDocletID, payload ).subscribe( (response: InterfaceHTTPGateway): void => {
				this.formBusy = false;
				if ( response?.success ) {
					this.colProfiles.fetchProfileByID( this.profileDocletID as string );
					this.updateUserCacheOnExit = false;
					// TODO: do something else besides taking the user to a diff page.
				} else {
					alert( 'An error occurred. Please try again later.' );
				}
			} );
		}
	}

	public blur( E: Event ): void {
		(E.target as HTMLElement).blur();
	}

	// ===== Image Cropper ===== //

	public inputTypeFileOnChange( E: Event ): void {
		this.imageChangedEvent = E;
	}

	public imageCropperOnChange( E: ImageCroppedEvent ): void {
		if ( typeof E.base64 === 'string' ) {
			this.b64ProfileImageData = E.base64;
		} else {
			this.imageCropperFail();
		}
	}

	public imageCropperOnImageLoad( image: LoadedImage ): void {
		console.log( 'img loaded', image );
		// show cropper
		this.usingImageCropper = true;
	}

	public cropperReady(): void {
		this.usingImageCropper = true;
	}

	public loadImageFailed(): void {
		this.imageCropperFail();
	}

	private imageCropperFail(): void {
		// TODO: this.
		// photo is too small.
		// file is not a valid photo. must be one of: png/jpeg/webp/bmp
	}

	public setProfilePhoto(): void {
		if ( this.b64ProfileImageData && this.b64ProfileImageData.length > 0 && this.profileDocletID ) {
			this.owapi.workspace.doclets.updateDocletData( // 3 params
				this.appConfig.getContext(),
				this.profileDocletID,
				{
					photo: this.b64ProfileImageData
				}
			).subscribe( (response: InterfaceHTTPGateway): void => {
				if ( response?.success ) {
					this.updateUserCacheOnExit = true; // don't trigger a user:re-sync, it may trample over the users profile fields.
					this.templateConsumerData.photo = this.b64ProfileImageData;
					this.clearImageCropper();
					/*
					const apiResponse: InterfaceOWAPIDocletResponse = response.data;
					if ( apiResponse && apiResponse.data && apiResponse.data.data ) {
						const apiResponseConsumer: InterfaceOWTemplateConsumer = apiResponse.data.data as InterfaceOWTemplateConsumer;
						if ( apiResponseConsumer.photo === this.b64ProfileImageData ) {
							console.log( 'it worked' );
						}
					}
					*/
				}
			} );
		}
	}

	public clearImageCropper(): void {
		this.usingImageCropper = false;
		this.b64ProfileImageData = '';
		this.imageChangedEvent = undefined;
	}

	public cashlessToggle( b: boolean ): void {
		const profileID: string | null = this.auth.getProfileID();
		if ( profileID === null ) {
			return;
		}
		this.owapi.workspace.actions.core.toggleCashlessSpending( this.appConfig.getContext(), profileID, b, this.realmIdPortal ).subscribe( (response: InterfaceHTTPGateway): void => {
			let failed: boolean = true;
			if ( response && response.success ) {
				const apiResponse: InterfaceOWAPIGetDocletsResponse = response.data;
				if ( apiResponse && apiResponse.data && apiResponse.data.items ) {
					failed = false;
					const justAPIThings: ({} | InterfaceOWAPICardVault)[] = apiResponse.data.items;
					this.cashlessSpending = justAPIThings.length > 0 && 'vault_id' in justAPIThings[0];
				}
			}
			if ( failed ) {
				this.cashlessSpending = false;
			}
		} );
	}

	private setCashlessToggleBox( b: boolean ): void {
		// angular won't update the child component because the @Input() doesn't see a change.
		// it looks at references rather than values, and the variable
		this.cashlessSpending = b;
		// setTimeout( () => { console.log( 'set parent to', this.primaryAccountHolder.cashlessSpending ); }, 1400 );
		this.recycleTB = false;
		setTimeout( (): void => {
			this.recycleTB = true;
		}, 10 );
		// */
	}
}
