import {Injectable} from '@angular/core';
// ===== App ===== //
import {AppEvents} from '../app.events';
// ===== Interfaces ===== //
import {InterfaceAppEvent, InterfaceNotification} from '../interfaces/interfaces';
//
@Injectable( {
	providedIn: 'root',
} )
export class ServiceNotifications {
	//
	public constructor(
		private readonly appEvents: AppEvents
	) {
		//
	}

	public show( header: string, body: string, id?: string ): void { // this seeeeems like the places that call this, can just directly call AppEvents
		this.appEvents.broadcast( {
			topic: 'notification',
			data: {
				header: header,
				body: body,
				id: id ? id : undefined
			} as InterfaceNotification
		} as InterfaceAppEvent );
	}
}
