import {Component, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
// ===== App ===== //
import {AppEvents} from '../../app.events';
// ===== Interfaces ===== //
import {InterfaceAppEvent, InterfaceNotification} from '../../interfaces/interfaces';
//
@Component( {
	selector: 'app-notifications',
	templateUrl: './notifications.html',
	styleUrls: [
		'./notifications.less'
	],
} )
export class ComponentNotifications implements OnDestroy {
	private subNotifications: Subscription | null = null;
	public notifications: InterfaceNotification[] = [];
	private notificationIDs: {
		[key: string]: number;
	} = {};
	//
	public constructor(
		private appEvents: AppEvents
	) {
		this.initNotifications();
	}

	private initNotifications(): void {
		this.subNotifications = this.appEvents.listen( 'notification' ).subscribe( (event: InterfaceAppEvent): void => {
			const notification: InterfaceNotification = (event.data as InterfaceNotification);
			if ( !notification.hasOwnProperty( 'id' ) || !notification.id ) {
				notification.id = 'n-' + String( Math.floor( Math.random() * 100000 ) ) + '-' + String( +new Date() );
			} else {
				notification.id = 'n-' + notification.id;
			}
			if ( !this.notificationIDs.hasOwnProperty( notification.id ) ) {
				this.notificationIDs[ notification.id ] = window.setTimeout( (): void => {
					this.dismissNotification( '' + notification.id );
				}, notification.duration ? Math.max( 2000, notification.duration ) : 5000 );
				this.notifications.push( notification );
			}
		} );
	}

	public dismissNotification( id: string ): void {
		if ( id && this.notificationIDs.hasOwnProperty( id ) ) {
			clearTimeout( this.notificationIDs[ id ] );
			for ( let x: number = 0; x < this.notifications.length; ++x ) {
				if ( this.notifications[x].id && this.notifications[x].id === id ) {
					this.notifications.splice( x, 1 );
					(this.notificationIDs[ id ] as any) = null;
					delete this.notificationIDs[ id ];
					break;
				}
			} // end for each notification to scan for a matching ID.
		} // end if the notification's ID is "valid".
	}

	public ngOnDestroy(): void {
		if ( this.subNotifications ) {
			this.subNotifications.unsubscribe();
			this.subNotifications = null;
		}
	}
}
