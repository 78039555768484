import {Pipe, PipeTransform} from '@angular/core';
// ===== Interfaces ===== //
import {InterfaceVenuePassportTicketPriceType} from '../interfaces/interfaces';
// ===== Transformers ===== //
import {TransformerVenuePassportTicket} from '../transformers/vpTicket';
//
@Pipe({
	name: 'passShortCodes',
	pure: true
})
export class PipePassShortCodes implements PipeTransform {
	public transform( code: string ): string {
		return TransformerVenuePassportTicket.getTicketTypeFromShortCode( code );
	}
}
