import {Component, Input, OnInit} from '@angular/core';
// ===== App ===== //
import {AppEvents} from '../../app.events';
// ===== Collections ===== //
import {CollectionProfiles} from '../../collections/profiles';
// ===== Interfaces ===== //
import {InterfaceOWDoclet, InterfaceOWDocletConsumer, InterfaceOWUser} from '../../interfaces/interfaces';
interface InterfaceMemberData {
	_id: string,
	firstName: string;
	lastName: string;
	email: string;
}
interface InterfaceMemberErrors {
	firstName: boolean;
	lastName: boolean;
	email: boolean;
}
// ===== Services ===== //
import {ServiceRegex} from '../../services/regex';
//
@Component({
	selector: 'modal-edit-group-member',
	templateUrl: './edit-group-member.html',
	styleUrls: [
		'./edit-group-member.less'
	]
})
export class ModalEditGroupMember implements OnInit {
	@Input()
	public memberDoclet: InterfaceOWDoclet | null = null;
	//
	public memberData: InterfaceMemberData = {
		_id: '',
		firstName: '',
		lastName: '',
		email: ''
	};
	public memberErrors: InterfaceMemberErrors = {
		firstName: false,
		lastName: false,
		email: false
	};
	public emailLocked: boolean = false;
	//
	public constructor(
		private readonly appEvents: AppEvents,
		private readonly colProfiles: CollectionProfiles
	) {
		//
	}

	public ngOnInit(): void {
		if ( this.memberDoclet && this.memberDoclet._id ) {
			this.memberData._id = this.memberDoclet._id.$oid;
			this.memberData.firstName = this.memberDoclet.data['first_name'] ?? '';
			this.memberData.lastName = this.memberDoclet.data['last_name'] ?? '';
			const invitedEmail: string = this.memberDoclet.data['invited_email'] ?? '';
			if ( (this.memberDoclet as InterfaceOWDocletConsumer)?.realm?.pid?.$oid ) {
				const profileID: string = (this.memberDoclet as InterfaceOWDocletConsumer)!.realm!.pid.$oid ;
				this.colProfiles.fetchProfileByID( profileID, (userProfile: InterfaceOWUser | null): void => {
					if ( userProfile && userProfile.email ) {
						this.memberData.email = userProfile.email;
						this.emailLocked = true;
					} else {
						this.memberData.email = invitedEmail;
					}
				} );
			} else {
				this.memberData.email = invitedEmail;
			}
		}
	}

	public validateField( field: keyof InterfaceMemberData ): void {
		this.memberData[field] = this.memberData[field].replace( ServiceRegex.trimRegExp, '' );
		switch ( field ) {
			case 'firstName':
			case 'lastName': {
				if ( this.memberData[field].length < 1 ) {
					this.memberErrors[field] = true;
				}
				break;
			}
			case 'email': {
				this.memberErrors[field ] = this.emailLocked ? false : !ServiceRegex.emailRegExp.test( this.memberData[field] );
				break;
			}
		}
	}

	public closeModal(): void {
		this.appEvents.broadcast( 'modal:close:edit-group-member' );
	}

	public saveMember(): void {
		let fail: boolean = false;
		const keys: (keyof InterfaceMemberData)[] = Object.keys( this.memberData ) as (keyof InterfaceMemberData)[];
		for ( let x: number = 0; x < keys.length; ++x ) {
			this.validateField( keys[x] );
		}
		const errors: (keyof InterfaceMemberErrors)[] = Object.keys( this.memberErrors ) as (keyof InterfaceMemberErrors)[];
		for ( let x: number = 0; !fail && x < errors.length; ++x ) {
			fail = this.memberErrors[ errors[x] ];
		}
		if ( !fail ) {
			this.appEvents.broadcast( {
				topic: 'data:edit-group-member',
				data: this.memberData
			} );
			this.closeModal();
		}
	}
}
