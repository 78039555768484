import {Injectable} from '@angular/core';
// ===== Services ===== //
import {ServiceOWAPIWorkspaceActions} from './workspace/actions';
// import {ServiceOWAPIWorkspaceActionsPos} from './workspace/actions-pos';
import {ServiceOWAPIWorkspaceDoclets} from './workspace/doclets';
import {ServiceOWAPIWorkspaceSettings} from './workspace/settings';
import {ServiceOWAPIWorkspaceTasks} from './workspace/tasks';
import {ServiceOWAPIWorkspaceTemplates} from './workspace/templates';
import {ServiceOWAPIWorkspaceWorkspaces} from './workspace/workspaces';
//
@Injectable( {
	providedIn: 'root'
} )
export class ServiceOWAPIWorkspace {
	private readonly routePrefix: string = 'workspace/';
	//
	public readonly actions: {
		core: ServiceOWAPIWorkspaceActions /*,
		pos: ServiceOWAPIWorkspaceActionsPos */
	} = {
		core: this.owapiActions /*,
		pos: this.owapiActionsPOS */
	};
	//
	public constructor(
		// private owapiActionsPOS: ServiceOWAPIWorkspaceActionsPos,
		private owapiActions: ServiceOWAPIWorkspaceActions,
		public doclets: ServiceOWAPIWorkspaceDoclets,
		public settings: ServiceOWAPIWorkspaceSettings,
		public tasks: ServiceOWAPIWorkspaceTasks,
		public templates: ServiceOWAPIWorkspaceTemplates,
		public workspaces: ServiceOWAPIWorkspaceWorkspaces
	) {
		//
	}
}
