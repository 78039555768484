import {Component} from '@angular/core';
// ===== App ===== //
import {AppEvents} from '../../app.events';
//
@Component( {
	selector: 'modal-liability-waiver',
	templateUrl: './liability-waiver.html',
	styleUrls: [
		'./liability-waiver.less'
	]
} )
export class ModalLiabilityWaiver {
	//
	public constructor(
		private readonly appEvents: AppEvents
	) {
		//
	}

	public closeModal(): void {
		this.appEvents.broadcast( 'modal:close:liability-waiver' );
	}
}
