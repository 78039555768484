// ===== App ===== //
import {AppRouterLinks} from './app.router-links';
// ===== Collections ===== //
//
// ===== Components ===== //
//
// ===== Modals ===== //
//
// ===== Modules ===== //
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
// ===== Pages ===== //
import {PageBuyNow} from './pages/buynow/buynow';
import {PageBuylater} from './pages/buylater/buylater';
import {PageCheckEmail} from './pages/check-email/check-email';
import {PageCheckout} from './pages/checkout/checkout';
import {PageCompleteAccount} from './pages/complete-account/complete-account';
import {PageCreateGroup} from './pages/create-group/create-group';
import {PageDashboard} from './pages/dashboard/dashboard';
import {PageEditFamily} from './pages/edit-family/edit-family';
import {PageEditGroup} from './pages/edit-group/edit-group';
import {PageFamily} from './pages/family/family';
import {PageMyAccount} from './pages/my-account/my-account';
import {PageOrders} from './pages/orders/orders';
import {PageResetPassword} from './pages/reset-password/reset-password';
import {PageSignIn} from './pages/sign-in/sign-in';
import {PageThankYou} from './pages/thank-you/thank-you';
// ===== Services ===== //
//
// ===== Transformers ===== //
//
const routes: Routes = [
	{
		component: PageDashboard,
		path: ''
	},
	{
		component: PageSignIn,
		path: AppRouterLinks.signIn,
	},
	{
		component: PageBuyNow,
		path: AppRouterLinks.buyNow + '/:promo',
		pathMatch: 'prefix' // i forget why i use prefix and full with near-duplicate paths...
	},
	{
		component: PageBuyNow,
		path: AppRouterLinks.buyNow,
		pathMatch: 'full'
	},
	{
		component: PageBuylater,
		path: AppRouterLinks.buyLater,
		pathMatch: 'full'
	},
	{
		component: PageCheckout,
		path: AppRouterLinks.checkout + '/:cart64',
		pathMatch: 'prefix'
	},
	{
		component: PageCheckout,
		path: AppRouterLinks.checkout,
		pathMatch: 'full'
	},
	{
		component: PageThankYou,
		path: AppRouterLinks.thankYou,
		pathMatch: 'full'
	},
	{
		component: PageCheckEmail,
		path: AppRouterLinks.checkEmail + '/:id',
		pathMatch: 'prefix'
	},
	{
		component: PageCheckEmail,
		path: AppRouterLinks.checkEmail,
		pathMatch: 'full'
	},
	{
		component: PageResetPassword,
		path: AppRouterLinks.resetPassword,
		pathMatch: 'full'
	},
	{
		component: PageResetPassword,
		path: AppRouterLinks.resetPassword + '/:t',
		pathMatch: 'prefix'
	},
	{
		component: PageMyAccount,
		path: AppRouterLinks.myAccount,
		pathMatch: 'full'
	},
	{
		component: PageFamily,
		path: AppRouterLinks.family,
		pathMatch: 'full'
	},
	{
		component: PageEditFamily,
		path: AppRouterLinks.editFamily + '/:id',
		pathMatch: 'full'
	},
	{
		component: PageCreateGroup,
		path: AppRouterLinks.createGroup,
		pathMatch: 'full'
	},
	{
		component: PageEditGroup,
		path: AppRouterLinks.editGroup,
		pathMatch: 'full'
	},
	{
		component: PageOrders,
		path: AppRouterLinks.orders,
		pathMatch: 'full'
	},
	{
		component: PageCompleteAccount,
		path: AppRouterLinks.completeAccount, // 'registration/complete?token=
		pathMatch: 'full'
	},
	{
		component: PageCompleteAccount,
		path: AppRouterLinks.completeAccount + '/:token', // registration/complete/token
		pathMatch: 'prefix'
	},
	{
		path: '**',
		redirectTo: ''
	}
];
//
@NgModule( {
	declarations: [],
	exports: [
		RouterModule
	],
	imports: [
		RouterModule.forRoot( routes )
	],
	providers: []
} )
export class AppRoutingModule {}
