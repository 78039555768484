import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
// ===== Interfaces ===== //
import {InterfaceNavMenuItem} from '../../interfaces/interfaces';
//
@Component({
	selector: 'app-masthead',
	templateUrl: './masthead.html',
	styleUrls: [
		'./masthead.less'
	]
})
export class ComponentMasthead {
	@Input() mastHeading: string = '';
	@Input() navMenuItems: InterfaceNavMenuItem[] = [];
	@Input() serialCode: string | undefined = undefined;
	//
	public constructor(
		public router: Router
	) {
		//
	}

	public navTo( args: string[] ): void {
		if ( args.length > 0 ) {
			this.router.navigate( args ).then( (_: boolean): void => {
				//
			} );
		}
	}
}
