import {Pipe, PipeTransform} from '@angular/core';
// ===== Transformers ===== //
import {TransformerText} from '../transformers/text';
//
@Pipe( {
	name: 'dateFromYYYYMM1DD',
	pure: true
} )
export class PipeDateFromYYYYMM1DD implements PipeTransform {
	public transform( YYYYMM1DD: string ): string {
		return TransformerText.dateFromYYYYMMDD( YYYYMM1DD );
	}
}
