import {Injectable} from '@angular/core';
// ===== Interfaces ===== //
import {
	InterfaceDocletIDToTicketProps,
	InterfaceOWAPITicketPriceByDate,
	InterfaceOWAPITicketPriceByDatePriceTier,
	InterfaceOWDoclet,
	InterfaceVenuePassportPass,
	InterfaceVenuePassportTicketPriceType,
	InterfaceVenuePassportTicketPricing
} from '../interfaces/interfaces';
//
@Injectable( {
	providedIn: 'root'
} )
export class TransformerVenuePassportTicket {
	//
	public constructor() {
		//
	}

	public static getTicketTimeDisplayFromHHMMSS( HHMMSS: string ): string {
		// HH:MM:SS ranges from 00:00:00 to 23:59:59
		// 16:00:00 should output "4:00 PM"
		let output: string = '';
		const arr: string[] = HHMMSS.split( /:/g );
		if ( arr.length === 3 ) {
			const hour: number = Number( arr[0] ) % 24;
			const minutes: number = Number( arr[1] );
			// const seconds: number = Number( arr[2] );
			const isPM: boolean = hour > 11;
			const hour12: number = (hour % 12) === 0 ? 12 : hour % 12;
			output = String( hour12 );
			if ( Number( minutes ) > 0 ) {
				output += ':' + String( '0' + minutes ).slice( -2 );
			}
			output += ' ' + (isPM ? 'PM' : 'AM');
		}
		return output;
	}

	public static getTicketTimeDisplayFromTicketDoclet( ticket: InterfaceOWDoclet ): string {
		let HHMMSS: string | undefined = ticket?.data?.['time'];
		if ( typeof HHMMSS === 'string' ) {
			return TransformerVenuePassportTicket.getTicketTimeDisplayFromHHMMSS( HHMMSS );
		}
		return '';
	}

	public static getTicketTimeFromPriceType(ticketPricing: InterfaceVenuePassportTicketPriceType | undefined, selectedYYYYMMDD1?: string ): string | undefined {
		let HHMMSS: string | undefined = undefined; // 'HH:MM:SS' or undefined if it cannot be found.
		if ( ticketPricing ) {
			if ( selectedYYYYMMDD1 && ticketPricing.hasOwnProperty( selectedYYYYMMDD1 ) ) {
				if ( typeof ticketPricing[selectedYYYYMMDD1]['time'] === 'string' ) {
					HHMMSS = ticketPricing[selectedYYYYMMDD1]['time'] as string;
				}
			} else if ( ticketPricing.hasOwnProperty( 'default' ) ) {
				if ( typeof ticketPricing['default']['time'] === 'string' ) {
					HHMMSS = ticketPricing['default']['time'];
				}
			}
		}
		return HHMMSS;
	}

	public static getTicketTimeDisplayFromPriceType(ticketPricing: InterfaceVenuePassportTicketPriceType | undefined, selectedYYYYMMDD1?: string ): string {
		let output: string = '';
		let HHMMSS: string | undefined = TransformerVenuePassportTicket.getTicketTimeFromPriceType( ticketPricing, selectedYYYYMMDD1 );
		if ( typeof HHMMSS === 'string' ) {
			output = TransformerVenuePassportTicket.getTicketTimeDisplayFromHHMMSS( HHMMSS );
		}
		return output;
	}

	public static passPricesToYYYYMMDDPrice( docletData: InterfaceOWAPITicketPriceByDate[] ): InterfaceVenuePassportTicketPriceType {
		const output: InterfaceVenuePassportTicketPriceType = {}; // { YYYYMMDD : InterfaceVenuePassportTicketPricing }
		for ( let x: number = 0; x < docletData.length; ++x ) {
			output[ docletData[x].date ] = {
				default: Number( docletData[x].price ),
				time: docletData[x].time,
				sph: docletData[x].hasOwnProperty( 'sph_price' ) ? Number( docletData[x].sph_price ) : null,
				sphRestriction: Array.isArray( docletData[x].sph_price_restriction )
					? docletData[x].sph_price_restriction!.reduce(
						(out: InterfaceVenuePassportTicketPricing['sphRestriction'], year: string): InterfaceVenuePassportTicketPricing['sphRestriction'] => {
							out[ year ] = true; // { "2022" : true }
							return out;
						}, {}
					)
					: {}, // else sph_price_restriction does not exist, so the value just defaults to {}
				priceTier: Array.isArray( docletData[x].price_tier )
					? docletData[x].price_tier!.sort( (A: InterfaceOWAPITicketPriceByDatePriceTier, B: InterfaceOWAPITicketPriceByDatePriceTier): number => {
						return A.count - B.count; // sort ASC, smallest first.
					} )
					: []
			};
		}
		return output;
	}

	public static getAdmissionTicketTypeDisplay( ticket: InterfaceOWDoclet ): string {
		let output: string = '';
		/* [ // Daily Admission Pass
			"Adopt Marine",
			"Adult Admission Pass",
			"Child Fund",
			"Employee Admission Pass",
			"Evening Admission Pass",
			"General",
			"General Admission Pass",
			"General Admission Pass 50",
			"General Admission Pass 55",
			"General Admission Pass Comp",
			"Group Admission Pass",
			"Junior",
			"Junior Admission Pass",
			"Junior Admission Pass 30",
			"Junior Admission Pass 35",
			"Junior Admission Pass Comp",
			"Memorial Day Admission Pass",
			"Military Admission Pass",
			"Mother's Day Special",
			"Opening Weekend Special",
			"Senior Admission Pass"
		] */

		if ( typeof ticket?.tc_raw?.$date === 'number' ) {
			if ( ticket.tc_raw.$date < 1655872878101 ) { // old tickets didn't have a type...
				// adults/general were 65 and 60.
				// junior were 40 and 35
				if ( ticket.data['purchase_price'] && ticket.data['purchase_price'] < 60 ) {
					return 'Junior'
				}
			}
		}

		if ( typeof ticket?.data?.['type'] === 'string' ) {
			const ticketType: string = ticket.data['type'];
			switch ( ticketType.toLowerCase() ) {
				case 'general': {
					return 'General';
				}
				case 'junior': {
					return 'Junior';
				}
				default: {
					if ( ticketType.match( /admission pass/i ) ) { // 'Whatever Admission Pass 123'
						const arr: string[] = ticketType.split( /admission pass/i );
						return arr[0].replace( /^\s+|\s+$/g, '' ); // 'Whatever'
					}
					break;
				}
			}
		}
		return output;
	}

	public static getSettingsPrefixByPassID( passID: string, passProps: InterfaceDocletIDToTicketProps ): string | false {
		let prefix: string | false = false;
		if ( passProps ) {
			if ( passProps.isDailyAdmission ) {
				prefix = 'ticket';
			} else if ( passProps.isDailyParking ) {
				prefix = 'parking';
			} else if ( passProps.isCabana ) {
				const cabanaPass: InterfaceOWDoclet = passProps.doclet;
				const cabanaData: InterfaceVenuePassportPass<InterfaceVenuePassportTicketPriceType> = cabanaPass.data as InterfaceVenuePassportPass<InterfaceVenuePassportTicketPriceType>;
				switch ( cabanaData.target.template_id ) { // regardless of which copy of a thing for sale, it'll have the same target.template_id
					case '62aa70c392ec3e75dafc2c5e': { // Castaway River, Green
						prefix = 'river_cabanas';
						break;
					}
					case '62aa711e92ec3e75dafc2c61': { // hidden, Red
						prefix = 'wavepool_south';
						break;
					}
					case '62aa710d92ec3e75dafc2c60': { // Tomcat, Aqua
						prefix = 'wavepool_north';
						break;
					}
					case '62aa70af92ec3e75dafc2c5d' : { // Cooks Cove, Orange
						prefix = 'cooks_cove';
						break;
					}
					case '62aa70ed92ec3e75dafc2c5f': { // Shaka Bay, Blue
						prefix = 'wavepool_beach';
						break;
					}
					case '62aa709a92ec3e75dafc2c5c': { // Kontiki Cover, Yellow
						prefix = 'kontiki_cove';
						break;
					}
				}
			}
		}
		return prefix;
	}

	public static getAvailabilityKeyByPassID( passID: string, passProps: InterfaceDocletIDToTicketProps  ): string | undefined {
		const prefix: string | false = TransformerVenuePassportTicket.getSettingsPrefixByPassID( passID, passProps );
		let output: string | undefined = undefined;
		if ( typeof prefix === 'string' && prefix.length > 0 ) {
			output = prefix + '_availability';
		}
		return output;
	}

	public static getSoldCountKeyByPassID( passID: string, passProps: InterfaceDocletIDToTicketProps  ): string | undefined {
		const prefix: string | false = TransformerVenuePassportTicket.getSettingsPrefixByPassID( passID, passProps );
		let output: string | undefined = undefined;
		if ( typeof prefix === 'string' && prefix.length > 0 ) {
			output = prefix + '_count';
		}
		return output;
	}

	public static getSAPTierText( ticket: InterfaceOWDoclet ): string {
		if ( !ticket.data.hasOwnProperty( 'level' ) ) {
			if ( ticket.tc_raw.$date <= 1678315822011 ) {
				ticket.data['level'] = 2; // the old season admission passes were all sold prior to Thurs, Mar 9th, 2023.
			}
		}
		switch ( ticket.data['level'] ) {
			case 0: return 'Employee';
			case 1: return 'Silver';
			case 2: return 'Gold';
			case 3: return 'Diamond';
			case 4: return 'Senior';
			default: return 'Basic';
		}
	}

	public static getTicketTypeFromShortCode( code: string ): string {
		switch ( code ) {
			case 'daily_tickets': {
				return 'Daily Tickets';
			}
			case 'parking': {
				return 'Daily Parking';
			}
			case 'cabana': {
				return 'Cabana';
			}
			case 'sp': {
				return 'Season Admission Pass';
			}
			case 'spp': {
				return 'Season Parking Pass';
			}
			case 'certs': {
				return 'Certification';
			}
			case 'spUpgrade': {
				return 'Season Admission Upgrades';
			}
		}
		return code;
	}
}
