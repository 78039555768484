<app-header></app-header>
<app-masthead
	[mastHeading]="mastHeading"
	[navMenuItems]="navMenuItems"
	[serialCode]="serialCode"
></app-masthead>
<div id="page-my-account" class="page wrapper">
	<div class="card columned">
		<div class="column form">
			<h3>About You</h3>
			<label>
				<span class="frame-label">First Name <b>*</b></span>
				<input type="text" autocomplete="off"
					[class.error]="templateConsumerErrors.firstName"
					[(ngModel)]="templateConsumerData.firstName"
					[ngModelOptions]="{ updateOn: 'blur' }"
					(ngModelChange)="validateTemplateConsumer( 'firstName' );"
				/>
			</label>
			<label>
				<span class="frame-label">Last Name <b>*</b></span>
				<input type="text" autocomplete="off"
					[class.error]="templateConsumerErrors.lastName"
					[(ngModel)]="templateConsumerData.lastName"
					[ngModelOptions]="{ updateOn: 'blur' }"
					(ngModelChange)="validateTemplateConsumer( 'lastName' );"
				/>
			</label>
			<label class="wide">
				<span class="frame-label">Email Address <b>*</b></span>
				<input type="text" autocomplete="off" readonly
					[class.error]="templateConsumerErrors.email"
					[(ngModel)]="templateConsumerData.email"
					[ngModelOptions]="{ updateOn: 'blur' }"
					(ngModelChange)="validateTemplateConsumer( 'email' );"
				/>
			</label>
			<hr/>
			<label class="wide">
				<span class="frame-label">Street Address <b>*</b></span>
				<input type="text" autocomplete="off"
					[class.error]="templateConsumerErrors.street"
					[(ngModel)]="templateConsumerData.street"
					[ngModelOptions]="{ updateOn: 'blur' }"
					(ngModelChange)="validateTemplateConsumer( 'street' );"
				/>
			</label>
			<label class="thin">
				<span class="frame-label">Unit</span>
				<input type="text" autocomplete="off"
					[class.error]="templateConsumerErrors.unit"
					[(ngModel)]="templateConsumerData.unit"
					[ngModelOptions]="{ updateOn: 'blur' }"
					(ngModelChange)="validateTemplateConsumer( 'unit' );"
				/>
			</label>
			<label>
				<span class="frame-label">Zip Code <b>*</b></span>
				<input type="text" autocomplete="off"
					[class.error]="templateConsumerErrors.zip"
					[(ngModel)]="templateConsumerData.zip"
					[ngModelOptions]="{ updateOn: 'blur' }"
					(ngModelChange)="validateTemplateConsumer( 'zip' );"
				/>
			</label>
			<label>
				<span class="frame-label">City <b>*</b></span>
				<input type="text" autocomplete="off"
					[class.error]="templateConsumerErrors.city"
					[(ngModel)]="templateConsumerData.city"
					[ngModelOptions]="{ updateOn: 'blur' }"
					(ngModelChange)="validateTemplateConsumer( 'city' );"
				/>
			</label>
			<label>
				<span class="frame-label">State <b>*</b></span>
				<input type="text" autocomplete="off"
					[class.error]="templateConsumerErrors.state"
					[(ngModel)]="templateConsumerData.state"
					[ngModelOptions]="{ updateOn: 'blur' }"
					(ngModelChange)="validateTemplateConsumer( 'state' );"
				/>
			</label>
			<label>
				<span class="frame-label">Date of Birth <b>*</b></span>
				<input type="date" autocomplete="off"
					[class.error]="templateConsumerErrors.dob"
					[(ngModel)]="templateConsumerData.dob"
					[ngModelOptions]="{ updateOn: 'blur' }"
					(ngModelChange)="validateTemplateConsumer( 'dob' )"
				/>
			</label>
			<label>
				<span class="frame-label">Phone <b>*</b></span>
				<input type="text" autocomplete="off"
					[class.error]="templateConsumerErrors.phone"
					[(ngModel)]="templateConsumerData.phone"
					[ngModelOptions]="{ updateOn: 'blur' }"
					(ngModelChange)="validateTemplateConsumer( 'phone' )"
				/>
			</label>
			<label class="wide cashless-spending">
				<span class="not-yet-a-frame-label">
					<span class="label-note">Cashless Spending</span>
					<span class="cashless-spending-note">Activate in park cashless spending on your park issued Wild Rivers wristband by storing your credit card.<br />Leave your wallet in your locker and simply tap your wristband to make any purchase throughout the park.</span>
				</span>
				<ui-toggle-box [checked]="cashlessSpending" (changed)="cashlessToggle( $event );"></ui-toggle-box>
			</label>
			<hr />
			<span class="button save" [ngClass]="{
				disabled: templateConsumerHasErrors || !this.profileDocletID,
				busy: this.formBusy
			}" (click)="updateProfile();">Save Changes<i class="busy-icon fa-solid fa-spin fa-circle-notch"></i></span>
		</div>
		<div class="column">
			<h3>Photo ID</h3>
			<div class="photo-id">
				<div class="photo-id-wrapper">
					<image-cropper
							[ngStyle]="{
								display: usingImageCropper ? 'block' : 'none'
							}"
							[imageChangedEvent]="imageChangedEvent"
							(imageCropped)="imageCropperOnChange( $event )"
							(imageLoaded)="imageCropperOnImageLoad( $event )"
							(cropperReady)="cropperReady()"
							(loadImageFailed)="loadImageFailed()"
							[format]="'jpeg'"
							[maintainAspectRatio]="true"
							[aspectRatio]="16 / 16"
							[cropperMinWidth]="150"
							[cropperMinHeight]="150"
							[cropperMaxHeight]="1200"
							[cropperMaxWidth]="1200"
							[imageQuality]="95"
							[alignImage]="'center'"
							[backgroundColor]="'#FFFFFF'"
					></image-cropper>
					<span class="placeholder" *ngIf="!usingImageCropper && templateConsumerData.photo.length < 1"><i class="fa-solid fa-user-astronaut"></i></span>
					<span class="profile-picture" *ngIf="!usingImageCropper && templateConsumerData.photo.length > 0"><img alt="" [src]="this.templateConsumerData.photo"/></span>
				</div>
				<label *ngIf="!usingImageCropper" class="button grey upload file-picker">
					<input accept="image/*" id="file-picker-profile-photo" type="file"
						(change)="inputTypeFileOnChange( $event )"/>
					<span class="button-display">Upload New Photo</span>
				</label>
				<label *ngIf="usingImageCropper" class="button green" (click)="setProfilePhoto();">
					<span class="button-display">Crop & Save Photo</span>
				</label>
				<label *ngIf="usingImageCropper" class="button text-only" (click)="clearImageCropper();">
					<span class="button-display text-only">Cancel Upload</span>
				</label>
			</div>
		</div>
	</div>
</div>
