import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
// ===== App ===== //
import {AppConfig} from '../../app.config';
import {AppRouterLinks} from '../../app.router-links';
// ===== Collections ===== //
import {CollectionProfiles} from '../../collections/profiles';
// ===== Image Cropper ===== //
import {ImageCroppedEvent, LoadedImage} from 'ngx-image-cropper'; // https://www.npmjs.com/package/ngx-image-cropper
// ===== Interfaces ===== //
import {
	InterfaceAnyObject,
	InterfaceAppContext,
	InterfaceHTTPGateway,
	InterfaceNavMenuItem,
	InterfaceOWUser,
	InterfaceOWDoclet,
	InterfaceOWAPIGetWeavesResponse,
	InterfaceOWWeaveV2,
	InterfaceOWAPIGetDocletResponse
} from '../../interfaces/interfaces';
interface InterfaceFamilyMemberData {
	firstName: string;
	lastName: string;
	dob: string;
	photo: string;
}
interface InterfaceFamilyMemberErrors {
	firstName: boolean;
	lastName: boolean;
	dob: boolean;
	photo: boolean;
}
// ===== Services ===== //
import {ServiceAuthentication} from '../../services/authentication';
import {ServiceRegex} from '../../services/regex';
import {ServiceNavigate} from '../../services/navigate';
import {ServiceOWAPI} from '../../services/ow-api';
//
@Component( {
	selector: 'page-edit-family',
	templateUrl: './edit-family.html',
	styleUrls: [
		'./edit-family.less'
	]
} )
export class PageEditFamily implements OnInit { // TODO: do not allow users to change their account info, to other people. or the season pass can be shuffled around between friends.
	public routes: typeof AppRouterLinks = AppRouterLinks;
	//
	public mastHeading: string = 'Manage Your Family';
	public editingNameHere: string = '';
	//
	private memberID: string | undefined = undefined; // member ID comes from the /:id in the URL
	private familyDocletID: string | undefined = undefined; // this ought to be the family doclet woven to the currently signed in user.
	public memberDoclet: InterfaceOWDoclet | undefined = undefined; // this ought to be the OW doclet where the ._id.$oid matches the /:id in the URL.
	public serialCode: string = '';
	public formBusy: boolean = false;
	public allowedToEdit: boolean = false;
	//
	public navMenuItems: InterfaceNavMenuItem[] = [
		{
			route: '/' + this.routes.dashboard,
			text: 'Dashboard'
		},
		{
			route: '/' + this.routes.family,
			text: 'Manage Family',
			shortText: 'Family',
			locked: true
		},/*
		{
			route: '/' + this.routes.editGroup,
			text: 'Manage Group',
			shortText: 'Group',
			locked: false
		},*/
		{
			route: '/' + this.routes.orders,
			text: 'Orders & Billing',
			shortText: 'Billing'
		}
	];
	//
	public familyMemberData: InterfaceFamilyMemberData = {
		firstName: '',
		lastName: '',
		dob: '',
		photo: ''
	};
	public familyMemberErrors: InterfaceFamilyMemberErrors = {
		firstName: false,
		lastName: false,
		dob: false,
		photo: false
	};
	public formHasErrors: boolean = false;
	//
	private docletCache: { [docletID: string]: InterfaceOWDoclet } = {};
	//
	public constructor(
		private readonly activeRoute: ActivatedRoute,
		private readonly appConfig: AppConfig,
		private readonly auth: ServiceAuthentication,
		private readonly colProfiles: CollectionProfiles,
		private readonly nav: ServiceNavigate,
		private readonly owapi: ServiceOWAPI,
		private readonly title: Title
	) {
		this.title.setTitle( 'Wild Rivers Portal' );
		if ( !this.auth.isSignedIn() ) {
			this.nav.toURL( '/' + this.routes.signIn );
		}
	}

	public checkFormForErrors(): void {
		this.formHasErrors = false;
		const errorKeys: (keyof InterfaceFamilyMemberErrors)[] = Object.keys( this.familyMemberErrors ) as (keyof InterfaceFamilyMemberErrors)[];
		for ( let x: number = 0; !this.formHasErrors && x < errorKeys.length; ++x ) {
			this.formHasErrors = this.familyMemberErrors[ errorKeys[x] ];
		}
	}

	private getMemberID(): void { // URL /family/edit/:id
		if ( this.activeRoute.snapshot.params && typeof this.activeRoute.snapshot.params['id'] === 'string' && this.activeRoute.snapshot.params['id'].length > 0 ) {
			this.memberID = this.activeRoute.snapshot.params['id'];
		}
	}

	private fetchFamilyMemberInfo(): void {
		// fetch the weaves for this user to grab the family doclet
		// fetch the woven users to the family doclet
		// ensure the ID in the URL belongs to the family. don't allow random users to edit other users data.
		const appContext: InterfaceAppContext = this.appConfig.getContext();
		this.colProfiles.getMyUserProfile( (userProfile: InterfaceOWUser | null): void => {
			if ( userProfile && userProfile.doclet_id ) {
				const userProfileDocletID: string = userProfile.doclet_id;
				// ===== fetch weaves for signed-in user ===== //
				console.log( 'Fetch family weaves - get weaves by doclet id (user profile ID)' );
				this.owapi.workspace.doclets.getWeavesByDocletID( appContext, userProfileDocletID ).subscribe( (responseGetProfileWeaves: InterfaceHTTPGateway): void => {
					if ( responseGetProfileWeaves?.success ) {
						const apiResponseGetProfileWeaves: InterfaceOWAPIGetWeavesResponse | undefined = responseGetProfileWeaves?.data;
						if ( apiResponseGetProfileWeaves && Array.isArray( apiResponseGetProfileWeaves?.data?.items ) ) {
							const userWeaves: InterfaceOWWeaveV2[] = apiResponseGetProfileWeaves.data.items;
							for ( let uw: number = 0; !this.familyDocletID && uw < userWeaves.length; ++uw ) {
								// find the entry for the Family template/doclet, then fetch the Family doclet by ID.
								if ( userWeaves[uw].t_id.$oid === this.appConfig.getTemplateID( 'Family' ) ) {
									this.familyDocletID = userWeaves[uw].c_id.$oid;
								}
							} // end for each weave on the user, to try and find the family doclet ID.
							if ( this.familyDocletID ) {
								console.log( 'Fetch family weaves - fetch weaves by ID (family doclet ID)' );
								this.owapi.workspace.doclets.getWeavesByDocletID( appContext, this.familyDocletID ).subscribe( (responseGetFamilyWeaves: InterfaceHTTPGateway): void => {
									if ( responseGetFamilyWeaves?.success ) {
										const apiResponseGetFamilyWeaves: InterfaceOWAPIGetWeavesResponse | undefined = responseGetFamilyWeaves?.data;
										if ( apiResponseGetFamilyWeaves && Array.isArray( apiResponseGetFamilyWeaves?.data?.items ) ) {
											const weavesOnFamily: InterfaceOWWeaveV2[] = apiResponseGetFamilyWeaves.data.items;
											let memberFound: boolean = false;
											const consumerTemplateID: string = this.appConfig.getTemplateID( 'Consumer' );
											for ( let wof: number = 0; wof < weavesOnFamily.length; ++wof ) {
												if ( weavesOnFamily[wof].t_id.$oid === consumerTemplateID && weavesOnFamily[wof].c_id.$oid === this.memberID ) {
													memberFound = true;
													this.owapi.workspace.doclets.getDocletByID( appContext, this.memberID ).subscribe( (responseGetMemberDoclet: InterfaceHTTPGateway): void => {
														if ( responseGetMemberDoclet?.success ) {
															const apiResponseGetMemberDoclet: InterfaceOWAPIGetDocletResponse | undefined = responseGetMemberDoclet?.data;
															if ( apiResponseGetMemberDoclet?.data?._id?.$oid ) { // .data is the payload, which is a doclet. not the doclet's .data sub proeprty.
																const famMember: InterfaceOWDoclet = apiResponseGetMemberDoclet.data;
																if ( famMember && famMember._id ) {
																	// ===== Success ===== //
																	this.memberDoclet = famMember;
																	// =================== //
																	this.familyMemberData.firstName = typeof this.memberDoclet.data['first_name'] === 'string' ? this.memberDoclet.data['first_name'] : '';
																	this.editingNameHere = this.familyMemberData.firstName.length > 0 ? this.familyMemberData.firstName : 'Member';
																	this.familyMemberData.lastName = typeof this.memberDoclet.data['last_name'] === 'string' ? this.memberDoclet.data['last_name'] : '';
																	this.familyMemberData.dob = this.memberDoclet.data['dob'] ? this.memberDoclet.data['dob'] : '';
																	this.familyMemberData.photo = typeof this.memberDoclet.data['photo'] === 'string' ? this.memberDoclet.data['photo'] : '';
																	this.allowedToEdit = !this.memberDoclet.data['profile_locked'];
																	const errorKeys: (keyof InterfaceFamilyMemberErrors)[] = Object.keys( this.familyMemberErrors ) as (keyof InterfaceFamilyMemberErrors)[];
																	for ( let x: number = 0; x < errorKeys.length; ++x ) {
																		this.validateData( errorKeys[x] );
																	}
																	this.checkFormForErrors();
																	// =================== //
																} else {
																	console.log( 'Tried to fetch a family members info to edit, but they do not exist.' );
																	this.nav.toURL( '/' + this.routes.dashboard );
																}
															}
														}
													} );
												} // else this weave isn't the family member we're looking for.
											} // end for each woven entry linked to the family doclet
											if ( !memberFound ) {
												console.log( 'No known family member linked to this users family by ID provided.' );
												this.nav.toURL( '/' + this.routes.dashboard );
											}
										}
									}
								} );
							} else {
								console.log( 'No family doclet woven to the user trying to edit a family member. aborting.' );
								this.nav.toURL( '/' + this.routes.dashboard );
							}
						}
					}
				} );
			} else {
				// can't verify the ownership of a family record, to verify if they're allowed to edit this member by member ID.
				console.log( 'No doclet_id for signed in user. cannot fetch weaves against the missing doclet_id' );
				this.nav.toURL( '/' + this.routes.dashboard );
			}
		} );
	}

	public ngOnInit(): void {
		this.getMemberID();
		if ( typeof this.memberID === 'string' && this.memberID.length > 0 ) {
			this.fetchFamilyMemberInfo();
		} else {
			this.nav.toURL( '/' + this.routes.dashboard );
		}
	}

	// public ngOnDestroy(): void {
	// 	if ( this.updateUserCacheOnExit && this.profileDocletID ) {
	// 		this.colProfiles.fetchProfileByID( this.profileDocletID as string );
	// 	}
	// }

	public validateData( key: keyof InterfaceFamilyMemberData ): void {
		this.familyMemberData[key] = this.familyMemberData[key].replace( ServiceRegex.trimRegExp, '' );
		switch ( key ) {
			case 'firstName': {
				this.familyMemberErrors.firstName = this.familyMemberData.firstName.length < 1;
				break;
			}
			case 'lastName': {
				this.familyMemberErrors.lastName = this.familyMemberData.lastName.length < 1;
				break;
			}
			case 'dob': {
				this.familyMemberErrors.dob = !this.familyMemberData.dob.match( /^\d\d\d\d-\d\d-\d\d$/ );
				break;
			}
			case 'photo': {
				this.familyMemberErrors.photo = !ServiceRegex.profilePhotoB64RegExp.test( this.familyMemberData.photo );
				break;
			}
		} // end switch ( key )
	}

	public updateFamilyMember(): void {
		if ( this.allowedToEdit && this.formBusy ) {
			return;
		}
		const appContext: InterfaceAppContext = this.appConfig.getContext();
		const errorKeys: (keyof InterfaceFamilyMemberErrors)[] = Object.keys( this.familyMemberErrors ) as (keyof InterfaceFamilyMemberErrors)[];
		for ( let x: number = 0; x < errorKeys.length; ++x ) {
			this.validateData( errorKeys[x] );
		}
		if ( !this.formHasErrors && this.memberID && this.memberID.length > 0 ) {
			if ( this.familyMemberData.photo.length < 1 ) {
				this.familyMemberErrors.photo = true;
				return;
			}
			const payload: InterfaceAnyObject = { // if a key is missing, it won't alter the users profile.
				first_name: this.familyMemberData.firstName, // if a value is missing, it will wipe the key's value on the profile.
				last_name: this.familyMemberData.lastName,
				dob: this.familyMemberData.dob,
				photo: this.familyMemberData.photo // TODO: don't upload the photo each time. just the things that changed.
			};
			this.formBusy = true;
			const tsStarted: Date = new Date();
			this.owapi.workspace.doclets.updateDocletData( appContext, this.memberID, payload ).subscribe( (response: InterfaceHTTPGateway): void => {
				const tsEnded: Date = new Date();
				const tsDelta: number = tsEnded.getTime() - tsStarted.getTime();
				setTimeout( (): void => { // debounce - give the user some time to see that it worked...
					this.formBusy = false;
				}, 1000 - tsDelta < 0 ? 0 : 1000 - tsDelta );
				console.log( response );
				if ( response && response.success && response.status === 200 ) {
					this.editingNameHere = this.familyMemberData.firstName;
					const cachedDoclet: InterfaceOWDoclet | null = this.docletCache[ this.memberID as string ];
					// update the run-time cache so we don't have to re-fetch the data from server-side.
					if ( cachedDoclet ) {
						Object.keys( payload ).forEach( (key: string): void => {
							cachedDoclet.data[key] = payload[key];
						} );
						this.docletCache[ cachedDoclet._id.$oid ] = cachedDoclet;
					}
					this.owapi.workspace.doclets.getWeavesByDocletID( appContext, this.memberID as string ).subscribe( (responseGetWeaves: InterfaceHTTPGateway): void => {
						if ( responseGetWeaves?.success ) {
							const apiResponseGetWeaves: InterfaceOWAPIGetWeavesResponse | undefined = responseGetWeaves?.data;
							if ( apiResponseGetWeaves && Array.isArray( apiResponseGetWeaves?.data?.items ) && apiResponseGetWeaves.data.items.length > 0 ) {
								const memberWeaves: InterfaceOWWeaveV2[] = apiResponseGetWeaves.data.items;
								for ( let x: number = 0; x < memberWeaves.length; ++x ) {
									if ( memberWeaves[x].t_id.$oid === this.appConfig.getTemplateID( 'Season Admission Ticket' ) ) {
										// all season passes must be updated, regardless of it's status (cancelled, etc.) in-case we un-cancel it.
										this.owapi.workspace.doclets.updateDocletData( appContext, memberWeaves[x].c_id.$oid, {
											assigned_first_name: this.familyMemberData.firstName,
											assigned_last_name: this.familyMemberData.lastName
										} ).subscribe( (_: InterfaceHTTPGateway): void => {
											// fire and forget.
										} );
									} // end if this woven entry is a season pass.
								} // end for each .weaves on the OWWeave.
							} // end if the api response has the OWWeave entry
						} // end if network apu succeeded.
					} ); // end get weaves of family member
				} // end if network api succeeded.
			} ); // end update doclet (the family member)
		} // end if the form doesn't have errors, is not busy, etc.
	} // end updateFamilyMember()

	public clearPictureError(): void {
		this.familyMemberErrors.photo = false;
	}

	public blur( E: Event ): void {
		(E.target as HTMLElement).blur();
	}

	// ===== Image Cropper ===== //
	public imageChangedEvent: Event | undefined = undefined;
	public b64ProfileImageData: string = '';
	public usingImageCropper: boolean = false;

	public inputTypeFileOnChange( E: Event ): void {
		if ( !this.allowedToEdit ) {
			return;
		}
		this.imageChangedEvent = E;
	}

	public imageCropperOnChange( E: ImageCroppedEvent ): void {
		if ( typeof E.base64 === 'string' ) {
			this.b64ProfileImageData = E.base64;
		} else {
			this.imageCropperFail();
		}
	}

	public imageCropperOnImageLoad( _: LoadedImage ): void {
		this.usingImageCropper = true;
	}

	public cropperReady(): void {
		this.usingImageCropper = true;
	}

	public loadImageFailed(): void {
		this.imageCropperFail();
	}

	private imageCropperFail(): void {
		this.familyMemberErrors.photo = true;
	}

	public setFamilyMemberPhoto(): void {
		if ( !this.allowedToEdit ) {
			return;
		}
		if ( this.b64ProfileImageData && this.b64ProfileImageData.length > 0 && this.memberID && this.memberID.length > 0 ) {
			this.owapi.workspace.doclets.updateDocletData( this.appConfig.getContext(), this.memberID, {
				photo: this.b64ProfileImageData
			} ).subscribe( (response: InterfaceHTTPGateway): void => {
				if ( response && response.success && response.status === 200 ) {
					this.familyMemberData.photo = this.b64ProfileImageData;
					// update the run-time cache so we don't have to re-fetch the data from server-side.
					const cachedDoclet: InterfaceOWDoclet | null = this.docletCache[ this.memberID as string ];
					if ( cachedDoclet ) {
						cachedDoclet.data['photo'] = this.b64ProfileImageData;
						this.docletCache[ cachedDoclet._id.$oid ] = cachedDoclet;
					}
					this.clearImageCropper();
					this.checkFormForErrors();
				}
			} );
		}
	}

	public clearImageCropper(): void {
		this.usingImageCropper = false;
		this.b64ProfileImageData = '';
		this.imageChangedEvent = undefined;
	}
}
