// ===== Modules ===== //
import {NgModule} from '@angular/core';
// ===== Pipes ===== //
import {PipeDateFromYYYYMM1DD} from './YYYYMMDD';
import {PipeOrdinal} from './ordinal';
import {PipePassFilterSPH} from './passFilterSPH';
import {PipePassPriceTime} from './passPriceTime';
import {PipePassShortCodes} from './passShortCodes';
import {PipePluck} from './pluck';
import {PipeReplace} from './replace';
import {PipeTicketAdmissionTime} from './ticketAdmissionTime';
import {PipeTicketAdmissionType} from './ticketAdmissionType';
import {PipeTimeAgo} from './timeago';
import {PipeTransactionAction} from './transactionAction';
import {PipeTransactionStatus} from './transactionStatus';
import {PipeTrim} from './trim';
//
const pipes: any[] = [
	PipeDateFromYYYYMM1DD,
	PipeOrdinal,
	PipePassFilterSPH,
	PipePassPriceTime,
	PipePassShortCodes,
	PipePluck,
	PipeReplace,
	PipeTicketAdmissionTime,
	PipeTicketAdmissionType,
	PipeTimeAgo,
	PipeTransactionAction,
	PipeTransactionStatus,
	PipeTrim
];
//
@NgModule( {
	declarations: pipes,
	exports: pipes,
	imports: [],
	providers: []
} )
export class ModulePipes {}
