import {Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
// ===== App ===== //
import {AppConfig} from '../../app.config';
import {AppEvents} from '../../app.events';
import {AppRouterLinks} from '../../app.router-links';
// ===== Collections ===== //
import {CollectionProfiles} from '../../collections/profiles';
// ===== Interfaces ===== //
import {
	InterfaceAnyObject,
	InterfaceAppContext,
	InterfaceAppEvent,
	InterfaceHTTPGateway,
	InterfaceNavMenuItem,
	InterfaceOWAPICreateDocletResponse,
	InterfaceOWAPICreateWeaveResponse,
	InterfaceOWAPIGetDocletResponse,
	InterfaceOWAPIGetWeavesResponse,
	InterfaceOWDoclet,
	InterfaceOWDocletConsumer,
	InterfaceOWTemplateConsumer,
	InterfaceOWTemplateGroup,
	InterfaceOWUser,
	InterfaceOWWeaveV2
} from '../../interfaces/interfaces';
// ===== Services ===== //
import {ServiceAuthentication} from '../../services/authentication';
import {ServiceNavigate} from '../../services/navigate';
import {ServiceOWAPI} from '../../services/ow-api';

interface InterfaceGroupWeavesData {
	groupDoclet: InterfaceOWDoclet | null;
	groupDocletID: string;
	members: InterfaceOWDocletConsumer[];
	loaded: boolean;
}
interface InterfaceInviteGroupMemberData {
	email: string;
	firstName: string;
	lastName: string;
}
interface InterfaceEditMemberData {
	_id: string;
	email: string;
	firstName: string;
	lastName: string;
}
interface InterfacePrimaryAccountUser {
	consumerDocletID: string;
	firstName: string;
	lastName: string;
	photo: string;
	cashlessSpending: boolean;
}
//
// TODO: un-fail all this. when inviting somebody to a group, it creates a Consumer.
// when the person accepts the invite, it's intended to convert that consumer record to a (top-level) kind...
// but an issue exists when multiple people invite the same person that has not-yet created an account nor accepted anybody.
// ...in this situation, there will be multiple Consumer records floating around.
// another issue is that the status-flag for the group invite is stored on the Consumer, but probably should be on the woven entry.
//
@Component( {
	selector: 'page-edit-group',
	templateUrl: './edit-group.html',
	styleUrls: [
		'./edit-group.less'
	]
} )
export class PageEditGroup implements OnDestroy, OnInit {
	public routes: typeof AppRouterLinks = AppRouterLinks;
	//
	private readonly strConsumerTemplateID: string = this.appConfig.getTemplateID( 'Consumer' );
	private readonly strGroupTemplateID: string = this.appConfig.getTemplateID( 'Group' );
	//
	private subProfilesUpdated: Subscription | null = null;
	private subUserReSync: Subscription | null = null;
	//
	public mastHeading: string = 'Hello'; // 'Hello, firstname'
	public navMenuItems: InterfaceNavMenuItem[] = [
		{
			route: '/' + this.routes.dashboard,
			text: 'Dashboard'
		},
		{
			route: '/' + this.routes.family,
			text: 'Manage Family',
			shortText: 'Family',
			locked: true
		},
		{
			route: '/' + this.routes.editGroup,
			text: 'Manage Group',
			shortText: 'Group',
			locked: false
		},
		{
			route: '/' + this.routes.orders,
			text: 'Orders & Billing',
			shortText: 'Billing'
		}
	];
	public haveUserInfo: boolean = false;
	public primaryAccountHolder: InterfacePrimaryAccountUser = {
		consumerDocletID: '',
		firstName: '',
		lastName: '',
		photo: '',
		cashlessSpending: false
	};
	//
	public groupWeaves: {
		asOwner: {
			groups: InterfaceGroupWeavesData[];
		};
		asMember: {
			groups: InterfaceGroupWeavesData[];
		};
		loaded: boolean;
	} = {
		asOwner: {
			groups: []
		},
		asMember: {
			groups: []
		},
		loaded: false
	};
	//
	private inviteGroupID: string | null = null;
	public inviteGroupMemberData: InterfaceInviteGroupMemberData = {
		email: '',
		firstName: '',
		lastName: ''
	};
	private subInviteGroupMemberData: Subscription | null = null;
	private subEditGroupMemberData: Subscription | null = null;
	public busy: boolean = false;
	//
	public constructor(
		private readonly appConfig: AppConfig,
		private readonly appEvents: AppEvents,
		private readonly auth: ServiceAuthentication,
		private readonly colProfiles: CollectionProfiles,
		private readonly nav: ServiceNavigate,
		private readonly owapi: ServiceOWAPI,
		private readonly title: Title
	) {
		this.title.setTitle( 'Wild Rivers Portal' );
		if ( this.auth.isSignedIn() ) {
			this.subProfilesUpdated = this.colProfiles.updated.subscribe( (): void => {
				this.fetchUserInfo();
			} );
			this.subUserReSync = this.appEvents.listen( 'user:re-sync' ).subscribe( (_: InterfaceAppEvent): void => {
				this.fetchUserInfo();
			} );
			this.subInviteGroupMemberData = this.appEvents.listen( 'data:invite-group-member' ).subscribe( (event: InterfaceAppEvent): void => {
				this.inviteGroupMemberData = event.data;
				if ( this.inviteGroupID ) {
					this.TEMPCODE_addMember( this.inviteGroupID, {
						first_name: this.inviteGroupMemberData.firstName,
						last_name: this.inviteGroupMemberData.lastName,
						invited_email: this.inviteGroupMemberData.email,
						invited_status: 'pending' // server-side should be doing this...
					} );
				}
			} );
			this.subEditGroupMemberData = this.appEvents.listen( 'data:edit-group-member' ).subscribe( (event: InterfaceAppEvent): void => {
				let groupID: string | null = null;
				let memberDoclet: InterfaceOWDoclet | null = null;
				const editMemberEventData: InterfaceEditMemberData = event.data;
				if ( '_id' in editMemberEventData && (editMemberEventData._id?.length ?? 0) > 0 ) {
					for ( let x: number = 0; x < this.groupWeaves.asOwner.groups.length; ++x ) {
						const gw: InterfaceGroupWeavesData = this.groupWeaves.asOwner.groups[x];
						if ( gw.groupDocletID ) {
							for ( let y: number = 0; y < gw.members.length; ++y ) {
								if ( gw.members[y]._id.$oid === editMemberEventData._id ) {
									groupID = gw.groupDocletID;
									memberDoclet = gw.members[y];
									gw.members[y].data['first_name'] = editMemberEventData.firstName;
									gw.members[y].data['last_name'] = editMemberEventData.lastName;
									gw.members[y].data['invited_email'] = editMemberEventData.email;
								}
							}
						}
					}
					if ( typeof groupID === 'string' && groupID.length > 0 && memberDoclet ) {
						this.saveMember( memberDoclet );
					} else {
						console.log( 'Could not located the member to update, in any existing group' );
					}
				} else {
					console.log( 'Failed to receive the _id for the member that was just updated.' );
				}
			} );
			this.fetchGroupWeaves();
		}
	}

	public ngOnInit(): void {
		if ( this.auth.isSignedIn() ) {
			this.fetchUserInfo();
		} else {
			this.nav.toURL( '/' + this.routes.signIn );
		}
	}

	public ngOnDestroy(): void {
		if ( this.subInviteGroupMemberData ) {
			this.subInviteGroupMemberData.unsubscribe();
			this.subInviteGroupMemberData = null;
		}
		if ( this.subProfilesUpdated ) {
			this.subProfilesUpdated.unsubscribe();
			this.subProfilesUpdated = null;
		}
		if ( this.subUserReSync ) {
			this.subUserReSync.unsubscribe();
			this.subUserReSync = null;
		}
		if ( this.subEditGroupMemberData ) {
			this.subEditGroupMemberData.unsubscribe();
			this.subEditGroupMemberData = null;
		}
		this.hideInviteModal();
		this.hideEditModal();
	}

	private fetchUserInfo(): void {
		this.colProfiles.getMyUserProfile( (userData: InterfaceOWUser | null): void => {
			if ( userData && userData.data ) {
				this.haveUserInfo = true;
				const templateConsumer: InterfaceOWTemplateConsumer = userData.data as InterfaceOWTemplateConsumer;
				this.mastHeading = 'Hello, ' + templateConsumer.first_name + ' ' + templateConsumer.last_name;
				this.primaryAccountHolder.consumerDocletID = userData.doclet_id;
				this.primaryAccountHolder.firstName = templateConsumer.first_name ? templateConsumer.first_name : '';
				this.primaryAccountHolder.lastName = templateConsumer.last_name ? templateConsumer.last_name : '';
				this.primaryAccountHolder.photo = templateConsumer.photo ? templateConsumer.photo : '';
				// account holders don't have a serial code. family members do that have a ticket/season-pass.
				// maybe in the future they will... or maybe they'll only create family members... not sure.
			}
		} );
	}

	private fetchGroupWeaves(): void {
		if ( this.groupWeaves.loaded ) {
			return;
		}
		const appContext: InterfaceAppContext = this.appConfig.getContext();
		this.colProfiles.getMyUserProfile( (userProfile: InterfaceOWUser | null): void => {
			if ( userProfile && userProfile.doclet_id ) {
				const userProfileDocletID: string = userProfile.doclet_id;
				// ===== fetch weaves for signed-in user ===== //
				console.log( 'Fetching (User)<-(Group)<-(Members)' )
				// TODO: fetch cached weave, we already fetched it when getting the family weaves.
				this.owapi.workspace.doclets.getWeavesByDocletID( appContext, userProfileDocletID ).subscribe( (responseGetFamilyWeaves: InterfaceHTTPGateway): void => {
					if ( responseGetFamilyWeaves?.success ) {
						const apiResponseGetFamilyWeaves: InterfaceOWAPIGetWeavesResponse | undefined = responseGetFamilyWeaves?.data;
						if ( apiResponseGetFamilyWeaves && Array.isArray( apiResponseGetFamilyWeaves?.data?.items ) ) {
							const userWeaves: InterfaceOWWeaveV2[] = apiResponseGetFamilyWeaves.data.items;
							console.log( 'user weaves', userWeaves );
							for ( let uw: number = 0; uw < userWeaves.length; ++uw ) {
								// find the entry for the Group doclet
								switch ( userWeaves[uw].t_id.$oid ) {
									case this.strGroupTemplateID: { // found a group, but it could be the owner, or only a member of a group.
										const groupWeave: InterfaceGroupWeavesData = {
											groupDocletID: userWeaves[uw].c_id.$oid,
											groupDoclet: null,
											members: [],
											loaded: false
										};
										if ( userWeaves[uw].data?.['role'] === 'Group Owner' ) {
											this.groupWeaves.asOwner.groups.push( groupWeave );
											this.groupWeaves.asOwner.groups.sort( (A: InterfaceGroupWeavesData, B: InterfaceGroupWeavesData): number => {
												return String( A.groupDoclet?.data['name'] ?? '' ).toLowerCase().localeCompare( String( B.groupDoclet?.data['name'] ?? '' ).toLowerCase(), undefined, {
													numeric: true,
													sensitivity: 'base'
												} );
											} );
										} else if ( userWeaves[uw].data?.['role'] === 'Group Member' ) {
											this.groupWeaves.asMember.groups.push( groupWeave );
											this.groupWeaves.asMember.groups.sort( (A: InterfaceGroupWeavesData, B: InterfaceGroupWeavesData): number => {
												return String( A.groupDoclet?.data['name'] ?? '' ).toLowerCase().localeCompare( String( B.groupDoclet?.data['name'] ?? '' ).toLowerCase(), undefined, {
													numeric: true,
													sensitivity: 'base'
												} );
											} );
										}
										break;
									}
								} // end switch template_id on the user's weaves.
							} // end for each user's weaves.
							//
							if ( this.groupWeaves.asOwner.groups.length < 1 && this.groupWeaves.asMember.groups.length < 1 ) {
								console.log( 'Fetched (User)<-(Group)<-(Members) - aborted early, no weaves.' ); // nothing to do.
								this.groupWeaves.loaded = true;
							} else {
								for ( let x: number = 0; x < this.groupWeaves.asOwner.groups.length; ++x ) {
									const groupWeave: InterfaceGroupWeavesData = this.groupWeaves.asOwner.groups[x];
									// Fetch Group Doclet
									this.owapi.workspace.doclets.getDocletByID( appContext, groupWeave.groupDocletID ).subscribe( (responseGetGroupDoclet: InterfaceHTTPGateway): void => {
										if ( responseGetGroupDoclet?.success ) {
											const apiResponseGetGroupDoclet: InterfaceOWAPIGetDocletResponse | undefined = responseGetGroupDoclet?.data;
											if ( apiResponseGetGroupDoclet?.data?._id?.$oid ) {
												const groupDoclet: InterfaceOWDoclet = apiResponseGetGroupDoclet.data;
												if ( groupDoclet && groupDoclet._id ) {
													groupWeave.groupDoclet = groupDoclet; // groupWeave was already pushed into an array, this is still updating it after the fact.
												}
											}
										}
									} );
									//
									this.owapi.workspace.doclets.getWeavesByDocletID( appContext, groupWeave.groupDocletID ).subscribe( (responseGetWeavesOnGroup: InterfaceHTTPGateway): void => {
										if ( responseGetWeavesOnGroup?.success ) {
											const apiResponseGetWeavesOnGroup: InterfaceOWAPIGetWeavesResponse | undefined = responseGetWeavesOnGroup?.data;
											if ( apiResponseGetWeavesOnGroup && Array.isArray( apiResponseGetWeavesOnGroup?.data?.items ) ) {
												const weavesOnGroup: InterfaceOWWeaveV2[] = apiResponseGetWeavesOnGroup.data.items;
												const groupMemberDocletIDs: string[] = [];
												for ( let wog: number = 0; wog < weavesOnGroup.length; ++wog ) {
													switch ( weavesOnGroup[wog].t_id.$oid ) {
														case this.strConsumerTemplateID: {
															if ( weavesOnGroup[wog]?.data?.['role'] === 'Group Member' ) { // only grab members, not the owner.
																groupMemberDocletIDs.push( weavesOnGroup[wog].c_id.$oid );
															}
															break;
														}
													} // end switch template_id of each weave
												} // end for each weave on the group.
												// ===== Fetch Group Members by ID ===== //
												console.log( 'Group member IDs', groupMemberDocletIDs );
												let membersRemaining: number = groupMemberDocletIDs.length;
												if ( membersRemaining < 1 ) {
													groupWeave.loaded = true;
													this.groupWeaveLoaded();
												} else {
													for ( let gm: number = 0; gm < groupMemberDocletIDs.length; ++gm ) {
														this.owapi.workspace.doclets.getDocletByID( appContext, groupMemberDocletIDs[gm] ).subscribe( (responseGetGroupMember: InterfaceHTTPGateway): void => {
															if ( responseGetGroupMember?.success ) {
																const apiResponseGetGroupMember: InterfaceOWAPIGetDocletResponse | undefined = responseGetGroupMember?.data;
																if ( apiResponseGetGroupMember?.data?._id?.$oid ) {
																	const groupMember: InterfaceOWDoclet = responseGetGroupMember.data;
																	if ( groupMember && groupMember._id ) {
																		groupWeave.members.push( groupMember as InterfaceOWDocletConsumer );
																	}
																	if ( --membersRemaining < 1 ) {
																		groupWeave.members.sort( (A: InterfaceOWDoclet, B: InterfaceOWDoclet): number => {
																			return String( A.data['first_name'] ?? '' ).toLowerCase().localeCompare( String( B.data['first_name'] ?? '' ).toLowerCase(), undefined, {
																				numeric: true,
																				sensitivity: 'base'
																			} );
																		} );
																		groupWeave.loaded = true;
																		this.groupWeaveLoaded();
																	} // end if this was the last group member
																}
															}
														} );
													} // end for each group member
												}
											}
										}
									} );
								} // end for each group weave to process, as an owner of the group.
								for ( let x: number = 0; x < this.groupWeaves.asMember.groups.length; ++x ) {
									const groupWeave: InterfaceGroupWeavesData = this.groupWeaves.asMember.groups[x];
									// Fetch Group Doclet
									this.owapi.workspace.doclets.getDocletByID( appContext, groupWeave.groupDocletID ).subscribe( (responseGetGroupDoclet: InterfaceHTTPGateway): void => {
										if ( responseGetGroupDoclet?.success ) {
											const apiResponseGetGroupDoclet: InterfaceOWAPIGetDocletResponse | undefined = responseGetGroupDoclet?.data;
											if ( apiResponseGetGroupDoclet?.data?._id?.$oid ) {
												const groupDoclet: InterfaceOWDoclet = apiResponseGetGroupDoclet.data;
												if ( groupDoclet && groupDoclet._id ) {
													groupWeave.groupDoclet = groupDoclet; // groupWeave was already pushed into an array, this is still updating it after the fact.
												}
												groupWeave.loaded = true;
												this.groupWeaveLoaded();
											}
										}
									} );
									// nothing more needed as a group-member.
								}
							}
						}
					}
				} );
			} else {
				console.log( 'No user Doclet ID, cannot fetch group data.' );
			}
		} );
	}

	private groupWeaveLoaded(): void {
		if ( this.groupWeaves.loaded ) {
			return;
		}
		const asOwnerCount: number = this.groupWeaves.asOwner.groups.length;
		const asOwnerLoaded: number = this.groupWeaves.asOwner.groups.filter( (groupData: InterfaceGroupWeavesData): boolean => {
			return groupData.loaded;
		} ).length;
		const asMemberCount: number = this.groupWeaves.asMember.groups.length;
		const asMemberLoaded: number = this.groupWeaves.asMember.groups.filter( (groupData: InterfaceGroupWeavesData): boolean => {
			return groupData.loaded;
		} ).length;
		console.log( 'Fetching (User)<-(Group)<-(Members), asOwner: ' + asOwnerCount + '/' + asOwnerLoaded + ', asMember: ' + asMemberCount + '/' + asMemberLoaded );
		if ( asOwnerCount === asOwnerLoaded && asMemberCount === asMemberLoaded ) {
			this.groupWeaves.loaded = true;
			console.log( 'Fetched (User)<-(Group)<-(Members)' );
		}
	}

	public saveMember( member: InterfaceOWDoclet ): void {
		if ( member && member._id && member._id.$oid ) {
			this.owapi.workspace.doclets.updateDocletData( this.appConfig.getContext(), member._id.$oid, member.data ).subscribe( (_: InterfaceHTTPGateway): void => {
				// fire and forget
			} );
		}
	}

	public saveMemberByID( memberID: string, groupID?: string ): void {
		let member: InterfaceOWDoclet | undefined = undefined;
		if ( groupID ) {
			const groupWeave: InterfaceGroupWeavesData | undefined = this.groupWeaves.asOwner.groups.filter( (gw: InterfaceGroupWeavesData): boolean => {
				return 'groupDocletID' in gw && gw.groupDocletID === groupID;
			} ).pop();
			if ( groupWeave ) {
				member = groupWeave.members.filter( (member: InterfaceOWDoclet): boolean => {
					return member._id.$oid === memberID;
				} ).pop();
			}
		} else {
			for ( let x: number = 0; !member && x < this.groupWeaves.asOwner.groups.length; ++x ) {
				for ( let y: number = 0; !member && y < this.groupWeaves.asOwner.groups[x].members.length; ++y ) {
					if ( this.groupWeaves.asOwner.groups[x].members[y]._id.$oid === memberID ) {
						member = this.groupWeaves.asOwner.groups[x].members[y];
					}
				}
			}
		}
		if ( member && member.data ) {
			this.saveMember( member );
		} else {
			console.log( 'No member found by ID', memberID );
		}
	}

	public TEMPCODE_addMember( groupID: string, memberData: InterfaceAnyObject ): void {
		if ( this.busy ) {
			return;
		}
		const appContext: InterfaceAppContext = this.appConfig.getContext();
		const group: InterfaceGroupWeavesData | undefined = this.groupWeaves.asOwner.groups.filter( (g: InterfaceGroupWeavesData): boolean => {
			return g.groupDocletID === groupID;
		} ).pop();
		if ( group && group.groupDoclet && group.groupDoclet.data && group.groupDoclet.data ) {
			const groupDocletData: InterfaceOWTemplateGroup = group.groupDoclet.data as InterfaceOWTemplateGroup;
			if ( group.members.length < groupDocletData.max_members ) {
				this.busy = true;
				// TODO: this may get replaced by server-side workflow, for inviting a member to a group.
				this.owapi.workspace.doclets.createDoclet( appContext, 'Group Member', memberData, this.strConsumerTemplateID ).subscribe( (cResponse: InterfaceHTTPGateway): void => {
					let failed: boolean = true;
					if ( cResponse && cResponse.success ) {
						const cAPIResponse: InterfaceOWAPICreateDocletResponse = cResponse.data;
						if ( cAPIResponse && cAPIResponse.data && cAPIResponse.data.doclet_id ) {
							failed = false;
							const memberID: string = cAPIResponse.data.doclet_id;
							this.owapi.workspace.doclets.createWeave( appContext, groupID, {
								role: 'Group Member'
							}, memberID ).subscribe( (w1Response: InterfaceHTTPGateway): void => {
								failed = true;
								if ( w1Response && w1Response.success ) {
									const w1APIResponse: InterfaceOWAPICreateWeaveResponse = w1Response.data;
									if ( w1APIResponse && w1APIResponse.data && Array.isArray( w1APIResponse.data.items ) && w1APIResponse.data.items.length > 0 ) {
										failed = false;
										this.owapi.workspace.doclets.createWeave( appContext, memberID, {
											role: 'Group Member'
										}, groupID ).subscribe( (w2Response: InterfaceHTTPGateway): void => {
											if ( w2Response && w2Response.success ) {
												const w2APIResponse: InterfaceOWAPICreateWeaveResponse = w2Response.data;
												if ( w2APIResponse && w2APIResponse.data && Array.isArray( w2APIResponse.data.items ) && w2APIResponse.data.items.length > 0 ) {
													failed = false;
												}
											}
											if ( failed ) {
												console.log( 'Failed to weave the Group to the Member (part 2)' );
												this.busy = false;
											} else {
												this.owapi.workspace.doclets.getDocletByID( appContext, memberID ).subscribe( (responseGetMember: InterfaceHTTPGateway): void => {
													if ( responseGetMember?.success ) {
														const apiResponseGetMember: InterfaceOWAPIGetDocletResponse | undefined = responseGetMember?.data;
														if ( apiResponseGetMember?.data?._id?.$oid ) {
															const member: InterfaceOWDoclet = apiResponseGetMember.data;
															if ( member && member._id && group && Array.isArray( group.members ) ) {
																group.members.push( member as InterfaceOWDocletConsumer );
																this.busy = false;
															}
														}
													}
												} );
											}
										} );
									}
								}
								if ( failed ) {
									console.log( 'Failed to weave the Member to the Group (part 1)' );
									this.busy = false;
								}
							} );
						}
					}
					if ( failed ) {
						console.log( 'Could not create a new Consumer doclet to become a Group Member.' );
						this.busy = false;
					}
				} );
			} else {
				console.log( 'Too many members in group, skipping adding a new one.' );
			}
		} else {
			console.log( 'No group found by ID to add a new member.' );
		}
	}

	public TEMPCODE_removeMember( groupID: string, memberID: string ): void {
		// TODO: this.
		console.log( 'missing API to remove weave on doclet', groupID, memberID );
		// this.owapi.workspace.doclets.removeWeave( ...
	}

	public showInviteModal( groupID: string | null ): void {
		if ( typeof groupID === 'string' && groupID.length > 0 ) {
			this.inviteGroupID = groupID;
			this.appEvents.broadcast( 'modal:open:invite-group-member' );
		}
	}

	public hideInviteModal(): void {
		this.appEvents.broadcast( 'modal:close:invite-group-member' );
	}

	public showEditModal( memberDoclet: InterfaceOWDoclet ): void {
		this.appEvents.broadcast( {
			topic: 'modal:open:edit-group-member',
			data: {
				memberDoclet: memberDoclet
			}
		} );
	}

	public hideEditModal(): void {
		this.appEvents.broadcast( 'modal:close:edit-group-member' );
	}
}
