import {Injectable} from '@angular/core';
import {BaseConfig} from '../../configs/base-config';
//
@Injectable( {
	providedIn: 'root'
} )
export class AppConfig extends BaseConfig {
	public constructor() {
		super();
	}

	public getAppShortName(): string {
		return this.appShortName;
	}

	public getBaseAPI(): string {
		return this.baseAPI;
	}

	public getRealmID( byName?: string ): string {
		if ( byName ) {
			if ( !this.realmIDs.hasOwnProperty( byName ) ) {
				console.log( 'Missing ID for realm:', byName );
			}
			return this.realmIDs[ byName ] ?? '';
		}
		return this.realmID;
	}

	// public setWorkspaceID( id: string ): void {
	// 	this.workspaceID = id;
	// }

	public getWorkspaceID(): string {
		return this.workspaceID;
	}

	public getIOHost(): string {
		return this.ioHost;
	}

	public getTemplateID( name: string ): string {
		if ( this.templateIDs.hasOwnProperty( name ) ) {
			return this.templateIDs[ name ];
		}
		console.log( 'No template ID for bad template name:', name );
		return '';
	}

	public getRoleID( name: string ): string {
		return this.roleIDs[name] ?? '';
	}

	public getSettingsID( name: string ): string {
		return this.settingsIDs[name] ?? '';
	}

	public getMode(): string {
		return this.mode;
	}
}
