import {Pipe, PipeTransform} from '@angular/core';
// ===== Interfaces ===== //
import {InterfaceOWDoclet} from '../interfaces/interfaces';
// ===== Transformers ===== //
import {TransformerTransactions} from '../transformers/transactions';
//
@Pipe({
	name: 'transactionAction',
	pure: true
})
export class PipeTransactionAction implements PipeTransform {
	public transform( transaction: InterfaceOWDoclet ): string {
		return TransformerTransactions.getAction( transaction );
	}
}
