import {Pipe, PipeTransform} from '@angular/core';
// ===== Interfaces ===== //
import {InterfaceVenuePassportTicketPriceType} from '../interfaces/interfaces';
// ===== Transformers ===== //
import {TransformerVenuePassportTicket} from '../transformers/vpTicket';
//
@Pipe( {
	name: 'passPriceTime',
	pure: true
} )
export class PipePassPriceTime implements PipeTransform {
	// passDoclet.data['price'] | passPriceTime : '2023-06-06'
	public transform( ticketPricing: InterfaceVenuePassportTicketPriceType | undefined, selectedYYYYMMDD1?: string ): string {
		return TransformerVenuePassportTicket.getTicketTimeDisplayFromPriceType( ticketPricing, selectedYYYYMMDD1 );
	}
}
