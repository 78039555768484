import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, ParamMap} from '@angular/router';
// ===== App ===== //
import {AppConfig} from '../../app.config';
import {AppRouterLinks} from '../../app.router-links';
// ===== Interfaces ===== //
import {InterfaceHTTPGateway} from '../../interfaces/interfaces';
interface InterfaceCompleteAccountData {
	firstName: string;
	lastName: string;
	password1: string;
	password2: string;
	phone: string;
	street: string;
	unit: string;
	city: string;
	state: string;
	zip: string;
	dob: string;
	driversLicense: string;
	photo: string;
}
interface InterfaceCompleteAccountErrors {
	firstName: boolean;
	lastName: boolean;
	password1: boolean;
	password2: boolean;
	phone: boolean;
	street: boolean;
	unit: boolean;
	city: boolean;
	state: boolean;
	zip: boolean;
	dob: boolean;
	driversLicense: boolean;
}
// ===== Services ===== //
import {ServiceAuthentication} from '../../services/authentication';
import {ServiceNavigate} from '../../services/navigate';
import {ServiceOWAPI} from '../../services/ow-api';
import {ServiceRegex} from '../../services/regex';
//
@Component( {
	selector: 'page-complete-account',
	templateUrl: './complete-account.html',
	styleUrls: [
		'./complete-account.less'
	]
} )
export class PageCompleteAccount implements OnInit { // on success, submitting this also confirms their e-mail address.
	public routes: typeof AppRouterLinks = AppRouterLinks;
	public accountToken: string = '';
	public formShown: boolean = true;
	public invalidToken: boolean = false; // TODO: use a bad code and see what happens. HTTP 500? 400? 200?!?
	public accountCompleted: boolean = false;
	public accountAlreadyCompleted: boolean = false; // we won't know if this is the case, until after the user submits their form...
	public completeAccountData: InterfaceCompleteAccountData = {
		firstName: '',
		lastName: '',
		password1: '',
		password2: '',
		phone: '',
		street: '',
		unit: '',
		city: '',
		state: '',
		zip: '',
		dob: '',
		driversLicense: '',
		photo: ''
	};
	public completeAccountErrors: InterfaceCompleteAccountErrors = {
		firstName: false,
		lastName: false,
		password1: false,
		password2: false,
		phone: false,
		street: false,
		unit: false,
		city: false,
		state: false,
		zip: false,
		dob: false,
		driversLicense: false
	};
	//
	public constructor(
		private readonly activeRoute: ActivatedRoute,
		private readonly appConfig: AppConfig,
		private readonly auth: ServiceAuthentication,
		private readonly nav: ServiceNavigate,
		private readonly owapi: ServiceOWAPI,
		private readonly title: Title
	) {
		this.title.setTitle( 'Wild Rivers Portal' );
	}

	public ngOnInit(): void {
		if ( this.auth.isSignedIn() ) {
			this.nav.toURL( '/' + this.routes.dashboard );
		} else { // else the user is not yet signed in.
			this.processAccountToken();
		}
	}

	private getToken(): void {
		if ( this.activeRoute.snapshot.params && typeof this.activeRoute.snapshot.params['token'] === 'string' && this.activeRoute.snapshot.params['token'].length > 0 ) {
			// from the route: /registration/complete/:token
			this.accountToken = this.activeRoute.snapshot.params['token'];
		} else {
			// else from the route: /registration/complete?token=
			this.activeRoute.queryParamMap.subscribe( (query: ParamMap): void => {
				const token: string | null = query.get( 'token' );
				if ( typeof token === 'string' && token.length > 0 ) {
					this.accountToken = token;
				}
			} );
		}
	}

	private processAccountToken(): void {
		this.getToken();
		if ( !this.accountToken || this.accountToken.length < 1 ) {
			this.nav.toURL( '/' + this.routes.dashboard );
		}
	}

	public validatePassword( key: keyof InterfaceCompleteAccountData & keyof InterfaceCompleteAccountErrors ): void {
		this.completeAccountErrors[key] = this.completeAccountData[key].length < 8;
	}

	public comparePasswords(): void {
		if ( this.completeAccountData.password1.length > 0 ) {
			if ( this.completeAccountData.password2.length > 0 ) {
				this.completeAccountErrors.password1 = this.completeAccountData.password1 !== this.completeAccountData.password2;
				this.completeAccountErrors.password2 = this.completeAccountData.password1 !== this.completeAccountData.password2;
			}
		}
	}
	
	public validateData( key: keyof InterfaceCompleteAccountData ): void {
		switch ( key ) {
			case 'firstName': {
				this.completeAccountData.firstName = this.completeAccountData.firstName.replace( ServiceRegex.trimRegExp, '' );
				this.completeAccountErrors.firstName = this.completeAccountData.firstName.length < 1;
				break;
			}
			case 'lastName': {
				this.completeAccountData.lastName = this.completeAccountData.lastName.replace( ServiceRegex.trimRegExp, '' );
				this.completeAccountErrors.lastName = this.completeAccountData.lastName.length < 1;
				break;
			}
			case 'password1': {
				this.completeAccountErrors.password1 = this.completeAccountData.password1.length < 1;
				if ( this.completeAccountData.password2.length > 0 ) {
					this.completeAccountErrors.password1 = this.completeAccountData.password1 !== this.completeAccountData.password2;
					this.completeAccountErrors.password2 = this.completeAccountData.password1 !== this.completeAccountData.password2;
				}
				break;
			}
			case 'password2': {
				this.completeAccountErrors.password2 = this.completeAccountData.password2.length < 1;
				if ( this.completeAccountData.password2.length > 0 ) {
					if ( this.completeAccountData.password1 !== this.completeAccountData.password2 ) {
						this.completeAccountErrors.password1 = this.completeAccountData.password1 !== this.completeAccountData.password2;
						this.completeAccountErrors.password2 = this.completeAccountData.password1 !== this.completeAccountData.password2;
					}
				}
				break;
			}
			case 'phone': {
				this.completeAccountErrors.phone = this.completeAccountData.phone.replace( /\D/g, '' ).length < 10; // "(123) 456-7890".replace( /non-digits/, "" )
				break;
			}
			case 'street': {
				this.completeAccountErrors.street = this.completeAccountData.street.length < 1;
				break;
			}
			case 'unit': {
				this.completeAccountErrors.unit = false;
				break;
			}
			case 'city': {
				this.completeAccountErrors.city = this.completeAccountData.city.length < 1;
				break;
			}
			case 'state': { // TODO: needs to be a drop-down.
				this.completeAccountErrors.state = this.completeAccountData.state.length < 2;
				break;
			}
			case 'zip': {
				this.completeAccountData.zip.replace( /-+$/, '' ); // remove any trailing dashes, so the dumb matcher on the next line won't think it's valid.
				this.completeAccountErrors.zip = !this.completeAccountData.zip.match( /^\d\d\d\d\d(?:-\d+)?$/ ); // "12345" or "12345-0000"
				break;
			}
			case 'dob': {
				this.completeAccountErrors.dob = this.completeAccountData.dob.length < 8; // should be < 10 but i'm not sure how <input type="date" /> will always behave.
				break;
			}
			case 'driversLicense': {
				// shouldn't be required to own a car to go to the water park.
				this.completeAccountErrors.driversLicense = false; // this.completeAccountData.driversLicense.length < 3;
				break;
			}
		}
	}

	public clearError( key: keyof InterfaceCompleteAccountErrors ): void {
		if ( this.completeAccountErrors.hasOwnProperty( key ) ) {
			this.completeAccountErrors[key] = false;
		}
	}

	public completeRegistration(): void {
		const eKeys: (keyof InterfaceCompleteAccountErrors)[] = Object.keys( this.completeAccountErrors ) as (keyof InterfaceCompleteAccountErrors)[];
		let hasErrors: boolean = false;
		for ( let x: number = 0; x < eKeys.length; ++x ) {
			this.validateData( eKeys[x] );
			hasErrors ||= this.completeAccountErrors[ eKeys[x] ];
		}
		if ( !hasErrors ) {
			this.owapi.account.registration.completeInvite(
				this.appConfig.getContext(),
				this.accountToken,
				this.completeAccountData.password1,
				this.appConfig.getTemplateID( 'Consumer' ),
				{
					'first_name': this.completeAccountData.firstName,
					'last_name': this.completeAccountData.lastName,
					// do not supply email. server-side wouldn't use it anyway.
					'phone': this.completeAccountData.phone,
					'street_address': this.completeAccountData.street,
					'suite': this.completeAccountData.unit,
					'city': this.completeAccountData.city,
					'state': this.completeAccountData.state,
					'zip': this.completeAccountData.zip,
					'dob': this.completeAccountData.dob,
					'drivers_license': this.completeAccountData.driversLicense
					// no photo required.
				}
			).subscribe( (response: InterfaceHTTPGateway): void => {
				if ( response ) {
					if ( response.success && response.status === 201 ) {
						this.formShown = false;
						this.accountCompleted = true;
					} else if ( response.status === 409 ) {
						// user already completed the registration process.
						this.formShown = false;
						this.accountAlreadyCompleted = true;
					} else { // } else if ( response.status === 500 ) {
						this.invalidToken = true; // pretend the token is at fault.
						console.log( response );
					}
				} else {
					alert( 'An error occurred. Please try again later.' );
				}
			} );
			this.formShown = false;
		}
	}

	public blur( E: Event ): void { // typescript error: $event.target.blur is not a function
		(E.target as HTMLElement).blur(); // because $event.target is an unknown type... typescript fail.
	}
}
