import {Pipe, PipeTransform} from "@angular/core";
//
@Pipe( {
	name: 'pluck'
} )
export class PipePluck implements PipeTransform {
	public transform( input: any[], key: string, prefix: string = '', suffix: string = '' ): any {
		return input.filter( (e: any): any => {
			return e && e[key];
		} ).map( (value: any) => {
			return prefix + value[key] + suffix;
		} );
	}
}
