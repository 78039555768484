import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges} from '@angular/core';
// ===== Interfaces ===== //
import {InterfaceDocletIDToTicketProps_T, InterfaceVenuePassportTicketPriceType} from '../../interfaces/interfaces';
interface InterfaceDocletIDToTicketProps extends InterfaceDocletIDToTicketProps_T<InterfaceVenuePassportTicketPriceType> {}
interface InterfaceYYYYMM1DD {
	year: number;
	month1: number; // 1 though 12
	day: number; // 0 through 31.
}
interface InterfaceCalendarData {
	year: number;
	month1: number; // 1 to 12
	leadingBlanks: undefined[];
	daysInMonth: number[];
	trailingBlanks: undefined[];
	blockedDays: { [day: string]: true; };
	weeksInMonth: undefined[];
}
//
const now: Date = new Date();
//
@Component({
	selector: 'app-calendar-embed',
	templateUrl: './calendar-embed.html',
	styleUrls: [
		'./calendar-embed.less'
	]
})
export class ComponentCalendarEmbed implements OnChanges, OnInit {
	public readonly thisYear: number = now.getFullYear();
	public readonly thisMonth1: number = now.getMonth() + 1;
	public readonly thisDay: number = now.getDate();
	@Input() public calendarsToShow: number = 2; //
	public arrCalendarDisplayIdx: number[] = []; // just a junk array to control how many loops for an *ngFor.
	@Input() public selectedYYYYMMDD: InterfaceYYYYMM1DD | string | undefined = undefined;
	public selectedYear: number = now.getFullYear();
	public selectedMonth1: number = 1 + now.getMonth(); // 1 through 12
	public selectedDay: number = now.getDate(); // 1 through 31
	@Input() public displayYear: number = new Date().getFullYear(); // basically it's which year the user is looking at on the calendar.
	// navigating through months ought to change the displayed date, but not change what's selected.
	@Input() public displayMonth1: number = 1 + new Date().getMonth(); // basically which month is on the (top or left)-most panel displayed to the user.
	@Input() public boundaryDateBegin: InterfaceYYYYMM1DD | undefined = undefined;
	@Input() public boundaryDateEnd: InterfaceYYYYMM1DD | undefined = undefined;
	@Input() public blockedDates: string[] = []; // YYYY-MM-DD // the park is closed on certain days...
	public calendarDatesBlocked: { [YYYYMMDD: string]: true; } = {}; // 20230804
	@Input() public passProps: InterfaceDocletIDToTicketProps | undefined = undefined; // the selected pass-props to use, when figuring out and displaying price tags per day.
	@Output() public dayChanged: EventEmitter<InterfaceYYYYMM1DD> = new EventEmitter<InterfaceYYYYMM1DD>();
	@Output() public monthChanged: EventEmitter<void> = new EventEmitter<void>();
	public readonly monthLabels: string[] = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
	public calendarData: InterfaceCalendarData[] = [];

	private isIFaceYMD( inputVar: unknown ): inputVar is InterfaceYYYYMM1DD { // type narrowing using type predicates.
		return typeof inputVar === 'object' && inputVar !== null && 'year' in inputVar && 'month1' in inputVar && 'day' in inputVar;
	}

	public constructor() {
		//
	}

	private updateCalendar(): void {
		// this recalculates everything.
		// it shouldn't be used when simply showing the next/prev month.
		// showing the next/prev month should be moving through what this creates.
		this.calendarData = [];
		const startAt: InterfaceYYYYMM1DD = this.boundaryDateBegin ? this.boundaryDateBegin : {
			year: now.getFullYear(),
			month1: 1 + now.getMonth(),
			day: now.getDay()
		};
		const stopAt: InterfaceYYYYMM1DD = this.boundaryDateEnd ? this.boundaryDateEnd : {
			year: now.getFullYear(),
			month1: 1 + now.getMonth(),
			day: new Date( now.getFullYear(), now.getMonth() + 1, 0 ).getDate() // the 1st of the next month, except it becomes "last night".
		};
		for ( let YYYY: InterfaceCalendarData['year'] = startAt.year; YYYY <= stopAt.year; ++YYYY ) {
			let startMM1: InterfaceCalendarData['month1'] = YYYY === startAt.year ? startAt.month1 : 1;
			const stopMM1: InterfaceCalendarData['month1'] = YYYY === stopAt.year
				? stopAt.month1 // if we're only going for the same year, then stop at the month defined by stopAt
				: ( // otherwise go all the way to month 12 unless it's the same year defined by stopAt.
					startAt.year < stopAt.year
					? 12
					: stopAt.month1
				)
			for ( ; startMM1 <= stopMM1; ++startMM1 ) {
				const leadingBlanks: InterfaceCalendarData['leadingBlanks'] = new Array( new Date( YYYY, startMM1 - 1, 1 ).getDay() ); // MM1 is 1 to 12 but new Date() wants 0 to 11.
				const daysInMonth: InterfaceCalendarData['daysInMonth'] = Array.from( { length: new Date( YYYY, startMM1, 0 ).getDate() } ).map( (_, idx: number): number => idx + 1 ); // [ 1, 2, 3, ..., 30, 31 ]
				const trailingBlanks: InterfaceCalendarData['trailingBlanks'] = new Array( Math.ceil( (leadingBlanks.length + daysInMonth.length) / 7 ) * 7 - leadingBlanks.length - daysInMonth.length );
				const blockedDates: InterfaceCalendarData['blockedDays'] = {};
				// ===== blocking past dates as needed. (out of bounds) ===== //
				if ( this.boundaryDateBegin ) {
					if ( YYYY < this.boundaryDateBegin.year || (YYYY === this.thisYear && startMM1 < this.boundaryDateBegin.month1) ) {
						for ( let dim: number = 0; dim < daysInMonth.length; ) {
							blockedDates[ String( ++dim ) ] = true;
						}
					} else if ( YYYY === this.boundaryDateBegin.year && startMM1 === this.boundaryDateBegin.month1 ) {
						for ( let dayOfMonth: number = 1; dayOfMonth < this.thisDay; ++dayOfMonth ) {
							if ( dayOfMonth < this.thisDay ) {
								blockedDates[ String( dayOfMonth ) ] = true;
							}
						}
					}
				}
				// ===== blocking future dates as needed. (out of bounds) ===== //
				if ( this.boundaryDateEnd ) {
					if ( YYYY > this.boundaryDateEnd.year || (YYYY === this.boundaryDateEnd.year && startMM1 > this.boundaryDateEnd.month1) ) {
						for ( let dayOfMonth: number = 0; dayOfMonth < daysInMonth.length; ) {
							blockedDates[ String( ++dayOfMonth ) ] = true;
						}
					} else if ( YYYY === this.boundaryDateEnd.year && startMM1 === this.boundaryDateEnd.month1 ) {
						for ( let dayOfMonth: number = 1; dayOfMonth <= daysInMonth.length; ++dayOfMonth ) {
							if ( dayOfMonth > this.boundaryDateEnd.day ) {
								blockedDates[ String( dayOfMonth ) ] = true;
							}
						}
					}
				}
				// TODO: calculate blocked days using the selected pass for sale.
				// ... or calculate it on the html side so that this doesn't have to re-run each time a user selects a pass?
				// each pass may have dates_blocked // [ YYYY-MM-DD, YYYY-MM-DD, ... ]
				// and/or it may have dates_valid // [ YYYY-MM-DD, YYYY-MM-DD, ... ]
				this.calendarData.push( {
					year: YYYY,
					month1: startMM1,
					leadingBlanks: leadingBlanks,
					daysInMonth: daysInMonth,
					trailingBlanks: trailingBlanks,
					blockedDays: blockedDates,
					weeksInMonth: new Array( (leadingBlanks.length + daysInMonth.length + trailingBlanks.length) / 7 )
				} );
			}
		}
		console.log( 'pre-calculated calendar month data junk things and stuff', this.calendarData, this.boundaryDateEnd );
	}

	public ngOnChanges( changes: SimpleChanges ): void {
		// OnInit happens after initial changes occur. ngOnChanges runs first.
		// but the [values] passed into the comp are already accessible, even though ngOnChanges hasn't yet fired.
		if ( changes.hasOwnProperty( 'blockedDates' ) ) {
			this.calendarDatesBlocked = {};
			const rxYMD: RegExp = /^\d\d\d\d-\d\d-\d\d$/;
			for ( let x: number = 0; x < this.blockedDates.length; ++x ) {
				if ( rxYMD.test( this.blockedDates[x] ) ) {
					this.calendarDatesBlocked[ this.blockedDates[x] ] = true;
				}
			}
		}
		//
		if ( changes.hasOwnProperty( 'passProps' ) ) {
			const ppc: SimpleChange = changes['passProps'];
			const oldDocletID: string | undefined = ppc.previousValue && ppc.previousValue.hasOwnProperty( 'doclet' ) ? ppc.previousValue?.doclet?._id?.$oid : undefined;
			const newDocletID: string | undefined = ppc.currentValue && ppc.currentValue.hasOwnProperty( 'doclet' ) ? ppc.currentValue?.doclet?._id?.$oid : undefined;
			if ( ppc.firstChange && !oldDocletID && !newDocletID ) {
				this.updateCalendar();
			} else {
				if ( !newDocletID || (oldDocletID && newDocletID && oldDocletID !== newDocletID ) ) {
					// if !newDocletID => the selected pass properties was removed. is now a basic calendar...
					// if old !== new => the selected pass props were changed.
					this.updateCalendar();
				}
			}
		}
		// must be after this.updateCalendar() because it uses the new length of this.calendarData.length
		if ( changes.hasOwnProperty( 'calendarsToShow' ) ) {
			this.arrCalendarDisplayIdx = Array.from( { length: Math.min( this.calendarData.length, Math.max( 1, this.calendarsToShow ) ) } ).map( (_, idx: number): number => idx );
		}
	}

	public ngOnInit(): void {
		// initial changes happen first, then OnInit is fired. see ngOnChanges
		if ( typeof this.selectedYYYYMMDD === 'string' && /^\d\d\d\d-\d\d-\d\d$/.test( this.selectedYYYYMMDD ) ) {
			// YYYY-MM-DD where MM is 01 to 12
			const arrYYYYMMDD: string[] = this.selectedYYYYMMDD.split( /-/g );
			this.selectedYear = Number( arrYYYYMMDD[0] );
			this.selectedMonth1 = Number( arrYYYYMMDD[1] );
			this.selectedDay = Number( arrYYYYMMDD[2] );
		} else if ( this.selectedYYYYMMDD && this.isIFaceYMD( this.selectedYYYYMMDD ) ) {
			// is an object, carry over the props.
			this.selectedYear = this.selectedYYYYMMDD.year;
			this.selectedMonth1 = this.selectedYYYYMMDD.month1;
			this.selectedDay = this.selectedYYYYMMDD.day;
		} // else is not defined. stays defaulted to (now)
		//
		this.arrCalendarDisplayIdx = Array.from( { length: Math.min( this.calendarData.length, Math.max( 1, this.calendarsToShow ) ) } ).map( (_, idx: number): number => idx );
	}

	public selectDay( YYYY: number, MM1: number, DD: number ): void {
		// YYYY could just be the displayed year (this.displayYear)
		// MM1 could be (this.displayMonth1)
		// but it's possible multiple calendar panels are going to be showing...
		//
		// rather than always passing them in, the values can be taken from displayYear and displayMonth1.
		// or: ngFor="let y = calendarData[x].daysInMonth;"
		// (click)="selectDay( calendarMonthData.year, calendarMonthData.month1, y );"
		this.selectedYear = YYYY;
		this.selectedMonth1 = MM1;
		this.selectedDay = DD;
		//
		this.dayChanged.emit( {
			year: this.selectedYear,
			month1: this.selectedMonth1,
			day: this.selectedDay
		} );
	}
}
