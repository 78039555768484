<header id="masthead"
	[ngClass]="{
		'minimized': didPickDate
	}">
	<div class="wrapper logo-bar">
		<img class="logo" alt="Wild Rivers Logo" src="assets/img/WR_MAINLOGO.png">
		<div class="cart-toggle">
			<i class="cart-icon fa-solid fa-cart-shopping"></i>
			<ul class="contents">
				<li class="price">{{ grandTotal | currency : 'USD' : 'symbol-narrow' : '1.0-0' }}</li>
				<li class="quantity">{{ cartCount }} Items</li>
			</ul>
		</div>
	</div>
	<div class="wrapper cta-wrapper">
		<h1>Buy 2023 Season Passes & Tickets</h1>
		<h2>Something about selecting a date to get started or something, which will go away once they pick one, one way or another.</h2>
		<div class="filter-bar" tabindex="0">
			<div class="input-wrapper">
				<i class="fa-solid fa-calendar"></i>
				<input type="text" [value]="monthLabels[ selectedDate.month1 - 1 ] + ' ' + selectedDate.day + ', ' + selectedDate.year" />
				<i class="fa-solid fa-chevron-down"></i>
			</div>
			<div class="calendar-frame">
				<app-calendar-embed
					[selectedYYYYMMDD]="selectedDate"
					[boundaryDateBegin]="calendarRangeStart"
					[boundaryDateEnd]="calendarRangeEnd"
					(dayChanged)="daySelected( $event );"
					[passProps]="selectedPassForCalendar"
				></app-calendar-embed>
			</div>
		</div>
	</div>
</header>
<div class="wrapper">
	<section id="listing-passes" class="list-tickets">
		<div class="ticket type-pass"
			*ngIf="seasonPassT1Renewal && allowedSAPQuantityTotal > 0"
		>
			<div class="ticket-header">
				<span class="title">Silver Renewal</span>
				<span class="price">{{ getSeasonPassPrice( seasonPassT1Renewal._id.$oid ) | currency : 'USD' : 'symbol-narrow' : '1.0-0' }}<em>/ea</em></span>
				<span class="button">Buy Now</span>
				<span class="note">Some note here about taxes or whatever.</span>
			</div>
			<div class="ticket-body">Something</div>
		</div>
		<div class="ticket type-pass"
			*ngIf="seasonPassT2Renewal && allowedSAPQuantityTotal > 0"
		>
			<div class="ticket-header">
				<span class="title">Gold Renewal</span>
				<span class="price">{{ getSeasonPassPrice( seasonPassT2Renewal._id.$oid ) | currency : 'USD' : 'symbol-narrow' : '1.0-0' }}<em>/ea</em></span>
				<span class="button">Buy Now</span>
				<span class="note">Some note here about taxes or whatever.</span>
			</div>
			<div class="ticket-body">Something</div>
		</div>
		<div class="ticket type-pass"
			*ngIf="seasonPassT3Renewal && allowedSAPQuantityTotal > 0"
		>
			<div class="ticket-header">
				<span class="title">Diamond Renewal</span>
				<span class="price">{{ getSeasonPassPrice( seasonPassT3Renewal._id.$oid ) | currency : 'USD' : 'symbol-narrow' : '1.0-0' }}<em>/ea</em></span>
				<span class="button">Buy Now</span>
				<span class="note">Some note here about taxes or whatever.</span>
				<span class="callout"><i class="icon fa-solid fa-bolt"></i> Most Popular</span>
			</div>
			<div class="ticket-body">[HTML AREA, List, etc.]</div>
		</div>
		<div class="ticket type-pass"
				 *ngIf="seasonPassT1"
		>
			<div class="ticket-header">
				<span class="title">{{ seasonPassT1.data['name'] }}</span>
				<span class="price">{{ getSeasonPassPrice( seasonPassT1._id.$oid ) | currency : 'USD' : 'symbol-narrow' : '1.0-0' }}<em>/ea</em></span>
				<span class="button">Buy Now</span>
				<span class="note">Some note here about taxes or whatever.</span>
			</div>
			<div class="ticket-body">
				<ul class="sap-silver-perks entitlements"
					*ngIf="showingSAPEntitlements && (seasonPassT1Entitlements.includes.length > 0 || seasonPassT1Entitlements.excludes.length > 0)"
				>
					<li class="included"
						*ngFor="let entitlement of seasonPassT1Entitlements.includes;"
					><i class="far fa-circle-check"></i><span>{{ entitlement }}</span></li>
					<li class="excluded"
						*ngFor="let entitlement of seasonPassT1Entitlements.excludes;"
					><i class="far fa-circle-check"></i><span>{{ entitlement }}</span></li>
				</ul>
			</div>
		</div>
		<div class="ticket type-pass"
			*ngIf="seasonPassT2"
		>
			<div class="ticket-header">
				<span class="title">{{ seasonPassT2.data['name'] }}</span>
				<span class="price">{{ getSeasonPassPrice( seasonPassT2._id.$oid ) | currency : 'USD' : 'symbol-narrow' : '1.0-0' }}<em>/ea</em></span>
				<span class="button">Buy Now</span>
				<span class="note">Some note here about taxes or whatever.</span>
			</div>
			<div class="ticket-body">
				<ul class="sap-silver-perks entitlements"
					*ngIf="showingSAPEntitlements && (seasonPassT2Entitlements.includes.length > 0 || seasonPassT2Entitlements.excludes.length > 0)"
				>
					<li class="included"
						*ngFor="let entitlement of seasonPassT2Entitlements.includes;"
					><i class="far fa-circle-check"></i><span>{{ entitlement }}</span></li>
					<li class="excluded"
						*ngFor="let entitlement of seasonPassT2Entitlements.excludes;"
					><i class="far fa-circle-check"></i><span>{{ entitlement }}</span></li>
				</ul>
			</div>
		</div>
		<div class="ticket type-pass"
			*ngIf="seasonPassT3"
		>
			<div class="ticket-header">
				<span class="title">{{ seasonPassT3.data['name'] }}</span>
				<span class="price">{{ getSeasonPassPrice( seasonPassT3._id.$oid ) | currency : 'USD' : 'symbol-narrow' : '1.0-0' }}<em>/ea</em></span>
				<span class="button">Buy Now</span>
				<span class="note">Some note here about taxes or whatever.</span>
				<span class="callout"><i class="icon fa-solid fa-bolt"></i> Most Popular</span>
			</div>
			<div class="ticket-body">
				<ul class="sap-silver-perks entitlements"
					*ngIf="showingSAPEntitlements && (seasonPassT3Entitlements.includes.length > 0 || seasonPassT3Entitlements.excludes.length > 0)"
				>
					<li class="included"
						*ngFor="let entitlement of seasonPassT3Entitlements.includes;"
					><i class="far fa-circle-check"></i><span>{{ entitlement }}</span></li>
					<li class="excluded"
						*ngFor="let entitlement of seasonPassT3Entitlements.excludes;"
					><i class="far fa-circle-check"></i><span>{{ entitlement }}</span></li>
				</ul>
			</div>
		</div>
		<div class="ticket type-pass"
			*ngFor="let spp of seasonParkingPasses;"
		>
			<div class="ticket-header">
				<span class="title">{{ spp.data['name'] }}</span>
				<span class="price">{{ getSeasonPassPrice( spp._id.$oid ) | currency : 'USD' : 'symbol-narrow' : '1.0-0' }}<em>/ea</em></span>
				<span class="button">Buy Now</span>
				<span class="note">Some note here about taxes or whatever.</span>
			</div>
			<div class="ticket-body"></div>
		</div>
	</section>
	<p class="notice">Season Passes are valid any 2023 normal operating day. Each guest must have a valid 2023 Season Pass for entry. Season Passes are non-refundable. Once activated, Season Passes are non-transferable. See terms and conditions for more details.</p>
</div>
<div class="color-band">
	<div class="wrapper">
		<span class="section-header">Daily Tickets</span>
		<span class="section-subheader">Daily tickets are only valid for the date chosen.</span>
		<section id="listing-daily" class="list-tickets grid">
			<div class="ticket type-pass"
				*ngFor="let pdap of promotedDailyAdmissionPasses;"
			>
				<div class="ticket-header">
					<span class="title">{{ pdap.data['name'] }}</span>
					<span class="price">{{ getTicketPrice( pdap._id.$oid, selectedDate.day, selectedDate.month1, selectedDate.day ) | currency : 'USD' : 'symbol-narrow' : '1.0-0' }}<em>/ea</em></span>
					<span class="button">Buy Now</span>
					<span class="note">Some note here about taxes or whatever.</span>
				</div>
			</div>
			<div class="ticket type-pass"
				*ngFor="let dap of dailyAdmissionPasses;"
			>
				<div class="ticket-header">
					<span class="title">{{ dap.data['name'] }}</span>
					<span class="price">{{ getTicketPrice( dap._id.$oid, selectedDate.day, selectedDate.month1, selectedDate.day ) | currency : 'USD' : 'symbol-narrow' : '1.0-0' }}<em>/ea</em></span>
					<span class="button">Buy Now</span>
					<span class="note">Some note here about taxes or whatever.</span>
				</div>
			</div>
			<div class="ticket type-pass"
				*ngFor="let complex of complexProducts;"
			>
				<div class="ticket-header">
					<span class="title">{{ complex.data['name'] }}</span>
					<span class="price">{{ getTicketPrice( complex._id.$oid, selectedDate.day, selectedDate.month1, selectedDate.day ) | currency : 'USD' : 'symbol-narrow' : '1.0-0' }}<em>/ea</em></span>
					<span class="button">Buy Now</span>
					<span class="note">Some note here about taxes or whatever.</span>
				</div>
			</div>
		</section>
	</div>
</div>

<div class="wrapper">
	<span class="section-header">Add-Ons</span>
	<span class="section-subheader">Daily tickets are only valid for the date chosen.</span>
	<section id="listing-addons" class="list-tickets grid">
		<div class="ticket type-pass"
			*ngFor="let dpp of dailyParkingPasses;"
		>
			<div class="ticket-header">
				<span class="title">{{ dpp.data['name'] }}</span>
				<span class="price">{{ getTicketPrice( dpp._id.$oid, selectedDate.day, selectedDate.month1, selectedDate.day ) | currency : 'USD' : 'symbol-narrow' : '1.0-0' }}<em>/ea</em></span>
				<span class="button">Buy Now</span>
				<span class="note">Some note here about taxes or whatever.</span>
			</div>
		</div>
		<div class="ticket type-pass"
			*ngFor="let cp of cabanaPasses;"
		>
			<div class="ticket-header">
				<span class="title">{{ cp.data['name'] }}</span>
				<span class="price">{{ getTicketPrice( cp._id.$oid, selectedDate.day, selectedDate.month1, selectedDate.day ) | currency : 'USD' : 'symbol-narrow' : '1.0-0' }}<em>/ea</em></span>
				<span class="button">Buy Now</span>
				<span class="note">Some note here about taxes or whatever.</span>
			</div>
		</div>
	</section>
</div>
