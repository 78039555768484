import {Pipe, PipeTransform} from '@angular/core';
// ===== Transformers ===== //
import {TransformerText} from '../transformers/text';
//
@Pipe( {
	name: 'ordinal'
} )
export class PipeOrdinal implements PipeTransform {
	public transform( n: number, keepNumber: boolean = true ): string {
		return (keepNumber ? n : '') + TransformerText.numOrdinal( n );
	}
}
