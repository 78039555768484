import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
// ===== App ===== //
import {AppConfig} from '../../app.config';
import {AppRouterLinks} from '../../app.router-links';
// ===== Collections ===== //
import {CollectionProfiles} from '../../collections/profiles';
// ===== Interfaces ===== //
import {
	InterfaceAppContext,
	InterfaceDocletIDToTicketProps_T,
	InterfaceHTTPGateway,
	InterfaceOWAPIBulkRecordRequest,
	InterfaceOWAPICancelledReservationItemsResponse,
	InterfaceOWAPIDailyAdmissionAvailabilityResponse,
	InterfaceOWAPIGetDocletResponse,
	InterfaceOWAPIGetWeavesResponse,
	InterfaceOWAPIOrderItems,
	InterfaceOWAPIPaginatedResponse_T,
	InterfaceOWAPIPromoCodeItems,
	InterfaceOWAPIPromoCodeItemsItem,
	InterfaceOWAPIPromoCodeResponse,
	InterfaceOWAPIReserveItemResponse,
	InterfaceOWAPITicketPriceByDatePriceTier,
	InterfaceOWCancelledReservationItems,
	InterfaceOWDailyAdmissionAvailability,
	InterfaceOWDailyReservedSeating,
	InterfaceOWDoclet,
	InterfaceOWReservedItem,
	InterfaceOWUser,
	InterfaceOWWeaveV2,
	InterfaceVenuePassportCartItem,
	InterfaceVenuePassportCartItemTicket,
	InterfaceVenuePassportCompactCartItems,
	InterfaceVenuePassportPass,
	InterfaceVenuePassportTicketPriceType,
	InterfaceVenuePassportTicketPricing
} from '../../interfaces/interfaces';
interface InterfaceSeasonPassEntitlements {
	includes: string[];
	excludes: string[];
}
interface InterfaceTicketSelectionFlags {
	isSeasonPass?: boolean; // special logic to ignore month and day, and possibly other things. ordinary stuff for season passes.
	isAnyDay?: boolean; // special logic to ignore month and day, but for non season passes.
	isComplexBundle?: boolean;
}
interface InterfaceTicketSelectionData {
	qty: number;
	locationID?: string; // cabana's have this.
}
interface InterfaceTicketSelections {
	seasonPasses: { // season admission and season parking.
		[passID: string]: InterfaceTicketSelectionData;
	};
	anyDayPasses: { // general & junior 'any' day tickets.
		[passID: string]: InterfaceTicketSelectionData;
	};
	dailyPasses: { // general admission, junior admission, cabana, parking.
		[passID: string]: InterfaceTicketSelectionData;
	};
	complexPasses: {
		[passID: string]: InterfaceTicketSelectionData;
	}
}
interface InterfaceDocletIDToTicketProps extends InterfaceDocletIDToTicketProps_T<InterfaceVenuePassportTicketPriceType> {}
// ===== Services ===== //
import {ServiceAuthentication} from '../../services/authentication';
import {ServiceOWAPI} from '../../services/ow-api';
import {ServiceSorting} from '../../services/sorting';
// ===== Transformers ===== //
import {TransformerVenuePassportTicket} from '../../transformers/vpTicket';
// ===== Types ===== //
type TypeProductTab = 'passes' | 'tickets' | 'cabanas' | string;
//
const now: Date = new Date();
const parkOpensOn2024Jan1st: Date = new Date( 2024, 0, 1, 0, 0, 0, 0 );
if ( now.getTime() < parkOpensOn2024Jan1st.getTime() ) {
	now.setTime( parkOpensOn2024Jan1st.getTime() );
}
const parkClosesOn2024Oct8th: Date = new Date( 2024, 9, 8, 23, 59, 59, 0 );
//
@Component({
	selector: 'page-buynow',
	templateUrl: './buynow.html',
	styleUrls: [
		'./buynow.less'
	]
})
export class PageBuyNow implements OnInit {
	public routes: typeof AppRouterLinks = AppRouterLinks;
	public isParkeClosed: boolean = new Date().getTime() > parkClosesOn2024Oct8th.getTime();
	public isSignedIn: boolean = false;
	public isSeasonPassHolder: boolean = false;
	public productTab: TypeProductTab = 'passes';
	public isSAPSilverForSale: boolean = false;
	public showingSAPEntitlements: boolean = false; // when true, it causes the gird layout to do bad things...
	private SPHCounter: number = 0; // the amount of detected consumers with a season pass holder...need to process them all to see if they have valid season passes, etc.
	public readonly currentSeasonYear: string = '2024';
	public readonly priorSeasonYear: string = '2023';
	public ticketSAPYearsActive: { [year: string]: boolean; } = {}; // if the user has a 2022 season pass that isn't cancelled, then we'll see {"2022":true} -- used for sph_pricing logic.
	public ticketSAPYearQuantity: { [year: string]: { [level: string]: number; }; } = {}; // this was used for the old 1:1 renewal logic.
	public totalQtySAPOwned20220223Max: number = 0; // sap renewals are supposed to be 1:any instead of 1:1
	public totalQtySAPOwned2024: number = 0; // basically how many passes were "renewed" because they own some 2024 passes...
	private ticketSPPYearActive: { [year: string]: boolean; } = {}; // not really used, other than logging.
	private ticketSPPYearQuantity: { [year: string]: number; } = {};
	public allowedSAPQuantityByTier: { [level: string]: number; } = {};
	public allowedSAPQuantityTotal: number = 0; // how many total passes can be renewed.
	public readonly monthLabels: string[] = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
	private readonly strWebRole: string = this.appConfig.getRoleID( 'Web' );
	private readonly strCabanaPassTemplateID: string = this.appConfig.getTemplateID( 'Cabana Pass' );
	private readonly strConsumerTemplateID: string = this.appConfig.getTemplateID( 'Consumer' );
	private readonly strDailyAdmissionPassTemplateID: string = this.appConfig.getTemplateID( 'Daily Admission Pass' );
	private readonly strFamilyTemplateID: string = this.appConfig.getTemplateID( 'Family' );
	private readonly strParkingPassTemplateID: string = this.appConfig.getTemplateID( 'Parking Pass' );
	private readonly strSeasonAdmissionPassTemplateID: string = this.appConfig.getTemplateID( 'Season Admission Pass' );
	private readonly strSeasonAdmissionTicketTemplateID: string = this.appConfig.getTemplateID( 'Season Admission Ticket' );
	private readonly strSeasonParkingPassTemplateID: string = this.appConfig.getTemplateID( 'Season Parking Pass' );
	private readonly strSeasonParkingTicketTemplateID: string = this.appConfig.getTemplateID( 'Season Parking Ticket' );
	private readonly strComplexProductTemplateID: string = this.appConfig.getTemplateID( 'Complex Product Pass' );
	// ===== calendar day-blocking pre-calc stuff ===== //
	public readonly intNowYYYYMMDD1: number = now.getFullYear() * 10000 + (now.getMonth() + 1) * 100 + now.getDate(); // month is 01 - 12.
	public strTargetYYYY_MM1: string = now.getFullYear() + '-' + String( '0' + (now.getMonth() + 1) ).slice( -2 ); // target year and month in "YYYY-MM" format. month is 01 - 12.
	public passIDToPassProps: { [passID: string]: InterfaceDocletIDToTicketProps; } = {}; // keep having to build up caches for the different properties and logic for things... meh
	// ===== calendar ui ===== //
	public noGoingBack: boolean = true; // true when we don't want the user going back to older months
	public noGoingForward: boolean = true; // true when we don't want the user to progress forwards through the months.
	public selectedYear: number = now.getFullYear(); // 2022 to 2039
	public selectedMonth: number = now.getMonth() + 1; // 1 to 12.
	public selectedDay: number = 0; // 1 to 31.
	public datesUsed: { [YYYYMMDD: string]: InterfaceVenuePassportCartItem; } = {};
	public seasonPassDatesUsed: {
		[YYYY: string]: InterfaceVenuePassportCartItem;
	} = {}; // atm users can only buy season passes for a single year, so the YYYY is only one possibility, for now..
	// ===== calendar cells ===== //
	public leadingBlanks: undefined[] = []; // amount of days to leave blank, before the 1st of the month shows up.
	public daysInMonth: undefined[] = [];
	public trailingBlanks: undefined[] = []; // remaining calendar cells after the end of the month.
	// ===== ticket availability ===== //
	public dailyAdmissionAvailability: InterfaceOWDailyAdmissionAvailability = {}; // [YYYY-MM-DD] : { location: int } // MM is 01 - 12
	public readonly ticketIDToAvailabilityKey: { [settings_key: string]: string; } = {};
	public availabilityLoaded: boolean = false;
	public dailyAdmissionPasses: InterfaceOWDoclet[] = []; // the passes for sale.
	public promotedDailyAdmissionPasses: InterfaceOWDoclet[] = [];
	public cabanaPasses: InterfaceOWDoclet[] = [];
	public parkingPasses: InterfaceOWDoclet[] = [];
	public seasonAdmissionPasses: InterfaceOWDoclet[] = [];
	public seasonPassT1: InterfaceOWDoclet | undefined = undefined; // Silver
	public seasonPassT1Entitlements: InterfaceSeasonPassEntitlements = {
		includes: [],
		excludes: []
	};
	public seasonPassT1Renewal: InterfaceOWDoclet | undefined = undefined; // Silver Renewal (doesn't exist)
	public seasonPassT2: InterfaceOWDoclet | undefined = undefined; // Gold
	public seasonPassT2Entitlements: InterfaceSeasonPassEntitlements = {
		includes: [],
		excludes: []
	};
	public seasonPassT2Renewal: InterfaceOWDoclet | undefined = undefined; // Gold Renewal
	public seasonPassT3: InterfaceOWDoclet | undefined = undefined; // Diamond
	public seasonPassT3Entitlements: InterfaceSeasonPassEntitlements = {
		includes: [],
		excludes: []
	};
	public seasonPassT3Renewal: InterfaceOWDoclet | undefined = undefined;
	public seasonAdmissionPassRenewals: InterfaceOWDoclet[] = [];
	public seasonParkingPasses: InterfaceOWDoclet[] = [];
	public complexProducts: InterfaceOWDoclet[] = [];
	public nothingToBuy: boolean = false; // for when things things just don't go as planned...
	// ===== ticket selection ===== //
	public ticketSelections_v2: InterfaceTicketSelections = {
		seasonPasses: {},
		anyDayPasses: {},
		dailyPasses: {},
		complexPasses: {}
	};
	private ticketHoldings: { [docletID: string]: string[]; } = {};
	// ===== shopping cart ===== //
	public busy: boolean = false; // true when we're setting/removing a temp-hold on tickets.
	// ===== cart details ===== //
	public cartItems: InterfaceVenuePassportCartItem[] = [];
	public cartCount: number = 0;
	public grandTotal: number = 0;
	public promoCode: string = '';
	public invalidPromoCode: boolean = false;
	public discountAmount: number = 0;
	// ===== cart ui ===== //
	public cartShown: boolean = false;
	public overlayShown: boolean = false;
	public cartHasTickets: boolean = false;
	//
	public showingCalendar: boolean = false;
	public skipCapacityCheck: boolean = false; // not used anymore...
	//
	public readonly jrText1: string = '(Under 48")';
	public readonly jrText2: string = '(Guests 2 and under get in free)';
	public readonly addonText: string = '(Daily Admission ticket required)';
	public readonly eveningTextA: string = '(Valid '; // (Valid 4pm to 7pm only.)
	public readonly eveningTextB: string = ' to closing only)';
	public readonly limitPerOrderTextA: string = '(Limit '; // limitPerOrderTextA + '6' + limitPerOrderTextB
	public readonly limitPerOrderTextB: string = ' per order)'; // white-space already exists before/after the number.
	public readonly carloadText1: string = '(Maximum 7 guests)';
	public readonly carloadText2: string = '(Includes Parking Fee)';
	public isAddOnAllowedInCart: boolean = false; // true when whatever requirements are met. (like a general admission is already in the cart)
	// TODO: enforce matching dates. ex: general admission for Saturday allows for the same add-on for the same Saturday, too.
	public readonly militaryText: string = 'Valid October 7&8 2024 only. Must be accompanied by an active duty or retired member of the armed forces. Please be prepare to present military ID at the park.'
	public readonly militaryPromoID: string = '651f246b8d2ec1e4c97c2853';
	//
	public readonly membershipBundleID: string = '65f0b6b8c417dba1fd51b84c';
	public membershipBundle: InterfaceOWDoclet | undefined = undefined;
	public membershipBundlePerks: InterfaceSeasonPassEntitlements = {
		includes: [
			'Two Adults',
			'One Guest',
			'Children Under 18 From Household',
			'OR Grandchildren Under 18 From Household',
			'Discounted General Admission Tickets',
			'Exclusive Discounts to Wild Lights',
			'Special Access to Events'
		],
		excludes: []
	};
	//
	public constructor(
		private readonly activeRoute: ActivatedRoute,
		private readonly appConfig: AppConfig,
		private readonly auth: ServiceAuthentication,
		private readonly colProfiles: CollectionProfiles,
		private readonly owapi: ServiceOWAPI,
		private readonly router: Router,
		private readonly title: Title
	) {
		// ========================== //
		// use the docletID of tickets to the settings key, to find the availability of that day, for the ticket selection.
		// server-side already enforces going over by not allowing the front end to use the (+) buttons.
		// but if the availability is zero, then the ticket selection item shouldn't be enabled, or it'll be confusing.
		// ===== these pass IDs are for the Portal. the POS has different pass IDs ===== //
		// TODO: switch to using the pass's target template id, rater than the pass's ._id
		this.title.setTitle( 'Wild Rivers Waterpark Irvine Tickets & Season Passes' );
		this.ticketIDToAvailabilityKey['62aa164d5576850cbaa529de'] = 'ticket_availability'; // adult/general
		this.ticketIDToAvailabilityKey['62aa168c5576850cbaa529df'] = 'ticket_availability'; // junior
		this.ticketIDToAvailabilityKey['62aa145b5576850cbaa529dd'] = 'parking_availability'; // parking
		this.ticketIDToAvailabilityKey['629a58bda0fdd06636abddb3'] = 'kontiki_cove_availability'; // Kontiki Cove, Yellow
		this.ticketIDToAvailabilityKey['629a58bda0fdd06636abddb1'] = 'cooks_cove_availability'; // Cook's Cove, Orange
		this.ticketIDToAvailabilityKey['629a58bda0fdd06636abddb2'] = 'wavepool_beach_availability'; // Wavepool Beach, Blue
		this.ticketIDToAvailabilityKey['629a58bde702436560e6b742'] = 'wavepool_north_availability'; // Wavepool North, Aqua
		this.ticketIDToAvailabilityKey['629a58bde702436560e6b741'] = 'wavepool_south_availability'; // Wavepool South, Red
		this.ticketIDToAvailabilityKey['629a58bde702436560e6b740'] = 'river_cabanas_availability'; // River Cabanas, Green
		this.seasonPassT1Entitlements.includes.push(
			'Limited Admission Days',
			'Early Entry ($10 Upcharge)'
		);
		this.seasonPassT1Entitlements.excludes.push(
			'September & October',
			'Season Parking Pass',
			'Season Pass Holder Preview',
			'Season Pass Bring-a-Friend Free',
			'Discount on Food & Drinks',
			'Season Souvenir Bottle',
			'Discount on Retail',
			'Discount on Cabanas'
		);
		this.seasonPassT2Entitlements.includes.push(
			'Includes the rest of 2023',
			'Daily Admission',
			'All Weekends',
			'Season Pass Preview Day',
			'Two (2) Early Entry on Select Days',
			'Season Parking Pass Addon Available for $95',
			'Bring-a-Friend Free in May',
			'Season Pass Holder Bonus Hour for $10',
			'Season Souvenir Bottle for $45'
		);
		this.seasonPassT2Entitlements.excludes.push(
			'Discount on Food & Drinks',
			'Discount on Retail',
			'Discount on Cabanas'
		);
		this.seasonPassT3Entitlements.includes.push(
			'Includes the rest of 2023',
			'Daily Admission',
			'All Weekends',
			'Season Pass Preview Day',
			'Early Entry on Select Days',
			'Season Parking Pass Addon for $95',
			'15% off Food & Drinks',
			'10% off Retail',
			'10% off Weekday Cabanas',
			'10% off Weekend Cabanas',
			'All Early Entry Dates',
			'Season Pass Holder Bonus Hours (4 times a year)',
			'Bring-a-Friend Free on Selected Days'
		);
		//
		this.isSignedIn = this.auth.isSignedIn();
		this.owapi.workspace.actions.core.recordResourceUse( this.appConfig.getContext(), this.routes.buyNow, 'page', {
			'isParkClosed': this.isParkeClosed,
			'isSignedIn': this.auth.isSignedIn(),
			'profileID': this.auth.getProfileID()
		} ).subscribe( (_: InterfaceHTTPGateway): void => {
			// fire and forget.
		} );
		this.owapi.workspace.doclets.getAllDocletsByTemplateID( this.appConfig.getContext(), {
			templateID: [
				this.strDailyAdmissionPassTemplateID,
				this.strParkingPassTemplateID,
				this.strCabanaPassTemplateID,
				this.strComplexProductTemplateID
			],
			query: {
				'data.status': 'active',
				'ow_roles': '{id}' + this.strWebRole
			},
			withoutAuth: true
		}, false, (response: InterfaceOWAPIBulkRecordRequest<InterfaceOWDoclet>): void => {
			if ( response.success ) {
				this.processPassDoclets( response.records );
				this.dailyAdmissionPasses.sort( (A:InterfaceOWDoclet, B:InterfaceOWDoclet): number => {
					if ( A.data.hasOwnProperty( 'sort' ) && B.data.hasOwnProperty( 'sort' ) ) {
						return A.data['sort'] - B.data['sort'];
					}
					return ServiceSorting.naturalSort( this.passIDToPassProps[ A._id.$oid ].name, this.passIDToPassProps[ B._id.$oid ].name );
				} );
				this.parkingPasses.sort( (A:InterfaceOWDoclet, B:InterfaceOWDoclet): number => {
					return ServiceSorting.naturalSort( this.passIDToPassProps[ A._id.$oid ].name, this.passIDToPassProps[ B._id.$oid ].name );
				} );
				this.cabanaPasses.sort( (A:InterfaceOWDoclet, B:InterfaceOWDoclet): number => {
					return ServiceSorting.naturalSort( this.passIDToPassProps[ A._id.$oid ].name, this.passIDToPassProps[ B._id.$oid ].name );
				} );
				if ( this.dailyAdmissionPasses.length < 1 ) {
					this.nothingToBuy = true; // users are not allowed to buy parking, nor cabana's, without first buying a daily admission ticket.
				}
			} else {
				console.log( 'Bulk record fetch fail - nothing to buy' );
				this.nothingToBuy = true;
			}
		} );
		this.owapi.workspace.doclets.getAllDocletsByTemplateID( this.appConfig.getContext(), {
			templateID: [
				this.strSeasonAdmissionPassTemplateID,
				this.strSeasonParkingPassTemplateID
			],
			query: {
				'data.status': 'active',
				'ow_roles': '{id}' + this.strWebRole,
				'data.year': '2024'
			},
			withoutAuth: true
		}, false, (response: InterfaceOWAPIBulkRecordRequest<InterfaceOWDoclet>): void => {
			if ( response.success ) {
				this.processPassDoclets( response.records );
				console.log( this.seasonAdmissionPasses, this.seasonAdmissionPassRenewals );
				this.seasonAdmissionPasses.sort( (A:InterfaceOWDoclet, B:InterfaceOWDoclet): number => {
					if ( typeof A.data['level'] === 'number' && typeof B.data['level'] === 'number' ) {
						return A.data['level'] - B.data['level'];
					} else if ( typeof B.data['level'] === 'number' ) {
						// then A did not have a level??
						return -1; // A goes before B.
					} else if ( typeof A.data['level'] === 'number' ) {
						// then B did not have a level??
						return 0; // undefined sorting. don't move them, A is already in front i think.
					}
					// else no level, just sort by name.
					return ServiceSorting.naturalSort( this.passIDToPassProps[ A._id.$oid ].name, this.passIDToPassProps[ B._id.$oid ].name );
				} );
				this.seasonAdmissionPassRenewals.sort( (A:InterfaceOWDoclet, B:InterfaceOWDoclet): number => {
					if ( typeof A.data['level'] === 'number' && typeof B.data['level'] === 'number' ) {
						return A.data['level'] - B.data['level'];
					} else if ( typeof B.data['level'] === 'number' ) {
						// then A did not have a level??
						return -1; // A goes before B.
					} else if ( typeof A.data['level'] === 'number' ) {
						// then B did not have a level??
						return 0; // undefined sorting. don't move them, A is already in front i think.
					}
					// else no level, just sort by name.
					return ServiceSorting.naturalSort( this.passIDToPassProps[ A._id.$oid ].name, this.passIDToPassProps[ B._id.$oid ].name );
				} );
				this.seasonParkingPasses.sort( (A:InterfaceOWDoclet, B:InterfaceOWDoclet): number => {
					return ServiceSorting.naturalSort( this.passIDToPassProps[ A._id.$oid ].name, this.passIDToPassProps[ B._id.$oid ].name );
				} );
			} else {
				console.log( 'Failed to fetch season passes.' );
			}
		} );
		this.checkIfUserIsSPH();
	}

	private processPassDoclets( passDoclets: InterfaceOWDoclet[] ): void {
		for ( let x: number = 0; x < passDoclets.length; ++x ) {
			const passID: string = passDoclets[x]._id.$oid;
			if ( !Array.isArray( passDoclets[x].data['blocked_dates'] ) ) {
				passDoclets[x].data['blocked_dates'] = [];
				passDoclets[x].data['__blocked_dates'] = {};
			} else {
				passDoclets[x].data['__blocked_dates'] = {};
				passDoclets[x].data['blocked_dates'].forEach( (blockedDate: string): void => {
					passDoclets[x].data['__blocked_dates'][ blockedDate ] = true;
				} );
			}
			switch ( passDoclets[x].template_id.$oid ) {
				case this.strDailyAdmissionPassTemplateID: {
					this.ticketSelections_v2.dailyPasses[passID] = {
						qty: 0
					};
					this.ticketSelections_v2.anyDayPasses[passID] = {
						qty: 0
					};
					this.ticketHoldings[passID] = []; // an array of temp-tickets that server-side will hang onto, for a while. used for capacity calculations.
					passDoclets[x].data['price'] = TransformerVenuePassportTicket.passPricesToYYYYMMDDPrice( passDoclets[x].data['price'] );
					passDoclets[x].data['__lowestPrice'] = this.getLowestPrice( passDoclets[x] );
					if ( Array.isArray( passDoclets[x].data['dates_valid'] ) && passDoclets[x].data['dates_valid'].length > 0 ) {
						passDoclets[x].data['__datesValid'] = {}; // if __datesValid exists, it triggers the calendar to block out ALL dates, except what's in this object.
						for ( let y: number = 0; y < passDoclets[x].data['dates_valid'].length; ++y ) {
							const YYYYMMDD1: string = passDoclets[x].data['dates_valid'][y]; // YYYY-MM-DD where MM is 01-12
							passDoclets[x].data['__datesValid'][ YYYYMMDD1 ] = true; // this forces the calendar to only allow 'these' dates to be accessible.
						}
					}
					this.passIDToPassProps[passID] = {
						name: passDoclets[x].data['name'],
						price: passDoclets[x].data['price'],
						sort: passDoclets[x].data['sort'] ?? 1,
						isPromoted: passDoclets[x].data?.['is_promoted'] ?? false,
						isLimitPerOrder: (passDoclets[x].data?.['limit_per_order'] ?? 0) > 0,
						isDailyAdmission: true,
						isEvening: !!passDoclets[x].data['is_evening'],
						isJunior: passDoclets[x].data['is_junior'] ?? false,
						isAddOn: passDoclets[x].data['is_addon'] ?? false,
						isDailyParking: false,
						isCabana: false,
						isCompensation: false, // $0 passes
						isSeasonPass: false,
						isSAP: false,
						isSPP: false,
						isCertification: false,
						isMerchandise: false,
						skipCapacityCheck: !passDoclets[x].data['require_capacity'] || passDoclets[x].data['is_any_day'],
						role: {
							admin: false,
							pos: false,
							staff: false,
							web: true
						},
						doclet: passDoclets[x]
					};
					if ( this.passIDToPassProps[passID].isPromoted ) {
						this.promotedDailyAdmissionPasses.push( passDoclets[x] );
					} else {
						this.dailyAdmissionPasses.push( passDoclets[x] );
					}
					break;
				}
				case this.strParkingPassTemplateID: {
					this.ticketSelections_v2.dailyPasses[passID] = {
						qty: 0
					};
					this.ticketHoldings[passID] = []; // an array of temp-tickets that server-side will hang onto, for a while, to hold your place in line...
					passDoclets[x].data['price'] = TransformerVenuePassportTicket.passPricesToYYYYMMDDPrice( passDoclets[x].data['price'] );
					passDoclets[x].data['__lowestPrice'] = this.getLowestPrice( passDoclets[x] );
					this.parkingPasses.push( passDoclets[x] );
					this.passIDToPassProps[passID] = {
						name: passDoclets[x].data['name'],
						price: passDoclets[x].data['price'],
						sort: 2,
						isPromoted: passDoclets[x].data?.['is_promoted'] ?? false,
						isLimitPerOrder: (passDoclets[x].data?.['limit_per_order'] ?? 0) > 0,
						isDailyAdmission: false,
						isEvening: !!passDoclets[x].data['is_evening'],
						isDailyParking: true,
						isCabana: false,
						isCompensation: false, // $0 passes
						isSeasonPass: false,
						isSAP: false,
						isSPP: false,
						isCertification: false,
						isMerchandise: false,
						skipCapacityCheck: !passDoclets[x].data['require_capacity'] || passDoclets[x].data['is_any_day'],
						role: {
							admin: false,
							pos: false,
							staff: false,
							web: true
						},
						doclet: passDoclets[x]
					};
					break;
				}
				case this.strSeasonParkingPassTemplateID: {
					if ( passDoclets[x].data['year'] === this.currentSeasonYear ) {
						this.ticketSelections_v2.seasonPasses[passID] = {
							qty: 0
						};
						this.ticketHoldings[passID] = []; // an array of temp-tickets that server-side will hang onto, for a while, to hold your place in line...
						passDoclets[x].data['price'] = TransformerVenuePassportTicket.passPricesToYYYYMMDDPrice( passDoclets[x].data['price'] );
						passDoclets[x].data['__lowestPrice'] = this.getLowestPrice( passDoclets[x] );
						this.seasonParkingPasses.push( passDoclets[x] );
						this.passIDToPassProps[passID] = {
							name: passDoclets[x].data['name'],
							price: passDoclets[x].data['price'],
							sort: 1, // sorting within the Season Parking Passes
							isPromoted: passDoclets[x].data?.['is_promoted'] ?? false,
							isLimitPerOrder: (passDoclets[x].data?.['limit_per_order'] ?? 0) > 0,
							isDailyAdmission: false,
							isDailyParking: false, // is SPP, not the daily kind.
							isCabana: false,
							isCompensation: false, // $0 passes
							isSeasonPass: true,
							year: passDoclets[x].data['year'],
							isSAP: false,
							isSPP: true,
							level: passDoclets[x].data['level'] ?? undefined,
							isCertification: false,
							isMerchandise: false,
							skipCapacityCheck: !passDoclets[x].data['require_capacity'] || passDoclets[x].data['is_any_day'],
							role: {
								admin: false,
								pos: false,
								staff: false,
								web: true
							},
							doclet: passDoclets[x]
						};
					}
					break;
				}
				case this.strCabanaPassTemplateID: {
					this.ticketSelections_v2.dailyPasses[passID] = {
						qty: 0
					};
					this.ticketHoldings[passID] = []; // an array of temp-tickets that server-side will hang onto, for a while, to hold your place in line...
					passDoclets[x].data['price'] = TransformerVenuePassportTicket.passPricesToYYYYMMDDPrice( passDoclets[x].data['price'] );
					passDoclets[x].data['__lowestPrice'] = this.getLowestPrice( passDoclets[x] );
					this.cabanaPasses.push( passDoclets[x] );
					this.passIDToPassProps[passID] = {
						name: passDoclets[x].data['name'],
						price: passDoclets[x].data['price'],
						sort: 3,
						isPromoted: passDoclets[x].data?.['is_promoted'] ?? false,
						isLimitPerOrder: (passDoclets[x].data?.['limit_per_order'] ?? 0) > 0,
						isDailyAdmission: false,
						isEvening: !!passDoclets[x].data['is_evening'],
						isDailyParking: false,
						isCabana: true,
						isCompensation: false, // $0 passes
						isSeasonPass: false,
						isSAP: false,
						isSPP: false,
						isCertification: false,
						isMerchandise: false,
						skipCapacityCheck: !passDoclets[x].data['require_capacity'] || passDoclets[x].data['is_any_day'],
						role: {
							admin: false,
							pos: false,
							staff: false,
							web: true
						},
						doclet: passDoclets[x]
					};
					break;
				}
				case this.strSeasonAdmissionPassTemplateID: {
					if ( passDoclets[x].data['year'] === this.currentSeasonYear ) {
						this.ticketSelections_v2.seasonPasses[passID] = {
							qty: 0
						};
						this.ticketHoldings[passID] = []; // this ought to always be empty, for season passes. // an array of temp-tickets that server-side will hang onto, for a while, to hold your place in line...
						passDoclets[x].data['price'] = TransformerVenuePassportTicket.passPricesToYYYYMMDDPrice( passDoclets[x].data['price'] );
						passDoclets[x].data['__lowestPrice'] = this.getLowestPrice( passDoclets[x] );
						if ( passDoclets[x].data['is_renewal'] ) {
							this.seasonAdmissionPassRenewals.push( passDoclets[x] );
						} else {
							this.seasonAdmissionPasses.push( passDoclets[x] );
						}
						this.passIDToPassProps[passID] = {
							name: passDoclets[x].data['name'],
							price: passDoclets[x].data['price'],
							sort: 0,
							isPromoted: passDoclets[x].data?.['is_promoted'] ?? false,
							isLimitPerOrder: (passDoclets[x].data?.['limit_per_order'] ?? 0) > 0,
							isDailyAdmission: false,
							isDailyParking: false,
							isCabana: false,
							isCompensation: false, // $0 passes
							isSeasonPass: true,
							isRenewal: passDoclets[x].data['is_renewal'] ?? false,
							year: passDoclets[x].data['year'],
							isSAP: true,
							isSPP: false,
							level: passDoclets[x].data['level'] ?? undefined,
							isCertification: false,
							isMerchandise: false,
							skipCapacityCheck: !passDoclets[x].data['require_capacity'] || passDoclets[x].data['is_any_day'],
							role: {
								admin: false,
								pos: false,
								staff: false,
								web: true
							},
							doclet: passDoclets[x]
						};
						if ( this.passIDToPassProps[passID].level === 1 ) {
							if ( this.passIDToPassProps[passID].isRenewal ) {
								this.seasonPassT1Renewal = passDoclets[x];
							} else {
								this.seasonPassT1 = passDoclets[x];
							}
						} else if ( this.passIDToPassProps[passID].level === 2 ) {
							if ( this.passIDToPassProps[passID].isRenewal ) {
								this.seasonPassT2Renewal = passDoclets[x];
							} else {
								this.seasonPassT2 = passDoclets[x];
							}
						} else if ( this.passIDToPassProps[passID].level === 3 ) {
							if ( this.passIDToPassProps[passID].isRenewal ) {
								this.seasonPassT3Renewal = passDoclets[x];
							} else {
								this.seasonPassT3 = passDoclets[x];
							}
						}
					}
					break;
				}
				case this.strComplexProductTemplateID: {
					this.ticketSelections_v2.complexPasses[passID] = {
						qty: 0
					};
					this.ticketHoldings[passID] = []; // bundles don't have capacity logic. it'll be an issue later.
					passDoclets[x].data['price'] = TransformerVenuePassportTicket.passPricesToYYYYMMDDPrice( passDoclets[x].data['price'] );
					passDoclets[x].data['__lowestPrice'] = this.getLowestPrice( passDoclets[x] );
					if ( Array.isArray( passDoclets[x].data['dates_valid'] ) && passDoclets[x].data['dates_valid'].length > 0 ) {
						passDoclets[x].data['__datesValid'] = {}; // if __datesValid exists, it triggers the calendar to block out ALL dates, except what's in this object.
						for ( let y: number = 0; y < passDoclets[x].data['dates_valid'].length; ++y ) {
							const YYYYMMDD1: string = passDoclets[x].data['dates_valid'][y]; // YYYY-MM-DD where MM is 01-12
							passDoclets[x].data['__datesValid'][ YYYYMMDD1 ] = true; // this forces the calendar to only allow 'these' dates to be accessible.
						}
					}
					if ( passDoclets[x]._id.$oid === '64bea6aa9badb7c6d4d4ae45' ) {
						// Carload
						passDoclets[x].data['__note1'] = this.carloadText1;
						passDoclets[x].data['__note2'] = this.carloadText2;
					}
					this.passIDToPassProps[passID] = {
						name: passDoclets[x].data['name'],
						price: passDoclets[x].data['price'],
						sort: passDoclets[x].data['sort'] ?? 1,
						isPromoted: passDoclets[x].data?.['is_promoted'] ?? false,
						isLimitPerOrder: (passDoclets[x].data?.['limit_per_order'] ?? 0) > 0,
						isDailyAdmission: false,
						isEvening: !!passDoclets[x].data['is_evening'],
						isJunior: passDoclets[x].data['is_junior'] ?? false,
						isAddOn: passDoclets[x].data['is_addon'] ?? false,
						isDailyParking: false,
						isCabana: false,
						isComplexBundle: true,
						isCompensation: false, // $0 passes
						isSeasonPass: false,
						isSAP: false,
						isSPP: false,
						isCertification: false,
						isMerchandise: false,
						skipCapacityCheck: !passDoclets[x].data['require_capacity'] || passDoclets[x].data['is_any_day'],
						role: {
							admin: false,
							pos: false,
							staff: false,
							web: true
						},
						doclet: passDoclets[x]
					};
					if ( passDoclets[x]._id.$oid === this.membershipBundleID ) { // a bundle used for a demo...
						this.membershipBundle = passDoclets[x];
					} else {
						this.complexProducts.push( passDoclets[x] );
					}
					break;
				}
			} // end switch template ID
		} // end for each doclet to bucket up.
	}

	private updateLowestPrices(): void {
		Object.keys( this.passIDToPassProps ).forEach( (passID: string): void => {
			const doclet: InterfaceOWDoclet = this.passIDToPassProps[passID].doclet;
			doclet.data['__lowestPrice'] = this.getLowestPrice( doclet );
		} );
	}

	private getYYYYMMDD( D: Date ): [string, string] {
		const YYYYMMDD0: string = String( D.getFullYear() ) + '-' + String( '0' + D.getMonth() ).slice( -2 ) + '-' + String( '0' + D.getDate() ).slice( -2 );
		const YYYYMMDD1: string = String( D.getFullYear() ) + '-' + String( '0' + (1 + D.getMonth()) ).slice( -2 ) + '-' + String( '0' + D.getDate() ).slice( -2 );
		return [ // YYYY-MM-DD
			YYYYMMDD0, // month is 00 - 11
			YYYYMMDD1 // month is 01 - 12
		];
	}

	private checkIfUserIsSPH(): void {
		this.isSeasonPassHolder = false;
		if ( this.isSignedIn ) {
			this.ticketSAPYearsActive = {};
			const appContext: InterfaceAppContext = this.appConfig.getContext();
			this.colProfiles.getMyUserProfile( (userProfile: InterfaceOWUser | null): void => {
				if ( userProfile && userProfile.doclet_id ) {
					const userProfileDocletID: string = userProfile.doclet_id;
					this.owapi.workspace.doclets.getWeavesByDocletID( appContext, userProfileDocletID ).subscribe( (responseGetUserWeaves: InterfaceHTTPGateway): void => {
						if ( responseGetUserWeaves?.success ) {
							const apiResponseGetUserWeaves: InterfaceOWAPIGetWeavesResponse | undefined = responseGetUserWeaves?.data;
							if ( apiResponseGetUserWeaves && Array.isArray( apiResponseGetUserWeaves?.data?.items ) ) {
								const userWeaves: InterfaceOWWeaveV2[] = apiResponseGetUserWeaves.data.items;
								let familyDocletKnown: boolean = false;
								for ( let uw: number = 0; uw < userWeaves.length; ++uw ) {
									switch ( userWeaves[uw].t_id.$oid ) {
										case this.strFamilyTemplateID: {
											familyDocletKnown = true;
											const familyDocletID: string = userWeaves[uw].c_id.$oid;
											this.owapi.workspace.doclets.getWeavesByDocletID( appContext, familyDocletID ).subscribe( (responseGetFamilyWeaves: InterfaceHTTPGateway): void => {
												if ( responseGetFamilyWeaves?.success ) {
													const apiResponseGetFamilyWeaves: InterfaceOWAPIGetWeavesResponse | undefined = responseGetFamilyWeaves?.data;
													if ( apiResponseGetFamilyWeaves && Array.isArray( apiResponseGetFamilyWeaves?.data?.items ) ) {
														const weavesOnFamily: InterfaceOWWeaveV2[] = apiResponseGetFamilyWeaves.data.items;
														const consumerDocletIDs: string[] = [];
														for ( let wof: number = 0; wof < weavesOnFamily.length; ++wof ) {
															switch ( weavesOnFamily[wof].t_id.$oid ) {
																case this.strConsumerTemplateID: {
																	if ( weavesOnFamily[wof]?.data?.['role'] === 'Pass Holder' ) {
																		consumerDocletIDs.push( weavesOnFamily[wof].c_id.$oid );
																	} // end if this consumer is a season pass holder.
																	break;
																} // end id if this weave is a consumer
															} // end switch template_id of this weave
														} // end for each weaves on the family.
														if ( consumerDocletIDs.length > 0 ) {
															console.log( 'user may be a season pass holder, of some kind...' );
															this.SPHCounter = consumerDocletIDs.length;
															for ( let cd: number = 0; cd < consumerDocletIDs.length; ++cd ) {
																this.owapi.workspace.doclets.getWeavesByDocletID( appContext, consumerDocletIDs[cd] ).subscribe( (responseGetConsumerWeaves: InterfaceHTTPGateway): void => {
																	if ( responseGetConsumerWeaves?.success ) {
																		const apiResponseGetConsumerWeaves: InterfaceOWAPIGetWeavesResponse | undefined = responseGetConsumerWeaves?.data;
																		if ( apiResponseGetConsumerWeaves && Array.isArray( apiResponseGetConsumerWeaves?.data?.items ) ) {
																			const weavesOnConsumer: InterfaceOWWeaveV2[] = apiResponseGetConsumerWeaves.data.items;
																			if ( weavesOnConsumer.length > 0 ) {
																				let seasonPassDocletIDs: string[] = weavesOnConsumer.filter( (w: InterfaceOWWeaveV2): boolean => w.t_id.$oid === this.strSeasonAdmissionTicketTemplateID ).map( (w: InterfaceOWWeaveV2): string => w.c_id.$oid );
																				if ( seasonPassDocletIDs.length ) {
																					// investigating season passes for a consumer...
																					// passes must have 'data.status' and data.status must not be 'canceled' (expired, active, whatever)
																					let seasonPassesToCheck: number = seasonPassDocletIDs.length;
																					for ( let spd: number = 0; spd < seasonPassDocletIDs.length; ++spd ) {
																						this.owapi.workspace.doclets.getDocletByID( appContext, seasonPassDocletIDs[spd] ).subscribe( (responseGetDoclet: InterfaceHTTPGateway): void => {
																							if ( responseGetDoclet?.success ) {
																								const apiResponseGetDoclet: InterfaceOWAPIGetDocletResponse | undefined = responseGetDoclet?.data;
																								if ( apiResponseGetDoclet?.data?._id?.$oid ) {
																									const seasonPassDoclet: InterfaceOWDoclet = apiResponseGetDoclet.data;
																									if ( seasonPassDoclet && seasonPassDoclet.data && seasonPassDoclet.data['status'] && seasonPassDoclet.data['status'] !== 'canceled' && seasonPassDoclet ) {
																										this.isSeasonPassHolder = true;
																										// must not be banned. must have a status. must be from current or prior year. must not be cancelled.
																										if ( typeof seasonPassDoclet.data['year'] === 'string' ) {
																											const seasonPassYear: string = seasonPassDoclet.data['year'];
																											const seasonPassLevel: number = seasonPassDoclet.data['level'] ?? 2;
																											switch ( seasonPassYear ) {
																												case this.currentSeasonYear: // 2024
																												case this.priorSeasonYear: // 2023
																												case '2022': {
																													this.ticketSAPYearsActive[seasonPassYear] = true;
																													if ( !this.ticketSAPYearQuantity.hasOwnProperty( seasonPassYear ) ) {
																														this.ticketSAPYearQuantity[seasonPassYear] = {};
																													}
																													if ( !this.ticketSAPYearQuantity[seasonPassYear].hasOwnProperty( String( seasonPassLevel ) ) ) {
																														this.ticketSAPYearQuantity[seasonPassYear][seasonPassLevel] = 0;
																													}
																													++this.ticketSAPYearQuantity[seasonPassYear][seasonPassLevel];
																													break;
																												}
																											}
																										}
																									}
																								}
																							}
																							if ( --seasonPassesToCheck < 1 ) {
																								this.finishedCheckingConsumerWeavesForSeasonPasses();
																							}
																						} );
																					} // end for each season pass to check
																				} else { // else no season passes for this consumer.
																					this.finishedCheckingConsumerWeavesForSeasonPasses();
																				}
																			} else { // else no weaves on this consumer, so no passes, either.
																				this.finishedCheckingConsumerWeavesForSeasonPasses();
																			}
																		}
																	}
																} );
															}
														} // end if there were any pass holders.
													}
												}
												//
											} );
											break;
										} // end if this was the family template_id
										case this.strSeasonParkingTicketTemplateID: {
											this.owapi.workspace.doclets.getDocletByID( appContext, userWeaves[uw].c_id.$oid ).subscribe( (responseGetDoclet: InterfaceHTTPGateway): void => {
												if ( responseGetDoclet?.success ) {
													const apiResponseGetDoclet: InterfaceOWAPIGetDocletResponse | undefined = responseGetDoclet?.data;
													if ( apiResponseGetDoclet?.data?._id?.$oid ) {
														const SPP: InterfaceOWDoclet = apiResponseGetDoclet.data;
														if ( SPP && SPP.data && SPP.data['status'] && SPP.data['status'] !== 'canceled' && typeof SPP.data['year'] === 'string' ) {
															const seasonPassYear: string = SPP.data['year'];
															this.ticketSPPYearActive[seasonPassYear] = true;
															if ( !this.ticketSPPYearQuantity.hasOwnProperty( seasonPassYear ) ) {
																this.ticketSPPYearQuantity[seasonPassYear] = 0;
															}
															++this.ticketSPPYearQuantity[seasonPassYear];
														}
													}
												}
											} );
											break;
										} // end if this Purchaser has a Season Parking Pass woven to the user.
									} // end switch template_id of each weave
								} // end for each weave on the user
							}
						}
					} );
				} // end if profile was fetched
			} ); // end fetch profile
		} // end if signed in
	}

	public sap2024QtyInCart: number = 0;
	private reSyncSAPQty2024InCart(): void {
		this.sap2024QtyInCart = 0;
		for ( let x: number = 0; x < this.cartItems.length; ++x ) {
			if ( this.cartItems[x].hasSAP ) {
				this.sap2024QtyInCart += this.cartItems[x].tickets.reduce( (out: number, cartItemTicket: InterfaceVenuePassportCartItemTicket): number => {
					if ( this.passIDToPassProps[ cartItemTicket.passID ].isSAP && this.passIDToPassProps[ cartItemTicket.passID ].year === this.currentSeasonYear ) {
						out += cartItemTicket.qty;
					}
					return out;
				}, 0 );
			}
		}
	}

	private finishedCheckingConsumerWeavesForSeasonPasses(): void {
		if ( --this.SPHCounter < 1 ) {
			console.log( 'SAP by Year, Quantity', this.ticketSAPYearsActive, this.ticketSAPYearQuantity );
			console.log( 'SPP by Year, Quantity', this.ticketSPPYearActive, this.ticketSPPYearQuantity );
			this.allowedSAPQuantityByTier['1'] = 0; // silver
			this.allowedSAPQuantityByTier['2'] = 0; // gold
			this.allowedSAPQuantityByTier['3'] = 0; // diamond
			let sap2022: number = 0;
			let sap2023: number = 0;
			if ( this.ticketSAPYearQuantity.hasOwnProperty( '2022' ) ) {
				Object.keys( this.ticketSAPYearQuantity['2022'] ).forEach( (sapLevel: string): void => {
					sap2022 += this.ticketSAPYearQuantity['2022'][sapLevel];
					this.allowedSAPQuantityByTier[sapLevel] = this.ticketSAPYearQuantity['2022'][sapLevel];
				} );
			}
			if ( this.ticketSAPYearQuantity.hasOwnProperty( this.priorSeasonYear ) ) {
				Object.keys( this.ticketSAPYearQuantity[ this.priorSeasonYear ] ).forEach( (sapLevel: string): void => {
					sap2023 += this.ticketSAPYearQuantity[ this.priorSeasonYear ][sapLevel];
					this.allowedSAPQuantityByTier[sapLevel] = Math.max( this.allowedSAPQuantityByTier[sapLevel], this.ticketSAPYearQuantity[ this.priorSeasonYear ][sapLevel] );
				} );
			}
			if ( this.ticketSAPYearQuantity.hasOwnProperty( this.currentSeasonYear ) ) {
				Object.keys( this.ticketSAPYearQuantity[ this.currentSeasonYear ] ).forEach( (sapLevel: string): void => {
					this.allowedSAPQuantityByTier[sapLevel] -= this.ticketSAPYearQuantity[ this.currentSeasonYear ][sapLevel];
					this.totalQtySAPOwned2024 += this.ticketSAPYearQuantity[ this.currentSeasonYear ][sapLevel];
				} );
			}
			this.totalQtySAPOwned20220223Max = Math.max( sap2022, sap2023 );
			this.allowedSAPQuantityTotal = this.totalQtySAPOwned20220223Max - this.totalQtySAPOwned2024;
			console.log( 'SAP Upgrade stats', this.allowedSAPQuantityByTier, this.totalQtySAPOwned20220223Max, this.totalQtySAPOwned2024, this.allowedSAPQuantityTotal );
		}
	}

	private setCalendarToNow(): void {
		this.updateCalendar( now.getFullYear(), now.getMonth() + 1 );
	}

	public ngOnInit(): void {
		this.setCalendarToNow();
		this.owapi.workspace.actions.core.getDailyAdmissionAvailabilityFromDateRange( this.appConfig.getContext(), now, parkClosesOn2024Oct8th, this.strWebRole ).subscribe( (response: InterfaceHTTPGateway): void => {
			if ( response && response.success && response.status === 200 ) {
				const apiResponse: InterfaceOWAPIDailyAdmissionAvailabilityResponse = response.data;
				// don't have to worry about pagination. data.items is an array of length 1.
				if ( apiResponse && apiResponse.data && Array.isArray( apiResponse.data.items ) ) {
					if ( apiResponse.data.items.length > 0 ) {
						this.dailyAdmissionAvailability = apiResponse.data.items[0];
						this.availabilityLoaded = true;
						console.log( 'Availability', this.dailyAdmissionAvailability );
						this.updateLowestPrices();
					}
				}
			}
		} );
		//
		if ( this.activeRoute.snapshot.params && typeof this.activeRoute.snapshot.params['promo'] === 'string' && this.activeRoute.snapshot.params['promo'].length > 0 ) {
			const promoSomething: string = this.activeRoute.snapshot.params['promo'];
			switch ( promoSomething.toLowerCase() ) {
				case 'passes': {
					this.productTab = 'passes';
					break;
				}
				case 'tickets': {
					this.productTab = 'tickets';
					break;
				}
				case 'cabanas': {
					this.productTab = 'cabanas';
					break;
				}
				default: {
					this.promoCode = promoSomething ? promoSomething.toUpperCase() : '';
					break;
				}
			}
			this.useDiscountCode();
		}
		/*
		try {
			const _gtag: Function = (window as any).gtag as Function;
			_gtag( 'config', 'AW-10797476692', {
				'page_path': '/' + this.routes.buyNow
			} );
		} catch( _ ) {}
		*/
	}

	private getSettingsPrefixByPassID( passID: string ): string | false {
		let prefix: string | false = false;
		const passProps: InterfaceDocletIDToTicketProps = this.passIDToPassProps[passID];
		if ( passProps ) {
			if ( passProps.isDailyAdmission ) {
				prefix = 'ticket';
			} else if ( passProps.isDailyParking ) {
				prefix = 'parking';
			} else if ( passProps.isCabana ) {
				const cabanaPass: InterfaceOWDoclet = passProps.doclet;
				const cabanaData: InterfaceVenuePassportPass<InterfaceVenuePassportTicketPriceType> = cabanaPass.data as InterfaceVenuePassportPass<InterfaceVenuePassportTicketPriceType>;
				switch ( cabanaData.target.template_id ) {
					case '62aa70c392ec3e75dafc2c5e': { // Castaway River, Green, _id: 629a58bde702436560e6b740
						prefix = 'river_cabanas';
						break;
					}
					case '62aa711e92ec3e75dafc2c61': { // hidden, Red, _id: 629a58bde702436560e6b741
						prefix = 'wavepool_south';
						break;
					}
					case '62aa710d92ec3e75dafc2c60': { // Tomcat, Aqua, _id: 629a58bde702436560e6b742
						prefix = 'wavepool_north';
						break;
					}
					case '62aa70af92ec3e75dafc2c5d' : { // Cooks Cove, Orange, _id: 629a58bda0fdd06636abddb1
						prefix = 'cooks_cove';
						break;
					}
					case '62aa70ed92ec3e75dafc2c5f': { // Shaka Bay, Blue, _id: 629a58bda0fdd06636abddb2
						prefix = 'wavepool_beach';
						break;
					}
					case '62aa709a92ec3e75dafc2c5c': { // Kontiki Cover, Yellow, _id: 629a58bda0fdd06636abddb3
						prefix = 'kontiki_cove';
						break;
					}
				}
			}
		}
		return prefix;
	}

	private getAvailabilityKeyByPassID( passID: string ): string | undefined {
		const prefix: string | false = this.getSettingsPrefixByPassID( passID );
		let output: string | undefined = undefined;
		if ( typeof prefix === 'string' && prefix.length > 0 ) {
			output = prefix + '_availability';
		}
		return output;
	}

	private getSoldCountKeyByPassID( passID: string ): string | undefined {
		const prefix: string | false = this.getSettingsPrefixByPassID( passID );
		let output: string | undefined = undefined;
		if ( typeof prefix === 'string' && prefix.length > 0 ) {
			output = prefix + '_count';
		}
		return output;
	}

	private getLowestPrice( transformedPassDoclet: InterfaceOWDoclet ): number | null {
		// circular dependency issue.
		// lowest price by date, excluding dates where there is no more availability:
		// - need to know which ticket type this is, which depends on this.passIDToPassProps.
		// - need to know if the pass for sale, has more than 0 available to purchase by date.
		// ...which requires this.dailyAdmissionAvailability to already be loaded.
		// ...plus this.passIDToPassProps to already be loaded to know if it's not a season pass (is a daily ticket type)
		const docletData: { [YYYYMMDD1: string]: InterfaceVenuePassportTicketPricing; } =
			Array.isArray( transformedPassDoclet.data['price'] ) // if not already transformed (it should be)
			? TransformerVenuePassportTicket.passPricesToYYYYMMDDPrice( transformedPassDoclet.data['price'] ) // then transform it
			: transformedPassDoclet.data['price']; // else we're ready to go.
		let lowestPrice: number | null = null;
		const passID: string = transformedPassDoclet._id.$oid;
		const passProps: InterfaceDocletIDToTicketProps | undefined = this.passIDToPassProps[passID]; // it's possible that passIDToPassProps is being built up right now, won't exist, etc.
		const availabilityKey: string | undefined = this.getAvailabilityKeyByPassID( passID );
		const canUseAvailability: boolean = (passProps?.isDailyAdmission || passProps?.isDailyParking || passProps?.isCabana) && this.availabilityLoaded && !!availabilityKey;
		if ( 'default' in docletData && 'default' in docletData['default'] ) {
			lowestPrice = docletData['default'].default;
			const YYYY: number = now.getFullYear();
			const MM1: number = now.getMonth() + 1;
			const DD: number = now.getDate()
			const nowYYYYMMDD1: string = YYYY + '-' + String( '0' + MM1 ).slice( -2 ) + '-' + String( '0' + DD ).slice( -2 );
			if ( Array.isArray( transformedPassDoclet.data?.['dates_valid'] && transformedPassDoclet.data['dates_valid'].length > 0 ) ) {
				// only take the prices from the dates that are valid
				let YYYYMMDD1: string = transformedPassDoclet.data['dates_valid'][0];
				if ( YYYYMMDD1 in docletData && 'default' in docletData[YYYYMMDD1] ) {
					// this takes the YYYY-MM-DD value for each (only valid on these dates) and looks up their ordinary pricing by date (by this specific date).
					if ( canUseAvailability ) {
						if ( this.dailyAdmissionAvailability.hasOwnProperty( YYYYMMDD1 ) && availabilityKey && this.dailyAdmissionAvailability[YYYYMMDD1].hasOwnProperty( availabilityKey ) ) {
							if ( this.dailyAdmissionAvailability[YYYYMMDD1][availabilityKey] > 0 ) {
								lowestPrice = docletData[YYYYMMDD1].default;
							} // else no availability for that day, don't use the price override for that day.
						} // else the daily availability has no knowledge about this ticket.
					} else { // else daily availability isn't loaded yet, or we're in the middle of building up passProps and it doesn't exist yet to know how to use the daily availability yet.
						lowestPrice = docletData[YYYYMMDD1].default;
					}
					if ( Array.isArray( docletData[YYYYMMDD1].priceTier ) ) {
						// typescript is having a super-fail, after checking if a property is an array, is says it's possibly undefined.
						const arr: InterfaceOWAPITicketPriceByDatePriceTier[] = docletData[YYYYMMDD1].priceTier as InterfaceOWAPITicketPriceByDatePriceTier[];
						const ticketsSold: number = this.getTicketSoldCount( passID, YYYY, MM1, DD );
						for ( let x: number = 0; x < arr.length; ++x ) {
							if ( ticketsSold <= arr[x].count) {
								lowestPrice = Math.min( lowestPrice, arr[x].price );
							}
						}
					}
				} else {
					// error, a date in which they're valid for, is not listed in the price matrix.
					// just keep the default.
				}
				transformedPassDoclet.data['dates_valid'].forEach( (YYYYMMDD1: string): void => {
					if ( nowYYYYMMDD1.localeCompare( YYYYMMDD1, undefined, { numeric: true, sensitivity: 'base' } ) < 1 ) {
						// if the date listed is today or further. don't count past dates.
						const availabilityExists: boolean = canUseAvailability // if canUseAvailability is false, then it doesn't matter what date we're checking against.
							&& !!availabilityKey
							&& this.dailyAdmissionAvailability.hasOwnProperty( YYYYMMDD1 )
							&& this.dailyAdmissionAvailability[YYYYMMDD1].hasOwnProperty( availabilityKey )
							&& this.dailyAdmissionAvailability[YYYYMMDD1][availabilityKey] > 0;
						if ( !canUseAvailability // if we don't have availability loaded, then use the date.
							|| availabilityExists // ..but if we do have it loaded, then check if the date has availability.
						) {
							lowestPrice = Math.min(
								lowestPrice as number,
								docletData.hasOwnProperty( YYYYMMDD1 ) ? docletData[YYYYMMDD1].default : docletData['default'].default
							);
							if ( Array.isArray( docletData[YYYYMMDD1].priceTier ) ) {
								const arr: InterfaceOWAPITicketPriceByDatePriceTier[] = docletData[YYYYMMDD1].priceTier as InterfaceOWAPITicketPriceByDatePriceTier[];
								const arrYYYYMM1DD: string[] = YYYYMMDD1.split( /-/g );
								const YYYY: number = Number( arrYYYYMM1DD[0] );
								const MM1: number = Number( arrYYYYMM1DD[1] );
								const DD: number = Number( arrYYYYMM1DD[2] );
								const ticketsSold: number = this.getTicketSoldCount( passID, YYYY, MM1, DD );
								for ( let x: number = 0; x < arr.length; ++x ) {
									if ( ticketsSold <= arr[x].count ) {
										lowestPrice = Math.min( lowestPrice, arr[x].price );
									}
								}
							} // end if this ticket has tiered pricing set up
						} // end if we don't yet have availability loaded, or we do AND there is capacity to sell this type of ticket.
					} // end if the date to check against, is today or in the future.
				} );
			} else { // else all dates are valid, so just deal with price overrides, if any.
				Object.keys( docletData ).forEach( (YYYYMMDD1: string): void => {
					if ( YYYYMMDD1 === 'default' || nowYYYYMMDD1.localeCompare( YYYYMMDD1, undefined, { numeric: true, sensitivity: 'base' } ) < 1 ) {
						// '2023-04-01'.localeCompare( '2023-04-02', undefined, { 'numeric' : true, 'sensitivity' : 'base' } )
						// A is less than B returns -1
						// A is the same as B returns 0
						// A is greater than B returns 1
						// if the date listed is today or further. don't count past dates.
						const availabilityExists: boolean = canUseAvailability // if canUseAvailability is false, then it doesn't matter what date we're checking against.
							&& !!availabilityKey
							&& this.dailyAdmissionAvailability.hasOwnProperty( YYYYMMDD1 )
							&& this.dailyAdmissionAvailability[YYYYMMDD1].hasOwnProperty( availabilityKey )
							&& this.dailyAdmissionAvailability[YYYYMMDD1][availabilityKey] > 0;
						if ( !canUseAvailability // if we don't have availability loaded, then use the date.
							|| (YYYYMMDD1 === 'default' || availabilityExists) // ..but if we do have it loaded, then check if the date has availability.
						) {
							const price: number = docletData[YYYYMMDD1].default;
							lowestPrice = Math.min( price, lowestPrice as number );
							if ( Array.isArray( docletData[YYYYMMDD1].priceTier ) ) {
								const arr: InterfaceOWAPITicketPriceByDatePriceTier[] = docletData[YYYYMMDD1].priceTier as InterfaceOWAPITicketPriceByDatePriceTier[];
								let YYYY: number = 0;
								let MM1: number = 0;
								let DD: number = 0;
								if ( YYYYMMDD1 === 'default' ) {
									YYYY = now.getFullYear();
									MM1 = now.getMonth() + 1;
									DD = now.getDate();
								} else {
									const arrYYYYMM1DD: string[] = YYYYMMDD1.split( /-/g );
									YYYY = Number( arrYYYYMM1DD[0] );
									MM1 = Number( arrYYYYMM1DD[1] );
									DD = Number( arrYYYYMM1DD[2] );
								}
								const ticketsSold: number = this.getTicketSoldCount( passID, YYYY, MM1, DD );
								for ( let x: number = 0; x < arr.length; ++x ) {
									if ( ticketsSold <= arr[x].count ) {
										lowestPrice = Math.min( lowestPrice, arr[x].price );
									}
								}
							}
						}
					}
				} );
			}
		}
		return lowestPrice;
	}

	public numOrdinal( num: number ): string {  // 11th, 1st, 22nd, 12th, etc.
		let ord: string = 'th'
		if ( num > 3 && num < 21 ) {
			return num + ord;
		}
		if ( num < -3 && num > -21 ) { // -11th, -1st, -22nd, -12th, etc.
			return num + ord; // -12nd, -13rd lol
		}
		switch ( num % 10 ) {
			case 0: case 4: case 5: case 6: case 7: case 8: case 9: { ord = 'th'; break; }
			case 1: { ord = 'st'; break; }
			case 2: { ord = 'nd'; break; }
			case 3: { ord = 'rd'; break; }
		}
		return num + ord;
	}

	private willAllowSPHPricing( sphRestriction: InterfaceVenuePassportTicketPricing['sphRestriction'] ): boolean {
		const requiredYears: string[] = Object.keys( sphRestriction );
		let allowSPHPricing: boolean = true;
		for ( let x: number = 0; allowSPHPricing && x < requiredYears.length; ++x ) {
			if ( !this.ticketSAPYearsActive[ requiredYears[x] ] ) {
				allowSPHPricing = false; // current user does not have a season pass from the required year.
			}
		}
		return allowSPHPricing;
	}

	private getTicketSoldCount( passID: string, year: number, month1: number, day: number ): number {
		const YYYY: string = String( year );
		const MM1: string = ('0' + month1).slice( -2 );
		const DD: string = ('0' + day).slice( -2 );
		const YYYYMMDD1: string = YYYY + '-' + MM1 + '-' + DD;
		const availabilityAndSoldByDate: InterfaceOWDailyAdmissionAvailability[string] = this.dailyAdmissionAvailability[ YYYYMMDD1 ];
		if ( !availabilityAndSoldByDate ) {
			return 0; // if there is no info for that date, then assume no tickets were sold.
		}
		const passProps: InterfaceDocletIDToTicketProps = this.passIDToPassProps[passID];
		if ( passProps.isCabana ) { // cabanas are treated differently now. it is now intended for price tier to be spread out among all cabanas sold for the day.
			const cabanaKeys: string[] = [
				'kontiki_cove_count', // Kontiki Cove, Yellow
				'cooks_cove_count', // Cooks Cove, Orange
				'wavepool_beach_count', // Shaka Bay, Blue
				'wavepool_north_count', // Tomcat, Aqua
				'wavepool_south_count', // Red, hidden
				'river_cabanas_count' // Castaway River, Green
			];
			return cabanaKeys.reduce( (out: number, key: string): number => {
				return out + availabilityAndSoldByDate[key];
			}, 0 );
		} else { // else is not a cabana.
			const settingsKey: string | undefined = this.getSoldCountKeyByPassID( passID );
			if ( settingsKey ) {
				return availabilityAndSoldByDate?.[settingsKey] ?? 0;
			}
		}
		return 0;
	}

	public getTicketPrice( passID: string, year: number, month1: number, day: number ): number {
		let passPrice: number = 0;
		const YYYY: string = String( year );
		const MM1: string = ('0' + month1).slice( -2 );
		const DD: string = ('0' + day).slice( -2 );
		const YYYYMMDD1: string = YYYY + '-' + MM1 + '-' + DD;
		if ( this.passIDToPassProps.hasOwnProperty( passID ) ) {
			const ticketsSold: number = this.getTicketSoldCount( passID, year, month1, day );
			if ( this.passIDToPassProps[passID].price.hasOwnProperty( YYYYMMDD1 ) ) {
				let useSPHPrice: boolean = false;
				if ( this.isSeasonPassHolder ) { // new -- extra logic is used to ensure the sph-price applies to only certain season pass holder years, like only 2022, etc.
					useSPHPrice = this.willAllowSPHPricing( this.passIDToPassProps[passID].price[YYYYMMDD1].sphRestriction );
				}
				if ( useSPHPrice && this.passIDToPassProps[passID].price[YYYYMMDD1]['sph'] !== null ) {
					passPrice = Number( this.passIDToPassProps[passID].price[YYYYMMDD1]['sph'] );
				} else if ( Array.isArray( this.passIDToPassProps[passID].price[YYYYMMDD1].priceTier ) ) {
					// tiered pricing is assumed to be pre-sorted.
					const PT: InterfaceOWAPITicketPriceByDatePriceTier[] = this.passIDToPassProps[passID].price[YYYYMMDD1].priceTier ?? [];
					let found: boolean = false;
					for ( let x: number = 0; x < PT.length; ++x ) {
						if ( ticketsSold <= PT[x].count ) {
							found = true;
							passPrice = Number( PT[x].price );
							break;
						}
					}
					if ( !found ) { // else the ticket price is just the normal price.
						passPrice = Number( this.passIDToPassProps[passID].price[YYYYMMDD1]['default'] );
					}
				} else { // else not SPH nor tiered pricing
					passPrice = Number( this.passIDToPassProps[passID].price[YYYYMMDD1]['default'] );
				}
			} else if ( this.passIDToPassProps[passID].price.hasOwnProperty( 'default' ) ) {
				// same logic as above, except [YYYYMMDD] is ['default'] for the date chosen.
				let useSPHPrice: boolean = false;
				if ( this.isSeasonPassHolder ) {
					useSPHPrice = this.willAllowSPHPricing( this.passIDToPassProps[passID].price['default'].sphRestriction );
				}
				if ( useSPHPrice && this.passIDToPassProps[passID].price['default']['sph'] !== null ) {
					passPrice = Number( this.passIDToPassProps[passID].price['default']['sph'] );
				} else if ( Array.isArray( this.passIDToPassProps[passID].price['default'].priceTier ) ) {
					const PT: InterfaceOWAPITicketPriceByDatePriceTier[] = this.passIDToPassProps[passID].price['default'].priceTier ?? [];
					let found: boolean = false;
					for ( let x: number = 0; x < PT.length; ++x ) {
						if ( ticketsSold <= PT[x].count ) {
							found = true;
							passPrice = Number( PT[x].price );
							break;
						}
					}
					if ( !found ) { // else the ticket price is just the normal price.
						passPrice = Number( this.passIDToPassProps[passID].price['default']['default'] ); // basically .price[date][non-sph]
					}
				} else { // else not SPH nor tiered pricing.
					passPrice = Number( this.passIDToPassProps[passID].price['default']['default'] ); // default date, default (non-SPH) price
				}
			} else {
				console.log( 'missing price tag for', passID );
			}
		} else {
			console.log( 'no know price for', passID );
		}
		return passPrice;
	}

	public getSeasonPassPrice( passID: string ): number {
		return this.getTicketPrice( passID, now.getFullYear(), now.getMonth() + 1, now.getDate() );
	}

	// ===== Calendar ===== //
	public calendarPassID: string | undefined = undefined;
	public calendarPassDoclet: InterfaceOWDoclet | undefined = undefined;
	public showCalendar( passID?: string ): void {
		this.selectedDay = 0;
		if ( typeof passID === 'string' ) {
			this.setCalendarToNow();
			if ( this.passIDToPassProps[ passID ].doclet.data.hasOwnProperty( '__datesValid' ) ) {
				const smallestYYYYMMDD1: string | undefined = Object.keys( this.passIDToPassProps[ passID ].doclet.data['__datesValid'] ).filter( (YYYYMMDD1: string): boolean => {
					return !!YYYYMMDD1.match( /^\d\d\d\d-\d\d-\d\d$/ ) && this.intNowYYYYMMDD1 <= Number( YYYYMMDD1.replace( /-/g, '' ) );
				} ).sort( ServiceSorting.naturalSort ).shift(); // this gives us the smallest value, that is today or further ahead.
				if ( typeof smallestYYYYMMDD1 === 'string' ) {
					const arrYMD: string[] = smallestYYYYMMDD1.split( /-/g );
					this.updateCalendar( Number( arrYMD[0] ), Number( arrYMD[1] ) );
				}
			}
		}
		this.showingCalendar = true;
		if ( passID ) {
			this.calendarPassID = passID;
			this.calendarPassDoclet = this.passIDToPassProps[passID].doclet;
		}
	}

	public hideCalendar(): void {
		this.showingCalendar = false;
	}

	public hideCalendarTicketSelection(): void { // removing the fake-modal, and going back to the calendar view...
		this.showingCalendarTicketSelection = false;
	}

	public resetCalendarTicketData(): void {
		this.calendarPassID = undefined;
		this.calendarPassDoclet = undefined;
	}

	public isThereCapacityToday( YYYYMMDD: string ): boolean {
		if ( this.calendarPassID ) {
			if ( this.passIDToPassProps[ this.calendarPassID ].isComplexBundle ) {
				return true;
			}
			if ( this.dailyAdmissionAvailability.hasOwnProperty( YYYYMMDD ) ) {
				if ( this.passIDToPassProps[ this.calendarPassID ].isDailyAdmission ) { // adult/jr
					return this.dailyAdmissionAvailability[YYYYMMDD]?.['ticket_availability'] > 0;
				} else if ( this.passIDToPassProps[ this.calendarPassID ].isDailyParking ) { // normal parking, not seasonal
					return this.dailyAdmissionAvailability[YYYYMMDD]?.['parking_availability'] > 0;
				} else if ( this.passIDToPassProps[ this.calendarPassID ].isCabana ) {
					const availabilityKey: string | undefined = this.ticketIDToAvailabilityKey?.[ this.calendarPassID ];
					return typeof availabilityKey === 'string' && (this.dailyAdmissionAvailability[YYYYMMDD]?.[ availabilityKey ] ?? 0) > 0;
				}
			}
		} else {
			if ( this.dailyAdmissionAvailability.hasOwnProperty( YYYYMMDD ) ) {
				let ticketTypes: string[] = Object.keys( this.dailyAdmissionAvailability[YYYYMMDD] );
				for ( let x: number = 0; x < ticketTypes.length; ++x ) {
					if ( this.dailyAdmissionAvailability[YYYYMMDD][ ticketTypes[x] ] > 0 ) {
						return true;
					}
				}
			}
		}
		return false;
	}

	public setSelectedDay( day: number ): void {
		this.selectedDay = day;
	}

	public syncCalendarTicketSelectionFromCart(): void { // this needs to be called, each time the selected date changed.
		// check the cart, find out if there are tickets by ID, matching our this.calendarTicketID ticket..
		Object.keys( this.ticketSelections_v2.dailyPasses ).forEach( (passID: string): void => {
			this.ticketSelections_v2.dailyPasses[passID].qty = 0;
		} );
		Object.keys( this.ticketSelections_v2.complexPasses ).forEach( (passID: string): void => {
			this.ticketSelections_v2.complexPasses[passID].qty = 0;
		} )
		if ( !this.calendarPassID ) {
			return;
		}
		let found: boolean = false;
		for ( let x: number = 0; !found && x < this.cartItems.length; ++x ) {
			if ( !this.cartItems[x].isSeasonPass && !this.cartItems[x].isAnyDay ) { // if is from the calendar, etc.
				if ( this.cartItems[x].date.year === this.selectedYear
					&& this.cartItems[x].date.month === this.selectedMonth
					&& this.cartItems[x].date.day === this.selectedDay
				) {
					for ( let y: number = 0; !found && y < this.cartItems[x].tickets.length; ++y ) {
						if ( this.cartItems[x].tickets[y].passID === this.calendarPassID ) {
							if ( this.passIDToPassProps[ this.calendarPassID ].isComplexBundle ) {
								this.ticketSelections_v2.complexPasses[ this.calendarPassID ].qty = this.cartItems[x].tickets[y].qty;
							} else {
								this.ticketSelections_v2.dailyPasses[ this.calendarPassID ].qty = this.cartItems[x].tickets[y].qty;
							}
							found = true;
						}
					}
				}
			}
		}
	}

	private updateCabanaLocationsAvailable( YYYYMMDD1: string ): void {
		this.owapi.workspace.actions.core.getReservedSeating( this.appConfig.getContext(), YYYYMMDD1, this.strWebRole ).subscribe( (response: InterfaceHTTPGateway): void => {
			if ( response?.success ) {
				const apiResponse: InterfaceOWAPIPaginatedResponse_T<InterfaceOWDailyReservedSeating> = response?.data;
				if ( Array.isArray( apiResponse?.data?.items ) ) {
					const targetCabana: string | undefined = this.calendarPassDoclet?.data?.['target']?.['template_id'];
					if ( targetCabana ) {
						const seatingAvailable: InterfaceOWDailyReservedSeating = {};
						apiResponse.data.items.forEach( (seating: InterfaceOWDailyReservedSeating): void => {
							Object.keys( seating ).forEach( (ticketID: string): void => {
								seatingAvailable[ticketID] = seating[ticketID];
							} );
						} );
						if ( Array.isArray( seatingAvailable[targetCabana] ) ) {
							this.openCabanaLocations = seatingAvailable[targetCabana];
						}
					}
				}
			}
		} );
	}

	public showingCalendarTicketSelection: boolean = false;
	public showCalendarTicketSelection(): void {
		const YYYYMMDD1: string = this.selectedYear + '-' + ('0' + this.selectedMonth).slice( -2 ) + '-' + ('0' + this.selectedDay).slice( -2 );
		if ( this.datesUsed[ YYYYMMDD1 ] ) {
			this.syncCartItemToTicketSelections( this.datesUsed[ YYYYMMDD1 ] );
		}
		this.overlayShown = false;
		this.cartShown = false;
		//
		this.openCabanaLocations = [];
		this.selectedCabanaLocationIdx = undefined;
		//
		this.showingCalendarTicketSelection = true;
		if ( this.calendarPassID && this.passIDToPassProps[ this.calendarPassID ].isCabana ) {
			if ( this.ticketSelections_v2.dailyPasses?.[this.calendarPassID]?.qty ) {
				this.ticketSelections_v2.dailyPasses[this.calendarPassID].qty = 0; // this is trying to prevent the quantity from stacking up, when adding to the cart...
			}
			this.updateCabanaLocationsAvailable( YYYYMMDD1 );
		}
	}

	public updateCalendar( year: number, month1: number ): void { // month is 1 to 12.
		const intTodayYYYYMM1: number = now.getFullYear() * 100 + now.getMonth() + 1;
		this.strTargetYYYY_MM1 = String( year ) + '-' + String( '0' + month1 ).slice( -2 );
		this.selectedYear = year;
		this.selectedMonth = month1;
		this.noGoingBack = year * 100 + month1 - 1 < intTodayYYYYMM1;
		this.noGoingForward = year * 100 + month1 + 1 > parkClosesOn2024Oct8th.getFullYear() * 100 + parkClosesOn2024Oct8th.getMonth() + 1;
		this.selectedDay = 0;
		this.leadingBlanks = new Array( new Date( this.selectedYear, this.selectedMonth - 1, 1 ).getDay() ); // selectedMonth is 1 to 12, but Date() needs 0 to 11.
		this.daysInMonth = new Array( new Date( this.selectedYear, this.selectedMonth, 0 ).getDate() ); // next months "last-night"
		this.trailingBlanks = new Array( Math.ceil( (this.leadingBlanks.length + this.daysInMonth.length) / 7 ) * 7 - this.leadingBlanks.length - this.daysInMonth.length );
	}

	public prevReservationCalendarMonth(): void {
		const intTodayYYYYMM: number = now.getFullYear() * 100 + now.getMonth() + 1;
		let targetMonth: number = this.selectedMonth - 1;
		let targetYear: number = this.selectedYear;
		if ( targetMonth < 1 ) {
			--targetYear;
			targetMonth = 12;
		}
		if ( targetYear * 100 + targetMonth < intTodayYYYYMM ) {
			return; // attempted to go into the past.
		}
		this.updateCalendar( targetYear, targetMonth );
	}

	public nextReservationCalendarMonth(): void {
		let targetMonth: number = this.selectedMonth + 1; // months are 1 - 12.
		let targetYear: number = this.selectedYear;
		if ( targetMonth > 12 ) {
			++targetYear;
			targetMonth = 1;
		}
		if ( targetYear * 100 + targetMonth > parkClosesOn2024Oct8th.getFullYear() * 100 + parkClosesOn2024Oct8th.getMonth() + 1 ) {
			return; // attempted to go past the month when the park closes.
		}
		this.updateCalendar( targetYear, targetMonth );
	}

	// ===== //

	public toggleCart(): void {
		this.cartShown = !this.cartShown;
		this.overlayShown = this.cartShown;
	}

	// ===== Ticket Selection ===== //
	public closeCart( clearHoldings?: true ): void {
		// if you cancel your ticket selection, we need to wipe the holdings.
		// but if you just click the (X) on the cart thing, we don't...
		this.cartShown = false;
		this.overlayShown = false;
		this.clearSelections( clearHoldings, (): void => {
			this.autoCleanLineItems();
		} );
	}

	private syncCartItemToTicketSelections( cartItem: InterfaceVenuePassportCartItem ): void {
		// based upon just the passID, we don't really know if it's a season pass, an anyDay, or a normal thing.
		// if month and day are 0, then they are an 'any' day pass.
		if ( cartItem.isAnyDay ) {
			for ( let x: number = 0; x < cartItem.tickets.length; ++x ) {
				if ( this.ticketSelections_v2.anyDayPasses.hasOwnProperty( cartItem.tickets[x].passID ) ) {
					this.ticketSelections_v2.anyDayPasses[ cartItem.tickets[x].passID ].qty = cartItem.tickets[x].qty;
				}
			}
		} else if ( cartItem.isSeasonPass ) {
			for ( let x: number = 0; x < cartItem.tickets.length; ++x ) {
				if ( this.ticketSelections_v2.seasonPasses.hasOwnProperty( cartItem.tickets[x].passID ) ) {
					this.ticketSelections_v2.seasonPasses[ cartItem.tickets[x].passID ].qty = cartItem.tickets[x].qty;
				}
			}
		} else { // else is normal daily tickets. (jr/general, parking, cabana, not a season pass nor an 'any' day.)
			// ===== ticket selections based upon the calendar can't be sync'd like this ===== // (it's missing the date logic)
			// for ( let x: number = 0; x < cartItem.tickets.length; ++x ) {
			// 	if ( this.ticketSelections_v2.normalPasses.hasOwnProperty( cartItem.tickets[x].passID ) ) {
			// 		this.ticketSelections_v2.normalPasses[ cartItem.tickets[x].passID ].qty = cartItem.tickets[x].qty;
			// 	}
			// }
		}
	}

	public updateTicketSelection( passID: string, amount: -1 | 1, flags: InterfaceTicketSelectionFlags ): void {
		if ( this.busy ) {
			return;
		}
		let requiresHeldTicket: boolean = false; // only cabana and ordinary admissions (general/jr) need this. season passes don't, and neither do 'any-day' tickets.
		let maxQuantity: number = 10;
		if ( this.passIDToPassProps[passID].isLimitPerOrder ) {
			if ( Number.isFinite( this.passIDToPassProps[passID].doclet.data['limit_per_order'] ) && this.passIDToPassProps[passID].doclet.data['limit_per_order'] > 0 ) {
				maxQuantity = this.passIDToPassProps[passID].doclet.data['limit_per_order'];
			}
		}
		// ensure the bucket the type of ticket belongs in, exists...
		if ( flags.isAnyDay ) {
			// 'any' day passes do not need/use ticket-holdings -- where the cart item also creates a server-side temp-ticket, to ensure park capacity isn't exceeded.
			// they're still limited to no more than 10 passes in the cart.
			if ( !this.ticketSelections_v2.anyDayPasses.hasOwnProperty( passID ) ) {
				this.ticketSelections_v2.anyDayPasses[passID] = {
					qty: 0
				};
			}
			if ( amount < 0 && this.ticketSelections_v2.anyDayPasses[passID].qty + amount < 0 ) {
				return; // skip all logic if the user wanted a grand total of -1 quantity or less.
			}
			if ( amount > 0 && this.ticketSelections_v2.anyDayPasses[passID].qty + amount > maxQuantity ) {
				return; // cannot go over the limit.
			}
			this.ticketSelections_v2.anyDayPasses[passID].qty += amount;
		} else if ( flags.isSeasonPass ) {
			// season passes don't use ticket holdings. (they're just for capacity enforcement)
			if ( !this.ticketSelections_v2.seasonPasses.hasOwnProperty( passID ) ) {
				this.ticketSelections_v2.seasonPasses[passID] = {
					qty: 0
				};
			}
			if ( amount < 0 && this.ticketSelections_v2.seasonPasses[passID].qty + amount < 0 ) {
				return; // skip all logic if the user wanted a grand total of -1 quantity or less.
			}
			if ( amount > 0 && this.ticketSelections_v2.seasonPasses[passID].qty + amount > maxQuantity ) {
				return; // cannot go above the limit.
			}
			if ( amount > 0 && this.passIDToPassProps[passID].isRenewal ) {
				if ( amount + this.sap2024QtyInCart > this.allowedSAPQuantityTotal ) {
					return; // not allowed to get extra renewals
				}
			}
			const seasonPassYear: string = this.passIDToPassProps[passID].year ?? '0000';
			const seasonAdmissionPassIDsForThisSeason: string[] = Object.keys( this.passIDToPassProps ).filter( (pID: string): boolean => {
				return this.passIDToPassProps[pID].isSeasonPass && this.passIDToPassProps[pID].isSAP && this.passIDToPassProps[pID].year === seasonPassYear;
			} );
			if ( this.passIDToPassProps[passID].isSPP ) {
				if ( amount > 0 ) {
					// only allowed to add to the cart, if they already own SAP or have SAP in their cart.
					if ( this.ticketSAPYearsActive[seasonPassYear] ) {
						// allowed
					} else {
						const allSAPQuantity: number = seasonAdmissionPassIDsForThisSeason.reduce( (out: number, pID: string): number => {
							out += this.ticketSelections_v2.seasonPasses.hasOwnProperty( pID ) ? this.ticketSelections_v2.seasonPasses[pID].qty : 0;
							return out;
						}, 0 );
						if ( allSAPQuantity > 0 ) {
							// allowed
						} else {
							return; // denied
						}
					}
				}
				// nothing more needed. they're allowed to change their SPP quantity.
			} else if ( this.passIDToPassProps[passID].isSAP ) {
				// enforce season parking limits.
				// user needs to own a season admission pass, from this season, or have one in their cart.
				// if it's cart only -- if this is the last pass in the cart, and the amount is -1, then we need to wipe the SPP qty.
				if ( this.ticketSAPYearsActive[seasonPassYear] ) {
					// then we don't care if the user is doing -1 or +1 to the SAP.
				} else { // else the Consumer does not yet have any 2023 season passes, etc.
					// we only care if all SAP are becoming qty: 0
					const allSAPQuantity: number = seasonAdmissionPassIDsForThisSeason.reduce( (out: number, pID: string): number => {
						out += this.ticketSelections_v2.seasonPasses.hasOwnProperty( pID ) ? this.ticketSelections_v2.seasonPasses[pID].qty : 0;
						return out;
					}, 0 );
					if ( allSAPQuantity + amount < 1 ) {
						// no season passes in cart, nor any on the account, so wipe the SPP
						const seasonParkingPassesForThisSeason: string[] = Object.keys( this.passIDToPassProps ).filter( (pID: string): boolean => {
							return this.passIDToPassProps[pID].isSeasonPass && this.passIDToPassProps[pID].isSPP && this.passIDToPassProps[pID].year === seasonPassYear;
						} );
						seasonParkingPassesForThisSeason.forEach( (pID: string): void => {
							if ( this.ticketSelections_v2.seasonPasses.hasOwnProperty( pID ) ) {
								this.ticketSelections_v2.seasonPasses[pID].qty = 0;
							}
						} );
					} // end if the Consumer has no SAP anywhere.
				} // end else the Consumer doesn't have any SAP already on their account... for this year.
			} // end if the ticket selection, was a SAP.
			this.ticketSelections_v2.seasonPasses[passID].qty += amount;
		} else if ( flags.isComplexBundle ) {
			// nearly the same logic as 'any' day tickets. just add it to the cart, don't verify capacity, etc.
			if ( !this.ticketSelections_v2.complexPasses.hasOwnProperty( passID ) ) {
				this.ticketSelections_v2.complexPasses[passID] = {
					qty: 0
				};
			}
			if ( amount < 0 && this.ticketSelections_v2.complexPasses[passID].qty + amount < 0 ) {
				return; // skip all logic if the user wanted a grand total of -1 quantity or less.
			}
			if ( amount > 0 && this.ticketSelections_v2.complexPasses[passID].qty + amount > maxQuantity ) {
				return; // cannot go above the limit.
			}
			this.ticketSelections_v2.complexPasses[passID].qty += amount;
		} else { // else is a daily pass. (adult/jr/parking/cabana)
			if ( !this.ticketSelections_v2.dailyPasses.hasOwnProperty( passID ) ) {
				this.ticketSelections_v2.dailyPasses[passID] = {
					qty: 0
				};
			}
			if ( amount < 0 && this.ticketSelections_v2.dailyPasses[passID].qty + amount < 0 ) {
				return; // skip all logic if the user wanted a grand total of -1 quantity or less.
			}
			if ( amount > 0 && this.ticketSelections_v2.dailyPasses[passID].qty + amount > maxQuantity ) {
				return; // cannot go above the limit.
			}
			if ( !Array.isArray( this.ticketHoldings[passID] ) ) {
				this.ticketHoldings[passID] = [];
			}
			requiresHeldTicket = true; // the ticket quantity (+/-) will be processed after a network request.
		} // end else is an ordinary pass.
		// if we don't have to deal with ticket-holdings, we can sync the ticket selection to the cart right away.
		// otherwise, if we're adding to the qty, we need to first check with server-side about holding a ticket.
		// if we're just removing some qty for basic passes, we need to release the ticket-hold on the server-side.
		if ( requiresHeldTicket ) {
			if ( amount > 0 ) {
				// ===== Ticket Reservation, for normal daily tickets (parking, cabana, jr/general) ===== //
				this.busy = true;
				this.owapi.workspace.actions.core.reserveTicket( // 6 params
					this.appConfig.getContext(),
					this.strWebRole,
					this.selectedYear, // param 3
					this.selectedMonth,
					this.selectedDay, // param 5
					passID // param 6
				).subscribe( (response: InterfaceHTTPGateway): void => {
					if ( response && response.success && response.status === 200 ) {
						const apiResponse: InterfaceOWAPIReserveItemResponse = response.data;
						if ( apiResponse && apiResponse.data && Array.isArray( apiResponse.data.items ) ) {
							const reservedItem: InterfaceOWReservedItem = apiResponse.data.items.shift() as InterfaceOWReservedItem; // .pop_front()
							if ( reservedItem && reservedItem.item_id ) {
								this.ticketHoldings[passID].push( reservedItem.item_id );
								this.ticketSelections_v2.dailyPasses[passID].qty += amount;
								this.syncTicketSelectionToCart();
								this.updateFlagsBasedOnCartItem();
								this.enforceCartQuantityOfAddOns();
							} else {
								// TODO: unsuccessful. no capacity left.
							}
						} else {
							// server-side issue... we should always get an array back, even if it's empty.
						}
					} else {
						// server-side issue... doesn't mean there is or is NOT any supply left.
					}
					this.busy = false;
				} );
			} else { // else we are doing -1
				if ( this.ticketHoldings[passID].length > 0 ) {
					const oldestHolding: string = this.ticketHoldings[passID][0];
					this.busy = true;
					this.owapi.workspace.actions.core.removeTicketReservation( this.appConfig.getContext(), [ oldestHolding ] ).subscribe( (response: InterfaceHTTPGateway): void => {
						let failed: boolean = false;
						if ( response && response.success && response.status === 200 ) {
							const apiResponse: InterfaceOWAPICancelledReservationItemsResponse = response.data;
							if ( apiResponse && apiResponse.data && Array.isArray( apiResponse.data.items ) ) {
								// .data.items = [ { items_removed: string[] } ]
								const cancelledItemsData: InterfaceOWCancelledReservationItems = apiResponse.data.items.shift() as InterfaceOWCancelledReservationItems; // .pop_front()
								if ( cancelledItemsData && Array.isArray( cancelledItemsData.items_removed ) && cancelledItemsData.items_removed.length > 0 ) {
									// it doesn't even matter if we get a confirmation back, just destroy the holding on our side and --quantity.
								}
							} else {
								failed = true;
								// server-side error. should always get back an array, even if it's empty.
							}
						} else {
							failed = true;
							// server-side error. should always be a 200, even if the items removed is empty.
						}
						if ( !failed ) {
							for ( let y: number = 0; y < this.ticketHoldings[passID].length; ++y ) {
								if ( this.ticketHoldings[passID][y] === oldestHolding ) {
									this.ticketHoldings[passID].splice( y, 1 );
									break;
								}
							}
							this.ticketSelections_v2.dailyPasses[passID].qty = Math.max( 0, this.ticketSelections_v2.dailyPasses[passID].qty + amount ); // amount is negative. 5 + -1 = 4
							this.syncTicketSelectionToCart( passID );
							this.updateFlagsBasedOnCartItem();
							this.enforceCartQuantityOfAddOns();
						}
						this.busy = false;
					} );
				} else { // else no holdings?
					this.ticketSelections_v2.dailyPasses[passID].qty = Math.max( 0, this.ticketSelections_v2.dailyPasses[passID].qty + amount );
					this.syncTicketSelectionToCart();
					this.updateFlagsBasedOnCartItem();
					this.enforceCartQuantityOfAddOns();
				}
			}
		} else { // else we are not messing with ticket-holdings
			this.syncTicketSelectionToCart( amount < 0 && this.passIDToPassProps[passID].isComplexBundle ? passID : undefined ); // just sync the cart and keep going.
			this.updateFlagsBasedOnCartItem();
			this.enforceCartQuantityOfAddOns();
			this.reSyncSAPQty2024InCart();
		}
	}

	private _clearTicketSelections(): void {
		const passTypes: (keyof InterfaceTicketSelections)[] = Object.keys( this.ticketSelections_v2 ) as (keyof InterfaceTicketSelections)[];
		for ( let x: number = 0; x < passTypes.length; ++x ) {
			if ( passTypes[x] !== 'seasonPasses' ) {
				const passIDs: string[] = Object.keys( this.ticketSelections_v2[ passTypes[x] ] );
				for ( let y: number = 0; y < passIDs.length; ++y ) {
					this.ticketSelections_v2[ passTypes[x] ][ passIDs[y] ].qty = 0;
				}
			}
		}
	}

	public clearSelections( clearHoldings?: true, callback?: () => void ): void {
		// if you're using the clearHoldings, you'll need to use the callback to run the rest of your code...
		if ( clearHoldings ) {
			// closing the modal without adding to the cart needs to clear it's holding.
			const holdingsToCancel: string[] = [];
			// for each of the season passes, any-day, normal tickets, etc..
			const anyDayPassIDs: string[] = Object.keys( this.ticketSelections_v2.anyDayPasses );
			for ( let x: number = 0; x < anyDayPassIDs.length; ++x ) {
				const oldAnyDayQuantity: number = this.ticketSelections_v2.anyDayPasses[ anyDayPassIDs[x] ].qty;
				for ( let y: number = 0; y < this.ticketHoldings[ anyDayPassIDs[x] ].length && y < oldAnyDayQuantity; ++y ) {
					let holdingID: string | undefined = this.ticketHoldings[ anyDayPassIDs[x] ].shift(); // .pop_front()
					if ( holdingID ) {
						holdingsToCancel.push( holdingID );
					}
				}
			}
			const dailyPassIDs: string[] = Object.keys( this.ticketSelections_v2.dailyPasses );
			for ( let x: number = 0; x < dailyPassIDs.length; ++x ) {
				const oldNormalQuantity: number = this.ticketSelections_v2.dailyPasses[ dailyPassIDs[x] ].qty;
				for ( let y: number = 0; y < this.ticketHoldings[ dailyPassIDs[x] ].length && y < oldNormalQuantity; ++y ) {
					let holdingID: string | undefined = this.ticketHoldings[ dailyPassIDs[x] ].shift(); // .pop_front()
					if ( holdingID ) {
						holdingsToCancel.push( holdingID );
					}
				}
			}
			this.owapi.workspace.actions.core.removeTicketReservation( this.appConfig.getContext(), holdingsToCancel ).subscribe( (_: InterfaceHTTPGateway): void => {
				this._clearTicketSelections();
				if ( typeof callback === 'function' ) {
					callback();
				}
			} );
		} else {
			// don't remove holdings, because "addToCart" uses this fn as a clear()
			this._clearTicketSelections();
			if ( typeof callback === 'function' ) {
				callback();
			}
		}
	}

	// ===== Cart ===== //
	public updateFlagsBasedOnCartItem(): void {
		// ===== Add Ons ===== //
		// current rules are:
		// - if a daily admission ticket (general or jr) is added to the cart (regardless of what day it's for),
		// ...then add-ons can be added to the cart, too, regardless of what dates the add-ons are for.
		this.isAddOnAllowedInCart = false;
		//
		this.cartHasTickets = false; // controls the UI over moving onwards to /checkout
		//
		for ( let x: number = 0; x < this.cartItems.length; ++x ) {
			for ( let y: number = 0; y < this.cartItems[x].tickets.length; ++y ) {
				if ( this.cartItems[x].tickets[y].qty > 0 ) {
					this.cartHasTickets = true;
				}
				if ( !this.passIDToPassProps[ this.cartItems[x].tickets[y].passID ].isAddOn && this.passIDToPassProps[ this.cartItems[x].tickets[y].passID ].isDailyAdmission && this.cartItems[x].tickets[y].qty > 0 ) {
					// don't count other add-ons. only daily admissions count. must be qty > 0.
					this.isAddOnAllowedInCart = true;
				}
			}
		}
	}

	private enforceCartQuantityOfAddOns(): void {
		// if there are add-ons in the cart,
		// and if their restrictions aren't met,
		// then set their quantities to zero.
		if ( this.isAddOnAllowedInCart ) {
			return; // nothing to do.
		}
		const holdingsToCancel: string[] = [];
		for ( let x: number = 0; x < this.cartItems.length; ++x ) {
			for ( let y: number = 0; y < this.cartItems[x].tickets.length; ++y ) {
				if ( this.passIDToPassProps[ this.cartItems[x].tickets[y].passID ].isAddOn ) {
					// ticket is an addon, but none are allowed in the cart.
					this.cartItems[x].tickets[y].holds.splice( 0, this.cartItems[x].tickets[y].holds.length ).forEach( (ticketID: string): void => {
						holdingsToCancel.push( ticketID );
					} );
					this.cartItems[x].tickets[y].qty = 0;
				}
			} // end for each ticket (of this group)
		} // end for each cart item (group of tickets by date)
		if ( holdingsToCancel.length > 0 ) {
			this.owapi.workspace.actions.core.removeTicketReservation( this.appConfig.getContext(), holdingsToCancel ).subscribe( (_: InterfaceHTTPGateway): void => {} );
			// TODO: sync ticket selections from cart. (not just a cart item)
		}
		this.calcGrandTotal();
	}

	public calcGrandTotal(): void { // lots of functions call this.. try not to call functions from inside this fn.
		this.grandTotal = 0;
		this.cartCount = 0;
		for ( let x: number = 0; x < this.cartItems.length; ++x ) {
			for ( let y: number = 0; y < this.cartItems[x].tickets.length; ++y ) {
				if ( this.cartItems[x].isSeasonPass ) {
					this.grandTotal += this.getSeasonPassPrice( this.cartItems[x].tickets[y].passID ) * this.cartItems[x].tickets[y].qty;
				} else {
					this.grandTotal += this.getTicketPrice(
						this.cartItems[x].tickets[y].passID,
						this.cartItems[x].date.year,
						this.cartItems[x].date.month,
						this.cartItems[x].date.day
					) * this.cartItems[x].tickets[y].qty;
				}
				this.cartCount += this.cartItems[x].tickets[y].qty;
			}
		}
		if ( this.promoCode ) {
			this.useDiscountCode();
		}
	}

	public setTab( tab: TypeProductTab ): void {
		// This hook is here in case we wanted to lazy load products by type.
		this.productTab = tab;
	}

	public syncTicketSelectionToCart( passIDToReSync?: string ): void {
		// if passID is sent in, it means the entry in the cart, for the date selected, was probably set to zero or is heading that way.
		// ===== keep non-season passes, non any-day passes ===== //
		const calendarCartItems: InterfaceVenuePassportCartItem[] = [];
		for ( let x: number = 0; x < this.cartItems.length; ++x ) {
			// preserve the line items that are not 'any' day passes or season passes
			if ( !this.cartItems[x].isAnyDay && !this.cartItems[x].isSeasonPass ) {
				// bugfix for not being able to remove the last item from the cart because the .filter of .qty > 0 later on never picks up tickets that were set to zero qty.
				if ( this.cartItems[x].date.year === this.selectedYear && this.cartItems[x].date.month === this.selectedMonth && this.cartItems[x].date.day === this.selectedDay ) {
					for ( let y: number = 0; y < this.cartItems[x].tickets.length; ++y ) {
						if ( this.cartItems[x].tickets[y].passID === passIDToReSync ) {
							if ( this.passIDToPassProps[passIDToReSync].isDailyAdmission ) {
								this.cartItems[x].tickets[y].qty = this.ticketSelections_v2.dailyPasses[passIDToReSync].qty;
							} else if ( this.passIDToPassProps[passIDToReSync].isDailyParking ) {
								this.cartItems[x].tickets[y].qty = this.ticketSelections_v2.dailyPasses[passIDToReSync].qty;
							} else if ( this.passIDToPassProps[passIDToReSync].isComplexBundle ) {
								this.cartItems[x].tickets[y].qty = this.ticketSelections_v2.complexPasses[passIDToReSync].qty;
							}
						}
					}
				}
				calendarCartItems.push( this.cartItems[x] );
			}
		}
		this.cartItems = calendarCartItems; // reset and sync the 'any' day and season passes.
		const cartItemsToProcess: InterfaceVenuePassportCartItem[] = [];
		const complexBundleItems: string[] = Object.keys( this.ticketSelections_v2.complexPasses ).filter( (passID: string): boolean => {
			return this.ticketSelections_v2.complexPasses[passID].qty > 0;
		} );
		if ( complexBundleItems.length > 0 ) {
			const cartItemComplexBundle: InterfaceVenuePassportCartItem = {
				date: {
					year: this.selectedYear,
					month: this.selectedMonth,
					day: this.selectedDay,
				},
				tickets: complexBundleItems.map( (passID: string): InterfaceVenuePassportCartItemTicket => {
					return {
						passID: passID,
						qty: this.ticketSelections_v2.complexPasses[passID].qty,
						holds: [] // these don't exist for bundles. will become an issue later.
					};
				} ),
				isSeasonPass: false,
				isAnyDay: false,
				isCabana: false,
				isComplexBundle: true
			};
			cartItemsToProcess.push( cartItemComplexBundle );
		}
		const anyDayItems: string[] = Object.keys( this.ticketSelections_v2.anyDayPasses ).filter( (passID: string): boolean => {
			return this.ticketSelections_v2.anyDayPasses[passID].qty > 0;
		} );
		if ( anyDayItems.length > 0 ) {
			const cartItemAnyDay: InterfaceVenuePassportCartItem = {
				date: {
					year: 2023,
					month: 0,
					day: 0
				},
				tickets: anyDayItems.map( (passID: string): InterfaceVenuePassportCartItemTicket => {
					return {
						passID: passID,
						qty: this.ticketSelections_v2.anyDayPasses[passID].qty,
						holds: [] // these don't exist for any-day nor season passes. normally they are populated when we pack up the items to /checkout.
					};
				} ),
				isSeasonPass: false,
				isAnyDay: true,
				isCabana: false,
				isComplexBundle: false
			};
			cartItemsToProcess.push( cartItemAnyDay );
		}
		const SPItems: string[] = Object.keys( this.ticketSelections_v2.seasonPasses ).filter( (passID: string): boolean => {
			return this.ticketSelections_v2.seasonPasses[passID].qty > 0;
		} );
		if ( SPItems.length > 0 ) {
			let isSPP: boolean = false;
			let isSAP: boolean = false;
			const cartItemSP: InterfaceVenuePassportCartItem = {
				date: {
					year: Number( this.passIDToPassProps[ SPItems[0] ].year ?? this.currentSeasonYear ), // good all year
					month: 1, // starting from jan 1st...
					day: 1
				},
				tickets: SPItems.map( (passID: string): InterfaceVenuePassportCartItemTicket => {
					if ( this.passIDToPassProps[passID].isSAP ) {
						isSAP = true;
					}
					if ( this.passIDToPassProps[passID].isSPP ) {
						isSPP = true;
					}
					return {
						passID: passID,
						qty: this.ticketSelections_v2.seasonPasses[passID].qty,
						holds: [], // these don't exist for any-day nor season passes. normally they are populated when we pack up the items to /checkout.
					};
				} ),
				isSeasonPass: true,
				isAnyDay: false,
				hasSAP: isSAP,
				hasSPP: isSPP
			};
			cartItemsToProcess.push( cartItemSP );
		}
		const dailyItems: string[] = Object.keys( this.ticketSelections_v2.dailyPasses ).filter( (passID: string): boolean => {
			return !this.passIDToPassProps[passID].isCabana && this.ticketSelections_v2.dailyPasses[passID].qty > 0;
		} );
		if ( dailyItems.length > 0 ) {
			const dailyCartItem: InterfaceVenuePassportCartItem = {
				date: {
					year: this.selectedYear,
					month: this.selectedMonth,
					day: this.selectedDay,
				},
				tickets: dailyItems.map( (passID: string): InterfaceVenuePassportCartItemTicket => {
					return {
						passID: passID,
						qty: this.ticketSelections_v2.dailyPasses[passID].qty,
						holds: [] // this will get populated during serialization when we move to /checkout.
					};
				} ),
				isSeasonPass: false,
				isAnyDay: false,
				isCabana: false,
				isComplexBundle: false
			};
			cartItemsToProcess.push( dailyCartItem );
		}
		const cabanaItems: string[] = Object.keys( this.ticketSelections_v2.dailyPasses ).filter( (passID: string): boolean => {
			return this.passIDToPassProps[passID].isCabana && this.ticketSelections_v2.dailyPasses[passID].qty > 0;
		} );
		if ( cabanaItems.length > 0 ) {
			const cabanaItem: InterfaceVenuePassportCartItem = {
				date: {
					year: this.selectedYear,
					month: this.selectedMonth,
					day: this.selectedDay
				},
				tickets: cabanaItems.map( (passID: string): InterfaceVenuePassportCartItemTicket => {
					return {
						passID: passID,
						qty: this.ticketSelections_v2.dailyPasses[passID].qty,
						holds: [],
						location: this.ticketSelections_v2.dailyPasses[passID].locationID
					};
				} ),
				isSeasonPass: false,
				isAnyDay: false,
				isCabana: true,
				isComplexBundle: false
			};
			cartItemsToProcess.push( cabanaItem );
		}
		//
		for ( let x: number = 0; x < cartItemsToProcess.length; ++x ) {
			cartItemsToProcess[x].tickets.sort( (A: InterfaceVenuePassportCartItemTicket, B: InterfaceVenuePassportCartItemTicket): number => {
				if ( this.passIDToPassProps[A.passID].sort < this.passIDToPassProps[B.passID].sort ) {
					return -1;
				} else if ( this.passIDToPassProps[A.passID].sort > this.passIDToPassProps[B.passID].sort ) {
					return 1;
				}
				return ServiceSorting.naturalSort( this.passIDToPassProps[A.passID].name, this.passIDToPassProps[B.passID].name );
			} );
			let cartItemExistsByDate: boolean = false;
			// line items are by date, so if a user modifies things for that date, it will replace the entire group of tickets for that date.
			if ( cartItemsToProcess[x].isSeasonPass ) {
				for ( let y: number = 0; !cartItemExistsByDate && y < this.cartItems.length; ++y ) {
					if ( this.cartItems[y].isSeasonPass && this.cartItems[y].date.year === cartItemsToProcess[x].date.year ) {
						this.cartItems[y] = cartItemsToProcess[x];
						cartItemExistsByDate = true;
					}
				}
			} else if ( cartItemsToProcess[x].isAnyDay ) {
				for ( let y: number = 0; !cartItemExistsByDate && y < this.cartItems.length; ++y ) {
					if ( this.cartItems[y].isAnyDay && cartItemsToProcess[x].date.year === this.cartItems[y].date.year ) {
						this.cartItems[y] = cartItemsToProcess[x];
						cartItemExistsByDate = true;
					}
				}
			} else { // complex bundles and normal daily tickets
				if ( !cartItemsToProcess[x].isCabana ) {
					for ( let y: number = 0; !cartItemExistsByDate && y < this.cartItems.length; ++y ) {
						if ( !this.cartItems[y].isSeasonPass && !this.cartItems[y].isAnyDay ) {
							if ( this.cartItems[y].date.year === cartItemsToProcess[x].date.year ) {
								if ( this.cartItems[y].date.month === cartItemsToProcess[x].date.month ) {
									if ( this.cartItems[y].date.day === cartItemsToProcess[x].date.day ) {
										if ( !this.cartItems[y].isCabana ) { // prevent things like parking from trampling over cabana..
											cartItemExistsByDate = true;
											// cartItemsToProcess was built-up from the ticket selection.
											// the date may exist, but the ticket selection may not.
											// rather than trampling over the existing line item (cart items are grouped by date),
											// ...this just adds onto that line item.
											//
											// this.cartItems[y] is the same date selected, as cartItemsToProcess[x] (they belong in the group/line-item)
											//
											for ( let ci2pTicketIdx: number = 0; ci2pTicketIdx < cartItemsToProcess[x].tickets.length; ++ci2pTicketIdx ) {
												let found: boolean = false;
												const passID: string = cartItemsToProcess[x].tickets[ci2pTicketIdx].passID;
												// is passID already in the cart? if so, update the qty from the ticket selection.
												for ( let existingCartTicketIdx: number = 0; !found && existingCartTicketIdx < this.cartItems[y].tickets.length; ++existingCartTicketIdx ) {
													if ( this.cartItems[y].tickets[existingCartTicketIdx].passID === passID ) {
														found = true;
														this.cartItems[y].tickets[existingCartTicketIdx].qty = cartItemsToProcess[x].tickets[ci2pTicketIdx].qty;
													}
												} // end for each pass for sale inside the cart item to process. (needed to sync the quantities from the ticket selection into the cart)
												if ( !found ) {
													this.cartItems[y].tickets.push( cartItemsToProcess[x].tickets[ci2pTicketIdx] );
												}
											} // end for each cart-item to process for finding an existing cart entry.
										} // end if this is not a cabana (they do not have quantities, and cannot be combined anymore)
									} // end if the day matches (found a line-item to update, meaning we're not creating a new one)
								} // end if the months match for the ticket group to process and the existing cart ticket group.
							} // end if the years match for the ticket group to process and the existing cart ticket group.
						} // end if this group is NOT a season pass, and is NOT an any-day ticket
					} // end for each group in the cart
				} // end if the (normal daily ticket) is not a cabana
				if ( !cartItemExistsByDate ) {
					const YYYYMMDD1: string = this.selectedYear + '-' + ('0' + this.selectedMonth).slice( -2 ) + '-' + ('0' + this.selectedDay).slice( -2 );
					this.datesUsed[YYYYMMDD1] = cartItemsToProcess[x];
				}
			} // end else this group is for normal daily tickets.
			if ( !cartItemExistsByDate ) {
				this.cartItems.push( cartItemsToProcess[x] );
				this.cartHasTickets = true;
				this.cartItems.sort( (A: InterfaceVenuePassportCartItem, B: InterfaceVenuePassportCartItem): number => {
					return A.date.year * 10000 + A.date.month * 100 + A.date.day - B.date.year * 10000 - B.date.month * 100 - B.date.day;
				} );
			}
		}
		this.calcGrandTotal();
	}

	public removeCartItem( overloadIdxCartItem: number | InterfaceVenuePassportCartItem ): void {
		// this removes an entries group of tickets, by date.
		// if you want to remove tickets from a group, but not the whole group, see: removeTicketGroup
		if ( typeof overloadIdxCartItem === 'number' ) {
			this.removeCartItem( this.cartItems[overloadIdxCartItem] );
		} else {
			const holdingsToCancel: string[] = [];
			for ( let x: number = 0; x < this.cartItems.length; ++x ) {
				if ( this.cartItems[x].isSeasonPass ) {
					if ( this.cartItems[x].date.year === overloadIdxCartItem.date.year ) {
						const oldCartItems: InterfaceVenuePassportCartItem[] = this.cartItems.splice( x, 1 );
						delete this.seasonPassDatesUsed[ String( overloadIdxCartItem.date.year ) ];
						for ( let y: number = 0; y < oldCartItems.length; ++y ) {
							for ( let z: number = 0; z < oldCartItems[y].tickets.length; ++z ) {
								const oldTicketID: string = oldCartItems[y].tickets[z].passID;
								const heldTicketID: string | undefined = this.ticketHoldings[oldTicketID].shift(); // .pop_front()
								if ( heldTicketID ) {
									holdingsToCancel.push( heldTicketID );
								} // else the ticket does not use temp-held ticket IDs (season passes, any-day tickets, etc.)
							} // end for each old ticket that was removed
						} // end for each cart item that was removed. (should just be an array with 1 element)
					} // end if we found the season pass for the year selected.
				} else {
					if ( this.cartItems[x].date.day === overloadIdxCartItem.date.day ) {
						if ( this.cartItems[x].date.year === overloadIdxCartItem.date.year ) {
							if ( this.cartItems[x].date.month === overloadIdxCartItem.date.month ) {
								const oldCartItems: InterfaceVenuePassportCartItem[] = this.cartItems.splice( x, 1 );
								const YYYYMMDD: string = overloadIdxCartItem.date.year + '-' + ('0' + overloadIdxCartItem.date.month).slice( -2 ) + '-' + ('0' + overloadIdxCartItem.date.day).slice( -2 );
								delete this.datesUsed[ YYYYMMDD ];
								for ( let y: number = 0; y < oldCartItems.length; ++y ) {
									for ( let z: number = 0; z < oldCartItems[y].tickets.length; ++z ) {
										const oldTicketID: string = oldCartItems[y].tickets[z].passID;
										const heldTicketID: string | undefined = this.ticketHoldings[oldTicketID].shift(); // .pop_front()
										if ( heldTicketID ) {
											holdingsToCancel.push( heldTicketID );
										} else { // else we are out of held ticket IDs but had tickets??
											break; // should never reach this.
										}
									} // end for each old ticket that was removed
								} // end for each cart item that was removed. (should just be an array with 1 element)
							} // end if we found the cart item by it's month
						} // end if we found the cart item by it's year
					} // end if we found the cart item by it's day
				}
			} // end for each cart item
			if ( holdingsToCancel.length > 0 ) {
				this.owapi.workspace.actions.core.removeTicketReservation( this.appConfig.getContext(), holdingsToCancel ).subscribe( (_: InterfaceHTTPGateway): void => {
					//
				} );
			}
			this.updateFlagsBasedOnCartItem();
			this.enforceCartQuantityOfAddOns();
		} // end else a cart item was passed in.
	}

	public removeTicketGroup(cartItem: InterfaceVenuePassportCartItem, ticketIdx: number ): void {
		// this removes an entire type of tickets, (ex: all jr, leaving adult)
		// see: removeCartItem() -- for removing an entire group of tickets by date. (ex: all 2023 season passes)
		if ( cartItem.tickets.length > ticketIdx ) { // if not out of bounds...
			const oldTickets: InterfaceVenuePassportCartItemTicket[] = cartItem.tickets.splice( ticketIdx, 1 );
			// this.cartItems is now missing the ticket that we just spliced away.
			const holdingsToCancel: string[] = [];
			for ( let x: number = 0; x < oldTickets.length; ++x ) {
				const passID: string = oldTickets[x].passID;
				const heldTicketID: string | undefined = this.ticketHoldings[passID].shift(); // .pop_front()
				if ( heldTicketID ) {
					holdingsToCancel.push( heldTicketID );
				} // else this ticket type doesn't have any held ticket IDs, or we messed up somewhere...
				if ( this.passIDToPassProps[ oldTickets[x].passID ].isSAP ) { // Season Admission Pass
					// the user removed a SAP from their cart.
					// now we need to double check if they already have SAP on their account,
					// or if they still have SAP in their cart.
					// if so, they're allowed to keep any SPP they have in their cart.
					const seasonPassYear: string = this.passIDToPassProps[ oldTickets[x].passID ].year ?? '0000';
					if ( this.ticketSAPYearsActive[seasonPassYear] ) {
						// nothing more needed. they're allowed to have SPP.
					} else {
						const seasonAdmissionPassesInCart: number = this.cartItems.reduce( (sum1: number, item: InterfaceVenuePassportCartItem): number => {
							return item.tickets.reduce( (sum2: number, ticket: InterfaceVenuePassportCartItemTicket): number => {
								if ( this.passIDToPassProps.hasOwnProperty( ticket.passID ) && this.passIDToPassProps[ ticket.passID ].isSeasonPass && this.passIDToPassProps[ ticket.passID ].isSAP && this.passIDToPassProps[ ticket.passID ].year === seasonPassYear ) {
									sum2 += ticket.qty;
								}
								return sum2;
							}, 0 );
						}, 0 );
						if ( seasonAdmissionPassesInCart > 0 ) {
							// nothing more needed. they're allowed to have SPP
						} else {
							// we need to remove all SPP from the cart, for the same season.
							for ( let y: number = 0; y < this.cartItems.length; ++y ) {
								for ( let z: number = 0; z < this.cartItems[y].tickets.length; ++z ) {
									const pID: string = this.cartItems[y].tickets[z].passID;
									if ( this.passIDToPassProps[pID].isSeasonPass && this.passIDToPassProps[pID].isSPP && this.passIDToPassProps[pID].year === seasonPassYear ) {
										while ( this.cartItems[y].tickets[z].qty > 0 ) {
											const heldSPP: string | undefined = this.cartItems[y].tickets[z].holds.shift(); // .pop_front()
											if ( heldSPP ) {
												holdingsToCancel.push( heldSPP );
											}
											--this.cartItems[y].tickets[z].qty;
										}
										// ===== Recursion ===== //
										this.removeTicketGroup( this.cartItems[y], z );
										// warning -- if the recursion triggered this.removeCartItem, then [y] is gone.
										y = -1; // bug-fix.
										break;
										// ===================== //
									} // end if this SPP matches the seasonal year of the SAP.
								} // end for each ticket-type for this line-item.
							} // end for each line-item in the cart.
						} // end else there are zero SAP for the season in the cart. (SPP removal)
					} // end else the Consumer doesn't have this seasons admission passes on their account.
				} // end if we removed a season admission pass (triggers season parking pass enforcement)
				// ===== update the ticket selection, if possible ===== //
				if ( this.passIDToPassProps[passID].isSeasonPass ) {
					// user removed all of their season admission passes
					this.ticketSelections_v2.seasonPasses[passID].qty = 0;
				}
			} // end for each ticket that was removed from the cart.
			// if season parking passes start to have temp-held tickets on server-side, they also need to be wiped, too.
			if ( holdingsToCancel.length > 0 ) {
				this.owapi.workspace.actions.core.removeTicketReservation( this.appConfig.getContext(), holdingsToCancel ).subscribe( (_: InterfaceHTTPGateway): void => {
					// nothing needed.
				} );
			}
			if ( cartItem.tickets.length < 1 ) {
				this.removeCartItem( cartItem ); // issue: SAP vs SPP and datesUsed[YYYYMMDD] - sap removes date used, but parking needed it.
			}
			this.calcGrandTotal();
		}
		this.reSyncSAPQty2024InCart();
	}

	public updateCartItem( idxCartItem: number, idxTicket: number, amount: -1 | 1 ): void {
		if ( this.busy ) {
			return;
		}
		const cartItem: InterfaceVenuePassportCartItem = this.cartItems[idxCartItem];
		const ticket: InterfaceVenuePassportCartItemTicket = cartItem.tickets[idxTicket];
		let maxQuantity: number = 10;
		if ( this.passIDToPassProps[ ticket.passID].isLimitPerOrder ) {
			if ( Number.isFinite( this.passIDToPassProps[ ticket.passID ].doclet.data['limit_per_order'] ) && this.passIDToPassProps[ ticket.passID ].doclet.data['limit_per_order'] > 0 ) {
				maxQuantity = this.passIDToPassProps[ ticket.passID ].doclet.data['limit_per_order'];
			}
		}
		if ( ticket.qty >= maxQuantity && amount > 0 ) {
			return;
		}
		const isSeasonPass: boolean = this.cartItems[idxCartItem].isSeasonPass ?? false;
		if ( isSeasonPass ) {
			// do not reserve tickets (held ticket IDs) for SAP/SPP. no capacity checking is needed.
			const seasonPassYear: string = this.passIDToPassProps[ ticket.passID ].year ?? '0000';
			if ( this.passIDToPassProps[ ticket.passID ].isSPP ) {
				const qtySapInCart: number = this.cartItems.reduce( (out1: number, cItem: InterfaceVenuePassportCartItem): number => {
					if ( cItem.hasSAP ) {
						out1 += cItem.tickets.reduce( (out2: number, tItem: InterfaceVenuePassportCartItemTicket): number => {
							if ( this.passIDToPassProps[tItem.passID].year === seasonPassYear ) {
								out2 += this.passIDToPassProps[tItem.passID].isSAP ? tItem.qty : 0;
							}
							return out2;
						}, 0 );
					}
					return out1;
				}, 0 );
				// the user is allowed to add more SPP, if they already a season admission pass owner this year, or have some in their cart somewhere.
				if ( amount > 0 ) {
					if ( qtySapInCart > 0 || this.ticketSAPYearsActive[seasonPassYear] ) {
						this.cartItems[idxCartItem].tickets[idxTicket].qty += amount;
					}
				} else { // else amount is negative
					// just prevent it from going below zero qty.
					this.cartItems[idxCartItem].tickets[idxTicket].qty = Math.max( 0, this.cartItems[idxCartItem].tickets[idxTicket].qty + amount ) // 5 + -1 = 4
				}
			} else if ( this.passIDToPassProps[ ticket.passID ].isSAP ) {
				if ( amount > 0 ) {
					if ( this.passIDToPassProps[ ticket.passID ].isRenewal ) {
						if ( amount + this.sap2024QtyInCart > this.allowedSAPQuantityTotal ) {
							// denied, trying to add too many renewals
						} else {
							this.cartItems[idxCartItem].tickets[idxTicket].qty += amount;
						}
					} else {
						this.cartItems[idxCartItem].tickets[idxTicket].qty += amount;
					}
				} else { // else amount is < 0
					// prevent SAP from becoming negative.
					this.cartItems[idxCartItem].tickets[idxTicket].qty = Math.max( 0, this.cartItems[idxCartItem].tickets[idxTicket].qty + amount ) // 5 + -1 = 4
					// reducing the SAP amount may cause the SPP amount, if any, to become zero.
					// calculations are done, after updating the SAP cart quantity.
					const qtySapInCart: number = this.cartItems.reduce( (out1: number, cItem: InterfaceVenuePassportCartItem): number => {
						if ( cItem.hasSAP ) {
							out1 += cItem.tickets.reduce( (out2: number, tItem: InterfaceVenuePassportCartItemTicket): number => {
								if ( this.passIDToPassProps[tItem.passID].year === seasonPassYear ) {
									out2 += this.passIDToPassProps[tItem.passID].isSAP ? tItem.qty : 0;
								}
								return out2;
							}, 0 );
						} // end if this cart item has any SAP.
						return out1;
					}, 0 );
					if ( !this.ticketSAPYearsActive[seasonPassYear] && qtySapInCart < 1 ) {
						// no known SAP for this year, have to take the SPP down to zero, if one exists.
						this.cartItems.forEach( (cItem: InterfaceVenuePassportCartItem, cartIdx: number, arrCartItems: InterfaceVenuePassportCartItem[]): void => {
							cItem.tickets.forEach( (tItem: InterfaceVenuePassportCartItemTicket, ticketIdx: number): void => {
								if ( this.passIDToPassProps[tItem.passID].isSPP && this.passIDToPassProps[tItem.passID].year === seasonPassYear ) {
									arrCartItems[cartIdx].tickets[ticketIdx].qty = 0;
									this.syncCartItemToTicketSelections( cItem );
								}
							} );
						} );
					} // end if this user does NOT have any season admission passes for the season year.
				} // end else amount is < 0 (for SAP)
			} // end if isSAP
			// don't acquire a held ticket ID.
			this.syncCartItemToTicketSelections( cartItem );
			this.calcGrandTotal();
			this.updateFlagsBasedOnCartItem();
			this.enforceCartQuantityOfAddOns();
			this.reSyncSAPQty2024InCart();
			return;
		}
		const isAnyDay: boolean = this.cartItems[idxCartItem].isAnyDay ?? false
		if ( isAnyDay ) {
			if ( amount > 0 ) {
				if ( this.cartItems[idxCartItem].tickets[idxTicket].qty > 9 ) {
					this.cartItems[idxCartItem].tickets[idxTicket].qty = 10; // limit 10
				}
				this.cartItems[idxCartItem].tickets[idxTicket].qty += amount;
			} else {
				this.cartItems[idxCartItem].tickets[idxTicket].qty = Math.max( 0, this.cartItems[idxCartItem].tickets[idxTicket].qty + amount ); // 5 + -1 = 4
			}
			this.syncCartItemToTicketSelections( cartItem );
			this.calcGrandTotal();
			this.updateFlagsBasedOnCartItem();
			this.enforceCartQuantityOfAddOns();
			return;
		}
		this.busy = true;
		if ( amount > 0 ) {
			// acquire a held ticket ID:
			this.owapi.workspace.actions.core.reserveTicket( // 6 params
				this.appConfig.getContext(),
				this.strWebRole,
				cartItem.date.year,
				cartItem.date.month,
				cartItem.date.day,
				ticket.passID
			).subscribe( (response: InterfaceHTTPGateway): void => {
				if ( response && response.success && response.status === 200 ) {
					const apiResponse: InterfaceOWAPIReserveItemResponse = response.data;
					if ( apiResponse && apiResponse.data && Array.isArray( apiResponse.data.items ) && apiResponse.data.items.length > 0 ) {
						const reservedItem: InterfaceOWReservedItem = apiResponse.data.items.shift() as InterfaceOWReservedItem; // .pop_front()
						if ( reservedItem && reservedItem.item_id ) {
							this.ticketHoldings[ticket.passID].push( reservedItem.item_id );
							this.cartItems[idxCartItem].tickets[idxTicket].qty += amount;
							this.calcGrandTotal();
							this.updateFlagsBasedOnCartItem();
							this.enforceCartQuantityOfAddOns();
						} else {
							// TODO: unsuccessful, no capacity left.
						}
					} else {
						// else server-side issue... we should always get an array of items back, even if blank.
					}
				} else { // else not a HTTP 200
					// server-side issue... doesn't mean there is or is not any supply left.
				}
				this.busy = false;
			} );
		} else { // else we are reducing a tickets quantity.
			if ( this.ticketHoldings[ticket.passID].length > 0 ) {
				const oldestHolding: string = this.ticketHoldings[ticket.passID][0]; // always try to use the oldest held ticket to give up first...
				this.owapi.workspace.actions.core.removeTicketReservation( this.appConfig.getContext(), [ oldestHolding ] ).subscribe( (response: InterfaceHTTPGateway): void => {
					let ohNoes: boolean = false;
					if ( response && response.success && response.status === 200 ) {
						const apiResponse: InterfaceOWAPICancelledReservationItemsResponse = response.data;
						if ( apiResponse && apiResponse.data && Array.isArray( apiResponse.data.items ) ) {
							// .data.items = [ { items_removed: string[] } ]
							const cancelledItemsData: InterfaceOWCancelledReservationItems = apiResponse.data.items.shift() as InterfaceOWCancelledReservationItems; // .pop_front()
							if ( cancelledItemsData && Array.isArray( cancelledItemsData.items_removed ) && cancelledItemsData.items_removed.length > 0 ) {
								// it doesn't even matter if we get a confirmation back, just destroy the holding on our side and --quantity.
							}
						} else {
							ohNoes = true;
							// server-side error. should always get back an array, even if it's empty.
						}
					} else {
						ohNoes = true;
					}
					if ( !ohNoes ) {
						for ( let x: number = 0; x < this.ticketHoldings[ticket.passID].length; ++x ) {
							if ( this.ticketHoldings[ticket.passID][x] === oldestHolding ) {
								this.ticketHoldings[ticket.passID].splice( x, 1 );
								break;
							}
						}
						this.cartItems[idxCartItem].tickets[idxTicket].qty = Math.max( 0, this.cartItems[idxCartItem].tickets[idxTicket].qty + amount ); // 5 + -1 = 4
						this.calcGrandTotal();
					}
					this.updateFlagsBasedOnCartItem();
					this.enforceCartQuantityOfAddOns();
					this.busy = false;
				} );
			} else {
				this.cartItems[idxCartItem].tickets[idxTicket].qty = 0;
				this.calcGrandTotal();
				this.updateFlagsBasedOnCartItem();
				this.enforceCartQuantityOfAddOns();
				this.busy = false;
			}
		}
	}

	public autoCleanLineItems(): void {
		// should not have to worry about held tickets here... because they're already zero quantity in our cart...
		// remove any line-items that have no tickets in them.
		for ( let x: number = 0; x < this.cartItems.length; ++x ) {
			// if all ticket quantities are zero, then wipe the line item.
			let allZeros: boolean = true;
			for ( let y: number = 0; allZeros && y < this.cartItems[x].tickets.length; ++y ) {
				allZeros = this.cartItems[x].tickets[y].qty < 1;
			} // end for each ticket in the line-item.
			if ( allZeros ) {
				this.cartItems.splice( x--, 1 );
			}
		} // end for each line-item.
		this.updateFlagsBasedOnCartItem();
		this.enforceCartQuantityOfAddOns();
	}

	public serializeCartItems(): InterfaceVenuePassportCompactCartItems[] {
		const output: InterfaceVenuePassportCompactCartItems[] = [];
		const ticketIDs: string[] = Object.keys( this.ticketHoldings );
		const ticketCounters: { [docletID: string]: number; } = {}; // kvp of counts of reserved tickets.
		for ( let x: number = 0; x < ticketIDs.length; ++x ) {
			ticketCounters[ ticketIDs[x] ] = 0;
		}
		for ( let x: number = 0; x < this.cartItems.length; ++x ) {
			const compactCartItem: InterfaceVenuePassportCompactCartItems = {
				d: { // date
					y: this.cartItems[x].date.year, // number
					m: this.cartItems[x].date.month, // number. 1 - 12 for normal tickets, 1 for season passes, 0 for any-day tickets.
					d: this.cartItems[x].date.day // number. 1 - 31 for normal tickets, 1 for season passes, 0 for any-day tickets.
				},
				t: [] // held-ticket IDs. season pass and any-day tickets won't have these.
			};
			for ( let y: number = 0; y < this.cartItems[x].tickets.length; ++y ) {
				const heldTickets: string[] = [];
				const passID: string = this.cartItems[x].tickets[y].passID;
				for ( let z: number = 0; z < this.cartItems[x].tickets[y].qty; ++z ) {
					if ( ticketCounters[passID] < this.ticketHoldings[passID].length ) {
						heldTickets.push( this.ticketHoldings[passID][ ticketCounters[passID]++ ] );
					}
				}
				const item: InterfaceVenuePassportCompactCartItems['t'][number] = {
					i: this.cartItems[x].tickets[y].passID,
					q: this.cartItems[x].tickets[y].qty,
					h: heldTickets
				};
				if ( this.cartItems[x].tickets[y]?.location?.length ?? 0 > 0 ) { // field may be missing. ought to be a string.
					item.l = this.cartItems[x].tickets[y].location;
				}
				compactCartItem.t.push( item );
			} // end for each ticket type, for this line-item.
			output.push( compactCartItem );
		} // end for each line-item.
		return output;
	}

	public checkout(): void {
		if ( this.cartItems.length > 0 ) {
			const compactCartItems: InterfaceVenuePassportCompactCartItems[] = this.serializeCartItems();
			const marioCart64: string = encodeURIComponent( btoa( JSON.stringify( {
				items: compactCartItems,
				promo: this.promoCode
			} ) ).replace( /=+$/, '' ).split( '' ).reverse().join( '' ) ); // Wahoo!
			// base64 decoding doesn't need the '=' char's at the end.
			// btoa( 'a' ) => 'YQ=='
			// and both atob( 'YQ==' ) and atoB( 'YQ' ) => 'a'
			this.router.navigateByUrl( '/' + this.routes.checkout + '/' + marioCart64, {
				state: {
					// on the next page, the nav state will be an array of things. you normally won't know which object is yours, unless you tag it somehow ahead of time...
					'data-type': 'cart-data',
					'data': {
						'items': compactCartItems,
						'promo': this.promoCode
					}
				}
			} ).then( (_: boolean): void => {
				// else force the url..?
			} )
		}
	}

	private packUpOrderedItems(): InterfaceOWAPIOrderItems[] {
		// do not use this fn to send data over the URL to reach /checkout. use the serialize fn instead.
		const output: InterfaceOWAPIOrderItems[] = [];
		const passIDs: string[] = Object.keys( this.ticketHoldings );
		const ticketCounters: { [passID: string]: number; } = {}; // kvp of counts of reserved tickets.
		for ( let x: number = 0; x < passIDs.length; ++x ) {
			ticketCounters[ passIDs[x] ] = 0;
		}
		for ( let x: number = 0; x < this.cartItems.length; ++x ) {
			const items: InterfaceOWAPIOrderItems['items'] = [];
			for ( let y: number = 0; y < this.cartItems[x].tickets.length; ++y ) {
				const passID: string = this.cartItems[x].tickets[y].passID;
				for ( let z: number = 0; z < this.cartItems[x].tickets[y].qty; ++z ) {
					const itemData: InterfaceOWAPIOrderItems['items'][number]['data'] = {};
					if ( this.cartItems[x].isSeasonPass ) {
						itemData.year = String( this.cartItems[x].date.year );
					}
					items.push( {
						doclet_id: passID,
						item_id: this.ticketHoldings[passID][ ticketCounters[passID]++ ] ?? null,
						source: 'web',
						data: itemData
					} );
				}
			}
			if ( items.length > 0 ) {
				output.push( {
					date: this.cartItems[x].date.year + '-' + ('0' + this.cartItems[x].date.month).slice( -2 ) + '-' + ('0' + this.cartItems[x].date.day).slice( -2 ),
					items: items
				} );
			}
		}
		return output;
	}

	private discountStackCookie: string = '';
	public useDiscountCode(): void {
		this.invalidPromoCode = false;
		this.discountAmount = 0;
		if ( this.promoCode.length < 1 ) {
			return;
		}
		// doesn't seem like the new pagination affects the returned discounted items...
		const stackCookie: string = String( Math.random() + '-' + new Date().getTime() );
		this.discountStackCookie = stackCookie;
		this.owapi.workspace.actions.core.checkPromoCode( this.appConfig.getContext(), this.packUpOrderedItems(), this.promoCode ).subscribe( (response: InterfaceHTTPGateway): void => {
			if ( stackCookie !== this.discountStackCookie ) {
				// console.log( 'something is calling useDiscountCode too quickly.' );
				return; // it's calcGrandTotal, caused by enforceCartQuantityOfAddOns, caused by +/- ticket selection.
			}
			let failed: boolean = true;
			if ( response && response.success ) {
				const apiResponse: InterfaceOWAPIPromoCodeResponse = response.data;
				if ( apiResponse && apiResponse.data && Array.isArray( apiResponse.data.items ) ) {
					failed = false;
					const items: InterfaceOWAPIPromoCodeItems[] = apiResponse.data.items;
					if ( items.length > 0 && items[0] && items[0].error ) {
						this.invalidPromoCode = true;
					} else {
						for ( let x: number = 0; x < items.length; ++x ) {
							// { date: 2022-07-04, items: [] }
							for ( let y: number = 0; y < items[x].items.length; ++y ) {
								let item: InterfaceOWAPIPromoCodeItemsItem = items[x].items[y];
								// "discount_applied" - how much money was taken off the product.
								// "item_price_discounted" - the new cost of the item, after the discount is applied.
								this.discountAmount = Number( (Number( item.discount_applied ) + this.discountAmount).toFixed( 2 ) );
								// }
							}
						}
					}
				}
			}
			if ( failed ) {
				this.invalidPromoCode = true;
			}
		} );
	}

	public selectedCabanaLocationIdx: number | undefined = undefined;
	public openCabanaLocations: string[] = [];
	public cabanaLocationSelectBusy: boolean = false;
	public pickCabanaLocation(): void {
		if ( this.calendarPassID ) {
			const passID: string = this.calendarPassID;
			const locationID: string | undefined = typeof this.selectedCabanaLocationIdx === 'number' ? this.openCabanaLocations?.[ this.selectedCabanaLocationIdx ] : undefined;
			if ( typeof locationID === 'undefined' ) {
				console.log( 'No location ID, aborting' );
				// TODO: un-reserve a previously selected cabana....
				return;
			}
			if ( !Array.isArray( this.ticketHoldings[passID] ) ) {
				this.ticketHoldings[passID] = [];
			} // hmm this needs to track things by ticket id AND location (seat) id...
			this.cabanaLocationSelectBusy = true;
			this.owapi.workspace.actions.core.reserveTicket( // 7 params instead of 6.
				this.appConfig.getContext(),
				this.strWebRole,
				this.selectedYear, // param 3
				this.selectedMonth,
				this.selectedDay,
				passID, // param 6
				locationID // param 7
			).subscribe( (response: InterfaceHTTPGateway): void => {
				this.cabanaLocationSelectBusy = false;
				if ( response?.success ) {
					const apiResponse: InterfaceOWAPIReserveItemResponse = response?.data;
					if ( Array.isArray( apiResponse?.data?.items ) ) {
						const reservedItem: InterfaceOWReservedItem | undefined = apiResponse.data.items.shift(); // .pop_front()
						if ( reservedItem?.item_id ) {
							this.ticketHoldings[passID].push( reservedItem.item_id );
							this.ticketSelections_v2.dailyPasses[passID].qty = 1;
							this.ticketSelections_v2.dailyPasses[passID].locationID = locationID;
							this.syncTicketSelectionToCart();
							// close all the things
							this.hideCalendarTicketSelection();
							this.resetCalendarTicketData();
							this.hideCalendar();
							this.toggleCart();
						} else {
							this.selectedCabanaLocationIdx = undefined;
							const YYYYMMDD1: string = this.selectedYear + '-' + ('0' + this.selectedMonth).slice( -2 ) + '-' + ('0' + this.selectedDay).slice( -2 );
							this.updateCabanaLocationsAvailable( YYYYMMDD1 );
							alert( 'Location #' + locationID + ' is no longer available.' ); // TODO: notification server. make use of it, style it, etc.
						}
					}
				}
			} );
		}
	}
}
