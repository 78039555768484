import {Injectable} from '@angular/core';
//
@Injectable( {
	providedIn: 'root'
} )
export class ServiceRegex {
	public static readonly emailRegExp: RegExp = /^(?:[\w!#$%&'*+\-\/=?^`{|}~]+\.)*[\w!#$%&'*+\-\/=?^`{|}~]+@(?:(?:[a-zA-Z0-9_](?:[a-zA-Z0-9_\-](?!\.)){0,61}[a-zA-Z0-9_-]?\.)+[a-zA-Z0-9_](?:[a-zA-Z0-9_\-](?!$)){0,61}[a-zA-Z0-9_]?|\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])])$/;
	public static readonly trimRegExp: RegExp = /^\s+|\s+$/g;
	public static readonly profilePhotoB64RegExp: RegExp = /^data:image\/(png|jpeg);base64,(([A-Za-z0-9+\/]{4})+([A-Za-z0-9+\/]===|[A-Za-z0-9+\/]{2}==|[A-Za-z0-9+\/]{3}=)?)$/;
	//
	public constructor() {
		//
	}
}
