import {Injectable} from '@angular/core';
// ===== Services ===== //
import {ServiceOWAPIAccountAuth} from './accounts/auth';
import {ServiceOWAPIAccountProfile} from './accounts/profile';
import {ServiceOWAPIAccountRegistration} from './accounts/registration';
//
@Injectable( {
	providedIn: 'root'
} )
export class ServiceOWAPIAccount {
	private readonly routePrefix: string = 'account/';
	//
	public constructor(
		public auth: ServiceOWAPIAccountAuth,
		public profile: ServiceOWAPIAccountProfile,
		public registration: ServiceOWAPIAccountRegistration
	) {
		//
	}
}
