import {Injectable} from '@angular/core';
//
@Injectable( {
	providedIn: 'root'
} )
export class TransformerText {
	//
	public constructor() {
		//
	}

	public static numOrdinal( num: number ): string {  // 11th, 1st, 22nd, 12th, etc.
		let ord: string = 'th'
		if ( num > 3 && num < 21 ) {
			return num + ord;
		}
		if ( num < -3 && num > -21 ) { // -11th, -1st, -22nd, -12th, etc.
			return num + ord; // -12nd, -13rd lol
		}
		switch ( num % 10 ) {
			case 0: case 4: case 5: case 6: case 7: case 8: case 9: { ord = 'th'; break; }
			case 1: { ord = 'st'; break; }
			case 2: { ord = 'nd'; break; }
			case 3: { ord = 'rd'; break; }
		}
		return num + ord;
	}

	public static dateFromYYYYMMDD( YYYYMM1DD: string ): string {
		// values are intended to be in YYYY-MM-DD format.
		// but also can be the value "any".
		if ( YYYYMM1DD === 'any' ) {
			return '--';
		}
		if ( /^\d\d\d\d-\d\d-\d\d$/.test( YYYYMM1DD ) ) {
			const arr: string[] = YYYYMM1DD.split( /-/g );
			return arr[1] + '/' + arr[2] + '/' + arr[0];
		}
		return 'N/A';
	}

	public static getYYYYMM1DD( D?: Date ): string {
		const d: Date = D ? D : new Date();
		return String( d.getFullYear() ) + '-' + String( '0' + (1 + d.getMonth()) ).slice( -2 ) + '-' + String( '0' + d.getDate() ).slice( -2 );
	}

	public static MMDDYYFromYYYYMMDD( YYYYMM1DD: string ): string {
		if ( /^\d\d\d\d-\d\d-\d\d$/.test( YYYYMM1DD ) ) {
			const arr: string[] = YYYYMM1DD.split( /-/g );
			return arr[1] + '/' + arr[2] + '/' + arr[0].slice( -2 );
		}
		return '';
	}
}
