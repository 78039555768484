<nav id="utility">
	<div class="wrapper">
		<i class="fa-solid fa-bell mobile-icon disabled"></i>
		<h1>
			<a *ngIf="router.url !== '/sign-in'" [routerLink]="[ '/' + routes.signIn ]" ><img alt="" src="assets/img/WR_MAINLOGO.png" /></a>
			<img *ngIf="router.url === '/sign-in'" alt="" src="assets/img/WR_MAINLOGO.png" />
		</h1>
		<ul class="action-menu">
			<li *ngIf="!lockdown">
				<span (click)="goBuyNow();">Buy Tickets</span>
			</li>
			<li *ngIf="!lockdown && isSignedIn">
				<span class="action" (click)="enabledLinkDashboard && goDashboard();">Dashboard</span>
			</li>
			<li *ngIf="!lockdown && isSignedIn">
				<span class="action" (click)="enabledLinkMyAccount && goMyAccount();">My Account</span>
			</li>
			<li>
				<span class="action" *ngIf="isSignedIn" (click)="signOut();">Sign Out</span>
				<span class="action" *ngIf="!isSignedIn" (click)="goSignIn();">Sign In</span>
			</li>
		</ul>
		<i class="fa-solid {{ isShowingMenu ? 'fa-xmark' : 'fa-bars' }} mobile-icon" (click)="toggleMenu();"></i>
	</div>
	<div id="mobile-menu" [ngClass]="{ active: isShowingMenu }" (click)="toggleMenu();">
		<ul>
			<li *ngIf="!lockdown">
				<span (click)="goBuyNow();">Buy Tickets</span>
			</li>
			<li *ngIf="!lockdown && isSignedIn">
				<span class="action" (click)="enabledLinkDashboard && goDashboard();">Dashboard</span>
			</li>
			<li *ngIf="!lockdown && isSignedIn">
				<span class="action" (click)="enabledLinkMyAccount && goMyAccount();">My Account</span>
			</li>
			<li>
				<span class="action" *ngIf="isSignedIn" (click)="signOut();">Sign Out</span>
				<span class="action" *ngIf="!isSignedIn" (click)="goSignIn();">Sign In</span>
			</li>
		</ul>
	</div>
</nav>
