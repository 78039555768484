<app-header></app-header>
<app-masthead
	[mastHeading]="mastHeading"
	[navMenuItems]="navMenuItems"
	[serialCode]="serialCode"
></app-masthead>
<div id="page-family" class="page wrapper">
	<section class="card columned cards-on-file">
		<div class="column"></div>
	</section>
</div>
