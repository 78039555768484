<app-header *ngIf="resetToken.length > 0"></app-header>
<div id="page-reset-password" class="page" *ngIf="resetToken.length > 0">
	<div class="heading">Reset Password</div>

	<div class="content-wrapper password-success" *ngIf="resetPasswordCompleted">
		<div>Your password was changed.</div>
		<div>Please <a [routerLink]="[ '/' + routes.signIn ]">Sign-In</a> to continue.</div>
	</div>

	<div class="content-wrapper" *ngIf="!resetPasswordCompleted">
		<label [class.error]="resetPasswordData.password1">
			<input type="password" autocomplete="off" name="password1" value="" placeholder="New Password"
				[(ngModel)]="resetPasswordData.password1"
				[ngModelOptions]="{ updateOn : 'blur' }"
				(ngModelChange)="validatePassword( 'password1' );"
				(keyup.enter)="blur( $event );"
			/>
			<span class="frame-label"><i class="fa-solid fa-lock"></i></span>
		</label>

		<label [class.error]="resetPasswordData.password2">
			<input type="password" autocomplete="off" name="password2" value="" placeholder="Re-Type Password"
				[(ngModel)]="resetPasswordData.password2"
				[ngModelOptions]="{ updateOn : 'blur' }"
				(ngModelChange)="validatePassword( 'password2' );"
				(keyup.enter)="blur( $event );"
			/>
			<span class="frame-label"><i class="fa-solid fa-lock"></i></span>
		</label>
	</div>

	<div class="action-wrapper" *ngIf="!resetPasswordCompleted">
		<div class="actions">
			<span class="button onwards" (click)="resetPassword();">Submit</span>
		</div>
	</div>

	<div class="error-wrapper">
		<div *ngIf="invalidToken">Your token has expired or is invalid. Please use <a [routerLink]="[ '/' + routes.signIn ]">reset your password</a> to receive a new one.</div>
	</div>
</div>
