import {Pipe, PipeTransform} from '@angular/core';
//
@Pipe( {
	name: 'replace',
} )
export class PipeReplace implements PipeTransform {
	public transform( input: string | null, pattern: any, replacement: any ): any {
		return input ? input.toString().replace( pattern, replacement ) : input;
	}
}
