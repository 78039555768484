// Passes are the things for sale on the portal. They're content/cosmetic.
// They're not tickets. Tickets are created when a pass is sold.

import {InterfaceOWDoclet} from '../ow/doclets';

export interface InterfaceOWAPITicketPriceByDatePriceTier {
	count: number; // how many tickets must be sold...
	price: number; // ...before this price tag is used.
}

export interface InterfaceOWAPITicketPriceByDate {
	date: string; // 'YYYY-MM-DD' or the word 'default' to use, if your YYYYMMDD doesn't find a match.
	time?: string; // HH:MM:SS format. used for the evening logic.
	price: number;
	sph_price?: number;
	sph_price_restriction?: string[]; // an array of years
	price_tier?: InterfaceOWAPITicketPriceByDatePriceTier[];
}

export interface InterfaceVenuePassportPass<PriceType> {
	name: string;
	price: PriceType; // the un-transformed version uses InterfaceOWAPITicketPriceByDate[]. the transformed version uses { [YYYYMMDD: string] : InterfaceOWAPITicketPriceByDate; }
	status: 'active' | 'hidden' | string;
	target: {
		template_id: string; // the template of the ticket this pass represents.
	};
}

export interface InterfaceVenuePassportSeasonPass extends InterfaceVenuePassportPass<InterfaceOWAPITicketPriceByDate[]> {
	year: string;
}

export interface InterfaceOWTemplateCabanaPass extends InterfaceVenuePassportPass<InterfaceOWAPITicketPriceByDate[]> {
	color: string; // i don't think this field is in use...
}

export interface InterfaceOWTemplateDailyAdmissionPass extends InterfaceVenuePassportPass<InterfaceOWAPITicketPriceByDate[]> {}

export interface InterfaceOWTemplateParkingPass extends InterfaceVenuePassportPass<InterfaceOWAPITicketPriceByDate[]> {}

export interface InterfaceOWTemplateSeasonAdmissionPass extends InterfaceVenuePassportSeasonPass {}

export interface InterfaceOWTemplateSeasonParkingPass extends InterfaceVenuePassportSeasonPass {}

/*
{
	"_id" : ObjectId("629a45934d177beedbe7a157"),
	"name" : "Cabana Pass",
	"workspaces" : [
		ObjectId("6222b56fb41f06d49ea56fc7")
	],
	"realms" : [
		ObjectId("6222b4b8b41f06d49ea56fc6")
	],
	"fields" : [
		{
			"key" : "name",
			"label" : "Name",
			"sort" : 0,
			"type" : "string",
			"default" : "",
			"readonly" : false
		},
		{
			"key" : "color",
			"label" : "Color",
			"sort" : 1,
			"type" : "string",
			"default" : "",
			"readonly" : false
		},
		{
			"key" : "price",
			"label" : "Price",
			"sort" : 3,
			"type" : "object_array",
			"readonly" : false
		},
		{
			"label" : "Status",
			"key" : "status",
			"sort" : 4,
			"type" : "string",
			"default" : "",
			"readonly" : false,
			"enum" : [
				"active",
				"hidden"
			]
		},
		{
			"label" : "Target",
			"key" : "target",
			"sort" : 5,
			"type" : "object",
			"readonly" : true
		}
	],
	"tags" : [
		"Pass"
	],
	"group" : "Pass"
}
{
	"_id" : ObjectId("62aa0ce25576850cbaa529da"),
	"name" : "Daily Admission Pass",
	"workspaces" : [
		ObjectId("6222b56fb41f06d49ea56fc7")
	],
	"fields" : [
		{
			"type" : "string",
			"label" : "Name",
			"key" : "name",
			"sort" : 0,
			"default" : "",
			"readonly" : false
		},
		{
			"label" : "Price",
			"key" : "price",
			"sort" : 1,
			"type" : "object_array",
			"readonly" : false
		},
		{
			"label" : "Status",
			"key" : "status",
			"sort" : 2,
			"type" : "string",
			"default" : "active",
			"readonly" : false,
			"enum" : [
				"active",
				"hidden"
			]
		},
		{
			"label" : "Target",
			"key" : "target",
			"sort" : 3,
			"type" : "object",
			"readonly" : true
		},
		{
			"label" : "Is Add-On",
			"key" : "is_addon",
			"sort" : 4,
			"type" : "boolean",
			"default" : false,
			"readonly" : false
		},
		{
			"label" : "Is Promoted",
			"key" : "is_promoted",
			"sort" : 5,
			"type" : "boolean",
			"default" : false,
			"readonly" : false
		},
		{
			"label" : "Is Junior",
			"key" : "is_junior",
			"sort" : 6,
			"type" : "boolean",
			"default" : false,
			"readonly" : false
		},
		{
			"label" : "Limit Per Order",
			"key" : "limit_per_order",
			"sort" : 7,
			"type" : "number",
			"default" : 0,
			"readonly" : false
		}
	],
	"realms" : [
		ObjectId("625f38c561b6eb3efaebceb3")
	],
	"tags" : [
		"Pass"
	],
	"group" : "Pass"
}
{
	"_id" : ObjectId("62aa0dba5576850cbaa529db"),
	"name" : "Parking Pass",
	"workspaces" : [
		ObjectId("6222b56fb41f06d49ea56fc7")
	],
	"fields" : [
		{
			"type" : "string",
			"label" : "Name",
			"key" : "name",
			"sort" : 0,
			"default" : "",
			"readonly" : false
		},
		{
			"label" : "Price",
			"key" : "price",
			"sort" : 1,
			"type" : "object_array",
			"readonly" : false
		},
		{
			"label" : "Status",
			"key" : "status",
			"sort" : 2,
			"type" : "string",
			"default" : "",
			"readonly" : false,
			"enum" : [
				"active",
				"hidden"
			]
		},
		{
			"label" : "Target",
			"key" : "target",
			"sort" : 3,
			"type" : "object",
			"readonly" : true
		}
	],
	"realms" : [
		ObjectId("625f38c561b6eb3efaebceb3")
	],
	"tags" : [
		"Pass"
	],
	"group" : "Pass"
}
{
	"_id" : ObjectId("62d889e5f56d739da86c9b76"),
	"name" : "Season Admission Pass",
	"fields" : [
		{
			"key" : "name",
			"label" : "Name",
			"sort" : 0,
			"type" : "string",
			"default" : "",
			"readonly" : false
		},
		{
			"key" : "price",
			"label" : "Price",
			"sort" : 1,
			"type" : "object_array",
			"readonly" : false
		},
		{
			"key" : "status",
			"label" : "Status",
			"sort" : 2,
			"type" : "string",
			"default" : "",
			"readonly" : false,
			"enum" : [
				"active",
				"hidden"
			]
		},
		{
			"key" : "target",
			"label" : "Target",
			"sort" : 3,
			"type" : "object",
			"readonly" : true
		},
		{
			"key" : "year",
			"label" : "Year",
			"sort" : 4,
			"type" : "string",
			"default" : "",
			"readonly" : false
		},
		{
			"key" : "is_sap",
			"label" : "Is a Season Admission Pass",
			"sort" : 5,
			"type" : "boolean",
			"default" : true,
			"readonly" : false
		}
	],
	"realms" : [
		ObjectId("625f38c561b6eb3efaebceb3")
	],
	"workspaces" : [
		ObjectId("6222b56fb41f06d49ea56fc7")
	],
	"tags" : [
		"Pass"
	],
	"group" : "Pass"
}
{
	"_id" : ObjectId("637d0f566253e4daf151e4b1"),
	"name" : "Season Parking Pass",
	"fields" : [
		{
			"key" : "name",
			"label" : "Name",
			"sort" : 0,
			"type" : "string",
			"default" : "",
			"readonly" : false
		},
		{
			"key" : "price",
			"label" : "Price",
			"sort" : 1,
			"type" : "object_array",
			"readonly" : false
		},
		{
			"key" : "status",
			"label" : "Status",
			"sort" : 2,
			"type" : "string",
			"default" : "",
			"readonly" : false,
			"enum" : [
				"active",
				"hidden"
			]
		},
		{
			"key" : "target",
			"label" : "Target",
			"sort" : 3,
			"type" : "object",
			"readonly" : true
		},
		{
			"key" : "year",
			"label" : "Year",
			"sort" : 4,
			"type" : "string",
			"default" : "",
			"readonly" : false
		},
		{
			"key" : "is_spp",
			"label" : "Is a Season Parking Pass",
			"sort" : 5,
			"type" : "boolean",
			"default" : true,
			"readonly" : true
		}
	],
	"realms" : [
		ObjectId("625f38c561b6eb3efaebceb3")
	],
	"workspaces" : [
		ObjectId("6222b56fb41f06d49ea56fc7")
	],
	"tags" : [
		"Pass"
	],
	"group" : "Pass"
}
*/

export interface InterfaceVenuePassportTicketPricing {
	default: number; // the price tag of a ticket or pass, if the user is not a season pass holder...
	time?: string; // HH:MM:SS format, for when the ticket is allowed to be activated for the day. (evening logic)
	sph: number | null; // the price tag if the user is a season pass holder, but may be restricted to certain prior seasons.
	sphRestriction: { // if the values (a year) exists, then sph price is only available, if the user has a season admission from an enabled year.
		[YYYY: string]: boolean; // "2022": true
	};
	priceTier?: InterfaceOWAPITicketPriceByDatePriceTier[]; // an array of rules, basically if the number of tickets sold reaches a threshold, the prices change.
}

export interface InterfaceVenuePassportTicketPriceType {
	[YYYYMMDD: string]: InterfaceVenuePassportTicketPricing;
}

export interface InterfaceDocletIDToTicketProps_T<PriceType> {
	name: string;
	price: PriceType; // POS and Web price-formats have diverged. the Web is transforming them, so that it's easier on the HTML to access it. (calendar)
	sort: number;
	isDailyAdmission: boolean;
	isAnyDay?: boolean; // if true, the date this ticket is valid, should be any normal operating day...
	isEvening?: boolean; // daily admission, daily parking and cabana only. if true, displays a little note about the valid start-times...
	skipCapacityCheck: boolean; // if true, this pass does not obey the normal capacity/date logic for daily admissions.
	isAddOn?: boolean; // if true, it requires a Daily Admission pass to be in the cart, first.
	isPromoted?: boolean; // if true, it appears above the list of (whatever type it is)
	isLimitPerOrder?: boolean; // if true, a (larger than zero) value exists for .data.limit_per_order, and users cannot add more than X to the cart.
	isDailyParking: boolean; // non-season parking pass.
	isJunior?: boolean; // Jr admission
	isCabana: boolean;
	isComplexBundle?: boolean;
	isCompensation: boolean; // these have a special date of 'any' to make them a one-time use pass for any day.
	isDiscounted?: boolean; // certain cabana passes are marked for special use.. gold/diamond discounts, etc.
	isSeasonPass: boolean; // both season admission and season parking passes...
	isRenewal?: boolean;
	isSAP: boolean; // season admission pass.
	isSPP: boolean; // season parking pass.
	year?: string; // what year the season pass, is for.
	level?: number; // what SAP level (0 - 4) known as: [ not-yet-used, Silver, Gold, Diamond ]
	isCertification: boolean; // life-guard and CPR certificates that are for sale.
	isMerchandise: boolean;
	role: {
		admin: boolean;
		pos: boolean;
		staff: boolean;
		web: boolean;
	};
	doclet: InterfaceOWDoclet;
}

export interface InterfaceDocletIDToTicketProps extends InterfaceDocletIDToTicketProps_T<InterfaceVenuePassportTicketPriceType> {}
