// ===== App ===== //
import {AppComponent} from './app.component';
import {AppConfig} from './app.config';
import {AppEvents} from './app.events';
import {AppRoutingModule} from './app-routing.module';
// ===== Collections ===== //
import {Collection} from './collections/collection';
import {CollectionProfiles} from './collections/profiles';
// ===== Components ===== //
// see: ModuleComponents
// ===== Modals ===== //
import {ModalBackDrop} from './modals/back-drop/back-drop';
import {ModalCancelReservation} from './modals/cancel-reservation/cancel-reservation';
import {ModalCreditCard} from './modals/credit-card/credit-card';
import {ModalEditGroupMember} from './modals/edit-group-member/edit-group-member';
import {ModalInviteGroupMember} from './modals/invite-group-member/invite-group-member';
import {ModalLiabilityWaiver} from './modals/liability-waiver/liability-waiver';
import {ModalTinySignIn} from './modals/tiny-sign-in/tiny-sign-in';
// ===== Modules ===== //
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule, Title} from '@angular/platform-browser';
import {ModuleComponents} from './components/components.module';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {ImageCropperModule} from 'ngx-image-cropper';
import {NgModule} from '@angular/core';
import {RecaptchaModule} from 'ng-recaptcha';
import {ModulePipes} from './pipes/pipes.module';
import {QRCodeModule} from 'angularx-qrcode';
// ===== Pages ===== //
import {PageBuylater} from './pages/buylater/buylater';
import {PageBuyNow} from './pages/buynow/buynow';
import {PageCheckEmail} from './pages/check-email/check-email';
import {PageCheckout} from './pages/checkout/checkout';
import {PageCompleteAccount} from './pages/complete-account/complete-account';
import {PageCreateGroup} from './pages/create-group/create-group';
import {PageDashboard} from './pages/dashboard/dashboard';
import {PageEditFamily} from './pages/edit-family/edit-family';
import {PageEditGroup} from './pages/edit-group/edit-group';
import {PageFamily} from './pages/family/family';
import {PageMyAccount} from './pages/my-account/my-account';
import {PageOrders} from './pages/orders/orders';
import {PageResetPassword} from './pages/reset-password/reset-password';
import {PageSignIn} from './pages/sign-in/sign-in';
import {PageThankYou} from './pages/thank-you/thank-you';
// ===== Pipes ===== //
// see: ModulePipes
// ===== Services ===== //
import {ServiceAuthentication} from './services/authentication';
import {ServiceNavigate} from './services/navigate';
import {ServiceNotifications} from './services/notifications';
import {ServiceOWAPI} from './services/ow-api';
import {ServiceOWAPIAccount} from './services/api/ow/account';
import {ServiceOWAPIAccountAuth} from './services/api/ow/accounts/auth';
import {ServiceOWAPIAccountProfile} from './services/api/ow/accounts/profile';
import {ServiceOWAPIAccountRegistration} from './services/api/ow/accounts/registration';
import {ServiceOWAPIAssets} from './services/api/ow/assets';
import {ServiceOWAPIAssetsFiles} from './services/api/ow/assets/files';
import {ServiceOWAPIMessage} from './services/api/ow/message';
import {ServiceOWAPIMessageChannels} from './services/api/ow/messages/channels';
import {ServiceOWAPISupport} from './services/api/ow/support';
import {ServiceOWAPIWorkspace} from './services/api/ow/workspace';
import {ServiceOWAPIWorkspaceActions} from './services/api/ow/workspace/actions';
import {ServiceOWAPIWorkspaceDoclets} from './services/api/ow/workspace/doclets';
import {ServiceOWAPIWorkspaceSettings} from './services/api/ow/workspace/settings';
import {ServiceOWAPIWorkspaceTasks} from './services/api/ow/workspace/tasks';
import {ServiceOWAPIWorkspaceTemplates} from './services/api/ow/workspace/templates';
import {ServiceOWAPIWorkspaceWorkspaces} from './services/api/ow/workspace/workspaces';
import {ServiceOWGateway} from './services/api/ow/ow-gateway';
import {ServiceRegex} from './services/regex';
import {ServiceSorting} from './services/sorting';
import {ServiceWorkspaces} from './services/workspaces';
// ===== Transformers ===== //
import {TransformerMongoObjectId} from './transformers/mongo/ObjectId';
import {TransformerOWAPI} from './transformers/owapi';
import {TransformerText} from './transformers/text';
import {TransformerVenuePassportTicket} from './transformers/vpTicket';
import {TransformerTransactions} from './transformers/transactions';
//
@NgModule( {
	bootstrap: [
		AppComponent
	],
	declarations: [ // components, modals (not modules), pages, pipes
		AppComponent,
		ModalBackDrop,
		ModalCancelReservation,
		ModalCreditCard,
		ModalEditGroupMember,
		ModalInviteGroupMember,
		ModalLiabilityWaiver,
		ModalTinySignIn,
		PageBuylater,
		PageBuyNow,
		PageCheckEmail,
		PageCheckout,
		PageCompleteAccount,
		PageCreateGroup,
		PageDashboard,
		PageEditFamily,
		PageEditGroup,
		PageFamily,
		PageMyAccount,
		PageOrders,
		PageResetPassword,
		PageSignIn,
		PageThankYou
	],
	exports: [ // modules
	],
	imports: [ // modules (not modals)
		AppRoutingModule,
		BrowserAnimationsModule,
		BrowserModule,
		ModuleComponents,
		FormsModule,
		HttpClientModule,
		ImageCropperModule,
		RecaptchaModule,
		ModulePipes,
		QRCodeModule
	],
	providers: [ // collections, services, transformers, injectables (things dragged through constructors)
		AppConfig,
		AppEvents,
		Collection,
		CollectionProfiles,
		ServiceAuthentication,
		ServiceNavigate,
		ServiceNotifications,
		ServiceOWAPI,
		ServiceOWAPIAccount,
		ServiceOWAPIAccountAuth,
		ServiceOWAPIAccountProfile,
		ServiceOWAPIAccountRegistration,
		ServiceOWAPIAssets,
		ServiceOWAPIAssetsFiles,
		ServiceOWAPIMessage,
		ServiceOWAPIMessageChannels,
		ServiceOWAPISupport,
		ServiceOWAPIWorkspace,
		ServiceOWAPIWorkspaceActions,
		ServiceOWAPIWorkspaceDoclets,
		ServiceOWAPIWorkspaceSettings,
		ServiceOWAPIWorkspaceTasks,
		ServiceOWAPIWorkspaceTemplates,
		ServiceOWAPIWorkspaceWorkspaces,
		ServiceOWGateway,
		ServiceRegex,
		ServiceSorting,
		ServiceWorkspaces,
		Title,
		TransformerMongoObjectId,
		TransformerOWAPI,
		TransformerText,
		TransformerVenuePassportTicket,
		TransformerTransactions
	]
} )
export class AppModule {}
