<ul class="horizontal-nav">
	<li class="horizontal-nav-item"
		routerLinkActive="active"
		*ngFor="let navItem of navItems; index as x;"
	>
		<a
			[routerLink]="[ navItem.route ]"
			[queryParams]="navItem.query ? navItem.query : null"
		>
			<!-- <i *ngIf="navItem['fa-icon']" [ngClass]="'fal ' + (navItem['fa-icon'] ? navItem['fa-icon'] : '')"></i> -->
			<span *ngIf="navItem.text">{{ navItem.text }}</span>
		</a>
	</li>
</ul>
