import {Component} from '@angular/core';
import {Title} from '@angular/platform-browser';
// ===== App ===== //
import {AppConfig} from '../../app.config';
import {AppRouterLinks} from '../../app.router-links';
// ===== Collections ===== //
import {CollectionProfiles} from '../../collections/profiles';
// ===== Interfaces ===== //
import {
	InterfaceAppContext,
	InterfaceDocletIDToTicketProps_T,
	InterfaceHTTPGateway,
	InterfaceOWAPIBulkRecordRequest,
	InterfaceOWAPIDailyAdmissionAvailabilityResponse,
	InterfaceOWAPIGetDocletResponse,
	InterfaceOWAPIGetWeavesResponse,
	InterfaceOWAPITicketPriceByDatePriceTier,
	InterfaceOWDailyAdmissionAvailability,
	InterfaceOWDoclet,
	InterfaceOWUser,
	InterfaceOWWeaveV2,
	InterfaceVenuePassportCartItem,
	InterfaceVenuePassportPass,
	InterfaceVenuePassportTicketPriceType,
	InterfaceVenuePassportTicketPricing
} from '../../interfaces/interfaces';
interface InterfaceDocletIDToTicketProps extends InterfaceDocletIDToTicketProps_T<InterfaceVenuePassportTicketPriceType> {}
interface InterfaceSeasonPassEntitlements {
	includes: string[];
	excludes: string[];
}
interface InterfaceTicketSelectionData {
	qty: number;
	locationID?: string; // cabana's have this.
}
interface InterfaceTicketSelections {
	seasonPasses: { // season admission and season parking.
		[passID: string]: InterfaceTicketSelectionData;
	};
	anyDayPasses: { // general & junior 'any' day tickets.
		[passID: string]: InterfaceTicketSelectionData;
	};
	dailyPasses: { // general admission, junior admission, cabana, parking.
		[passID: string]: InterfaceTicketSelectionData;
	};
	complexPasses: {
		[passID: string]: InterfaceTicketSelectionData;
	}
}
interface InterfaceYYYYMM1DD {
	year: number;
	month1: number; // 1 though 12
	day: number; // 1 through 31.
}
// ===== Services ===== //
import {ServiceAuthentication} from '../../services/authentication';
import {ServiceOWAPI} from '../../services/ow-api';
import {ServiceSorting} from '../../services/sorting';
// ===== Transformers ===== //
import {TransformerVenuePassportTicket} from '../../transformers/vpTicket';
//
const now: Date = new Date();
const parkOpensOn2024Jan1st: Date = new Date( 2024, 0, 1, 0, 0, 0, 0 );
if ( now.getTime() < parkOpensOn2024Jan1st.getTime() ) {
	now.setTime( parkOpensOn2024Jan1st.getTime() );
}
const parkClosesOn2024Oct1st: Date = new Date( 2024, 9, 1, 0, 0, 0, 0 );
//
@Component( {
	selector: 'page-buylater',
	templateUrl: './buylater.html',
	styleUrls: [
		'./buylater.less'
	]
} )
export class PageBuylater {
	public readonly routes: typeof AppRouterLinks = AppRouterLinks;
	public readonly isParkeClosed: boolean = new Date().getTime() > parkClosesOn2024Oct1st.getTime();
	public isSignedIn: boolean = false;
	public busy: boolean = false; // true when we're doing things that need to be in order/synchronous.
	// ===== Ticket Availability ===== //
	public dailyAdmissionAvailability: InterfaceOWDailyAdmissionAvailability = {}; // [YYYY-MM-DD] : { location: int } // MM is 01 - 12
	public availabilityLoaded: boolean = false;
	// ===== Template IDs ===== //
	private readonly strCabanaPassTemplateID: string = this.appConfig.getTemplateID( 'Cabana Pass' );
	private readonly strConsumerTemplateID: string = this.appConfig.getTemplateID( 'Consumer' );
	private readonly strDailyAdmissionPassTemplateID: string = this.appConfig.getTemplateID( 'Daily Admission Pass' );
	private readonly strFamilyTemplateID: string = this.appConfig.getTemplateID( 'Family' );
	private readonly strParkingPassTemplateID: string = this.appConfig.getTemplateID( 'Parking Pass' );
	private readonly strSeasonAdmissionPassTemplateID: string = this.appConfig.getTemplateID( 'Season Admission Pass' );
	private readonly strSeasonAdmissionTicketTemplateID: string = this.appConfig.getTemplateID( 'Season Admission Ticket' );
	private readonly strSeasonParkingPassTemplateID: string = this.appConfig.getTemplateID( 'Season Parking Pass' );
	private readonly strSeasonParkingTicketTemplateID: string = this.appConfig.getTemplateID( 'Season Parking Ticket' );
	private readonly strComplexProductTemplateID: string = this.appConfig.getTemplateID( 'Complex Product Pass' );
	// ===== Role IDs ===== //
	private readonly strWebRole: string = this.appConfig.getRoleID( 'Web' );
	// ===== Passes For Sale ===== //
	public passIDToPassProps: { [passID: string]: InterfaceDocletIDToTicketProps; } = {};
	public dailyAdmissionPasses: InterfaceOWDoclet[] = [];
	public promotedDailyAdmissionPasses: InterfaceOWDoclet[] = [];
	public cabanaPasses: InterfaceOWDoclet[] = [];
	public dailyParkingPasses: InterfaceOWDoclet[] = [];
	public seasonAdmissionPasses: InterfaceOWDoclet[] = [];
	public seasonAdmissionPassRenewals: InterfaceOWDoclet[] = [];
	public seasonPassT1: InterfaceOWDoclet | undefined = undefined; // Silver
	public seasonPassT1Entitlements: InterfaceSeasonPassEntitlements = {
		includes: [],
		excludes: []
	};
	public seasonPassT1Renewal: InterfaceOWDoclet | undefined = undefined; // Silver Renewal (doesn't exist)
	public seasonPassT2: InterfaceOWDoclet | undefined = undefined; // Gold
	public seasonPassT2Entitlements: InterfaceSeasonPassEntitlements = {
		includes: [],
		excludes: []
	};
	public seasonPassT2Renewal: InterfaceOWDoclet | undefined = undefined; // Gold Renewal
	public seasonPassT3: InterfaceOWDoclet | undefined = undefined; // Diamond
	public seasonPassT3Entitlements: InterfaceSeasonPassEntitlements = {
		includes: [],
		excludes: []
	};
	public seasonPassT3Renewal: InterfaceOWDoclet | undefined = undefined;
	public seasonParkingPasses: InterfaceOWDoclet[] = [];
	public complexProducts: InterfaceOWDoclet[] = [];
	// ===== Season Pass Configs ===== //
	public showingSAPEntitlements: boolean = true; // when true, it causes the gird layout to do bad things...
	public isSeasonPassHolder: boolean = false;
	public readonly currentSeasonYear: string = '2024';
	public readonly priorSeasonYear: string = '2023';
	public ticketSAPYearsActive: { [year: string]: boolean; } = {}; // if the user has a 2022 season pass that isn't cancelled, then we'll see {"2022":true} -- used for sph_pricing logic.
	public ticketSAPYearQuantity: { [year: string]: { [level: string]: number; }; } = {}; // this was used for the old 1:1 renewal logic.
	public totalQtySAPOwned20220223Max: number = 0; // sap renewals are supposed to be 1:any instead of 1:1
	public totalQtySAPOwned2024: number = 0; // basically how many passes were "renewed" because they own some 2024 passes...
	private ticketSPPYearActive: { [year: string]: boolean; } = {}; // not really used, other than logging.
	private ticketSPPYearQuantity: { [year: string]: number; } = {};
	public allowedSAPQuantityByTier: { [level: string]: number; } = {};
	public allowedSAPQuantityTotal: number = 0; // how many total passes can be renewed.
	private SPHCounter: number = 0; // the amount of detected consumers with a season pass holder...need to process them all to see if they have valid season passes, etc.
	// ===== Cart Items, Ticket Selection, Holdings, etc ===== //
	public ticketSelections: InterfaceTicketSelections = {
		seasonPasses: {},
		anyDayPasses: {},
		dailyPasses: {},
		complexPasses: {}
	};
	private ticketHoldings: { [docletID: string]: string[]; } = {};
	public cartItems: InterfaceVenuePassportCartItem[] = [];
	public cartCount: number = 0;
	public grandTotal: number = 0;
	public discountAmount: number = 0;
	// ===== Discount Codes ===== //
	public promoCode: string = '';
	public invalidPromoCode: boolean = false;
	// ===== Display Notes (things that ought to be stored, on the pass's content for display) ===== //
	public readonly jrText1: string = '(Under 48")';
	public readonly jrText2: string = '(Guests 2 and under get in free)';
	public readonly addonText: string = '(Daily Admission ticket required)';
	public readonly eveningTextA: string = '(Valid '; // (Valid 4pm to 7pm only.)
	public readonly eveningTextB: string = ' to closing only)';
	public readonly limitPerOrderTextA: string = '(Limit '; // limitPerOrderTextA + '6' + limitPerOrderTextB
	public readonly limitPerOrderTextB: string = ' per order)'; // white-space already exists before/after the number.
	public readonly carloadText1: string = '(Maximum 7 guests)';
	public readonly carloadText2: string = '(Includes Parking Fee)';
	// ===== Calendar ===== //
	public readonly monthLabels: string[] = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
	public selectedPassForCalendar: InterfaceDocletIDToTicketProps | undefined = undefined;
	public calendarRangeStart: InterfaceYYYYMM1DD = {
		year: now.getFullYear(),
		month1: 1 + now.getMonth(),
		day: now.getDate()
	};
	public calendarRangeEnd: InterfaceYYYYMM1DD = {
		year: parkClosesOn2024Oct1st.getFullYear(),
		month1: 1 + parkClosesOn2024Oct1st.getMonth(),
		day: parkClosesOn2024Oct1st.getDate()
	};
	// UI States
	public didPickDate: boolean = false; // true if they picked a date at all.
	public selectedDate: InterfaceYYYYMM1DD = {
		year: now.getFullYear(),
		month1: 1 + now.getMonth(),
		day: now.getDate()
	};
	//
	public constructor(
		private readonly appConfig: AppConfig,
		private readonly auth: ServiceAuthentication,
		private readonly colProfiles: CollectionProfiles,
		private readonly owapi: ServiceOWAPI,
		private readonly title: Title
	) {
		this.isSignedIn = this.auth.isSignedIn();
		this.title.setTitle( 'Wild Rivers Waterpark Irvine Tickets & Season Passes' );
		this.selectedDate = {
			year: now.getFullYear(),
			month1: 1 + now.getMonth(),
			day: now.getDate()
		}
		//
		// TODO: T1/T2/T3 entitlements - this should come from the pass doclet.
		//
		this.seasonPassT1Entitlements.includes.push(
			'Limited Admission Days',
			'Early Entry ($10 Upcharge)'
		);
		this.seasonPassT1Entitlements.excludes.push(
			'September & October',
			'Season Parking Pass',
			'Season Pass Holder Preview',
			'Season Pass Bring-a-Friend Free',
			'Discount on Food & Drinks',
			'Season Souvenir Bottle',
			'Discount on Retail',
			'Discount on Cabanas'
		);
		this.seasonPassT2Entitlements.includes.push(
			'Includes the rest of 2023',
			'Daily Admission',
			'All Weekends',
			'Season Pass Preview Day',
			'Two (2) Early Entry on Select Days',
			'Season Parking Pass Addon Available for $95',
			'Bring-a-Friend Free in May',
			'Season Pass Holder Bonus Hour for $10',
			'Season Souvenir Bottle for $45'
		);
		this.seasonPassT2Entitlements.excludes.push(
			'Discount on Food & Drinks',
			'Discount on Retail',
			'Discount on Cabanas'
		);
		this.seasonPassT3Entitlements.includes.push(
			'Includes the rest of 2023',
			'Daily Admission',
			'All Weekends',
			'Season Pass Preview Day',
			'Early Entry on Select Days',
			'Season Parking Pass Addon for $95',
			'15% off Food & Drinks',
			'10% off Retail',
			'10% off Weekday Cabanas',
			'10% off Weekend Cabanas',
			'All Early Entry Dates',
			'Season Pass Holder Bonus Hours (4 times a year)',
			'Bring-a-Friend Free on Selected Days'
		);
		//
		this.owapi.workspace.actions.core.recordResourceUse( this.appConfig.getContext(), this.routes.buyNow, 'page', {
			'isParkClosed': this.isParkeClosed,
			'isSignedIn': this.auth.isSignedIn(),
			'profileID': this.auth.getProfileID()
		} ).subscribe( (): void => {
			// fire and forget.
		} );
		this.owapi.workspace.actions.core.getDailyAdmissionAvailabilityFromDateRange( this.appConfig.getContext(), now, parkClosesOn2024Oct1st, this.strWebRole ).subscribe( (response: InterfaceHTTPGateway): void => {
			if ( response?.success ) {
				const apiResponse: InterfaceOWAPIDailyAdmissionAvailabilityResponse | undefined = response.data;
				// don't have to worry about pagination. data.items is an array of length 1.
				if ( apiResponse && Array.isArray( apiResponse?.data?.items ) ) {
					if ( apiResponse.data.items.length > 0 ) {
						this.dailyAdmissionAvailability = apiResponse.data.items[0];
						this.availabilityLoaded = true;
						console.log( 'Availability', this.dailyAdmissionAvailability );
						this.updateLowestPrices();
					}
				}
			}
		} );
		//
		this.owapi.workspace.doclets.getAllDocletsByTemplateID( this.appConfig.getContext(), {
			templateID: [ // daily stuff only. no season passes. (had to add a filter for .data.year)
				this.strDailyAdmissionPassTemplateID,
				this.strParkingPassTemplateID,
				this.strCabanaPassTemplateID,
				this.strComplexProductTemplateID
			],
			query: {
				'data.status': 'active',
				'ow_roles': '{id}' + this.strWebRole
			},
			withoutAuth: true
		}, false, (response: InterfaceOWAPIBulkRecordRequest<InterfaceOWDoclet>): void => {
			if ( response.success ) {
				this.processPassDoclets( response.records );
				this.dailyAdmissionPasses.sort( (A:InterfaceOWDoclet, B:InterfaceOWDoclet): number => {
					if ( A.data.hasOwnProperty( 'sort' ) && B.data.hasOwnProperty( 'sort' ) ) {
						return A.data['sort'] - B.data['sort'];
					}
					return ServiceSorting.naturalSort( this.passIDToPassProps[ A._id.$oid ].name, this.passIDToPassProps[ B._id.$oid ].name );
				} );
				this.dailyParkingPasses.sort( (A:InterfaceOWDoclet, B:InterfaceOWDoclet): number => {
					return ServiceSorting.naturalSort( this.passIDToPassProps[ A._id.$oid ].name, this.passIDToPassProps[ B._id.$oid ].name );
				} );
				this.cabanaPasses.sort( (A:InterfaceOWDoclet, B:InterfaceOWDoclet): number => {
					return ServiceSorting.naturalSort( this.passIDToPassProps[ A._id.$oid ].name, this.passIDToPassProps[ B._id.$oid ].name );
				} );
			} else {
				console.log( 'Bulk record fetch fail - nothing to buy' );
			}
		} );
		this.owapi.workspace.doclets.getAllDocletsByTemplateID( this.appConfig.getContext(), {
			templateID: [ // season passes only.
				this.strSeasonAdmissionPassTemplateID,
				this.strSeasonParkingPassTemplateID
			],
			query: {
				'data.status': 'active',
				'ow_roles': '{id}' + this.strWebRole,
				'data.year': '2024'
			},
			withoutAuth: true
		}, false, (response: InterfaceOWAPIBulkRecordRequest<InterfaceOWDoclet>): void => {
			if ( response.success ) {
				this.processPassDoclets( response.records );
				console.log( this.seasonAdmissionPasses, this.seasonAdmissionPassRenewals );
				this.seasonAdmissionPasses.sort( (A:InterfaceOWDoclet, B:InterfaceOWDoclet): number => {
					if ( typeof A.data['level'] === 'number' && typeof B.data['level'] === 'number' ) {
						return A.data['level'] - B.data['level'];
					} else if ( typeof B.data['level'] === 'number' ) {
						// then A did not have a level??
						return -1; // A goes before B.
					} else if ( typeof A.data['level'] === 'number' ) {
						// then B did not have a level??
						return 0; // undefined sorting. don't move them, A is already in front i think.
					}
					// else no level, just sort by name.
					return ServiceSorting.naturalSort( this.passIDToPassProps[ A._id.$oid ].name, this.passIDToPassProps[ B._id.$oid ].name );
				} );
				this.seasonAdmissionPassRenewals.sort( (A:InterfaceOWDoclet, B:InterfaceOWDoclet): number => {
					if ( typeof A.data['level'] === 'number' && typeof B.data['level'] === 'number' ) {
						return A.data['level'] - B.data['level'];
					} else if ( typeof B.data['level'] === 'number' ) {
						// then A did not have a level??
						return -1; // A goes before B.
					} else if ( typeof A.data['level'] === 'number' ) {
						// then B did not have a level??
						return 0; // undefined sorting. don't move them, A is already in front i think.
					}
					// else no level, just sort by name.
					return ServiceSorting.naturalSort( this.passIDToPassProps[ A._id.$oid ].name, this.passIDToPassProps[ B._id.$oid ].name );
				} );
				this.seasonParkingPasses.sort( (A:InterfaceOWDoclet, B:InterfaceOWDoclet): number => {
					return ServiceSorting.naturalSort( this.passIDToPassProps[ A._id.$oid ].name, this.passIDToPassProps[ B._id.$oid ].name );
				} );
			} else {
				console.log( 'Failed to fetch season passes.' );
			}
		} );
		this.checkIfUserIsSPH();
	}

	private finishedCheckingConsumerWeavesForSeasonPasses(): void {
		if ( --this.SPHCounter < 1 ) {
			console.log( 'SAP by Year, Quantity', this.ticketSAPYearsActive, this.ticketSAPYearQuantity );
			console.log( 'SPP by Year, Quantity', this.ticketSPPYearActive, this.ticketSPPYearQuantity );
			this.allowedSAPQuantityByTier['1'] = 0; // silver
			this.allowedSAPQuantityByTier['2'] = 0; // gold
			this.allowedSAPQuantityByTier['3'] = 0; // diamond
			let sap2022: number = 0;
			let sap2023: number = 0;
			if ( this.ticketSAPYearQuantity.hasOwnProperty( '2022' ) ) {
				Object.keys( this.ticketSAPYearQuantity['2022'] ).forEach( (sapLevel: string): void => {
					sap2022 += this.ticketSAPYearQuantity['2022'][sapLevel];
					this.allowedSAPQuantityByTier[sapLevel] = this.ticketSAPYearQuantity['2022'][sapLevel];
				} );
			}
			if ( this.ticketSAPYearQuantity.hasOwnProperty( this.priorSeasonYear ) ) {
				Object.keys( this.ticketSAPYearQuantity[ this.priorSeasonYear ] ).forEach( (sapLevel: string): void => {
					sap2023 += this.ticketSAPYearQuantity[ this.priorSeasonYear ][sapLevel];
					this.allowedSAPQuantityByTier[sapLevel] = Math.max( this.allowedSAPQuantityByTier[sapLevel], this.ticketSAPYearQuantity[ this.priorSeasonYear ][sapLevel] );
				} );
			}
			if ( this.ticketSAPYearQuantity.hasOwnProperty( this.currentSeasonYear ) ) {
				Object.keys( this.ticketSAPYearQuantity[ this.currentSeasonYear ] ).forEach( (sapLevel: string): void => {
					this.allowedSAPQuantityByTier[sapLevel] -= this.ticketSAPYearQuantity[ this.currentSeasonYear ][sapLevel];
					this.totalQtySAPOwned2024 += this.ticketSAPYearQuantity[ this.currentSeasonYear ][sapLevel];
				} );
			}
			this.totalQtySAPOwned20220223Max = Math.max( sap2022, sap2023 );
			this.allowedSAPQuantityTotal = this.totalQtySAPOwned20220223Max - this.totalQtySAPOwned2024;
			console.log( 'SAP Upgrade stats', this.allowedSAPQuantityByTier, this.totalQtySAPOwned20220223Max, this.totalQtySAPOwned2024, this.allowedSAPQuantityTotal );
		}
	}

	private checkIfUserIsSPH(): void {
		this.isSeasonPassHolder = false;
		if ( this.isSignedIn ) {
			this.ticketSAPYearsActive = {};
			const appContext: InterfaceAppContext = this.appConfig.getContext();
			this.colProfiles.getMyUserProfile( (userProfile: InterfaceOWUser | null): void => {
				if ( userProfile && userProfile.doclet_id ) {
					const userProfileDocletID: string = userProfile.doclet_id;
					this.owapi.workspace.doclets.getWeavesByDocletID( appContext, userProfileDocletID ).subscribe( (response: InterfaceHTTPGateway): void => {
						if ( response?.success ) {
							const apiResponse: InterfaceOWAPIGetWeavesResponse | undefined = response.data;
							if ( apiResponse && Array.isArray( apiResponse?.data?.items ) ) {
								const userWeaves: InterfaceOWWeaveV2[] = apiResponse.data.items;
								let familyDocletKnown: boolean = false;
								for ( let uw: number = 0; uw < userWeaves.length; ++uw ) {
									switch ( userWeaves[uw].t_id.$oid ) {
										case this.strFamilyTemplateID: {
											familyDocletKnown = true;
											const familyDocletID: string = userWeaves[uw].c_id.$oid;
											this.owapi.workspace.doclets.getWeavesByDocletID( appContext, familyDocletID ).subscribe( (response2: InterfaceHTTPGateway): void => {
												if ( response2?.success ) {
													const apiResponse2: InterfaceOWAPIGetWeavesResponse | undefined = response2.data;
													if ( apiResponse2 && Array.isArray( apiResponse2.data?.items ) ) {
														const weavesOnFamily: InterfaceOWWeaveV2[] = apiResponse2.data.items;
														const consumerDocletIDs: string[] = [];
														for ( let wof: number = 0; wof < weavesOnFamily.length; ++wof ) {
															switch ( weavesOnFamily[wof].t_id.$oid ) {
																case this.strConsumerTemplateID: {
																	if ( weavesOnFamily[wof]?.data?.['role'] === 'Pass Holder' ) {
																		consumerDocletIDs.push( weavesOnFamily[wof].c_id.$oid );
																	} // end if this consumer is a season pass holder.
																	break;
																} // end id if this weave is a consumer
															} // end switch template_id of this weave
														} // end for each weaves on the family.
														if ( consumerDocletIDs.length > 0 ) {
															console.log( 'user may be a season pass holder, of some kind...' );
															this.SPHCounter = consumerDocletIDs.length;
															for ( let cd: number = 0; cd < consumerDocletIDs.length; ++cd ) {
																this.owapi.workspace.doclets.getWeavesByDocletID( appContext, consumerDocletIDs[cd] ).subscribe( (response3: InterfaceHTTPGateway): void => {
																	if ( response3?.success ) {
																		const apiResponse3: InterfaceOWAPIGetWeavesResponse | undefined = response3.data;
																		if ( apiResponse3 && Array.isArray( apiResponse3?.data?.items ) ) {
																			const weavesOnConsumer: InterfaceOWWeaveV2[] = apiResponse3.data.items;
																			if ( weavesOnConsumer.length > 0 ) {
																				let seasonPassDocletIDs: string[] = weavesOnConsumer.filter( (w: InterfaceOWWeaveV2): boolean => w.t_id.$oid === this.strSeasonAdmissionTicketTemplateID ).map( (w: InterfaceOWWeaveV2): string => w.c_id.$oid );
																				if ( seasonPassDocletIDs.length ) {
																					// investigating season passes for a consumer...
																					// passes must have 'data.status' and data.status must not be 'canceled' (expired, active, whatever)
																					let seasonPassesToCheck: number = seasonPassDocletIDs.length;
																					for ( let spd: number = 0; spd < seasonPassDocletIDs.length; ++spd ) {
																						this.owapi.workspace.doclets.getDocletByID( appContext, seasonPassDocletIDs[spd] ).subscribe( (response4: InterfaceHTTPGateway): void => {
																							if ( response4?.success ) {
																								const apiResponse4: InterfaceOWAPIGetDocletResponse | undefined = response4.data;
																								if ( apiResponse4 && apiResponse4?.data?._id ) {
																									const seasonPassDoclet: InterfaceOWDoclet = apiResponse4.data;
																									if ( seasonPassDoclet && seasonPassDoclet.data && seasonPassDoclet.data['status'] && seasonPassDoclet.data['status'] !== 'canceled' && seasonPassDoclet ) {
																										this.isSeasonPassHolder = true;
																										// must not be banned. must have a status. must be from current or prior year. must not be cancelled.
																										if ( typeof seasonPassDoclet.data['year'] === 'string' ) {
																											const seasonPassYear: string = seasonPassDoclet.data['year'];
																											const seasonPassLevel: number = seasonPassDoclet.data['level'] ?? 2;
																											switch ( seasonPassYear ) {
																												case this.currentSeasonYear: // 2024
																												case this.priorSeasonYear: // 2023
																												case '2022': {
																													this.ticketSAPYearsActive[seasonPassYear] = true;
																													if ( !this.ticketSAPYearQuantity.hasOwnProperty( seasonPassYear ) ) {
																														this.ticketSAPYearQuantity[seasonPassYear] = {};
																													}
																													if ( !this.ticketSAPYearQuantity[seasonPassYear].hasOwnProperty( String( seasonPassLevel ) ) ) {
																														this.ticketSAPYearQuantity[seasonPassYear][seasonPassLevel] = 0;
																													}
																													++this.ticketSAPYearQuantity[seasonPassYear][seasonPassLevel];
																													break;
																												}
																											}
																										}
																									}
																									if ( --seasonPassesToCheck < 1 ) {
																										this.finishedCheckingConsumerWeavesForSeasonPasses();
																									}
																								}
																							}
																						} );
																					} // end for each season pass to check
																				} else { // else no season passes for this consumer.
																					this.finishedCheckingConsumerWeavesForSeasonPasses();
																				}
																			} else { // else no weaves on this consumer, so no passes, either.
																				this.finishedCheckingConsumerWeavesForSeasonPasses();
																			}
																		}
																	}
																} );
															}
														} // end if there were any pass holders.
													}
												}
											} ); // end of fetching weaves on the family
											break;
										} // end if this was the family template_id
										case this.strSeasonParkingTicketTemplateID: {
											this.owapi.workspace.doclets.getDocletByID( appContext, userWeaves[uw].c_id.$oid ).subscribe( (response2: InterfaceHTTPGateway): void => {
												if ( response2?.success ) {
													const apiResponse2: InterfaceOWAPIGetDocletResponse | undefined = response2.data;
													if ( apiResponse2 && apiResponse2?.data?._id ) {
														const SPP: InterfaceOWDoclet = apiResponse2.data;
														if ( SPP && SPP.data && SPP.data['status'] && SPP.data['status'] !== 'canceled' && typeof SPP.data['year'] === 'string' ) {
															const seasonPassYear: string = SPP.data['year'];
															this.ticketSPPYearActive[seasonPassYear] = true;
															if ( !this.ticketSPPYearQuantity.hasOwnProperty( seasonPassYear ) ) {
																this.ticketSPPYearQuantity[seasonPassYear] = 0;
															}
															++this.ticketSPPYearQuantity[seasonPassYear];
														}
													}
												}
											} );
											break;
										} // end if this Purchaser has a Season Parking Pass woven to the user.
									} // end switch template_id of each weave
								} // end for each weave on the user
							}
						}
					} ); // end of fetching weaves tied to this user.
				} // end if profile was fetched
			} ); // end fetch profile
		} // end if signed in
	}

	private processPassDoclets( passDoclets: InterfaceOWDoclet[] ): void {
		for ( let x: number = 0; x < passDoclets.length; ++x ) {
			const passID: string = passDoclets[x]._id.$oid;
			if ( !Array.isArray( passDoclets[x].data['blocked_dates'] ) ) {
				passDoclets[x].data['blocked_dates'] = [];
				passDoclets[x].data['__blocked_dates'] = {};
			} else {
				passDoclets[x].data['__blocked_dates'] = {};
				passDoclets[x].data['blocked_dates'].forEach( (blockedDate: string): void => {
					passDoclets[x].data['__blocked_dates'][ blockedDate ] = true;
				} );
			}
			switch ( passDoclets[x].template_id.$oid ) {
				case this.strDailyAdmissionPassTemplateID: {
					this.ticketSelections.dailyPasses[passID] = {
						qty: 0
					};
					this.ticketSelections.anyDayPasses[passID] = {
						qty: 0
					};
					this.ticketHoldings[passID] = []; // an array of temp-tickets that server-side will hang onto, for a while. used for capacity calculations.
					passDoclets[x].data['price'] = TransformerVenuePassportTicket.passPricesToYYYYMMDDPrice( passDoclets[x].data['price'] );
					passDoclets[x].data['__lowestPrice'] = this.getLowestPrice( passDoclets[x] );
					if ( Array.isArray( passDoclets[x].data['dates_valid'] ) && passDoclets[x].data['dates_valid'].length > 0 ) {
						passDoclets[x].data['__datesValid'] = {}; // if __datesValid exists, it triggers the calendar to block out ALL dates, except what's in this object.
						for ( let y: number = 0; y < passDoclets[x].data['dates_valid'].length; ++y ) {
							const YYYYMMDD1: string = passDoclets[x].data['dates_valid'][y]; // YYYY-MM-DD where MM is 01-12
							passDoclets[x].data['__datesValid'][ YYYYMMDD1 ] = true; // this forces the calendar to only allow 'these' dates to be accessible.
						}
					}
					this.passIDToPassProps[passID] = {
						name: passDoclets[x].data['name'],
						price: passDoclets[x].data['price'],
						sort: passDoclets[x].data['sort'] ?? 1,
						isPromoted: passDoclets[x].data?.['is_promoted'] ?? false,
						isLimitPerOrder: (passDoclets[x].data?.['limit_per_order'] ?? 0) > 0,
						isDailyAdmission: true,
						isEvening: !!passDoclets[x].data['is_evening'],
						isJunior: passDoclets[x].data['is_junior'] ?? false,
						isAddOn: passDoclets[x].data['is_addon'] ?? false,
						isDailyParking: false,
						isCabana: false,
						isCompensation: false, // $0 passes
						isSeasonPass: false,
						isSAP: false,
						isSPP: false,
						isCertification: false,
						isMerchandise: false,
						skipCapacityCheck: !passDoclets[x].data['require_capacity'] || passDoclets[x].data['is_any_day'],
						role: {
							admin: false,
							pos: false,
							staff: false,
							web: true
						},
						doclet: passDoclets[x]
					};
					if ( this.passIDToPassProps[passID].isPromoted ) {
						this.promotedDailyAdmissionPasses.push( passDoclets[x] );
					} else {
						this.dailyAdmissionPasses.push( passDoclets[x] );
					}
					break;
				}
				case this.strParkingPassTemplateID: {
					this.ticketSelections.dailyPasses[passID] = {
						qty: 0
					};
					this.ticketHoldings[passID] = []; // an array of temp-tickets that server-side will hang onto, for a while, to hold your place in line...
					passDoclets[x].data['price'] = TransformerVenuePassportTicket.passPricesToYYYYMMDDPrice( passDoclets[x].data['price'] );
					passDoclets[x].data['__lowestPrice'] = this.getLowestPrice( passDoclets[x] );
					this.dailyParkingPasses.push( passDoclets[x] );
					this.passIDToPassProps[passID] = {
						name: passDoclets[x].data['name'],
						price: passDoclets[x].data['price'],
						sort: 2,
						isPromoted: passDoclets[x].data?.['is_promoted'] ?? false,
						isLimitPerOrder: (passDoclets[x].data?.['limit_per_order'] ?? 0) > 0,
						isDailyAdmission: false,
						isEvening: !!passDoclets[x].data['is_evening'],
						isDailyParking: true,
						isCabana: false,
						isCompensation: false, // $0 passes
						isSeasonPass: false,
						isSAP: false,
						isSPP: false,
						isCertification: false,
						isMerchandise: false,
						skipCapacityCheck: !passDoclets[x].data['require_capacity'] || passDoclets[x].data['is_any_day'],
						role: {
							admin: false,
							pos: false,
							staff: false,
							web: true
						},
						doclet: passDoclets[x]
					};
					break;
				}
				case this.strSeasonParkingPassTemplateID: {
					if ( passDoclets[x].data['year'] === this.currentSeasonYear ) {
						this.ticketSelections.seasonPasses[passID] = {
							qty: 0
						};
						this.ticketHoldings[passID] = []; // an array of temp-tickets that server-side will hang onto, for a while, to hold your place in line...
						passDoclets[x].data['price'] = TransformerVenuePassportTicket.passPricesToYYYYMMDDPrice( passDoclets[x].data['price'] );
						passDoclets[x].data['__lowestPrice'] = this.getLowestPrice( passDoclets[x] );
						this.seasonParkingPasses.push( passDoclets[x] );
						this.passIDToPassProps[passID] = {
							name: passDoclets[x].data['name'],
							price: passDoclets[x].data['price'],
							sort: 1, // sorting within the Season Parking Passes
							isPromoted: passDoclets[x].data?.['is_promoted'] ?? false,
							isLimitPerOrder: (passDoclets[x].data?.['limit_per_order'] ?? 0) > 0,
							isDailyAdmission: false,
							isDailyParking: false, // is SPP, not the daily kind.
							isCabana: false,
							isCompensation: false, // $0 passes
							isSeasonPass: true,
							year: passDoclets[x].data['year'],
							isSAP: false,
							isSPP: true,
							level: passDoclets[x].data['level'] ?? undefined,
							isCertification: false,
							isMerchandise: false,
							skipCapacityCheck: !passDoclets[x].data['require_capacity'] || passDoclets[x].data['is_any_day'],
							role: {
								admin: false,
								pos: false,
								staff: false,
								web: true
							},
							doclet: passDoclets[x]
						};
					}
					break;
				}
				case this.strCabanaPassTemplateID: {
					this.ticketSelections.dailyPasses[passID] = {
						qty: 0
					};
					this.ticketHoldings[passID] = []; // an array of temp-tickets that server-side will hang onto, for a while, to hold your place in line...
					passDoclets[x].data['price'] = TransformerVenuePassportTicket.passPricesToYYYYMMDDPrice( passDoclets[x].data['price'] );
					passDoclets[x].data['__lowestPrice'] = this.getLowestPrice( passDoclets[x] );
					this.cabanaPasses.push( passDoclets[x] );
					this.passIDToPassProps[passID] = {
						name: passDoclets[x].data['name'],
						price: passDoclets[x].data['price'],
						sort: 3,
						isPromoted: passDoclets[x].data?.['is_promoted'] ?? false,
						isLimitPerOrder: (passDoclets[x].data?.['limit_per_order'] ?? 0) > 0,
						isDailyAdmission: false,
						isEvening: !!passDoclets[x].data['is_evening'],
						isDailyParking: false,
						isCabana: true,
						isCompensation: false, // $0 passes
						isSeasonPass: false,
						isSAP: false,
						isSPP: false,
						isCertification: false,
						isMerchandise: false,
						skipCapacityCheck: !passDoclets[x].data['require_capacity'] || passDoclets[x].data['is_any_day'],
						role: {
							admin: false,
							pos: false,
							staff: false,
							web: true
						},
						doclet: passDoclets[x]
					};
					break;
				}
				case this.strSeasonAdmissionPassTemplateID: {
					if ( passDoclets[x].data['year'] === this.currentSeasonYear ) {
						this.ticketSelections.seasonPasses[passID] = {
							qty: 0
						};
						this.ticketHoldings[passID] = []; // this ought to always be empty, for season passes. // an array of temp-tickets that server-side will hang onto, for a while, to hold your place in line...
						passDoclets[x].data['price'] = TransformerVenuePassportTicket.passPricesToYYYYMMDDPrice( passDoclets[x].data['price'] );
						passDoclets[x].data['__lowestPrice'] = this.getLowestPrice( passDoclets[x] );
						if ( passDoclets[x].data['is_renewal'] ) {
							this.seasonAdmissionPassRenewals.push( passDoclets[x] );
						} else {
							this.seasonAdmissionPasses.push( passDoclets[x] );
						}
						this.passIDToPassProps[passID] = {
							name: passDoclets[x].data['name'],
							price: passDoclets[x].data['price'],
							sort: 0,
							isPromoted: passDoclets[x].data?.['is_promoted'] ?? false,
							isLimitPerOrder: (passDoclets[x].data?.['limit_per_order'] ?? 0) > 0,
							isDailyAdmission: false,
							isDailyParking: false,
							isCabana: false,
							isCompensation: false, // $0 passes
							isSeasonPass: true,
							isRenewal: passDoclets[x].data['is_renewal'] ?? false,
							year: passDoclets[x].data['year'],
							isSAP: true,
							isSPP: false,
							level: passDoclets[x].data['level'] ?? undefined,
							isCertification: false,
							isMerchandise: false,
							skipCapacityCheck: !passDoclets[x].data['require_capacity'] || passDoclets[x].data['is_any_day'],
							role: {
								admin: false,
								pos: false,
								staff: false,
								web: true
							},
							doclet: passDoclets[x]
						};
						if ( this.passIDToPassProps[passID].level === 1 ) {
							if ( this.passIDToPassProps[passID].isRenewal ) {
								this.seasonPassT1Renewal = passDoclets[x];
							} else {
								this.seasonPassT1 = passDoclets[x];
							}
						} else if ( this.passIDToPassProps[passID].level === 2 ) {
							if ( this.passIDToPassProps[passID].isRenewal ) {
								this.seasonPassT2Renewal = passDoclets[x];
							} else {
								this.seasonPassT2 = passDoclets[x];
							}
						} else if ( this.passIDToPassProps[passID].level === 3 ) {
							if ( this.passIDToPassProps[passID].isRenewal ) {
								this.seasonPassT3Renewal = passDoclets[x];
							} else {
								this.seasonPassT3 = passDoclets[x];
							}
						}
					}
					break;
				}
				case this.strComplexProductTemplateID: {
					this.ticketSelections.complexPasses[passID] = {
						qty: 0
					};
					this.ticketHoldings[passID] = []; // bundles don't have capacity logic. it'll be an issue later.
					passDoclets[x].data['price'] = TransformerVenuePassportTicket.passPricesToYYYYMMDDPrice( passDoclets[x].data['price'] );
					passDoclets[x].data['__lowestPrice'] = this.getLowestPrice( passDoclets[x] );
					if ( Array.isArray( passDoclets[x].data['dates_valid'] ) && passDoclets[x].data['dates_valid'].length > 0 ) {
						passDoclets[x].data['__datesValid'] = {}; // if __datesValid exists, it triggers the calendar to block out ALL dates, except what's in this object.
						for ( let y: number = 0; y < passDoclets[x].data['dates_valid'].length; ++y ) {
							const YYYYMMDD1: string = passDoclets[x].data['dates_valid'][y]; // YYYY-MM-DD where MM is 01-12
							passDoclets[x].data['__datesValid'][ YYYYMMDD1 ] = true; // this forces the calendar to only allow 'these' dates to be accessible.
						}
					}
					if ( passDoclets[x]._id.$oid === '64bea6aa9badb7c6d4d4ae45' ) {
						// Carload
						passDoclets[x].data['__note1'] = this.carloadText1;
						passDoclets[x].data['__note2'] = this.carloadText2;
					}
					this.passIDToPassProps[passID] = {
						name: passDoclets[x].data['name'],
						price: passDoclets[x].data['price'],
						sort: passDoclets[x].data['sort'] ?? 1,
						isPromoted: passDoclets[x].data?.['is_promoted'] ?? false,
						isLimitPerOrder: (passDoclets[x].data?.['limit_per_order'] ?? 0) > 0,
						isDailyAdmission: false,
						isEvening: !!passDoclets[x].data['is_evening'],
						isJunior: passDoclets[x].data['is_junior'] ?? false,
						isAddOn: passDoclets[x].data['is_addon'] ?? false,
						isDailyParking: false,
						isCabana: false,
						isComplexBundle: true,
						isCompensation: false, // $0 passes
						isSeasonPass: false,
						isSAP: false,
						isSPP: false,
						isCertification: false,
						isMerchandise: false,
						skipCapacityCheck: !passDoclets[x].data['require_capacity'] || passDoclets[x].data['is_any_day'],
						role: {
							admin: false,
							pos: false,
							staff: false,
							web: true
						},
						doclet: passDoclets[x]
					};
					this.complexProducts.push( passDoclets[x] );
					break;
				}
			} // end switch docletID
		} // end for each doclet to bucket up.
	}

	private getAvailabilityKeyByPassID( passID: string ): string | undefined {
		const prefix: string | false = this.getSettingsPrefixByPassID( passID );
		let output: string | undefined = undefined;
		if ( typeof prefix === 'string' && prefix.length > 0 ) {
			output = prefix + '_availability';
		}
		return output;
	}

	private getSoldCountKeyByPassID( passID: string ): string | undefined {
		const prefix: string | false = this.getSettingsPrefixByPassID( passID );
		let output: string | undefined = undefined;
		if ( typeof prefix === 'string' && prefix.length > 0 ) {
			output = prefix + '_count';
		}
		return output;
	}

	private getSettingsPrefixByPassID( passID: string ): string | false {
		let prefix: string | false = false;
		const passProps: InterfaceDocletIDToTicketProps = this.passIDToPassProps[passID];
		if ( passProps ) {
			if ( passProps.isDailyAdmission ) {
				prefix = 'ticket';
			} else if ( passProps.isDailyParking ) {
				prefix = 'parking';
			} else if ( passProps.isCabana ) {
				const cabanaPass: InterfaceOWDoclet = passProps.doclet;
				const cabanaData: InterfaceVenuePassportPass<InterfaceVenuePassportTicketPriceType> = cabanaPass.data as InterfaceVenuePassportPass<InterfaceVenuePassportTicketPriceType>;
				switch ( cabanaData.target.template_id ) {
					case '62aa70c392ec3e75dafc2c5e': { // Castaway River, Green, _id: 629a58bde702436560e6b740
						prefix = 'river_cabanas';
						break;
					}
					case '62aa711e92ec3e75dafc2c61': { // hidden, Red, _id: 629a58bde702436560e6b741
						prefix = 'wavepool_south';
						break;
					}
					case '62aa710d92ec3e75dafc2c60': { // Tomcat, Aqua, _id: 629a58bde702436560e6b742
						prefix = 'wavepool_north';
						break;
					}
					case '62aa70af92ec3e75dafc2c5d' : { // Cooks Cove, Orange, _id: 629a58bda0fdd06636abddb1
						prefix = 'cooks_cove';
						break;
					}
					case '62aa70ed92ec3e75dafc2c5f': { // Shaka Bay, Blue, _id: 629a58bda0fdd06636abddb2
						prefix = 'wavepool_beach';
						break;
					}
					case '62aa709a92ec3e75dafc2c5c': { // Kontiki Cover, Yellow, _id: 629a58bda0fdd06636abddb3
						prefix = 'kontiki_cove';
						break;
					}
				}
			}
		}
		return prefix;
	}

	private getTicketSoldCount( passID: string, year: number, month1: number, day: number ): number {
		const YYYY: string = String( year );
		const MM1: string = ('0' + month1).slice( -2 );
		const DD: string = ('0' + day).slice( -2 );
		const YYYYMMDD1: string = YYYY + '-' + MM1 + '-' + DD;
		const availabilityAndSoldByDate: InterfaceOWDailyAdmissionAvailability[string] = this.dailyAdmissionAvailability[ YYYYMMDD1 ];
		if ( !availabilityAndSoldByDate ) {
			return 0; // if there is no info for that date, then assume no tickets were sold.
		}
		const passProps: InterfaceDocletIDToTicketProps = this.passIDToPassProps[passID];
		if ( passProps.isCabana ) { // cabanas are treated differently now. it is now intended for price tier to be spread out among all cabanas sold for the day.
			const cabanaKeys: string[] = [
				'kontiki_cove_count', // Kontiki Cove, Yellow
				'cooks_cove_count', // Cooks Cove, Orange
				'wavepool_beach_count', // Shaka Bay, Blue
				'wavepool_north_count', // Tomcat, Aqua
				'wavepool_south_count', // Red, hidden
				'river_cabanas_count' // Castaway River, Green
			];
			return cabanaKeys.reduce( (out: number, key: string): number => {
				return out + availabilityAndSoldByDate[key];
			}, 0 );
		} else { // else is not a cabana.
			const settingsKey: string | undefined = this.getSoldCountKeyByPassID( passID );
			if ( settingsKey ) {
				return availabilityAndSoldByDate?.[settingsKey] ?? 0;
			}
		}
		return 0;
	}

	private willAllowSPHPricing( sphRestriction: InterfaceVenuePassportTicketPricing['sphRestriction'] ): boolean {
		const requiredYears: string[] = Object.keys( sphRestriction );
		let allowSPHPricing: boolean = true;
		for ( let x: number = 0; allowSPHPricing && x < requiredYears.length; ++x ) {
			if ( !this.ticketSAPYearsActive[ requiredYears[x] ] ) {
				allowSPHPricing = false; // current user does not have a season pass from the required year.
			}
		}
		return allowSPHPricing;
	}

	public getTicketPrice( passID: string, year: number, month1: number, day: number ): number {
		let passPrice: number = 0;
		const YYYY: string = String( year );
		const MM1: string = ('0' + month1).slice( -2 );
		const DD: string = ('0' + day).slice( -2 );
		const YYYYMMDD1: string = YYYY + '-' + MM1 + '-' + DD;
		if ( this.passIDToPassProps.hasOwnProperty( passID ) ) {
			const ticketsSold: number = this.getTicketSoldCount( passID, year, month1, day );
			if ( this.passIDToPassProps[passID].price.hasOwnProperty( YYYYMMDD1 ) ) {
				let useSPHPrice: boolean = false;
				if ( this.isSeasonPassHolder ) { // new -- extra logic is used to ensure the sph-price applies to only certain season pass holder years, like only 2022, etc.
					useSPHPrice = this.willAllowSPHPricing( this.passIDToPassProps[passID].price[YYYYMMDD1].sphRestriction );
				}
				if ( useSPHPrice && this.passIDToPassProps[passID].price[YYYYMMDD1]['sph'] !== null ) {
					passPrice = Number( this.passIDToPassProps[passID].price[YYYYMMDD1]['sph'] );
				} else if ( Array.isArray( this.passIDToPassProps[passID].price[YYYYMMDD1].priceTier ) ) {
					// tiered pricing is assumed to be pre-sorted.
					const PT: InterfaceOWAPITicketPriceByDatePriceTier[] = this.passIDToPassProps[passID].price[YYYYMMDD1].priceTier ?? [];
					let found: boolean = false;
					for ( let x: number = 0; x < PT.length; ++x ) {
						if ( ticketsSold <= PT[x].count ) {
							found = true;
							passPrice = Number( PT[x].price );
							break;
						}
					}
					if ( !found ) { // else the ticket price is just the normal price.
						passPrice = Number( this.passIDToPassProps[passID].price[YYYYMMDD1]['default'] );
					}
				} else { // else not SPH nor tiered pricing
					passPrice = Number( this.passIDToPassProps[passID].price[YYYYMMDD1]['default'] );
				}
			} else if ( this.passIDToPassProps[passID].price.hasOwnProperty( 'default' ) ) {
				// same logic as above, except [YYYYMMDD] is ['default'] for the date chosen.
				let useSPHPrice: boolean = false;
				if ( this.isSeasonPassHolder ) {
					useSPHPrice = this.willAllowSPHPricing( this.passIDToPassProps[passID].price['default'].sphRestriction );
				}
				if ( useSPHPrice && this.passIDToPassProps[passID].price['default']['sph'] !== null ) {
					passPrice = Number( this.passIDToPassProps[passID].price['default']['sph'] );
				} else if ( Array.isArray( this.passIDToPassProps[passID].price['default'].priceTier ) ) {
					const PT: InterfaceOWAPITicketPriceByDatePriceTier[] = this.passIDToPassProps[passID].price['default'].priceTier ?? [];
					let found: boolean = false;
					for ( let x: number = 0; x < PT.length; ++x ) {
						if ( ticketsSold <= PT[x].count ) {
							found = true;
							passPrice = Number( PT[x].price );
							break;
						}
					}
					if ( !found ) { // else the ticket price is just the normal price.
						passPrice = Number( this.passIDToPassProps[passID].price['default']['default'] ); // basically .price[date][non-sph]
					}
				} else { // else not SPH nor tiered pricing.
					passPrice = Number( this.passIDToPassProps[passID].price['default']['default'] ); // default date, default (non-SPH) price
				}
			} else {
				console.log( 'missing price tag for', passID );
			}
		} else {
			console.log( 'no know price for', passID );
		}
		return passPrice;
	}

	public getSeasonPassPrice( passID: string ): number {
		return this.getTicketPrice( passID, now.getFullYear(), now.getMonth() + 1, now.getDate() );
	}

	private getLowestPrice( transformedPassDoclet: InterfaceOWDoclet ): number | null {
		// circular dependency issue.
		// lowest price by date, excluding dates where there is no more availability:
		// - need to know which ticket type this is, which depends on this.passIDToPassProps.
		// - need to know if the pass for sale, has more than 0 available to purchase by date.
		// ...which requires this.dailyAdmissionAvailability to already be loaded.
		// ...plus this.passIDToPassProps to already be loaded to know if it's not a season pass (is a daily ticket type)
		const docletData: { [YYYYMMDD1: string]: InterfaceVenuePassportTicketPricing; } =
			Array.isArray( transformedPassDoclet.data['price'] ) // if not already transformed (it should be)
				? TransformerVenuePassportTicket.passPricesToYYYYMMDDPrice( transformedPassDoclet.data['price'] ) // then transform it
				: transformedPassDoclet.data['price']; // else we're ready to go.
		let lowestPrice: number | null = null;
		const passID: string = transformedPassDoclet._id.$oid;
		const passProps: InterfaceDocletIDToTicketProps | undefined = this.passIDToPassProps[passID]; // it's possible that passIDToPassProps is being built up right now, won't exist, etc.
		const availabilityKey: string | undefined = this.getAvailabilityKeyByPassID( passID );
		const canUseAvailability: boolean = (passProps?.isDailyAdmission || passProps?.isDailyParking || passProps?.isCabana) && this.availabilityLoaded && !!availabilityKey;
		if ( 'default' in docletData && 'default' in docletData['default'] ) {
			lowestPrice = docletData['default'].default;
			const YYYY: number = now.getFullYear();
			const MM1: number = now.getMonth() + 1;
			const DD: number = now.getDate()
			const nowYYYYMMDD1: string = YYYY + '-' + String( '0' + MM1 ).slice( -2 ) + '-' + String( '0' + DD ).slice( -2 );
			if ( Array.isArray( transformedPassDoclet.data?.['dates_valid'] && transformedPassDoclet.data['dates_valid'].length > 0 ) ) {
				// only take the prices from the dates that are valid
				let YYYYMMDD1: string = transformedPassDoclet.data['dates_valid'][0];
				if ( YYYYMMDD1 in docletData && 'default' in docletData[YYYYMMDD1] ) {
					// this takes the YYYY-MM-DD value for each (only valid on these dates) and looks up their ordinary pricing by date (by this specific date).
					if ( canUseAvailability ) {
						if ( this.dailyAdmissionAvailability.hasOwnProperty( YYYYMMDD1 ) && availabilityKey && this.dailyAdmissionAvailability[YYYYMMDD1].hasOwnProperty( availabilityKey ) ) {
							if ( this.dailyAdmissionAvailability[YYYYMMDD1][availabilityKey] > 0 ) {
								lowestPrice = docletData[YYYYMMDD1].default;
							} // else no availability for that day, don't use the price override for that day.
						} // else the daily availability has no knowledge about this ticket.
					} else { // else daily availability isn't loaded yet, or we're in the middle of building up passProps and it doesn't exist yet to know how to use the daily availability yet.
						lowestPrice = docletData[YYYYMMDD1].default;
					}
					if ( Array.isArray( docletData[YYYYMMDD1].priceTier ) ) {
						// typescript is having a super-fail, after checking if a property is an array, is says it's possibly undefined.
						const arr: InterfaceOWAPITicketPriceByDatePriceTier[] = docletData[YYYYMMDD1].priceTier as InterfaceOWAPITicketPriceByDatePriceTier[];
						const ticketsSold: number = this.getTicketSoldCount( passID, YYYY, MM1, DD );
						for ( let x: number = 0; x < arr.length; ++x ) {
							if ( ticketsSold <= arr[x].count) {
								lowestPrice = Math.min( lowestPrice, arr[x].price );
							}
						}
					}
				} else {
					// error, a date in which they're valid for, is not listed in the price matrix.
					// just keep the default.
				}
				transformedPassDoclet.data['dates_valid'].forEach( (YYYYMMDD1: string): void => {
					if ( nowYYYYMMDD1.localeCompare( YYYYMMDD1, undefined, { numeric: true, sensitivity: 'base' } ) < 1 ) {
						// if the date listed is today or further. don't count past dates.
						const availabilityExists: boolean = canUseAvailability // if canUseAvailability is false, then it doesn't matter what date we're checking against.
							&& !!availabilityKey
							&& this.dailyAdmissionAvailability.hasOwnProperty( YYYYMMDD1 )
							&& this.dailyAdmissionAvailability[YYYYMMDD1].hasOwnProperty( availabilityKey )
							&& this.dailyAdmissionAvailability[YYYYMMDD1][availabilityKey] > 0;
						if ( !canUseAvailability // if we don't have availability loaded, then use the date.
							|| availabilityExists // ..but if we do have it loaded, then check if the date has availability.
						) {
							lowestPrice = Math.min(
								lowestPrice as number,
								docletData.hasOwnProperty( YYYYMMDD1 ) ? docletData[YYYYMMDD1].default : docletData['default'].default
							);
							if ( Array.isArray( docletData[YYYYMMDD1].priceTier ) ) {
								const arr: InterfaceOWAPITicketPriceByDatePriceTier[] = docletData[YYYYMMDD1].priceTier as InterfaceOWAPITicketPriceByDatePriceTier[];
								const arrYYYYMM1DD: string[] = YYYYMMDD1.split( /-/g );
								const YYYY: number = Number( arrYYYYMM1DD[0] );
								const MM1: number = Number( arrYYYYMM1DD[1] );
								const DD: number = Number( arrYYYYMM1DD[2] );
								const ticketsSold: number = this.getTicketSoldCount( passID, YYYY, MM1, DD );
								for ( let x: number = 0; x < arr.length; ++x ) {
									if ( ticketsSold <= arr[x].count ) {
										lowestPrice = Math.min( lowestPrice, arr[x].price );
									}
								}
							} // end if this ticket has tiered pricing set up
						} // end if we don't yet have availability loaded, or we do AND there is capacity to sell this type of ticket.
					} // end if the date to check against, is today or in the future.
				} );
			} else { // else all dates are valid, so just deal with price overrides, if any.
				Object.keys( docletData ).forEach( (YYYYMMDD1: string): void => {
					if ( YYYYMMDD1 === 'default' || nowYYYYMMDD1.localeCompare( YYYYMMDD1, undefined, { numeric: true, sensitivity: 'base' } ) < 1 ) {
						// '2023-04-01'.localeCompare( '2023-04-02', undefined, { 'numeric' : true, 'sensitivity' : 'base' } )
						// A is less than B returns -1
						// A is the same as B returns 0
						// A is greater than B returns 1
						// if the date listed is today or further. don't count past dates.
						const availabilityExists: boolean = canUseAvailability // if canUseAvailability is false, then it doesn't matter what date we're checking against.
							&& !!availabilityKey
							&& this.dailyAdmissionAvailability.hasOwnProperty( YYYYMMDD1 )
							&& this.dailyAdmissionAvailability[YYYYMMDD1].hasOwnProperty( availabilityKey )
							&& this.dailyAdmissionAvailability[YYYYMMDD1][availabilityKey] > 0;
						if ( !canUseAvailability // if we don't have availability loaded, then use the date.
							|| (YYYYMMDD1 === 'default' || availabilityExists) // ..but if we do have it loaded, then check if the date has availability.
						) {
							const price: number = docletData[YYYYMMDD1].default;
							lowestPrice = Math.min( price, lowestPrice as number );
							if ( Array.isArray( docletData[YYYYMMDD1].priceTier ) ) {
								const arr: InterfaceOWAPITicketPriceByDatePriceTier[] = docletData[YYYYMMDD1].priceTier as InterfaceOWAPITicketPriceByDatePriceTier[];
								let YYYY: number = 0;
								let MM1: number = 0;
								let DD: number = 0;
								if ( YYYYMMDD1 === 'default' ) {
									YYYY = now.getFullYear();
									MM1 = now.getMonth() + 1;
									DD = now.getDate();
								} else {
									const arrYYYYMM1DD: string[] = YYYYMMDD1.split( /-/g );
									YYYY = Number( arrYYYYMM1DD[0] );
									MM1 = Number( arrYYYYMM1DD[1] );
									DD = Number( arrYYYYMM1DD[2] );
								}
								const ticketsSold: number = this.getTicketSoldCount( passID, YYYY, MM1, DD );
								for ( let x: number = 0; x < arr.length; ++x ) {
									if ( ticketsSold <= arr[x].count ) {
										lowestPrice = Math.min( lowestPrice, arr[x].price );
									}
								}
							}
						}
					}
				} );
			}
		}
		return lowestPrice;
	}

	private updateLowestPrices(): void { //
		Object.keys( this.passIDToPassProps ).forEach( (passID: string): void => {
			const doclet: InterfaceOWDoclet = this.passIDToPassProps[passID].doclet;
			doclet.data['__lowestPrice'] = this.getLowestPrice( doclet );
		} );
	}

	public daySelected( selectedYMD: InterfaceYYYYMM1DD ): void {
		document.activeElement instanceof HTMLElement && document.activeElement.blur();
		this.didPickDate = true;
		this.selectedDate = selectedYMD;
	}
}
