<span class="check-box-wrapper">
	<span [ngClass]="{
		'check-box' : true,
		'checked' : isChecked,
		'error' : error
	}" (click)="toggle();">
		<img alt="" src="/assets/img/ui-icon/check.png" />
	</span>
	<span class="check-box-display">
		<span style="white-space: nowrap;"><span (click)="toggle();">I have read and agree to the&nbsp;</span><a style="color: #007EFA; text-decoration: none;" href="https://wildrivers.com/terms/" target="_blank">Terms &amp; Conditions</a>.</span>
	</span>
</span>
