<div class="calendar-listing">
	<div class="calendar-wrapper"
		*ngFor="let calIdx of arrCalendarDisplayIdx"
	>
		<div class="month-label">{{ monthLabels[ (displayMonth1 - 1 + calIdx) % 12 ] + ', ' + selectedYear }}</div>
		<ul class="weeks calendar-days" *ngIf="calendarData.length > calIdx">
			<li class="day-of-week">S</li>
			<li class="day-of-week">M</li>
			<li class="day-of-week">T</li>
			<li class="day-of-week">W</li>
			<li class="day-of-week">T</li>
			<li class="day-of-week">F</li>
			<li class="day-of-week">S</li>
			<li class="day empty" *ngFor="let leadingBlank of calendarData[calIdx].leadingBlanks;"></li>
			<li
				[ngClass]="{
					'day': true,
					'past': !(calendarData[calIdx].year > thisYear || calendarData[calIdx].month1 > thisMonth1 || day > thisDay || (calendarData[calIdx].year === thisYear && calendarData[calIdx].month1 === thisMonth1 && day === thisDay)),
					'unavailable': calendarData[calIdx].blockedDays[ day ],
					'selected': !calendarData[calIdx].blockedDays[ day ] && selectedYear === calendarData[calIdx].year && selectedMonth1 === calendarData[calIdx].month1 && selectedDay === day
				}"
				*ngFor="let day of calendarData[calIdx].daysInMonth;"
				(click)="selectDay( calendarData[calIdx].year, calendarData[calIdx].month1, day );"
			><b>{{ day }}</b><span
				[ngClass]="{
					'high logic-if-ticket-sold-count-is-on-last-tier': false,
					'low logic-if-ticket-sold-count-is-on-first-tier': false
				}"
				style="display: none;">$100</span></li>
			<li class="day empty" *ngFor="let trailingBlank of calendarData[calIdx].trailingBlanks;"></li>
		</ul>
	</div>
</div>
